// import package
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  useRoutes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import AuthRoute from "./components/Route/AuthRoute";
import HelperRoute from "./components/Route/HelperRoute";
import ConditionRoute from "./components/Route/ConditionRoute";
import Login from "./pages/Login.js";
import Register from "./pages/Register.js";
import Kyc from "./pages/Kyc.js";
import KycIndian from "./pages/KycIndian.js";
import Wallet from "./pages/Wallet.js";
import Mfee from "./pages/Mfee";
import WalletDeposit from "./pages/WalletDeposit.js";
import WalletWithdraw from "./pages/WalletWithdraw.js";
import WalletTransfer from "./pages/WalletTransfer.js";
import WalletTransferIndian from "./pages/WalletTransferIndian.js";
import WalletDepositIndian from "./pages/WalletDepositIndian.js";
import WalletWithdrawIndian from "./pages/WalletWithdrawIndian.js";
import Trade from "./pages/Trade.js";
import Dashboard from "./pages/Dashboard.js";
import NotificationSystemErrors from "./pages/NotificationSystemErrors.js";
import NotificationTradeRelated from "./pages/NotificationTradeRelated.js";
import NotificationAnnouncement from "./pages/NotificationAnnouncement.js";
import NotificationTipOffs from "./pages/NotificationTipOffs.js";
import NotificationAlerts from "./pages/NotificationAlerts.js";
import ApiBinding from "./pages/ApiBinding.js";
import EmailVerification from "./pages/EmailVerification.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import Profile from "./pages/Profile.js";
import Security from "./pages/Security.js";
import VideoTutorials from "./pages/VideoTutorials.js";
import Referral from "./pages/Referral.js";
import TradeZap from "./pages/TradeZap.js";
import TradeSmart from "./pages/TradeSmart.js";
import RevenueDetails from "./pages/RevenueDetails.js";
import ScholarDetails from "./pages/ScholarDetails.js";
import MentorDetails from "./pages/MentorDetails.js";
import ResetPassword from "./pages/ResetPassword.js";
import Strategysetting from "./pages/Strategysettting";
import IDCard from "./pages/IdCard";
import Sharemodal from "./components/ShareModalDownload";
// import routes from './routes'

// import action
import { decodeJwt } from "./actions/jsonWebToken";

// import lib
import store from "./store";
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";

// import Context
import SocketContext from "./components/Context/SocketContext";
// import config
import {
  socket,
  socketTickerBinance,
  socketTickerBitMart,
  socketTickerKucoin,
} from "./config/socketConnectivity";

function App() {
  const { isAuth } = store.getState().auth;

  useEffect(() => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <Router basename="/">
        <SocketContext.Provider
          value={{
            socket,
            socketTickerBinance,
            socketTickerBitMart,
            socketTickerKucoin,
          }}
        >
          <ToastContainer />
          <HelperRoute />
          <Routes>
            {/*{
        routes && routes.length>0 && Array.isArray(routes) && routes.map((route,key)=>{
          return (
                  <Route key={key} path={route.path} element={ 
                      <ConditionRoute type={route.type}> 
                          <route.element/>
                      </ConditionRoute> } 
                  />
            )
        })
      }*/}
            <Route
              path="/"
              element={
                <ConditionRoute type="auth">
                  <Register />
                </ConditionRoute>
              }
            />
            <Route
              path="/signin"
              element={
                <ConditionRoute type="auth">
                  <Login />
                </ConditionRoute>
              }
            />
            <Route
              path="/reset-password/:authToken"
              element={
                <ConditionRoute type="auth">
                  <ResetPassword />
                </ConditionRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <ConditionRoute type="auth">
                  <ForgotPassword />
                </ConditionRoute>
              }
            />
            <Route
              path="/kyc"
              element={
                <ConditionRoute type="private">
                  <Kyc />
                </ConditionRoute>
              }
            />
            <Route
              path="/kyc-indian"
              element={
                <ConditionRoute type="private">
                  <KycIndian />
                </ConditionRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <ConditionRoute type="private">
                  <Wallet />
                </ConditionRoute>
              }
            />

            <Route
              path="/mfee"
              element={
                <ConditionRoute type="private">
                  <Mfee />
                </ConditionRoute>
              }
            />

            <Route
              path="/wallet-deposit"
              element={
                <ConditionRoute type="private">
                  <WalletDeposit />
                </ConditionRoute>
              }
            />
            <Route
              path="/wallet-withdraw"
              element={
                <ConditionRoute type="private">
                  <WalletWithdraw />
                </ConditionRoute>
              }
            />
            <Route
              path="/wallet-transfer"
              element={
                <ConditionRoute type="private">
                  <WalletTransfer />
                </ConditionRoute>
              }
            />
            <Route
              path="/wallet-deposit-indian"
              element={
                <ConditionRoute type="private">
                  <WalletDepositIndian />
                </ConditionRoute>
              }
            />
            <Route
              path="/wallet-withdraw-indian"
              element={
                <ConditionRoute type="private">
                  <WalletWithdrawIndian />
                </ConditionRoute>
              }
            />
            <Route
              path="/wallet-transfer-indian"
              element={
                <ConditionRoute type="private">
                  <WalletTransferIndian />
                </ConditionRoute>
              }
            />
            <Route
              path="/trade/:pair/:exchange/:bot"
              element={
                <ConditionRoute type="private">
                  <Trade />
                </ConditionRoute>
              }
            />
            <Route
              path="/trade-zap"
              element={
                <ConditionRoute type="private">
                  <TradeZap />
                </ConditionRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ConditionRoute type="private">
                  <Dashboard />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-system-errors"
              element={
                <ConditionRoute type="private">
                  <NotificationSystemErrors />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-trade-related"
              element={
                <ConditionRoute type="private">
                  <NotificationTradeRelated />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-announcement"
              element={
                <ConditionRoute type="private">
                  <NotificationAnnouncement />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-tip-offs"
              element={
                <ConditionRoute type="private">
                  <NotificationTipOffs />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-alerts"
              element={
                <ConditionRoute type="private">
                  <NotificationAlerts />
                </ConditionRoute>
              }
            />
            <Route
              path="/api-binding"
              element={
                <ConditionRoute type="private">
                  <ApiBinding />
                </ConditionRoute>
              }
            />
            <Route
              path="/email-verification/:authToken"
              element={
                <ConditionRoute type="auth">
                  <EmailVerification />
                </ConditionRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ConditionRoute type="private">
                  <Profile />
                </ConditionRoute>
              }
            />

            <Route
              path="/security"
              element={
                <ConditionRoute type="private">
                  <Security />
                </ConditionRoute>
              }
            />
            <Route
              path="/referral"
              element={
                <ConditionRoute type="private">
                  <Referral />
                </ConditionRoute>
              }
            />
            <Route
              path="/strategy-setting"
              element={
                <ConditionRoute type="private">
                  <Strategysetting />
                </ConditionRoute>
              }
            />
            <Route
              path="/video-tutorials"
              element={
                <ConditionRoute type="private">
                  <VideoTutorials />
                </ConditionRoute>
              }
            />

            <Route
              path="/revenue-details"
              element={
                <ConditionRoute type="private">
                  <RevenueDetails />
                </ConditionRoute>
              }
            />
            <Route
              path="/scholar-details"
              element={
                <ConditionRoute type="private">
                  <ScholarDetails />
                </ConditionRoute>
              }
            />
            <Route
              path="/mentor-details"
              element={
                <ConditionRoute type="private">
                  <MentorDetails />
                </ConditionRoute>
              }
            />

            <Route
              path="/idcard"
              element={
                <ConditionRoute type="public">
                  <IDCard />
                </ConditionRoute>
              }
            />
            <Route
              path="/sharemodal"
              element={
                <ConditionRoute type="public">
                  <Sharemodal />
                </ConditionRoute>
              }
            />
          </Routes>
        </SocketContext.Provider>
      </Router>
    </Provider>
  );
}
export default App;
