import React, { useEffect } from "react";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Binding(props) {
  const { result } = props
  return (
  <>
    <h2>API Binding</h2>
    <ul class="nav nav-pills nav-fill mb-3 api_binding_tab mt-4" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id={result} data-bs-toggle="pill" onClick={() => result("Binance")} data-bs-target="#api_binding01" type="button" role="tab" aria-controls="api_binding01" aria-selected="true">
          <img src={require("../../assets/images/api_logo_01.png")} alt="API" className="img-fluid" />
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id={result} data-bs-toggle="pill" onClick={() => result("Kucoin")} data-bs-target="#api_binding02" type="button" role="tab" aria-controls="api_binding02" aria-selected="false">
          <img src={require("../../assets/images/api_logo_02.png")} alt="API" className="img-fluid" />
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id={result} data-bs-toggle="pill" onClick={() => result("BitMart")} data-bs-target="#api_binding_03" type="button" role="tab" aria-controls="api_binding_03" aria-selected="false">
          <img src={require("../../assets/images/api_logo_03.png")} alt="API" className="img-fluid" />
        </button>
      </li>                      
    </ul>
  </>

  );
}
