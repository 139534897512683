import BotBalance from "./BotBalance"
import BotType from "./BotType"
import PNL from "./PNL"
import TradeHistory from "./TradeHistory"

export default function Dasboard() {

return(
<div className="col-lg-9 col-md-12">
    <div className="dashboardTwoColumn">
      <div className="row">
        <div className="col-md-6">
        
        <BotBalance />
        <BotType />
        </div>
        <PNL />
    </div>
    <TradeHistory />
  </div>
</div>

)
}              