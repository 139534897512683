import React, { useEffect } from "react";
import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";

export default function TransferHistory(props) {
  const { TxnList, nationality, fetchtransferHistory } = props;

  const handleChange = async (e) => {
    const { value } = e.target;
    let reqData = {
      filter: value,
    };
    fetchtransferHistory(reqData);
  };

  const columns = [
    {
      name: "Date",
      width: "150px",
      selector: (row) => dateTimeFormat(row.createdAt),
    },
    {
      name: "Amount (INR)",
      width: "150px",
      selector: (row) => {
        // console.log(row, "_______row");
        return <>{row.amount}</>;
      },
    },
    {
      name: "Transfer Type",
      width: "150px",
      selector: (row) => {
        return <span>{row.transferType}</span>;
      },
    },
    {
      name: "Transferred by",
      width: "150px",
      selector: (row) => {
        if (row.userId && row.AdminId) {
          return <span>Admin</span>;
        } else if (row.userId) {
          return <span>User</span>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return <span className="greenText">{row.Status}</span>;
      },
    },
  ];

  const noncolumns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
    },
    {
      name: "Amount (USDT)",
      selector: (row) => {
        return <>{row.amount}</>;
      },
    },
    {
      name: "Transfer Type",
      width: "150px",
      selector: (row) => {
        return <span>{row.transferType}</span>;
      },
    },
    {
      name: "Transferred by",
      width: "150px",
      selector: (row) => {
        if (row.userId && row.AdminId) {
          return <span>Admin</span>;
        } else if (row.userId) {
          return <span>User</span>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return <span className="greenText">{row.Status}</span>;
      },
    },
  ];
  return (
    <div className="userContent mt-4">
      <div className="flexTitle">
        <h2>Transfer Record</h2>
        <select className="form-select filterSelect" onChange={handleChange}>
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="week">Last week</option>
          <option value="month">Last 1 month</option>
          <option value="year">Last year</option>
        </select>
      </div>
      <div class="table-responsive">
        <div class="table-responsive">
          {/* {nationality == "nonindian" && (
            <table class="table table-bordered customTable">
              <tr>
                <th>Date</th>
                <th>Amount (USDT)</th>
                <th>Status</th>
              </tr>
              {TxnList &&
                TxnList.length > 0 &&
                TxnList.map((item, key) => {
                  let status = item.Status;
                  let color = "";
                  if (status == "Pending") {
                    color = "yellowText";
                  } else if (status == "Rejected") {
                    color = "redText";
                  } else if (status == "Approved" || status == "Completed") {
                    color = "greenText";
                  }

                  return (
                    <tr>
                      <td>{dateTimeFormat(item.createdAt)}</td>
                      <td>{item.amount}</td>
                      <td className="greenText">{item.Status}</td>
                    </tr>
                  );
                })}
            </table>
          )} */}

          {/* {nationality == "indian" && (
            <table class="table table-bordered customTable">
              <tr>
                <th>Date</th>
                <th>Amount (INR)</th>
                <th>Status</th>
              </tr>
              {TxnList &&
                TxnList.length > 0 &&
                TxnList.map((item, key) => {
                  let status = item.Status;
                  let color = "";
                  if (status == "Pending") {
                    color = "yellowText";
                  } else if (status == "Rejected") {
                    color = "redText";
                  } else if (status == "Approved" || status == "Completed") {
                    color = "greenText";
                  }

                  return (
                    <tr>
                      <td>{dateTimeFormat(item.createdAt)}</td>
                      <td>{item.amount}</td>
                      <td className="greenText">{item.Status}</td>
                    </tr>
                  );
                })}
            </table>
          )} */}
          <div className="dashboardContent mt-4">
            <div className="primary_table">
              {nationality == "indian" && (
                <DataTable columns={columns} data={TxnList} pagination />
              )}
              {nationality == "nonindian" && (
                <DataTable columns={noncolumns} data={TxnList} pagination />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
