// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (data) => {
  if (data.nationality == "nonindian") {
    return DepositValid_NonIndia(data);
  } else if (data.nationality == "indian") {
    return DepositValid_India(data);
  }
};

export const DepositValid_NonIndia = (data) => {
  let errors = {};
  console.log(data, "--------15");

  let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;

  if (isEmpty(data.tokenType)) {
    errors.tokenType = "Please Select Chain";
  }

  if (isEmpty(data.depositType) || data.depositType == "") {
    errors.depositType = "Please Select Purpose";
  }

  if (isEmpty(data.amount)) {
    errors.amount = "Please Enter Amount";
  } else if (isNaN(data.amount)) {
    errors.amount = "ALLOW NUMERIC";
  } else if (parseInt(data.amount) <= 0) {
    errors.amount = "Invalid value";
  }

  // if (isEmpty(data.docName)) {
  //   errors.docName = "Please Enter Screenshot Name";
  // }

  if (data.docImage && data.docImage.size) {
    if (data.docImage.size > 10000000) {
      // 10 MB
      errors.docImage = "Please Select Less than 10MB";
    } else if (!imageFormat.test(data.docImage.name)) {
      errors.docImage = "Please Select only JPG/JPEG/PNG/PDF";
    }
  } else {
    errors.docImage = "Please Upload Screenshot Image";
  }

  return errors;
};

export const DepositValid_India = (data) => {
  let errors = {};
  console.log(data, "--------15");

  let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;

  if (isEmpty(data.bank) && isEmpty(data.tokenType)) {
    errors.bank = "Please Select Bank or Coin";
  }
  if (isEmpty(data.amount)) {
    errors.amount = "Please Enter Amount";
  } else if (isNaN(data.amount)) {
    errors.amount = "ALLOW NUMERIC";
  } else if (parseInt(data.amount) <= 0) {
    errors.amount = "Invalid value";
  }

  if (isEmpty(data.depositType) || data.depositType == "") {
    errors.depositType = "Please Select Purpose";
  }

  // if (isEmpty(data.docName)) {
  //   errors.docName = "Please Enter Screenshot Name";
  // }

  if (data.docImage && data.docImage.size) {
    if (data.docImage.size > 10000000) {
      // 10 MB
      errors.docImage = "Please Select Less than 10MB";
    } else if (!imageFormat.test(data.docImage.name)) {
      errors.docImage = "Please Select only JPG/JPEG/PNG/PDF";
    }
  } else {
    errors.docImage = "Please Upload Screenshot Image";
  }

  return errors;
};

export const WithdrawValid_NonIndia = (data) => {
  let errors = {};
  console.log(data, "--------15");

  if (isEmpty(data.amount)) {
    errors.amount = "Please Enter Amount";
  } else if (isNaN(data.amount)) {
    errors.amount = "ALLOW NUMERIC";
  }
  // else if (parseInt(data.amount) <= 0) {
  //   errors.amount = "Invalid value";
  // }

  return errors;
};

export const WithdrawValid_India = (data) => {
  let errors = {};
  console.log(data, "--------15");

  if (isEmpty(data.amount)) {
    errors.amount = "Please Enter Amount";
  } else if (isNaN(data.amount)) {
    errors.amount = "ALLOW NUMERIC";
  }
  // else if (parseInt(data.amount) <= 0) {
  //   errors.amount = "Invalid value";
  // }

  return errors;
};

export default validation;
