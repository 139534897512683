import React, { useEffect } from "react";

import ProfilePage from "../components/Profile";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Profile() {

  return (
    <>
      <ScrollToTopOnMount />
      <ProfilePage />
    </>

  );
}
