import React, { useRef, useEffect, useState, useContext } from "react";
import clsx from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NewBotModal from "../components/NewBotModal";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { dateTimeFormat } from "../lib/dateFilter";
// import action
import {
  logout,
  setTradeTheme,
  getPanicDet,
  changePanic,
  getNotifCount,
} from "../actions/users";

// import lib
import { firstCapital } from "../lib/String";
import { toastAlert } from "../lib/toastAlert";

// import context
import SocketContext from "../components/Context/SocketContext";

import NotificationSound from "../notification-sound.mp3";

import Logo from "../components/Logo";

export default function HeaderDashboard() {
  const audioPlayer = useRef(null);
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const data = { bot: "Smart", exchange: "Binance", pair: "BTC-USDT" };
  const initialFormValue = {
    showBalance: false,
    showId: false,
    showEmail: false,
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const { showId, showEmail } = formValue;
  const [notiCount, setNotiCount] = useState();
  const { showBalance } = formValue;
  // redux-state
  const accountData = useSelector((state) => state.account);
  let { exchangetype } = useSelector((state) => state.exchange);
  let { botType } = useSelector((state) => state.bot);
  const tradeThemeData = useSelector((state) => state.tradeThemeReducers);

  // state
  const [panStatus, setPanStat] = useState();

  useEffect(() => {
    fetchAllow();
    fetchNotiCount();
    socketContext.socket.on("notiCount", (result) => {
      setNotiCount(result.count);
      if (
        result.count > 0 &&
        ((location.pathname != "/notification-trade-related" &&
          result.dataType == "systemError") ||
          (location.pathname != "/notification-system-errors" &&
            result.dataType == "tradeRelated"))
      ) {
        playaudio();
      }
    });
  }, []);
  const playaudio = async () => {
    audioPlayer.current.play();
  };
  const fetchNotiCount = async () => {
    try {
      const { status, result } = await getNotifCount();
      if (status == "success") {
        setNotiCount(result.count);
      }
    } catch (err) {}
  };
  const fetchAllow = async () => {
    try {
      const { status, result } = await getPanicDet();
      if (status == "success") {
        setPanStat(result.panicStatus);
      }
    } catch (err) {}
  };
  const handlePanic = async (e) => {
    try {
      let reqData = {
        exchange: exchangetype,
        bot: botType,
      };
      const { status, result, message } = await changePanic(reqData);
      if (status == "success") {
        toastAlert("success", message);
        setPanStat(result.panicStatus);
        fetchAllow();
      } else {
        toastAlert("error", message);
      }
    } catch (err) {
      console.log(err, "-------89");
    }
  };

  // useEffect(() => {
  //   loadScript();
  // }, []);

  // function loadScript() {
  //   $(".theme_button").off("click");
  //   $(".theme_button").on("click", function () {
  //     $("body").toggleClass("light_theme");
  //     $(".theme_button i").toggleClass("d-none");
  //   });
  // }
  const checkPanic = (status) => {
    try {
      if (status) {
        return "OFF";
      } else {
        return "ON";
      }
    } catch (err) {}
  };
  const logoutUser = (e) => {
    e.preventDefault();
    logout(navigate, dispatch);
    toastAlert("success", "Logout Successfully");
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top userTopMenu trade_menu">
        <div className="container">
          <Logo />
          <div className="d-flex">
            <ul className="topProfileDropdown topProfileDropdown_mobile">
              <li className="nav-item">
                <a
                  src={tradeThemeData == "light" ? true : false}
                  name=""
                  onClick={() =>
                    setTradeTheme(
                      dispatch,
                      tradeThemeData == "light" ? "dark" : "light"
                    )
                  }
                  className="theme_button menu_round_btn"
                >
                  {tradeThemeData == "dark" && <i class="bi bi-moon-fill"></i>}
                  {tradeThemeData == "light" && <i class="bi bi-sun-fill"></i>}
                </a>
              </li>
              {/* <li className="nav-item"><a href="javascript:void(0)" className="notification_link menu_round_btn"><i class="bi bi-bell"></i></a></li> */}
            </ul>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <input
                type="checkbox"
                id="checkbox4"
                class="checkbox4 visuallyHidden"
              />
              <label for="checkbox4">
                <div class="hamburger hamburger4">
                  <span class="bar bar1"></span>
                  <span class="bar bar2"></span>
                  <span class="bar bar3"></span>
                  <span class="bar bar4"></span>
                  <span class="bar bar5"></span>
                </div>
              </label>
            </button>
          </div>
          <div
            className="collapse navbar-collapse profileSideMenu trade_sidemenu"
            id="navbarSupportedContent"
          >
            <div className="profileSideMenuBottom">
              <button
                className="navbar-toggler d-block d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <input
                  type="checkbox"
                  id="checkbox4"
                  class="checkbox4 visuallyHidden"
                />
                <label for="checkbox4">
                  <div class="hamburger hamburger4">
                    <span class="bar bar1"></span>
                    <span class="bar bar2"></span>
                    <span class="bar bar3"></span>
                    <span class="bar bar4"></span>
                    <span class="bar bar5"></span>
                  </div>
                </label>
              </button>
              <div className="profileSideMenuTop d_d_none">
                <h3>
                  {firstCapital(accountData.firstName)}
                  {accountData.lastName}
                </h3>
                <div className="profileSideMenuTop_eye">
                  <p className="mb-0">
                    {showEmail == false ? (
                      <a href="#">****************</a>
                    ) : (
                      <a href="#">{accountData && accountData.email}</a>
                    )}
                  </p>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return {
                          ...el,
                          ...{ showEmail: !el.showEmail },
                        };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showEmail },
                        { "bi-eye-slash": !showEmail }
                      )}
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
                <div className="kyc_verify">
                  <img
                    src={require("../assets/images/kyc_verified_icon.png")}
                    alt="Icon"
                    className="me-2"
                  />{" "}
                  <span>
                    {firstCapital(accountData && accountData.kycStatus)}
                  </span>
                </div>
                <div className="profileSideMenuTop_eye">
                  <p className="mb-0">
                    {showId == false ? (
                      <small>UserId: **********</small>
                    ) : (
                      <small>UserId: {accountData && accountData.userId}</small>
                    )}{" "}
                  </p>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return {
                          ...el,
                          ...{ showId: !el.showId },
                        };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showId },
                        { "bi-eye-slash": !showId }
                      )}
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
                <p className="mb-0">
                  <small>
                    Last Login Time:{" "}
                    {accountData &&
                      accountData.loginhistory &&
                      dateTimeFormat(
                        accountData.loginhistory[
                          accountData.loginhistory.length - 1
                        ].createdDate
                      )}
                  </small>
                </p>
              </div>
              <ul className="navbar-nav topProfileDropdown">
                <li className="nav-item">
                  <button
                    className="panic_btn"
                    onClick={handlePanic}
                    id="app-title"
                  >
                    <img
                      src={
                        panStatus
                          ? require("../assets/images/pause_panic_icon.png")
                          : require("../assets/images/start_panic_icon.png")
                      }
                      alt="Icon"
                      className="img-fluid"
                    />
                    {/* <img src={require("../assets/images/pause_panic_icon.png")} alt="Icon" className="img-fluid"/> */}
                    {panStatus && panStatus && <span>Stop Panic</span>}
                    {!panStatus && <span>Start Panic</span>}
                  </button>
                  <ReactTooltip
                    className="custom_tooltip"
                    anchorId="app-title"
                    content={`Your Panic Status is ${checkPanic(!panStatus)}`}
                  />
                </li>
                <li className="nav-item">
                  <button
                    className="new_bot_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#new_bot_modal"
                  >
                    Mini Bots
                  </button>
                </li>
                <li className="nav-item d_m_none">
                  <a
                    src={tradeThemeData == "light" ? true : false}
                    name=""
                    onClick={() =>
                      setTradeTheme(
                        dispatch,
                        tradeThemeData == "light" ? "dark" : "light"
                      )
                    }
                    className="theme_button menu_round_btn"
                  >
                    {tradeThemeData == "dark" && (
                      <i class="bi bi-moon-fill"></i>
                    )}
                    {tradeThemeData == "light" && (
                      <i class="bi bi-sun-fill"></i>
                    )}
                  </a>
                </li>
                <li className="nav-item d_m_none">
                  <a
                    href="/notification-trade-related"
                    className="notification_link menu_round_btn"
                  >
                    <i class="bi bi-bell"></i>
                    {notiCount > 0 && <sup>{notiCount}</sup>}
                  </a>
                </li>
                <li className="nav-item dropdown d_m_none profile_email">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return {
                          ...el,
                          ...{ showBalance: !el.showBalance },
                        };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showBalance },
                        { "bi-eye-slash": !showBalance }
                      )}
                      aria-hidden="true"
                    ></i>
                  </Link>
                  <a
                    className="nav-link dropdown-toggle"
                    href="javscript:void(0)"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {!showBalance && "**********"}
                    {showBalance && accountData && accountData.email}
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end profile_dropdown"
                    aria-labelledby="navbarDropdown"
                  >
                    {/* <li className="demo_list">
                      <label className="dropdown-item">
                        <span><img src={require("../assets/images/dropdown_icon_01.png")} alt="Icon" className="img-fluid"/>Demo</span>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                        </div>
                      </label>
                    </li> */}
                    <li>
                      <Link to="/dashboard" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_07.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/wallet" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_03.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Wallet
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_02.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        My Profile
                      </Link>
                    </li>
                    {/* <li><Link to="#" className="dropdown-item"><img src={require("../assets/images/dropdown_icon_04.png")} alt="Icon" className="img-fluid" />Referral</Link></li>
                    <li><Link to="#" className="dropdown-item"><img src={require("../assets/images/dropdown_icon_05.png")} alt="Icon" className="img-fluid" />Settings</Link></li> */}
                    <li>
                      <Link to="/security" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_09.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Security
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/notification-trade-related"
                        className="dropdown-item"
                      >
                        <img
                          src={require("../assets/images/dropdown_icon_08.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Notification
                      </Link>
                    </li>
                    <li>
                      <Link to="/kyc" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_11.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        KYC Verification
                      </Link>
                    </li>
                    <li>
                      <Link to="/api-binding" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_10.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        API Binding
                      </Link>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        target="_blank"
                        href="https://ugainindia.com/Userguide.html"
                      >
                        <img
                          src={require("../assets/images/menuIcon-14.png")}
                          alt="Icon"
                        />{" "}
                        User’s Guide
                      </a>
                    </li>
                    <li>
                      <Link to="/video-tutorials" className="dropdown-item">
                        <img
                          src={require("../assets/images/menuIcon-15.png")}
                          alt="Icon"
                        />{" "}
                        Video Tutorials
                      </Link>
                    </li>
                    <li>
                      <Link to="/referral" className="dropdown-item">
                        <img
                          src={require("../assets/images/menuIcon-16.png")}
                          alt="Icon"
                        />{" "}
                        Invite a Friend
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/strategy-setting"
                        state={{ type: data }}
                        className="nav-link"
                      >
                        <img
                          src={require("../assets/images/menuIcon-10.png")}
                          alt="Icon"
                        />{" "}
                        Strategy Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="javscript:void(0)"
                        className="dropdown-item"
                        onClick={logoutUser}
                      >
                        <img
                          src={require("../assets/images/dropdown_icon_06.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item demo_list d_d_none">
                  <label className="nav-link">
                    <span><img src={require("../assets/images/dropdown_icon_01.png")} alt="Icon" className="img-fluid" />Demo</span>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                    </div>
                  </label>
                </li> */}
                <li className="nav-item d_d_none">
                  <Link to="/dashboard" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_07.png")}
                      alt="Icon"
                    />
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/wallet" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_03.png")}
                      alt="Icon"
                    />
                    Wallet
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/profile" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_02.png")}
                      alt="Icon"
                    />
                    My Profile
                  </Link>
                </li>
                {/* <li className="nav-item d_d_none"><Link to="#" className="nav-link"><img src={require("../assets/images/dropdown_icon_04.png")} alt="Icon" />Referral</Link></li>
                <li className="nav-item d_d_none"><Link to="#" className="nav-link"><img src={require("../assets/images/dropdown_icon_05.png")} alt="Icon" />Settings</Link></li> */}
                <li className="nav-item d_d_none">
                  <Link to="/security" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_09.png")}
                      alt="Icon"
                    />
                    Security
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/notification-trade-related" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_08.png")}
                      alt="Icon"
                    />
                    Notification
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/kyc" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_11.png")}
                      alt="Icon"
                    />
                    KYC Verification
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/api-binding" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_10.png")}
                      alt="Icon"
                    />
                    API Binding
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://ugainindia.com/Userguide.html"
                  >
                    <img
                      src={require("../assets/images/menuIcon-14.png")}
                      alt="Icon"
                    />{" "}
                    User’s Guide
                  </a>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/video-tutorials" className="nav-link">
                    <img
                      src={require("../assets/images/menuIcon-15.png")}
                      alt="Icon"
                    />{" "}
                    Video Tutorials
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/referral" className="nav-link">
                    <img
                      src={require("../assets/images/menuIcon-16.png")}
                      alt="Icon"
                    />{" "}
                    Invite a Friend
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/strategy-setting"
                    state={{ type: data }}
                    className="nav-link"
                  >
                    <img
                      src={require("../assets/images/menuIcon-10.png")}
                      alt="Icon"
                    />{" "}
                    Strategy Settings
                  </Link>
                </li> */}
                <li className="nav-item d_d_none">
                  <Link
                    to="/strategy-setting"
                    state={{ type: data }}
                    className="nav-link"
                  >
                    <img
                      src={require("../assets/images/menuIcon-10.png")}
                      alt="Icon"
                    />{" "}
                    Strategy Settings
                  </Link>
                </li>

                <li className="nav-item d_d_none">
                  <Link
                    to="javscript:void(0)"
                    className="nav-link"
                    onClick={logoutUser}
                  >
                    <img
                      src={require("../assets/images/dropdown_icon_06.png")}
                      alt="Icon"
                    />
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <audio ref={audioPlayer} src={NotificationSound} />
      </nav>

      <NewBotModal />
    </>
  );
}
