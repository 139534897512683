import React, { useEffect, useState, Fragment } from "react";
import Header from "../components/Header";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer";
import $ from "jquery";
import { toastAlert } from "../lib/toastAlert";
import {
  AddStrategy,
  getAllStrategy,
  DeleteStrategy,
  UpdateStrategy,
} from "../actions/Bottrade";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import validation from "../components/BotTrade/validation";
import isEmpty from "../lib/isEmpty";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const Datas = {
  firstbuyAmount: "",
  margincallLimit: "",
  takeprofitRatio: "",
  takeprofitCallback: "",
  buyinCallback: "",
  marginCall: "",
  Ratio: "",
  AverageCallDrop: "",
};
const initialFormvalue = {
  Name: "",
  RiskType: "conservative",
};
export default function Profile(props) {
  const [tradedata, setTradeData] = useState(Datas);
  const [inputFields, setInputFields] = useState([]);
  const [validErr, setValidErr] = useState({});
  const [data, setData] = useState("");
  const [formValue, setFormValue] = useState(initialFormvalue);
  const [modalclose, setmodalclose] = useState(false);
  const location = useLocation();
  const { type } = location.state;

  console.log(type, "_fromfromfrom");
  let {
    firstbuyAmount,
    margincallLimit,
    takeprofitRatio,
    takeprofitCallback,
    buyinCallback,
    AverageCallDrop,
  } = tradedata;
  const history = useNavigate();
  let { Name, RiskType } = formValue;
  const params = useParams();

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputFields([]);
    let dataArr = [];
    for (let i = 0; i < value; i++) {
      dataArr.push({
        marginCall: "",
        Ratio: "",
      });
    }
    setInputFields(dataArr);
    setTradeData({ ...tradedata, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    // if (
    //   name == "takeprofitRatio" ||
    //   name == "takeprofitCallback" ||
    //   name == "buyinCallback"
    // ) {
    //   let str = value.toString();
    //   let convertStr = str.split(".");
    //   if (
    //     convertStr[1] &&
    //     convertStr[1].length > 2 &&
    //     process.env.REACT_APP_MODE === "demo"
    //   ) {
    //     return;
    //   }
    // }
    setTradeData({ ...tradedata, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };

  const handleName = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };

  const handleOnChange = (index, e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const values = [...inputFields];
    values[index][name] = value;
    // if (name == "marginCall") {
    //   let str = value.toString();
    //   let convertStr = str.split(".");
    //   if (
    //     convertStr[1] &&
    //     convertStr[1].length > 2 &&
    //     process.env.REACT_APP_MODE === "demo"
    //   ) {
    //     return;
    //   }
    // }
    setInputFields(values);
    if (value) {
      setValidErr({});
    }
  };

  const handleDelete = async (id) => {
    let datas = { id: id };
    let { status, data, result, message } = await DeleteStrategy(datas);
    if (status == "success") {
      toastAlert("success", message, "save");
      fetchTradeSetting();
      setData(data);
    } else {
    }
  };

  const handleEditRecord = async (record) => {
    setTradeData(record.Tradestrategy[0]);
    setFormValue(record);
    setInputFields(record.Tradestrategy[0].customAmountSetup);
  };

  const gotoTrade = async () => {
    const url = "/trade/" + type.pair + "/" + type.exchange + "/" + type.bot;
    window.location.href = url;
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    let tradeArr = [];
    tradeArr.push({
      firstbuyAmount: tradedata.firstbuyAmount,
      margincallLimit: tradedata.margincallLimit,
      takeprofitRatio: tradedata.takeprofitRatio,
      takeprofitCallback: tradedata.takeprofitCallback,
      buyinCallback: tradedata.buyinCallback,
      AverageCallDrop: tradedata.AverageCallDrop,
      customAmountSetup: inputFields,
    });
    let data = {
      Name: Name,
      RiskType: RiskType,
      tradesetting: tradeArr,
    };

    let valData = {
      takeprofitRatio: tradedata.takeprofitRatio,
      takeprofitCallback: tradedata.takeprofitCallback,
      buyinCallback: tradedata.buyinCallback,
      firstbuyAmount: tradedata.firstbuyAmount,
      formType: "smartSettingpop",
      margincallLimit: tradedata.margincallLimit,
      Name: Name,
    };
    let validationError = validation(valData);
    if (!isEmpty(validationError)) {
      setValidErr(validationError);
      return;
    }

    let { status, message, error } = await AddStrategy(data);
    if (status == "success") {
      toastAlert("success", message, "save");
      // history(`/trade/${type.pair}/${type.exchange}/${type.bot}`);
      setmodalclose(true);
      setTradeData(Datas);
      setFormValue(initialFormvalue);
      setInputFields([]);
      fetchTradeSetting();
      setValidErr({});
      setTimeout(() => {
        gotoTrade();
      }, 1000);
    } else {
      setmodalclose(false);
      if (error) {
        setValidErr(error);
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();

    let tradeArr = [];
    tradeArr.push({
      firstbuyAmount: tradedata.firstbuyAmount,
      margincallLimit: tradedata.margincallLimit,
      takeprofitRatio: tradedata.takeprofitRatio,
      takeprofitCallback: tradedata.takeprofitCallback,
      buyinCallback: tradedata.buyinCallback,
      AverageCallDrop: tradedata.AverageCallDrop,
      customAmountSetup: inputFields,
    });
    let data = {
      Name: Name,
      RiskType: RiskType,
      id: formValue._id,
      tradesetting: tradeArr,
    };

    let valData = {
      takeprofitRatio: tradedata.takeprofitRatio,
      takeprofitCallback: tradedata.takeprofitCallback,
      buyinCallback: tradedata.buyinCallback,
      firstbuyAmount: tradedata.firstbuyAmount,
      formType: "smartSettingpop",
      margincallLimit: tradedata.margincallLimit,
      Name: Name,
    };
    let validationError = validation(valData);
    if (!isEmpty(validationError)) {
      setValidErr(validationError);
      return;
    }

    let { status, message, error } = await UpdateStrategy(data);
    if (status == "success") {
      // history(`/trade/${type.pair}/${type.exchange}/${type.bot}`);
      // gotoTrade();
      toastAlert("success", message, "save");
      setmodalclose(true);
      setTradeData("");
      setInputFields([]);
      fetchTradeSetting();
      setValidErr({});
      setTimeout(() => {
        gotoTrade();
      }, 1000);
    } else {
      setmodalclose(false);
      if (error) {
        setValidErr(error);
      }
    }
  };

  const handleClick = async () => {
    setTradeData(Datas);
    setFormValue(initialFormvalue);
    setInputFields([]);
    fetchTradeSetting();
    setValidErr({});
  };

  const fetchTradeSetting = async () => {
    try {
      const { status, result } = await getAllStrategy();
      if (status == "success") {
        setData(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  function loadScript() {
    $("#custom_amt_setup").click(function () {
      $(".custom_amt_setup_panel").show();
      $(".trade_new_strategy_settings").hide();
    });
    $(".custom_amt_setup_back").click(function () {
      $(".custom_amt_setup_panel").hide();
      $(".trade_new_strategy_settings").show();
    });
  }

  useEffect(() => {
    fetchTradeSetting();
    loadScript();
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="userContent">
                {/* <button
                  className="edit_btn"
                  onClick={() => history("/trade/BTC-USDT/Binance/Smart")}
                >
                  Go Back
                </button> */}
                <div className="flexTitle profileTitleFlex">
                  <h2>Strategy Setting</h2>
                  <button
                    className="edit_btn"
                    id="strategy_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#strategy_modal"
                  >
                    <i className="bi bi-pencil-fill"></i> Add
                  </button>
                </div>
                {/* Trade New Strategy Settings Add*/}
                <div
                  class="modal fade primary_modal"
                  id="strategy_modal"
                  data-bs-backdrop="static"
                  tabindex="-1"
                  aria-labelledby="strategy_modalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="strategy_modalLabel">
                          Strategy Settings
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          onClick={handleClick}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="card trade_card">
                          <div className="card-body">
                            <div className="card_body_padding">
                              <div className="new_strategy_settings_panel">
                                <label>Strategy Name</label>
                                <input
                                  type="text"
                                  className="form-control new_strategy_inp w-50 mt-1"
                                  name="Name"
                                  value={Name}
                                  onChange={handleName}
                                />
                                <span className="text-danger">
                                  {validErr.Name}
                                </span>
                                <div></div>
                                <label>Initial Buying Amount</label>
                                <input
                                  type="text"
                                  className="form-control new_strategy_inp"
                                  name="firstbuyAmount"
                                  value={tradedata.firstbuyAmount}
                                  onChange={handleChange}
                                />
                                <span className="text-danger">
                                  {validErr.firstbuyAmount}
                                </span>
                                <div></div>
                                <label>No. of Averaging Calls</label>
                                <input
                                  type="text"
                                  className="form-control new_strategy_inp"
                                  name="margincallLimit"
                                  onChange={handleInputChange}
                                  value={tradedata.margincallLimit}
                                />
                                <span className="text-danger">
                                  {validErr.margincallLimit}
                                </span>
                                <div></div>
                                <label>Take Profit Ratio</label>
                                <input
                                  type="text"
                                  className="form-control new_strategy_inp"
                                  name="takeprofitRatio"
                                  value={tradedata.takeprofitRatio}
                                  onChange={handleChange}
                                />
                                <span className="text-danger">
                                  {validErr.takeprofitRatio}
                                </span>
                                <div></div>
                                <label>Sell Pull-Back Rate</label>
                                <input
                                  type="text"
                                  className="form-control new_strategy_inp"
                                  name="takeprofitCallback"
                                  value={tradedata.takeprofitCallback}
                                  onChange={handleChange}
                                />
                                <span className="text-danger">
                                  {validErr.takeprofitCallback}
                                </span>
                                <div></div>
                                {/* <div id="custom_amt_setup">
                                  <label>Custom Amount Setup</label>
                                  <i class="bi bi-chevron-right"></i>
                                </div>
                                <span className="text-danger">
                                  {validErr.customAmountSetup}
                                </span>
                                <div></div> */}
                                {/* <div
                                  className="custom_amt_setup_panel"
                                  style={{ display: "none" }}
                                > */}
                                {/* <div className="card trade_card"> */}
                                {/* <div className="card-header"> */}
                                <label>
                                  {/* <i class="bi bi-chevron-left me-2"></i>{" "} */}
                                  Custom Amount Setup
                                </label>
                                {/* </div> */}
                                <div className="card-body">
                                  <div className="card_body_padding">
                                    <div className="custom_amt_setup_div">
                                      {/* <div className="row">
                                            <div className="col-lg-6  col-md-6 col-6">
                                              <label>Averaging call drop</label>
                                            </div>
                                            <div className="col-lg-6  col-md-6 col-6">
                                              <label>Times</label>
                                            </div>
                                          </div> */}
                                      {inputFields.map((data, index) => {
                                        return (
                                          <Fragment key={`${data}~${index}`}>
                                            <div className="row">
                                              <div className="col-lg-6  col-md-6 col-6">
                                                <label>
                                                  Averaging call drop
                                                </label>
                                                <div class="input-group">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    value={data.Ratio}
                                                    name="Ratio"
                                                    onChange={(e) =>
                                                      handleOnChange(index, e)
                                                    }
                                                  />
                                                  <div class="input-group-text inp_grp_width">
                                                    %
                                                  </div>
                                                  <span className="text-danger">
                                                    {validErr &&
                                                      validErr.tradeSetting &&
                                                      validErr.tradeSetting
                                                        .length > 0 && (
                                                        <span className="text-danger">
                                                          {
                                                            validErr
                                                              .tradeSetting[
                                                              index
                                                            ].Ratio
                                                          }
                                                        </span>
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-lg-6  col-md-6 col-6">
                                                <label>Times</label>
                                                <div class="input-group">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    value={data.marginCall}
                                                    name="marginCall"
                                                    onChange={(e) =>
                                                      handleOnChange(index, e)
                                                    }
                                                  />

                                                  <div class="input-group-text inp_grp_width">
                                                    Times
                                                  </div>
                                                  <span className="text-danger">
                                                    {validErr &&
                                                      validErr.tradeSetting &&
                                                      validErr.tradeSetting
                                                        .length > 0 && (
                                                        <span className="text-danger">
                                                          {
                                                            validErr
                                                              .tradeSetting[
                                                              index
                                                            ].marginCall
                                                          }
                                                        </span>
                                                      )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                                {/* </div> */}
                                <label>Buy Pull-Back Rate</label>
                                <input
                                  type="text"
                                  className="form-control new_strategy_inp"
                                  name="buyinCallback"
                                  value={tradedata.buyinCallback}
                                  onChange={handleChange}
                                />
                                <span className="text-danger">
                                  {validErr.buyinCallback}
                                </span>
                                <div></div>
                                {/* <label>RiskType</label>
                                <select
                                  className="form-select new_strategy_inp w-50 mt-6"
                                  name="RiskType"
                                  value={RiskType}
                                  onChange={handleName}
                                >
                                  <option value="conservative">
                                    Conservative
                                  </option>
                                  <option value="steady">Steady</option>
                                  <option value="radical">Radical</option>
                                </select>{" "}
                                <span className="text-danger">
                                  {validErr.RiskType}
                                </span> */}
                                <div></div>
                              </div>

                              {formValue &&
                              formValue._id &&
                              formValue._id.length > 0 ? (
                                <button
                                  className="primary_btn"
                                  onClick={handleEdit}
                                  data-bs-dismiss={modalclose ? "modal" : ""}
                                  // data-bs-dismiss="modal"
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  className="primary_btn #strategy_modal_back"
                                  onClick={handleAdd}
                                  data-bs-dismiss={modalclose ? "modal" : ""}
                                  // data-bs-dismiss="modal"
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Trade Strategy Settings List*/}

                <div className="form-card">
                  <h3>
                    <span>Strategy Setting List</span>
                  </h3>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="member_box">
                        <table class="table table-bordered customTable">
                          <tr>
                            <th>Strategy Name</th>

                            <th>Action</th>
                          </tr>
                          {data &&
                            data.length > 0 &&
                            data.map((item, key) => {
                              return (
                                <tr>
                                  <td>{item.Name}</td>
                                  <td>
                                    {" "}
                                    <button
                                      className="edit_btn"
                                      id="strategy_btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#strategy_modal"
                                      onClick={() => handleEditRecord(item)}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>{" "}
                                    <button
                                      className="edit_btn"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
