import React, { useEffect } from "react";
import Header from "../components/Header.js";
import WalletSideMenu from "../components/WalletSideMenu";
import WalletBalance from "../components/WalletBalance";
import WithdrawWalletIndian from "../components/WithdrawWalletIndian";
import Footer from "../components/Footer.js";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function WalletWithdraw() {

  return (
<main>
	 <ScrollToTopOnMount />
          <Header />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <WalletSideMenu />
                  <WithdrawWalletIndian />
                </div>
                <div className="col-lg-8">
                    <WalletBalance />
                  <div className="userContent mt-4">
                    <div className="flexTitle">
                      <h2>Withdrawal Record</h2>
                      <select className="form-select filterSelect">
                        <option selected>Today</option>
                        <option value="1">Last week</option>
                        <option value="2">Last 1 month</option>
                        <option value="3">Last year</option>
                      </select>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered customTable">
                        <tr>
                          <th>Date</th>
                          <th>Bank Name</th>
                          <th>Acc.No.</th>
                          <th>Amount (INR)</th>
                          <th>Status</th>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>1357.25</td>
                          <td className="greenText">Approved</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>47895.36</td>
                          <td className="greenText">Approved</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>236.47</td>
                          <td className="redText">Rejected</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>67895.10</td>
                          <td className="yellowText">Pending</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>1357.25</td>
                          <td className="greenText">Approved</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>47895.36</td>
                          <td className="greenText">Approved</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>236.47</td>
                          <td className="redText">Rejected</td>
                        </tr>
                        <tr>
                          <td>06-09-2022  16:22</td>
                          <td>ICICI</td>
                          <td>250002688845</td>
                          <td>67895.10</td>
                          <td className="yellowText">Pending</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
    </main>

  );
}
