import { combineReducers } from "redux";
import authReducer from "./authReducer";
// import errorReducer from "./errorReducers";

import account from "./account";
import kycValidate from "./kycValidate";
import tradeThemeReducers from "./tradeTheme";
import exchange from "./exchangeType";
import bot from "./botType";
export default combineReducers({
  auth: authReducer,
  account,
  kycValidate,
  exchange,
  bot,
  tradeThemeReducers,
});
