import React, { useEffect } from "react";
import HeaderDashboard from "../components/HeaderDashboard.js";
import Footer from "../components/Footer.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function IDCard() {

  return (
    <main>
      <ScrollToTopOnMount />
      <HeaderDashboard />
      <div className="page-content">
        <div className="container">
          <div className="id_wrapper">
            <div className="id_card">
              <img src={require("../assets/images/shape_top.png")} alt="User" />
                <img src={require("../assets/images/logo.png")} alt="User" className="logo" />
                  <h2>Identity Card</h2>
                  <div className="id_img">
                    <img src={require("../assets/images/user.png")} alt="User" />
                  </div>
                  <div className="user_info">
                    <h3>Ashish Malik</h3>
                    <h4>Rank: S1</h4>
                  </div>
                  <div className="user_details">
                    <h5>ID No.: UGNI23456</h5>
                    <h5>Tel: +91 9876541230</h5>
                    <h5>Expiry Date: 17 Dec 2022</h5>
                  </div>
                  <img src={require("../assets/images/shape_bottom.png")} alt="User" className="shape_bottom" />
                  </div>
                  <div className="print_btn">
                    <button onClick={() => window.print()}>Print</button>
                  </div>
                </div>
            </div>
          </div>
          <Footer />
         
        </main>

        );
}
