import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";

//import context
import SocketContext from "../Context/SocketContext";
//import api
import { getDashpairs } from "../../actions/Bottrade";
//config
import config from "../../config";
//import lib
import isEmpty from "../../lib/isEmpty";
import { firstCapital } from "../../lib/String";
import { toFixed } from "../../lib/roundOf";
import { firstOnCap } from "../../lib/String";
import { useDispatch, useSelector } from "react-redux";
import { SET_DASHBOARD_BOTTYPE } from "../../constant";

export default function BotType() {
  //state
  const [PairList, setPairList] = useState();
  const [botType, setBottype] = useState("GENIE");
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();
  let { exchangetype } = useSelector((state) => state.exchange);

  //function
  const handleClick = (e, bot) => {
    // dispatch({
    //   type: SET_DASHBOARD_BOTTYPE,
    //   bot: {
    //     botType,
    //   },
    // });
    setBottype(bot);
  };

  const fetchpairs = async () => {
    dispatch({
      type: SET_DASHBOARD_BOTTYPE,
      bot: {
        botType,
      },
    });
    let reqData = {
      bot: botType,
      exchange: exchangetype,
    };
    const { result } = await getDashpairs(reqData);

    let pairList = [];

    for (let item of result) {
      let avarage = item.avgPrice / item.quantity;

      let returnrate_per =
        ((item.markPrice * item.quantity - avarage * item.quantity) /
          (avarage * item.quantity)) *
        100;

      let profit = item.markPrice * item.quantity - avarage * item.quantity;

      pairList.push({
        ...item,

        profit: profit ? toFixed(profit, item.priceDecimal) : 0.0,
        returnrate_per: returnrate_per ? returnrate_per : 0.0,

        markPrice: toFixed(item.markPrice, item.priceDecimal),
        change: toFixed(item.change, item.priceDecimal),
      });
    }

    setPairList(pairList);
  };

  var settingsGeniePair = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchpairs();
    console.log(exchangetype, "---------94");
    let socketTicker = "socketTicker" + exchangetype;
    socketContext &&
      socketContext[socketTicker] &&
      socketContext[socketTicker].on("marketPrice", (result) => {
        setPairList((el) => {
          let pairList = [];
          if (el) {
            el.map((item) => {
              if (item.pairId == result.pairId) {
                let avarage = item.avgPrice / item.quantity;

                let returnrate_per =
                  ((result.data.markPrice * item.quantity -
                    avarage * item.quantity) /
                    (avarage * item.quantity)) *
                  100;

                let profit =
                  result.data.markPrice * item.quantity -
                  avarage * item.quantity;

                pairList.push({
                  ...item,

                  profit: profit ? toFixed(profit, item.priceDecimal) : 0.0,
                  returnrate_per: returnrate_per ? returnrate_per : 0.0,

                  markPrice: toFixed(result.data.markPrice, item.priceDecimal),
                  change: toFixed(result.data.change, item.priceDecimal),
                });
              } else {
                pairList.push(item);
              }
            });
          }
          return pairList;
        });
      });
    socketContext.socket.on("runningOrder", (result) => {
      fetchpairs();
    });
    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, [botType, exchangetype]);

  // console.log(PairList, "_________PairList");

  return (
    <div className="dashboardContent mt-4">
      <div className="dashboardContentTitleBar p-0">
        <ul class="nav nav-tabs dashboardTabs" id="pairTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="genie-tab"
              data-bs-toggle="tab"
              data-bs-target="#genie"
              type="button"
              role="tab"
              aria-controls="genie"
              aria-selected="true"
              onClick={(e) => handleClick(e, "GENIE")}
            >
              Genie
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="smart-tab"
              data-bs-toggle="tab"
              data-bs-target="#smart"
              type="button"
              role="tab"
              aria-controls="smart"
              aria-selected="false"
              onClick={(e) => handleClick(e, "SMART")}
            >
              Smart
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="zap-tab"
              data-bs-toggle="tab"
              data-bs-target="#zap"
              type="button"
              role="tab"
              aria-controls="zap"
              aria-selected="false"
              onClick={(e) => handleClick(e, "ZAP")}
            >
              Zap
            </button>
          </li>
        </ul>
      </div>
      <div className="dashboardPairDetails p-4">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="genie"
            role="tabpanel"
            aria-labelledby="genie-tab"
          >
            <Slider {...settingsGeniePair}>
              {isEmpty(PairList) && (
                <img
                  src={require("../../assets/images/genie.png")}
                  alt="Icon"
                  width="80"
                  className="img-fluid mx-auto d-block"
                />
              )}
              {PairList &&
                PairList.length > 0 &&
                PairList.map((item, index) => {
                  return (
                    <a
                      href={`trade/${item.firstcurrency}-${
                        item.secondcurrency
                      }/${item.exchange}/${firstOnCap(item.BotType)}`}
                    >
                      <div className="pairCard" key={index}>
                        <div className="pair_table_bodyrow" key={index}>
                          <div className="pair_table_bodyrow_top" key={index}>
                            <div>
                              <img
                                src={
                                  config.API_URL +
                                  "/images/currency/" +
                                  item.image
                                }
                                alt={item.image}
                                className="img-fluid"
                              />

                              <h3>
                                {item.firstcurrency}
                                <span>/{item.secondcurrency}</span>
                              </h3>

                              {/* <i className="bi bi-recycle"></i> */}
                            </div>
                          </div>
                          <div className="pair_table_bodyrow_top mt-2">
                            <div>
                              <span className="badge bg_blue trade_type me-2">
                                {firstCapital(item.StrategyPeriod)}
                              </span>
                              <div
                                className={
                                  item && item.returnrate_per < 0
                                    ? "badge bg_red per_change"
                                    : "badge bg_green per_change"
                                }
                              >
                                {item && toFixed(item.returnrate_per, 4)}%
                              </div>
                            </div>
                          </div>
                          <div className="pair_table_bodyrow_bottom">
                            <div className="pair_table_bodyrow_bottom_left w-100">
                              <div>
                                <label>Quantity:</label>
                                <label>
                                  {toFixed(item.quantity, item.priceDecimal)}
                                </label>
                              </div>
                              <div>
                                <label>Price:</label>
                                <label>
                                  {toFixed(item.markPrice, item.priceDecimal)}
                                </label>
                              </div>
                              <div>
                                <label>Profit:</label>
                                <label
                                  className={
                                    item && item.profit < 0
                                      ? "redText"
                                      : "greenText"
                                  }
                                >
                                  {item && toFixed(item.profit, 2)}
                                </label>
                              </div>
                              <div>
                                <label>Chg 24H(%):</label>
                                <label
                                  className={
                                    item.change > 0 ? "greenText" : "redText"
                                  }
                                >
                                  {item.change}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}

              {/* <div className="pairCard">
                <div className="pair_table_bodyrow">
                  <div className="pair_table_bodyrow_top">
                    <div>
                      <img
                        src={require("../../assets/images/btc.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                      <h3>
                        BTC<span>/USDT</span>
                      </h3>
                      <i className="bi bi-recycle"></i>
                    </div>
                  </div>
                  <div className="pair_table_bodyrow_top mt-2">
                    <div>
                      <span className="badge bg_blue trade_type me-2">
                        Cycle
                      </span>
                      <div className="badge bg_green per_change">0.2647%</div>
                    </div>
                  </div>
                  <div className="pair_table_bodyrow_bottom">
                    <div className="pair_table_bodyrow_bottom_left w-100">
                      <div>
                        <label>Quantity:</label>
                        <label>718.0</label>
                      </div>
                      <div>
                        <label>Price:</label>
                        <label>0.2063</label>
                      </div>
                      <div>
                        <label>Profit($):</label>
                        <label className="greenText">0.3894</label>
                      </div>
                      <div>
                        <label>Chg 24H(%):</label>
                        <label className="greenText">0.0003</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pairCard">
                <div className="pair_table_bodyrow">
                  <div className="pair_table_bodyrow_top">
                    <div>
                      <img
                        src={require("../../assets/images/btc.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                      <h3>
                        BTC<span>/USDT</span>
                      </h3>
                      <i className="bi bi-recycle"></i>
                    </div>
                  </div>
                  <div className="pair_table_bodyrow_top mt-2">
                    <div>
                      <span className="badge bg_blue trade_type me-2">
                        Cycle
                      </span>
                      <div className="badge bg_green per_change">0.2647%</div>
                    </div>
                  </div>
                  <div className="pair_table_bodyrow_bottom">
                    <div className="pair_table_bodyrow_bottom_left w-100">
                      <div>
                        <label>Quantity:</label>
                        <label>718.0</label>
                      </div>
                      <div>
                        <label>Price:</label>
                        <label>0.2063</label>
                      </div>
                      <div>
                        <label>Profit($):</label>
                        <label className="greenText">0.3894</label>
                      </div>
                      <div>
                        <label>Chg 24H(%):</label>
                        <label className="greenText">0.0003</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pairCard">
                <div className="pair_table_bodyrow">
                  <div className="pair_table_bodyrow_top">
                    <div>
                      <img
                        src={require("../../assets/images/btc.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                      <h3>
                        BTC<span>/USDT</span>
                      </h3>
                      <i className="bi bi-recycle"></i>
                    </div>
                  </div>
                  <div className="pair_table_bodyrow_top mt-2">
                    <div>
                      <span className="badge bg_blue trade_type me-2">
                        Cycle
                      </span>
                      <div className="badge bg_green per_change">0.2647%</div>
                    </div>
                  </div>
                  <div className="pair_table_bodyrow_bottom">
                    <div className="pair_table_bodyrow_bottom_left w-100">
                      <div>
                        <label>Quantity:</label>
                        <label>718.0</label>
                      </div>
                      <div>
                        <label>Price:</label>
                        <label>0.2063</label>
                      </div>
                      <div>
                        <label>Profit($):</label>
                        <label className="greenText">0.3894</label>
                      </div>
                      <div>
                        <label>Chg 24H(%):</label>
                        <label className="greenText">0.0003</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>

          <div
            class="tab-pane fade"
            id="smart"
            role="tabpanel"
            aria-labelledby="smart-tab"
          >
            <Slider {...settingsGeniePair}>
              {isEmpty(PairList) && (
                <img
                  src={require("../../assets/images/smart.png")}
                  alt="Icon"
                  width="150"
                  className="img-fluid mx-auto d-block"
                />
              )}
              {PairList &&
                PairList.length > 0 &&
                PairList.map((item, index) => {
                  return (
                    <a
                      href={`trade/${item.firstcurrency}-${
                        item.secondcurrency
                      }/${item.exchange}/${firstOnCap(item.BotType)}`}
                    >
                      <div className="pairCard" key={index}>
                        <div className="pair_table_bodyrow" key={index}>
                          <div className="pair_table_bodyrow_top" key={index}>
                            <div>
                              <img
                                src={
                                  config.API_URL +
                                  "/images/currency/" +
                                  item.image
                                }
                                alt={item.image}
                                className="img-fluid"
                              />

                              <h3>
                                {item.firstcurrency}
                                <span>/{item.secondcurrency}</span>
                              </h3>

                              {/* <i className="bi bi-recycle"></i> */}
                            </div>
                          </div>
                          <div className="pair_table_bodyrow_top mt-2">
                            <div>
                              <span className="badge bg_blue trade_type me-2">
                                {firstCapital(item.StrategyPeriod)}
                              </span>
                              <div
                                className={
                                  item && item.returnrate_per < 0
                                    ? "badge bg_red per_change"
                                    : "badge bg_green per_change"
                                }
                              >
                                {item && toFixed(item.returnrate_per, 4)}%
                              </div>
                            </div>
                          </div>
                          <div className="pair_table_bodyrow_bottom">
                            <div className="pair_table_bodyrow_bottom_left w-100">
                              <div>
                                <label>Quantity:</label>
                                <label>
                                  {toFixed(item.quantity, item.priceDecimal)}
                                </label>
                              </div>
                              <div>
                                <label>Price:</label>
                                <label>
                                  {toFixed(item.markPrice, item.priceDecimal)}
                                </label>
                              </div>
                              <div>
                                <label>Profit:</label>
                                <label
                                  className={
                                    item && item.profit < 0
                                      ? "redText"
                                      : "greenText"
                                  }
                                >
                                  {item && toFixed(item.profit, 2)}
                                </label>
                              </div>
                              <div>
                                <label>Chg 24H(%):</label>
                                <label
                                  className={
                                    item.change > 0 ? "greenText" : "redText"
                                  }
                                >
                                  {item.change}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
            </Slider>
          </div>
          <div
            class="tab-pane fade"
            id="zap"
            role="tabpanel"
            aria-labelledby="zap-tab"
          >
            <Slider {...settingsGeniePair}>
              {PairList &&
                PairList.length > 0 &&
                PairList.map((item, index) => {
                  return (
                    <a
                      href={`trade/${item.firstcurrency}-${
                        item.secondcurrency
                      }/${item.exchange}/${firstOnCap(item.BotType)}`}
                    >
                      <div className="pairCard" key={index}>
                        <div className="pair_table_bodyrow" key={index}>
                          <div className="pair_table_bodyrow_top" key={index}>
                            <div>
                              <img
                                src={
                                  config.API_URL +
                                  "/images/currency/" +
                                  item.image
                                }
                                alt={item.image}
                                className="img-fluid"
                              />

                              <h3>
                                {item.firstcurrency}
                                <span>/{item.secondcurrency}</span>
                              </h3>

                              {/* <i className="bi bi-recycle"></i> */}
                            </div>
                          </div>
                          <div className="pair_table_bodyrow_top mt-2">
                            <div>
                              <span className="badge bg_blue trade_type me-2">
                                {firstCapital(item.StrategyPeriod)}
                              </span>
                              <div
                                className={
                                  item && item.returnrate_per < 0
                                    ? "badge bg_red per_change"
                                    : "badge bg_green per_change"
                                }
                              >
                                {item && toFixed(item.returnrate_per, 4)}%
                              </div>
                            </div>
                          </div>
                          <div className="pair_table_bodyrow_bottom">
                            <div className="pair_table_bodyrow_bottom_left w-100">
                              <div>
                                <label>Quantity:</label>
                                <label>
                                  {toFixed(item.quantity, item.priceDecimal)}
                                </label>
                              </div>
                              <div>
                                <label>Price:</label>
                                <label>
                                  {toFixed(item.markPrice, item.priceDecimal)}
                                </label>
                              </div>
                              <div>
                                <label>Profit:</label>
                                <label
                                  className={
                                    item && item.profit < 0
                                      ? "redText"
                                      : "greenText"
                                  }
                                >
                                  {item && toFixed(item.profit, 2)}
                                </label>
                              </div>
                              <div>
                                <label>Chg 24H(%):</label>
                                <label
                                  className={
                                    item.change > 0 ? "greenText" : "redText"
                                  }
                                >
                                  {item.change}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
            </Slider>
            {isEmpty(PairList) && (
              <img
                src={require("../../assets/images/zap.png")}
                alt="Icon"
                width="200"
                className="img-fluid mx-auto d-block"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
