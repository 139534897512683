import React, { useEffect, useState, Fragment } from "react";
import HeaderTrade from "../components/HeaderTrade.js";
import Footer from "../components/Footer.js";
import { Scrollbars } from "react-custom-scrollbars";
import DataTable from "react-data-table-component";
import $, { param } from "jquery";
import ShareModal from "../components/ShareModal";
import Chart from "../components/Chart/Chart";
import { useParams, useNavigate, Link } from "react-router-dom";
import isEmpty from "../lib/isEmpty.js";
import GenieSettings from "../components/BotTrade/GenieSettings";
import SmartSettings from "../components/BotTrade/SmartSettings";
import TradeHistory from "../components/BotTrade/TradeHistory.js";
import ZapSettings from "../components/BotTrade/ZapSettings.js";
import Announcement from "../components/BotTrade/Announcement";
import MarketPrice from "../components/BotTrade/MarketPrice.js";
import TradePairs from "../components/BotTrade/TradePairs.js";
import { toastAlert } from "../lib/toastAlert";
import { firstCapital } from "../lib/String";
import {
  getAllStrategy,
  getSingleStrategy,
  getIndicator,
  getMfeeBal,
  getReneualalert,
} from "../actions/Bottrade";

const InitialFormValue = [
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "CMP",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
  {
    indicatorType: "",
    indicator: "5",
    Candle: "1m",
    indicator1: "5",
    smaema1: "sma",
    smaema2: "sma",
    indicatorLength: "5",
    type: "histogram",
    Condition: "lessthan",
    ConditionSE: "up",
    band: "upper",
    value: "",
    price: "price",
  },
];
const indicatorslist = [
  { value: "5", label: "RSI-5" },
  { value: "7", label: "RSI-7" },
  { value: "14", label: "RSI-14" },
  { value: "2", label: "BB-2" },
  { value: "14", label: "SMA" },
  { value: "14", label: "EMA" },
  // { value: "8", label: "WMA" },
  // { value: "GoldenCross", label: "GoldenCross" },
  // { value: "DeathCross", label: "DeathCross" },
  { value: "12", label: "MACD" },
  { value: "14", label: "SMA/EMA Cross" },
];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Trade() {
  const { pair, exchange, bot } = useParams();
  const navigate = useNavigate();
  const params = { pair: pair, exchange: exchange, bot: bot };
  // console.log(pair, exchange, bot, "exchangeexchangeexchange");
  const [data, setData] = useState("");
  const [mfeeBal, setMfeeBal] = useState();
  const [record, setRecord] = useState("");
  const [macdType, setMacdType] = useState("histogram");
  const [inputFields, setInputFields] = useState([]);
  const [keyData, setKeyData] = useState([]);
  const [formValue, setFormValue] = useState(InitialFormValue);
  const {
    indicatorType,
    indicatorLength,
    indicator,
    type,
    ConditionSE,
    indicator1,
    Candle,
    Condition,
    smaema1,
    smaema2,
    band,
    value,
    price,
  } = formValue;
  const [Renalert, setRenalert] = useState();

  useEffect(() => {
    loadScript();
    fetchTradeSetting();
    fetchIndicatorList();
    fetchMfeeBal();
    fetchReneualAlert();
  }, []);

  const fetchReneualAlert = async () => {
    try {
      const { status, result } = await getReneualalert();
      if (status == "success") {
        setRenalert(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const fetchMfeeBal = async () => {
    try {
      const { status, result } = await getMfeeBal();
      if (status == "success") {
        setMfeeBal(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const fetchIndicatorList = async () => {
    try {
      let reqData = {
        pair: pair.replace(/\-/g, ""),
        exchange,
        bot: bot.toUpperCase(),
      };
      const { status, result } = await getIndicator(reqData);
      if (status == "success") {
        // console.log(result[0].Indicator, "_______-result");
        if (!isEmpty(result)) {
          setInputFields(result[0].Indicator);
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  console.log(inputFields, "---inputFields");

  const fetchTradeSetting = async () => {
    try {
      const { status, result } = await getAllStrategy();
      if (status == "success") {
        setData(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleClick = async (Name) => {
    try {
      let reqData = { Name };
      const { status, result } = await getSingleStrategy(reqData);
      if (status == "success") {
        setRecord(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleAdd = (key, e) => {
    try {
      e.preventDefault();
      const { name, id, value } = e.target;

      let checkArr = formValue;
      let values = [...checkArr];
      let origKey = key;

      console.log(values[origKey].indicatorType, "looo 99877");

      if (value == "2") {
        checkArr[key].indicatorType = "BB";
      } else if (id == "SMA") {
        checkArr[key].indicatorType = "SMA";
      } else if (id == "EMA") {
        checkArr[key].indicatorType = "EMA";
      } else if (id == "WMA") {
        checkArr[key].indicatorType = "WMA";
      } else if (id == "GoldenCross") {
        checkArr[key].indicatorType = "GoldenCross";
      } else if (id == "DeathCross") {
        checkArr[key].indicatorType = "DeathCross";
      } else if (id == "MACD") {
        checkArr[key].indicatorType = "MACD";
      } else if (id == "SMA/EMA Cross") {
        checkArr[key].indicatorType = "SMA/EMA Cross";
      } else {
        checkArr[key].indicatorType = "RSI";
      }
      if (
        values[origKey].indicatorType == "RSI" ||
        values[origKey].indicatorType == "WMA"
      ) {
        if (isEmpty(values[origKey].value) || values[origKey].value <= 0) {
          toastAlert("error", "Please Enter value or Valid Value");
          return false;
        }
      }
      console.log(values[origKey], "--------313");
      if (values[origKey].indicatorType == "MACD") {
        if (macdType == "histogram" && isEmpty(values[origKey].value)) {
          toastAlert("error", "Please Enter value or Valid Value");
          return false;
        }
      }
      values[key][name] = value;
      let keyValue = keyData;
      keyValue.push(key);
      setFormValue(values);
      setKeyData(keyValue);
      let datas = inputFields;

      if (
        values[origKey].indicatorType == "RSI" ||
        values[origKey].indicatorType == "WMA"
      ) {
        datas.push({
          indicatorType: values[origKey].indicatorType,
          indicator: values[origKey].indicator,
          Candle: values[origKey].Candle,
          Condition: values[origKey].Condition,
          value: values[origKey].value,
        });
      } else if (values[origKey].indicatorType == "BB") {
        datas.push({
          indicatorType: values[origKey].indicatorType,
          indicator: values[origKey].indicator,
          Condition: values[origKey].Condition,
          Candle: values[origKey].Candle,
          price: values[origKey].price,
          band: values[origKey].band,
        });
      } else if (values[origKey].indicatorType == "MACD") {
        datas.push({
          indicatorType: values[origKey].indicatorType,
          indicator: values[origKey].indicator,
          Condition: values[origKey].Condition,
          type: macdType,
          Candle: values[origKey].Candle,
          price: values[origKey].price,
          band: values[origKey].band,
          value: values[origKey].value,
        });
      } else if (
        values[origKey].indicatorType == "SMA" ||
        values[origKey].indicatorType == "EMA"
      ) {
        console.log(values[origKey], "-------------307");
        datas.push({
          indicatorType: values[origKey].indicatorType,
          indicator: values[origKey].indicatorLength,
          Condition: values[origKey].Condition,
          Candle: values[origKey].Candle,
        });
      } else if (
        values[origKey].indicatorType == "GoldenCross" ||
        values[origKey].indicatorType == "DeathCross"
      ) {
        datas.push({
          indicatorType: values[origKey].indicatorType,
          Candle: values[origKey].Candle,
        });
      } else if (values[origKey].indicatorType == "SMA/EMA Cross") {
        console.log(values[origKey], "-------------360");
        datas.push({
          indicatorType: values[origKey].indicatorType,
          smaema1: values[origKey].smaema1,
          smaema2: values[origKey].smaema2,
          Candle: values[origKey].Candle,
          Condition: values[origKey].ConditionSE,
          indicator: values[origKey].indicatorLength,
          indicator1: values[origKey].indicator1,
        });
      }
      console.log(datas, "-----------369");
      setInputFields(datas);
      // setTimeout(() => {
      //   setFormValue(InitialFormValue);
      // }, 1000);
    } catch (err) {
      console.log(err, "------------error");
    }
  };

  // useEffect(() => {}, [keyData, formValue]);

  const handleRemoveFields = (data, item, key) => {
    try {
      // console.log(data, item, "------------------items");
      let datas = "";
      if (
        data == "SMA" ||
        data == "EMA" ||
        data == "WMA" ||
        data == "GoldenCross" ||
        data == "DeathCross" ||
        data == "MACD"
      ) {
        datas = data;
      } else {
        datas = data + "-" + item;
      }

      let finddata = indicatorslist.find((el) => el.label == datas);
      let findkey = indicatorslist.indexOf(finddata);
      let keyss = keyData.indexOf(findkey);
      console.log(keyss);
      let remove = keyData;
      remove.splice(keyss, 1);
      setKeyData(remove);

      let values = [...inputFields];
      values.splice(key, 1);
      setInputFields(values);
      let initialvalue = [...formValue];
      console.log(initialvalue[findkey], "--------------------uehwer");
      initialvalue[findkey].indicatorType = "";
      initialvalue[findkey].indicator = "";
      initialvalue[findkey].Candle = "1m";
      initialvalue[findkey].Condition = "lessthan";
      initialvalue[findkey].band = "upper";
      initialvalue[findkey].value = "";
      initialvalue[findkey].price = "price";
      console.log(initialvalue, "---------------initialvalue");
      setFormValue(initialvalue);
    } catch (err) {
      console.log(err, "-----------------280");
    }
  };
  const handleLenChange = (key, e) => {
    e.preventDefault();
    try {
      const { name, value } = e.target;
      const values = formValue;
      values[key][name] = value;
      setFormValue(values);
    } catch (err) {
      console.log(err, "erwwerewrwrwr");
    }
  };
  const handleOnChange = (key, e) => {
    e.preventDefault();
    try {
      const { name, value } = e.target;
      console.log(name, "------460", value, key);
      if (value > 100) {
        toastAlert("error", "Value must not be higher than 100");
        return false;
      }
      if (name == "type") {
        setMacdType(value);
      }
      const values = formValue;
      values[key][name] = value;
      console.log(values, "-----469");
      setFormValue(values);
      // let datas = inputFields;
      // let keyValue = keyData;
      // setKeyData(keyValue);
      // setInputFields(datas);
    } catch (err) {
      console.log(err, "erwwerewrwrwr");
    }
  };

  function loadScript() {
    // $("#add_new_strategy").click(function () {
    //   $(".trade_new_strategy_settings").show();
    //   $(".trade_main_settings").hide();
    // });
    $("#custom_amt_setup").click(function () {
      $(".custom_amt_setup_panel").show();
      $(".trade_new_strategy_settings").hide();
    });
    $(".custom_amt_setup_back").click(function () {
      $(".custom_amt_setup_panel").hide();
      $(".trade_new_strategy_settings").show();
    });
    // $(".strategy_settings_close").click(function () {
    //   $(".trade_main_settings").show();
    //   $(".trade_strategy_settings").hide();
    //   $(".trade_new_strategy_settings").hide();
    // });

    $(".chart_btn").on("click", function () {
      $(".trade_chart").toggleClass("show");
      $("body").toggleClass("filter_overlay");
    });

    $(".chart_close").on("click", function () {
      $(".trade_chart").removeClass("show");
      $("body").removeClass("filter_overlay");
    });
  }
  function TimeDisplay(val) {
    let text;
    switch (val) {
      case "1m":
        text = "1 Min";
        break;
      case "5m":
        text = "5 Min";
        break;
      case "15m":
        text = "15 Min";
        break;

      case "30m":
        text = "30 Min";
        break;

      case "1h":
        text = "1 Hour";
        break;

      case "4h":
        text = "4 Hours";
        break;

      case "1d":
        text = "1 Day";
        break;

      default:
        text = "";
    }
    return text;
  }
  console.log(value, "-----------543", formValue[0]);
  return (
    <main>
      <ScrollToTopOnMount />
      <HeaderTrade />
      <div className="trade_wrapper">
        <div className="container-fluid">
          <MarketPrice params={params} />
          <div className="trade_main">
            <div className="trade_main_left">
              <div className="trade_main_left_top">
                <TradePairs params={params} />
                <div className="trade_chart">
                  <div className="chart_mobile_head">
                    <h5>Chart</h5>
                    <i className="bi bi-x chart_close"></i>
                  </div>
                  <Chart params={params} className="img-fluid" />

                  {/* <img
                    src={require("../assets/images/trade_chart.png")}
                    alt="chart"
                    className="img-fluid"
                  /> */}
                </div>
              </div>
              {!isEmpty(bot) && (
                <TradeHistory botType={bot} pair={pair} exchange={exchange} />
              )}
            </div>
            {!isEmpty(bot) && bot == "Genie" && (
              <GenieSettings params={params} />
            )}
            {!isEmpty(bot) && bot == "Smart" && (
              <SmartSettings
                params={params}
                record={record}
                inputData={inputFields}
              />
            )}
            {!isEmpty(bot) && bot == "Zap" && <ZapSettings params={params} />}
          </div>
          <div
            className="alert info_alert alert-dismissible fade show"
            role="alert"
          >
            <img src={require("../assets/images/info_icon.png")} alt="Icon" />
            <p>
              All information on this site is for educational purpose only, do
              not consider it as an investment advice.
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <Announcement />
          {mfeeBal && mfeeBal.status && (
            <div className="alert alert-danger" role="alert">
              <p>{mfeeBal.message}</p>
            </div>
          )}

          {Renalert && Renalert.status && (
            <div
              className="alert danger_alert alert-dismissible fade show"
              role="alert"
            >
              <img src={require("../assets/images/info_icon.png")} alt="Icon" />
              <p>{Renalert.message}</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
        </div>
      </div>
      <Footer />

      {/* Strategy Modal */}
      <div
        class="modal fade primary_modal"
        id="predefined_strategy_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="predefined_strategy_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="predefined_strategy_modalLabel">
                Pre-Defined Strategies
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {/* <div className="p-0 search_inp_div">
                <input
                  type="text"
                  placeholder="Seach Strategy"
                  className="form-control search_inp"
                />
                <i className="bi bi-search"></i>
              </div> */}
              {data &&
                data.length > 0 &&
                data.map((item, key) => {
                  return (
                    <div className="saved_strategies_panel">
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => handleClick(item.Name)}
                      >
                        {item.Name}
                      </button>

                      {/* <button>Gynoid</button>
                      <button>Cyborg</button>
                      <button>Cobot</button>
                      <button>Drone</button> */}
                    </div>
                  );
                })}
              <Link to="/strategy-setting" state={{ type: params }}>
                <button
                  className="primary_btn"
                  data-bs-dismiss="modal"
                  id="add_new_strategy"
                >
                  {" "}
                  Add New Strategy
                </button>
              </Link>
              {/* <button
                className="primary_btn"
                data-bs-dismiss="modal"
                id="add_new_strategy"
                onClick={() => navigate("/strategy-setting", params)}
              >
                {" "}
                Add New Strategy
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* Strategy Modal */}
      <div
        class="modal fade primary_modal"
        id="trade_setting_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="trade_setting_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="trade_setting_modalLabel">
                Pre-Defined Strategies
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="p-0 search_inp_div">
                <input
                  type="text"
                  placeholder="Seach Strategy"
                  className="form-control search_inp"
                />
                <i className="bi bi-search"></i>
              </div>
              {data &&
                data.length > 0 &&
                data.map((item, key) => {
                  return (
                    <div className="saved_strategies_panel">
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => handleClick(item.Name)}
                      >
                        {item.Name}
                      </button>

                      {/* <button>Gynoid</button>
                      <button>Cyborg</button>
                      <button>Cobot</button>
                      <button>Drone</button> */}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* Indicators Modal */}
      <div
        class="modal fade primary_modal"
        id="indicators_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="indicators_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="indicators_modalLabel">
                Indicator Strategies
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="accordion primary_accordian" id="indicators_list">
                {isEmpty(inputFields) && (
                  <h5 className="text-center">No Indicators Added</h5>
                )}
                {inputFields &&
                  inputFields.length > 0 &&
                  inputFields.map((item, key) => {
                    console.log(item, "2222222222");
                    return (
                      <Fragment key={`${item}~${key}`}>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id={"heading" + key}>
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapse" + key}
                              aria-expanded="true"
                              aria-controls={"collapse" + key}
                            >
                              {item.indicatorType == "RSI" ||
                              item.indicatorType == "BB"
                                ? item.indicatorType + "-" + item.indicator
                                : item.indicatorType}
                            </button>
                          </h2>
                          <div
                            id={"collapse" + key}
                            class="accordion-collapse collapse"
                            aria-labelledby={"heading" + key}
                            data-bs-parent="#indicators_list"
                          >
                            <div class="accordion-body">
                              <div className="indicators_panel">
                                <div>
                                  <label>
                                    Candle
                                    {item.indicatorType == "SMA" ||
                                    item.indicatorType == "EMA"
                                      ? " (CMP)"
                                      : " "}
                                  </label>
                                  <span className="greenText">
                                    {TimeDisplay(item.Candle)}
                                  </span>
                                </div>
                                {item.indicatorType == "MACD" && (
                                  <div>
                                    <label>Type</label>
                                    <span className="greenText">
                                      {macdType}
                                    </span>
                                  </div>
                                )}
                                {item.indicatorType !== "GoldenCross" &&
                                  item.indicatorType !== "DeathCross" &&
                                  macdType != "signal" && (
                                    <div>
                                      <label>
                                        Condition
                                        {item.indicatorType == "BB"
                                          ? " (CMP)"
                                          : item.indicatorType == "RSI"
                                          ? " (RSI)"
                                          : " "}
                                      </label>
                                      <span className="greenText">
                                        {item.Condition == "lessthan"
                                          ? "Less than"
                                          : item.Condition == "greaterthan"
                                          ? "Greater than"
                                          : item.Condition.toUpperCase()}
                                      </span>
                                    </div>
                                  )}
                                {(item.indicatorType == "SMA" ||
                                  item.indicatorType == "EMA") && (
                                  <div>
                                    <label>Length</label>
                                    <span className="greenText">
                                      {item.indicator}
                                    </span>
                                  </div>
                                )}
                                {item.indicatorType == "SMA/EMA Cross" && (
                                  <>
                                    <div>
                                      <label>
                                        {item.smaema1.toUpperCase()}
                                      </label>
                                      <span className="greenText">
                                        {item.indicator}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {item.indicatorType == "SMA/EMA Cross" && (
                                  <>
                                    <div>
                                      <label>
                                        {item.smaema2.toUpperCase()}
                                      </label>
                                      <span className="greenText">
                                        {item.indicator1}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {item.indicator == "2" && (
                                  <>
                                    <div>
                                      <label>Band</label>
                                      <span className="greenText">
                                        {firstCapital(item.band)} band
                                      </span>
                                    </div>
                                    {item.indicatorType == "BB-2" && (
                                      <div>
                                        <label>Price</label>
                                        <span className="greenText">
                                          {item.price}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}

                                {(item.indicatorType == "RSI" ||
                                  (item.indicatorType == "MACD" &&
                                    macdType == "histogram") ||
                                  item.indicatorType == "WMA") && (
                                  <div>
                                    <label>Value</label>
                                    <span className="greenText">
                                      {item.value}
                                    </span>
                                  </div>
                                )}

                                <div className="text-center">
                                  <button
                                    class="primary_btn primary_blue_btn w-50"
                                    // value={item.value}
                                    // id={item.type}
                                    // name="indicator"
                                    onClick={() =>
                                      handleRemoveFields(
                                        item.indicatorType,
                                        item.indicator,
                                        key
                                      )
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                      // <div className="saved_strategies_panel">
                      //   <button data-bs-dismiss="modal" disabled>
                      //     {item.indicator}
                      //   </button>

                      // </div>
                    );
                  })}

                <div className="text-center">
                  <button
                    class="primary_btn primary_blue_btn w-50"
                    data-bs-toggle="modal"
                    data-bs-target="#add_indicators_modal"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Indicators Modal */}
      <div
        class="modal fade primary_modal"
        id="add_indicators_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="add_indicators_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
                Select
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="accordion primary_accordian" id="indicators_list">
                {indicatorslist.map((item, key) => {
                  let indicatorStatus = "";
                  if (
                    item.label == "GoldenCross" ||
                    item.label == "DeathCross" ||
                    item.label == "WMA" ||
                    item.label == "MACD"
                  ) {
                    console.log("-------------856");
                    indicatorStatus =
                      inputFields &&
                      inputFields.find(
                        (el) =>
                          el.indicator == item.value &&
                          el.indicatorType == item.label
                      );
                  } else if (
                    item.label == "SMA" ||
                    item.label == "EMA" ||
                    item.label == "SMA/EMA Cross"
                  ) {
                    indicatorStatus =
                      inputFields &&
                      inputFields.find((el) => el.indicatorType == item.label);
                  } else {
                    indicatorStatus =
                      inputFields &&
                      inputFields.find(
                        (el) =>
                          el.indicator == item.value &&
                          el.indicatorType + "-" + el.indicator == item.label
                      );
                  }

                  // console.log(indicatorStatus, "_______indicatorStatus");
                  let keyStatus = keyData && keyData.find((el) => el == key);
                  console.log(value, "-------------873");

                  return (
                    <Fragment key={`${item}~${key}`}>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id={"heading" + key}>
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + key}
                            aria-expanded="true"
                            aria-controls={"collapse" + key}
                          >
                            {item.label}{" "}
                            {item.label == "MACD" &&
                              macdType == "signal" &&
                              "Cross UP"}
                          </button>
                        </h2>
                        <div
                          id={"collapse" + key}
                          class="accordion-collapse collapse"
                          aria-labelledby={"heading" + key}
                          data-bs-parent="#indicators_list"
                        >
                          <div class="accordion-body">
                            <div className="indicators_panel">
                              <div>
                                <label>
                                  Candle{" "}
                                  {item.label == "SMA" || item.label == "EMA"
                                    ? " (CMP)"
                                    : " "}
                                </label>
                                <select
                                  className="form-select indicators_inp"
                                  name="Candle"
                                  value={Candle}
                                  onChange={(e) => handleOnChange(key, e)}
                                >
                                  <option value="1m">1 min</option>
                                  <option value="5m">5 mins</option>
                                  <option value="15m">15 mins</option>
                                  <option value="30m">30 mins</option>
                                  <option value="1h">1 hour</option>
                                  <option value="4h">4 hours</option>
                                  <option value="1d">1 day</option>
                                </select>
                              </div>
                              {item.label == "SMA/EMA Cross" && (
                                <>
                                  {" "}
                                  <div>
                                    <select
                                      className="form-select indicators_inp"
                                      name="smaema1"
                                      value={smaema1}
                                      onChange={(e) => handleLenChange(key, e)}
                                    >
                                      <option value="sma">SMA</option>
                                      <option value="ema">EMA</option>
                                    </select>

                                    <select
                                      className="form-select indicators_inp"
                                      name="indicatorLength"
                                      value={indicatorLength}
                                      onChange={(e) => handleLenChange(key, e)}
                                    >
                                      <option value="5">5</option>
                                      <option value="7">7</option>
                                      <option value="10">10</option>
                                      <option value="21">21</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="200">200</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {(item.label == "RSI-5" ||
                                item.label == "RSI-7" ||
                                item.label == "RSI-14" ||
                                item.label == "BB-2" ||
                                item.label == "WMA" ||
                                (item.label == "MACD" &&
                                  macdType == "histogram") ||
                                item.label == "SMA" ||
                                item.label == "EMA") && (
                                <div>
                                  <label>
                                    Condition{" "}
                                    {item.label == "BB-2"
                                      ? " (CMP)"
                                      : item.label == "RSI-5" ||
                                        item.label == "RSI-7" ||
                                        item.label == "RSI-14"
                                      ? " (RSI)"
                                      : " "}
                                  </label>
                                  <select
                                    className="form-select indicators_inp"
                                    name="Condition"
                                    value={Condition}
                                    onChange={(e) => handleOnChange(key, e)}
                                  >
                                    <option value="lessthan">Less than</option>
                                    <option value="greaterthan">
                                      Greater than
                                    </option>
                                  </select>
                                </div>
                              )}
                              {(item.label == "SMA" || item.label == "EMA") && (
                                <>
                                  {/* <div>
                                    <label>Condition</label>
                                    <select
                                      className="form-select indicators_inp"
                                      name="Condition"
                                      value={Condition}
                                      onChange={(e) => handleOnChange(key, e)}
                                    >
                                      <option value="crossup">Cross up</option>
                                    </select>
                                  </div> */}
                                  <div>
                                    <label>Length</label>
                                    <select
                                      className="form-select indicators_inp"
                                      name="indicatorLength"
                                      value={indicatorLength}
                                      onChange={(e) => handleLenChange(key, e)}
                                    >
                                      <option value="5">5</option>
                                      <option value="7">7</option>
                                      <option value="10">10</option>
                                      <option value="21">21</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="200">200</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {item.label == "MACD" && (
                                <div>
                                  <label>Type</label>
                                  <select
                                    className="form-select indicators_inp"
                                    name="type"
                                    value={type}
                                    onChange={(e) => handleOnChange(key, e)}
                                  >
                                    <option value="histogram">HistoGram</option>
                                    <option value="signal">Signal</option>
                                  </select>
                                </div>
                              )}

                              {item.label == "SMA/EMA Cross" && (
                                <div>
                                  <label>Condition</label>
                                  <select
                                    className="form-select indicators_inp"
                                    name="ConditionSE"
                                    value={ConditionSE}
                                    onChange={(e) => handleOnChange(key, e)}
                                  >
                                    <option value="up">UP</option>
                                    <option value="down">Down</option>
                                  </select>
                                </div>
                              )}

                              {item.label == "SMA/EMA Cross" && (
                                <>
                                  {" "}
                                  <div>
                                    <select
                                      className="form-select indicators_inp"
                                      name="smaema2"
                                      value={smaema2}
                                      onChange={(e) => handleLenChange(key, e)}
                                    >
                                      <option value="sma">SMA</option>
                                      <option value="ema">EMA</option>
                                    </select>
                                    <select
                                      className="form-select indicators_inp"
                                      name="indicator1"
                                      value={indicator1}
                                      onChange={(e) => handleLenChange(key, e)}
                                    >
                                      <option value="5">5</option>
                                      <option value="7">7</option>
                                      <option value="10">10</option>
                                      <option value="21">21</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="200">200</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {item.label == "BB-2" && (
                                <>
                                  <div>
                                    <label>Band</label>
                                    <select
                                      className="form-select indicators_inp"
                                      name="band"
                                      value={band}
                                      onChange={(e) => handleOnChange(key, e)}
                                    >
                                      <option value="upper">Upper Band</option>
                                      <option value="lower">Lower Band</option>
                                      <option value="middle">
                                        Middle Band
                                      </option>
                                    </select>
                                  </div>
                                  {/* <div>
                                    <label>Price</label>
                                    <input
                                      class="form-control indicators_inp"
                                      name="price"
                                      type="text"
                                      value="CMP"
                                      // onChange={(e) => handleOnChange(key, e)}
                                    />
                                  </div> */}
                                </>
                              )}
                              {(item.label == "RSI-5" ||
                                item.label == "RSI-7" ||
                                item.label == "RSI-14" ||
                                (item.label == "MACD" &&
                                  macdType == "histogram") ||
                                item.label == "WMA") && (
                                <div>
                                  <label>Value</label>
                                  <input
                                    class="form-control indicators_inp"
                                    name="value"
                                    type="number"
                                    value={value} // {!isEmpty(value) ? value : 0}
                                    onChange={(e) => handleOnChange(key, e)}
                                  />
                                </div>
                              )}
                              {/* <span className="text-danger">
                                {validateError.value}
                              </span> */}
                            </div>
                            {keyStatus == undefined &&
                              isEmpty(indicatorStatus) && (
                                <div className="text-center">
                                  <button
                                    class="primary_btn primary_blue_btn w-50"
                                    value={item.value}
                                    id={item.label}
                                    name="indicator"
                                    onClick={(e) => handleAdd(key, e)}
                                  >
                                    Add
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Batch Settings Modal */}
      <div
        class="modal fade primary_modal"
        id="batch_setting_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="batch_setting_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="batch_setting_modalLabel">
                Batch Setting
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="batch_settings_modal_panel">
                <div className="coin_details">
                  <div className="card trade_card">
                    <div className="card-header">
                      <h2>Coins</h2>
                    </div>
                    <div className="card-body">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="coin_one-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_one"
                            type="button"
                            role="tab"
                            aria-controls="coin_one"
                            aria-selected="true"
                          >
                            USDT
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="coin_two-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_two"
                            type="button"
                            role="tab"
                            aria-controls="coin_two"
                            aria-selected="false"
                          >
                            BTC
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="coin_three-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_three"
                            type="button"
                            role="tab"
                            aria-controls="coin_three"
                            aria-selected="false"
                          >
                            ETH
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="search_inp_div">
                          <input
                            type="text"
                            placeholder="Seach Pair"
                            className="form-control search_inp"
                          />
                          <i className="bi bi-search"></i>
                        </div>
                        <div
                          className="tab-pane fade show active"
                          id="coin_one"
                          role="tabpanel"
                          aria-labelledby="coin_one-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 300 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    className="track_vertical"
                                    {...props}
                                    style={{
                                      ...style,
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    className="thumb_vertical"
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                    }}
                                  />
                                )}
                              >
                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/btc.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          BTC<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_blue trade_type">
                                          Cycle
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_green per_change">
                                        0.2647%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="greenText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="greenText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/eth.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          ETH<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_yellow trade_type">
                                          One shot
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_red per_change">
                                        -50.22%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="redText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="redText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/ada.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          ADA<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_blue trade_type">
                                          Cycle
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_red per_change">
                                        -12.85%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="redText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="redText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/dash.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          DASH<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_yellow trade_type">
                                          One shot
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_green per_change">
                                        0.2647%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="greenText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="greenText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/sol.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          SOL<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_yellow trade_type">
                                          One shot
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_green per_change">
                                        0.2647%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="greenText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="greenText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coin_two"
                          role="tabpanel"
                          aria-labelledby="coin_two-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 300 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    className="track_vertical"
                                    {...props}
                                    style={{
                                      ...style,
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    className="thumb_vertical"
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                    }}
                                  />
                                )}
                              ></Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coin_three"
                          role="tabpanel"
                          aria-labelledby="coin_three-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 300 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    className="track_vertical"
                                    {...props}
                                    style={{
                                      ...style,
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    className="thumb_vertical"
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                    }}
                                  />
                                )}
                              ></Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div className="batch_settings">
                          {/* <p>Binance <i className="bi bi-eye"></i></p> */}
                          <p
                            data-bs-toggle="modal"
                            data-bs-target="#batch_setting_modal"
                          >
                            <i className="bi bi-arrow-clockwise"></i> Batch
                            Setting
                          </p>
                        </div>
                        {/* <div className="choose_pair">
                              <img src={require("../assets/images/usdt.png")} alt="Icon" className="img-fluid" />
                              <p>USDT: 1</p>
                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="batch_settings_modal_right">
                  <div className="operate_settings">
                    <h3>Operate only for selected coin</h3>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        All
                      </label>
                    </div>
                  </div>
                  <div className="batch_settings_btn_grp">
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_01.png")}
                          alt="Icon"
                        />
                        Trade Setting
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_02.png")}
                          alt="Icon"
                        />
                        Sell at CMP
                      </button>
                    </div>
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_03.png")}
                          alt="Icon"
                        />
                        Start
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_04.png")}
                          alt="Icon"
                        />
                        Pause
                      </button>
                    </div>
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_05.png")}
                          alt="Icon"
                        />
                        One-Shot
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_06.png")}
                          alt="Icon"
                        />
                        Cycle
                      </button>
                    </div>
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_07.png")}
                          alt="Icon"
                        />
                        Open Averaging
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_08.png")}
                          alt="Icon"
                        />
                        Stop Averaging
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal */}
    </main>
  );
}
