// import config
import axios, { handleResp } from "../config/axios";

export const WithdrawList = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/withdraw`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const withdrawrequest = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/withdraw`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const withdrawrequestindian = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/withdrawindian`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
