import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../Header.js";
import ProfileSideMenu from "../ProfileSideMenu";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../Footer.js";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";
import { firstCapital } from "../../lib/String";

// import actions
import { getScholarDet, getMentorCounts } from "../../actions/users";

const columns = [
  {
    name: "Email",
    // sortable: true,
    width: "200px",
    selector: (row) => row.email,
  },
  {
    name: "UserId",
    // sortable: true,
    width: "150px",
    selector: (row) => row.userId,
  },
  {
    name: "Position",
    width: "150px",
    selector: (row) => firstCapital(row.Position),
    // sortable: true,
  },
  {
    name: "Date",
    width: "150px",
    selector: (row) => dateTimeFormat(row.Date),
    // sortable: true,
  },
];
const Team = (props) => {
  const dispatch = useDispatch();

  // redux-state
  const account = useSelector((state) => state.account);

  // state
  const [data, setData] = useState([]);
  const [mentor, setMentor] = useState();

  useEffect(() => {
    fetchDetail();
    mentorCount();
  }, []);

  const fetchDetail = async () => {
    try {
      let reqData = {
        level: "s1",
      };
      const { status, loading, result, message, error } = await getScholarDet(
        reqData
      );
      if (status == "success") {
        setData(result);
      }
    } catch (err) {}
  };

  const mentorCount = async () => {
    try {
      const { status, loading, result, message, error } =
        await getMentorCounts();
      if (status == "success") {
        setMentor(result);
      }
    } catch (err) {}
  };

  console.log(account, "___________________account");

  return (
    <div className="form-card">
      <h3>
        <span>Teams</span>
      </h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="flexTitle">
            <ul
              className="nav nav-tabs dashboardTabs"
              id="pairTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="direct_team-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#direct_team"
                  type="button"
                  role="tab"
                  aria-controls="direct_team"
                  aria-selected="true"
                >
                  Direct Team
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="level_team-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#level_team"
                  type="button"
                  role="tab"
                  aria-controls="level_team"
                  aria-selected="false"
                >
                  Level Team
                </button>
              </li> 
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="rank_team-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#rank_team"
                  type="button"
                  role="tab"
                  aria-controls="rank_team"
                  aria-selected="false"
                >
                  Rank Team
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="dashboardPairDetails p-4">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="direct_team"
              role="tabpanel"
              aria-labelledby="direct_team-tab"
            >
              <div className="primary_table">
                <DataTable columns={columns} data={data} pagination />
              </div>
            </div>
            <div
              className="tab-pane fade show active"
              id="level_team"
              role="tabpanel"
              aria-labelledby="level_team-tab"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 1</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s1}
                        </p>
                      </div>
                    </div>
                    {account && account.s1 > 0 && (
                      <a href={"/scholar-details?level=s1"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 2</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s2}
                        </p>
                      </div>
                    </div>
                    {account && account.s2 > 0 && (
                      <a href={"/scholar-details?level=s2"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 3</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s3}
                        </p>
                      </div>
                    </div>
                    {account && account.s3 > 0 && (
                      <a href={"/scholar-details?level=s3"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 4</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s4}
                        </p>
                      </div>
                    </div>
                    {account && account.s4 > 0 && (
                      <a href={"/scholar-details?level=s4"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 5</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s5}
                        </p>
                      </div>
                    </div>
                    {account && account.s5 > 0 && (
                      <a href={"/scholar-details?level=s5"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 6</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s6}
                        </p>
                      </div>
                    </div>
                    {account && account.s6 > 0 && (
                      <a href={"/scholar-details?level=s6"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Level: 7</h4>
                        <p className="mb-0">
                          Total Member: {account && account.s7}
                        </p>
                      </div>
                    </div>
                    {account && account.s7 > 0 && (
                      <a href={"/scholar-details?level=s7"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="rank_team"
              role="tabpanel"
              aria-labelledby="rank_team-tab"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Mentor: 1</h4>
                        <p className="mb-0">
                          Total Member:{" "}
                          {mentor && mentor.m1 && mentor.m1.length}
                        </p>
                      </div>
                    </div>
                    {mentor && mentor.m1 && mentor.m1.length > 0 && (
                      <a href={"/mentor-details?level=m1"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Mentor: 2</h4>
                        <p className="mb-0">
                          Total Member:{" "}
                          {mentor && mentor.m2 && mentor.m2.length}
                        </p>
                      </div>
                    </div>
                    {mentor && mentor.m2 && mentor.m2.length > 0 && (
                      <a href={"/mentor-details?level=m2"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Mentor: 3</h4>
                        <p className="mb-0">
                          Total Member:{" "}
                          {mentor && mentor.m3 && mentor.m3.length}
                        </p>
                      </div>
                    </div>
                    {mentor && mentor.m3 && mentor.m3.length > 0 && (
                      <a href={"/mentor-details?level=m3"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Mentor: 4</h4>
                        <p className="mb-0">
                          Total Member:{" "}
                          {mentor && mentor.m4 && mentor.m4.length}
                        </p>
                      </div>
                    </div>
                    {mentor && mentor.m4 && mentor.m4.length > 0 && (
                      <a href={"/mentor-details?level=m4"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="member_box">
                    <div>
                      <img
                        src={require("../../assets/images/gb_icon.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <div>
                        <h4>Mentor: 5</h4>
                        <p className="mb-0">
                          Total Member:{" "}
                          {mentor && mentor.m5 && mentor.m5.length}
                        </p>
                      </div>
                    </div>
                    {mentor && mentor.m5 && mentor.m5.length > 0 && (
                      <a href={"/mentor-details?level=m5"}>
                        View <i className="bi bi-arrow-right ml-2"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
