import React, { useEffect, useState, useContext } from "react";
import { MassetConversion } from "../../actions/Wallet";
import { useSelector, useDispatch } from "react-redux";
import clsx from "classnames";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { toastAlert } from "../../lib/toastAlert";

//import context
import SocketContext from "../Context/SocketContext";
import { toFixed } from "../../lib/roundOf";

import { getMfeeBal, getReneualalert } from "../../actions/Bottrade";

const initialvalue = {
  showmasset: false,
  showmfee: false,
};

export default function WalletBalance(props) {
  const navigate = useNavigate();
  const socketContext = useContext(SocketContext);
  // state
  const { masset } = props;
  const { kycStatus, nationality } = useSelector((state) => state.account);
  const [formValue, setFormValue] = useState(initialvalue);
  // const [masset, setMasset] = useState();
  const [mfeeBal, setMfeeBal] = useState();
  const { showmasset, showmfee } = formValue;
  const [Renalert, setRenalert] = useState();

  // const fetchMasset = async () => {
  //   const { result } = await MassetConversion();
  //   setMasset(result);
  // };
  const fetchMfeeBal = async () => {
    try {
      const { status, result } = await getMfeeBal();
      if (status == "success") {
        setMfeeBal(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const fetchReneualAlert = async () => {
    try {
      const { status, result } = await getReneualalert();
      if (status == "success") {
        setRenalert(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    fetchMfeeBal();
    fetchReneualAlert();
    // fetchMasset();
    if (kycStatus == "new" || kycStatus == "rejected") {
      toastAlert("error", "Please Submit Your KYC", "kycsection");

      navigate("/dashboard");
    }

    if (kycStatus == "pending") {
      toastAlert("error", "Your Kyc Not Approved", "kycsection");

      navigate("/dashboard");
    }
  }, []);

  // console.log(masset, "__________masset");

  // useEffect(() => {
  //   socketContext.socket.on("priceConv", (data) => {
  //     // console.log(data.convertPrice, "_______-socketContext");
  //     if (masset != undefined) {
  //       // console.log(masset, "_______-masset");

  //       masset.massetBalanceINR = masset.massetBalanceINR;
  //       masset.massetBalanceUSDT = masset.massetBalanceINR / data.convertPrice;
  //       masset.massetFeeINR = masset.massetFeeINR;
  //       masset.massetFeeUSDT = masset.massetFeeINR / data.convertPrice;
  //       setMasset(masset);
  //     }
  //   });
  // }, [masset]);
  // console.log(masset, "__________masset232");

  return (
    <div className="userContent mt-4 mt-lg-0">
      <div className="d-flex align-items-center">
        <h2>Wallet Balance</h2>
      </div>

      {mfeeBal && mfeeBal.status && (
        <div className="alert danger_alert" role="alert">
          <p>{mfeeBal.message}</p>
        </div>
      )}

      {Renalert && Renalert.status && (
        <div className="alert danger_alert" role="alert">
          <p>{Renalert.message}</p>
        </div>
      )}
      <div className="greenNote border-top-0 pt-0" style={{ color: "red" }}>
        {" "}
        Note: GainBOT will stop when MFEE balance falls below -{" "}
        {masset &&
          toFixed(
            masset.botAmount,
            masset && masset.currencySymbol == "INR" ? 2 : 6
          )}{" "}
        {masset && masset.currencySymbol}
      </div>

      <div className="WalletBalance">
        <div className="row">
          <div className="col-lg-6">
            <div className="wbCard">
              <h6>
                M- Asset{" "}
                <Link
                  className="ms-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormValue((el) => {
                      return {
                        ...el,
                        ...{ showmasset: !el.showmasset },
                      };
                    });
                  }}
                >
                  <i
                    className={clsx(
                      "bi",
                      { "bi-eye": showmasset },
                      { "bi-eye-slash": !showmasset }
                    )}
                    aria-hidden="true"
                  ></i>
                </Link>
              </h6>
              {showmasset == false ? (
                <h2>
                  ***** <small style={{ color: "#00c06b" }}>USDT</small>
                </h2>
              ) : (
                <h2>
                  {masset && toFixed(masset.massetBalanceUSDT, 4)}{" "}
                  <small style={{ color: "#00c06b" }}>USDT</small>
                </h2>
              )}
              {showmasset == false ? (
                <h4>= ₹ ***** INR</h4>
              ) : (
                <h4>= ₹ {masset && toFixed(masset.massetBalanceINR, 2)} INR</h4>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="wbCard">
              <h6>
                M- Fee{" "}
                <Link
                  className="ms-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormValue((el) => {
                      return {
                        ...el,
                        ...{ showmfee: !el.showmfee },
                      };
                    });
                  }}
                >
                  <i
                    className={clsx(
                      "bi",
                      { "bi-eye": showmfee },
                      { "bi-eye-slash": !showmfee }
                    )}
                    aria-hidden="true"
                  ></i>
                </Link>
              </h6>
              {showmfee == false ? (
                <h2>
                  ***** <small style={{ color: "#00c06b" }}>USDT</small>
                </h2>
              ) : (
                <h2>
                  {masset && toFixed(masset.massetFeeUSDT, 4)}{" "}
                  <small style={{ color: "#00c06b" }}>USDT</small>
                </h2>
              )}
              {showmfee == false ? (
                <h4>= ₹ ***** INR</h4>
              ) : (
                <h4>= ₹ {masset && toFixed(masset.massetFeeINR, 2)} INR</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
