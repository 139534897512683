// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  if (value.formType == "email") {
    return emailValidation(value);
  } else if (value.formType == "mobile") {
    return mobileValidation(value);
  }
};

export const emailValidation = (value) => {
  let errors = {};
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (isEmpty(value.email)) {
    errors.email = "Please Enter Email";
  } else if (!emailRegex.test(value.email)) {
    errors.email = "Invalid email";
  }

  if (isEmpty(value.password)) {
    errors.password = "Please Enter Password";
  }
  if (value.is2FA) {
    if (isEmpty(value.twoFACode)) {
      errors.twoFACode = "Please Enter 2FA Code";
    }
  }

  // if (isEmpty(value.otp)) {
  //     errors.otp = "REQUIRED"
  // } else if (isNaN(value.otp)) {
  //     errors.otp = "Numberic Number Only Allowed"
  // } else if (value.otp.length > 6) {
  //     errors.otp = "Invalid OTP"
  // }
  // if (!(value.isTerms == true)) {
  //     errors.isTerms = "REQUIRED"
  // }

  return errors;
};

export const mobileValidation = (value) => {
  let errors = {};
  if (isEmpty(value.phoneCode)) {
    errors.phoneCode = "Phone Number Field is Required";
  }

  if (isEmpty(value.phoneNo)) {
    errors.phoneNo = "Mobile Number Field is Required";
  }

  if (value.phoneCode == "91") {
    if (value.phoneNo.length < 10) {
      errors.phoneNo = "Mobile Number is Invalid";
    }
  }
  // if (isEmpty(value.otp)) {
  //     errors.otp = "REQUIRED"
  // } else if (isNaN(value.otp)) {
  //     errors.otp = "Numberic Number Only Allowed"
  // } else if (value.otp.length > 6) {
  //     errors.otp = "Invalid OTP"
  // }

  if (isEmpty(value.password)) {
    errors.password = "Please Enter Password";
  }

  if (value.is2FA) {
    if (isEmpty(value.twoFACode)) {
      errors.twoFACode = "Please Enter 2FA";
    }
  }

  // if (!(value.isTerms == true)) {
  //     errors.isTerms = "REQUIRED"
  // }

  return errors;
};

export default validation;
