// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  if (value.formType == "zap") {
    return zapValidation(value);
  }
  if (value.formType == "buyGenie") {
    return genBuyValidation(value);
  }
  if (value.formType == "sellGenie") {
    return genSellValidation(value);
  }
  if (value.formType == "smaStop") {
    return smaStopValidation(value);
  }
  if (value.formType == "smartSetting") {
    return smaSaveValidation(value);
  }

  if (value.formType == "smartSettingpop") {
    return smaSaveValidationSettings(value);
  }
};

export const zapValidation = (value) => {
  let errors = {};

  if (isEmpty(value.amount)) {
    errors.amount = "Please Enter Amount";
  } else if (value.amount <= 0) {
    errors.amount = "Please Enter Valid Amount";
  }

  if (isEmpty(value.positionData)) {
    if (isEmpty(value.stoploss)) {
      errors.stoploss = "Please Enter Stop Loss";
    }

    if (value.stoploss < 0) {
      errors.stoploss = "Please Enter Valid Amount";
    }

    // if (value.losstype == "USDT" && value.stoploss > value.currentPrice) {
    //   errors.stoploss = "Stop Loss should be Less than Current Price";
    // }

    // if (value.profittype == "USDT" && value.takeprofit < value.currentPrice) {
    //   errors.takeprofit = "Take Profit should be Greater than Current Price";
    // }

    // if (
    //   value.losstype == "%" &&
    //   value.profittype == "%" &&
    //   value.stoploss > value.takeprofit
    // ) {
    //   errors.stoploss = "Stop Loss should be Greater than Take Profit";
    // }
    if (isEmpty(value.takeprofit)) {
      errors.takeprofit = "Please Enter Take Profit";
    } else if (value.takeprofit <= 0) {
      errors.takeprofit = "Please Enter Valid Amount";
    }
    if (isEmpty(value.ratio)) {
      errors.ratio = "Please Enter Ratio";
    } else if (value.ratio < 0) {
      errors.ratio = "Please Enter Valid Amount";
    }
  }
  return errors;
};

export const genBuyValidation = (value) => {
  let errors = {};

  if (isEmpty(value.amount)) {
    errors.buyAmount = "Please Enter Amount";
  }
  if (value.amount <= 0) {
    errors.buyAmount = "Please Enter Valid Amount";
  }
  return errors;
};
export const genSellValidation = (value) => {
  let errors = {};

  if (isEmpty(value.amount)) {
    errors.sellAmount = "Please Enter Amount";
  }
  if (value.amount <= 0) {
    errors.sellAmount = "Please Enter Valid Amount";
  }
  return errors;
};

export const smaStopValidation = (value) => {
  let errors = {};
  let inputFields = value.inputFields;
  // Add their sum of ages
  const sumOfFields = inputFields.reduce((sum, currentField) => {
    return sum + parseFloat(currentField.Ratio);
  }, 0);
  if (isEmpty(value.lossType)) {
    errors.lossType = "Please Select Type";
  }
  if (
    value.lossType == "USDT" &&
    parseFloat(value.stopLoss) > parseFloat(value.currentPrice)
  ) {
    errors.stopLoss = "Stop Loss should be Less than Current Price";
  }
  if (value.lossType == "%" && parseFloat(value.stopLoss) < sumOfFields) {
    errors.stopLoss = "Please Enter Greater than " + sumOfFields;
  }
  // if (isEmpty(value.stopLoss)) {
  //   errors.stopLoss = "Please Enter Stop Loss";
  // }
  if (parseFloat(value.stopLoss) < 0) {
    errors.stopLoss = "Please Enter Valid Amount";
  }
  return errors;
};

export const smaSaveValidation = (value) => {
  let errors = {};
  if (isEmpty(value.takeprofitRatio)) {
    errors.takeprofitRatio = "Please Enter Take Profit Ratio";
  } else if (value.takeprofitRatio <= 0) {
    errors.takeprofitRatio = "Please Enter Valid Value";
  }
  if (isEmpty(value.takeprofitCallback)) {
    errors.takeprofitCallback = "Please Enter SellPullBack";
  } else if (value.takeprofitCallback < 0) {
    errors.takeprofitCallback = "Please Enter Valid Value";
  }
  if (isEmpty(value.buyinCallback)) {
    errors.buyinCallback = "Please Enter BuyInCallBack";
  } else if (value.buyinCallback < 0) {
    errors.buyinCallback = "Please Enter Valid Value";
  }

  if (isEmpty(value.firstbuyAmount)) {
    errors.firstbuyAmount = "Please Enter Amount";
  } else if (value.firstbuyAmount <= 0) {
    errors.firstbuyAmount = "Please Enter Valid Value";
  }

  if (value.priceType == "userPrice") {
    if (isEmpty(value.Price)) {
      errors.Price = "Please Enter Price";
    } else if (value.Price <= 0) {
      errors.Price = "Please Enter Valid Value";
    }
  }

  if (isEmpty(value.margincallLimit)) {
    errors.margincallLimit = "Please Enter Amount";
  } else if (value.margincallLimit <= 0) {
    errors.margincallLimit = "Please Enter Valid Value";
  }

  console.log(errors, "-----lll00999");

  return errors;
};

export const smaSaveValidationSettings = (value) => {
  console.log(value, "----777");
  let errors = {};
  if (isEmpty(value.takeprofitRatio)) {
    errors.takeprofitRatio = "Please Enter Take Profit Ratio";
  } else if (value.takeprofitRatio <= 0) {
    errors.takeprofitRatio = "Please Enter Valid Value";
  }
  if (isEmpty(value.takeprofitCallback)) {
    errors.takeprofitCallback = "Please Enter SellPullBack";
  } else if (value.takeprofitCallback < 0) {
    errors.takeprofitCallback = "Please Enter Valid Value";
  }
  if (isEmpty(value.buyinCallback)) {
    errors.buyinCallback = "Please Enter BuyInCallBack";
  } else if (value.buyinCallback < 0) {
    errors.buyinCallback = "Please Enter Valid Value";
  }

  if (isEmpty(value.firstbuyAmount)) {
    errors.firstbuyAmount = "Please Enter Amount";
  } else if (value.firstbuyAmount <= 0) {
    errors.firstbuyAmount = "Please Enter Valid Value";
  }

  if (isEmpty(value.margincallLimit)) {
    errors.margincallLimit = "Please Enter Amount";
  } else if (value.margincallLimit <= 0) {
    errors.margincallLimit = "Please Enter Valid Value";
  }

  if (isEmpty(value.Name)) {
    errors.Name = "Please Enter Strategy Name";
  }
  console.log(errors, "-----lll00999");

  return errors;
};

export default validation;
