// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  if (isEmpty(value.apiKey)) {
    errors.apiKey = "Please Enter API Key";
  }
  if (isEmpty(value.secretKey)) {
    errors.secretKey = "Please Enter Secret Key";
  }
  if (!value.riskStatus) {
    errors.riskStatus = "Please Select Risk Notice";
  }

  if (value.type == "Kucoin") {
    if (isEmpty(value.passphrase)) {
      errors.passphrase = "Please Enter PassPhrase";
    }
  }

  if (value.type == "BitMart") {
    if (isEmpty(value.memo)) {
      errors.memo = "Please Enter Memo";
    }
  }
  if (isEmpty(value.code)) {
    errors.code = "Kindly Verify Using your Email Verification Code";
  }

  return errors;
};

export default validation;
