// import package
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import browser from "browser-detect";
import Checkbox from "rc-checkbox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import clsx from "classnames";

// import action
import { getGeoInfoData, login, ResentOTP } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getLang } from "../../lib/localStorage";

const initialFormValue = {
  phoneCode: "",
  phoneNo: "",
  formType: "mobile",
  otp: "",
  password: "",
  twoFACode: "",
  remember: false,
  is2FA: false,
  showPassword: "",
};
var setInt;

const MobileForm = (props) => {
  const { tab } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [loginHistory, setLoginHistory] = useState({});
  const [showTwoFA, setShowTowFA] = useState(false);
  const [optStatus, setOtpStatus] = useState(false);
  const [mailOtp, setOTP] = useState(false);
  const [resend, setResend] = useState(false);
  const [Timer, setTimer] = useState(300);
  const [loaders, setLoaders] = useState(false);
  const [count, setCount] = useState("send");

  const {
    phoneCode,
    phoneNo,
    is2FA,
    otp,
    formType,
    password,
    showPassword,
    remember,
    twoFACode,
  } = formValue;

  function myStopFunction() {
    clearInterval(setInt);
    setResend(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "phoneNo" || name == "phoneCode" || name == "password") {
      myStopFunction();
    }
    if (name == "twoFACode" || name == "otp") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
      // if(name == 'twoFACode'){
      //     if(!is2FA){
      //         return
      //     }
      // }
    }

    let formData = { ...formValue, [name]: value };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  // const handleBlur = (e) => {
  //   const { name } = e.target;
  //   setToched({ ...toched, [name]: true });
  // };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipaddress: result.ip,
        region: result.region,
        broswername: browserResult.name,
        ismobile: browserResult.mobile,
        os: browserResult.os,
      });
    } catch (err) {}
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (Timer == 0) {
      if (isEmpty(otp)) {
        toastAlert(
          "success",
          "Please click 'Resend mail OTP' to receive the updated OTP in your registered mail id",
          "login"
        );
      }
    }
    let reqData = {
      phoneCode,
      phoneNo,
      count,
      otp,
      password,
      remember,
      twoFACode,
      loginHistory,
      langCode: getLang(),
      formType,
    };
    let { status, loading, message, userSetting, authToken, error } =
      await login(reqData, dispatch);
    setLoader(loading);
    if (status == "success") {
      setFormValue(initialFormValue);
      if (remember) {
        localStorage.setItem("remember", true);
        localStorage.setItem("phoneCode_remember", phoneCode);
        localStorage.setItem("phoneNo_remember", phoneNo);
        localStorage.setItem("password_remember", password);
      } else {
        localStorage.removeItem("remember");
        localStorage.removeItem("phoneCode_remember");
        localStorage.removeItem("phoneNo_remember");
        localStorage.removeItem("password_remember");
      }

      toastAlert("success", message, "login");
      if (
        userSetting &&
        userSetting.afterLogin &&
        userSetting.afterLogin != " "
      ) {
        navigate(userSetting.afterLogin.url);
      } else {
        navigate("/profile");
      }
    } else if (status == "TWO_FA") {
      setShowTowFA(true);
      toastAlert("error", message, "login");
    } else if (status == "OTP") {
      setOTP(true);

      toastAlert("success", message, "login");
      // setTimeout(() => {
      //   setOtpStatus(false);
      //   setResend(true);
      // }, 300000);
    } else {
      if (error) {
        setValidateError(error);
      }
      if (message == "Your Password is Old Please Reset Your Password") {
        toastAlert("error", message, "login");
        navigate("/reset-password/" + authToken);
      }
      toastAlert("error", message, "login");
    }
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let newPhoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        phoneCode: dialCode,
        phoneNo: newPhoneNo.slice(dialCode.length),
      };
    } else if (value) {
      formData = { ...formData, phoneNo: value };
    }

    setFormValue(formData);
    setValidateError(validation(formData));
  };

  // const handleBlurPhone = (e) => {
  //   setToched({ ...toched, phoneNo: true, phoneCode: true });
  // };

  const handle2FA = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleSentOTP = async (e) => {
    e.preventDefault();
    let reqData = {
      formType,
      phoneCode,
      phoneNo,
    };
    try {
      let { status, loading, error, message } = await ResentOTP(reqData);
      if (status == "success") {
        setLoaders(true);
        callinterval();
        setCount("re" + count);
        toastAlert("success", message, "mobileForm");
      } else {
        if (error) {
          setValidateError(error);
          return;
        }
        toastAlert("error", message, "mobileForm");
      }
    } catch (err) {}
  };

  async function callinterval() {
    var ts = 300;
    setTimer(ts);
    setInt = setInterval(() => {
      ts = ts - 1;
      if (ts == 0) {
        setTimer(ts);
        clearInterval(setInt);
        setLoaders(false);
      } else {
        setTimer(ts);
      }
    }, 1000);
  }

  useEffect(() => {
    getGeoInfo();
    let formData = {};
    if (localStorage.getItem("remember") == "true") {
      formData = formValue;
      formData["phoneCode"] = localStorage.getItem("phoneCode_remember");
      formData["phoneNo"] = localStorage.getItem("phoneNo_remember");
      formData["password"] = localStorage.getItem("password_remember");
      formData["remember"] = true;
      setFormValue(formData);
    } else {
      formData = {
        phoneCode: "",
        phoneNo: "",
        password: "",
        twoFACode: "",
        formType: "mobile",
        remember: false,
      };
      setFormValue(formData);
    }
    setValidateError(validation(formData));
  }, []);

  useEffect(() => {
    if (tab != "mobileform") {
      setFormValue(initialFormValue);
      setValidateError({});
      // setToched({});
    }
  }, [tab]);

  return (
    <Fragment>
      <form>
        {/*<div className="form-group">
                <label>Mobile Number</label>
                <div className="input_box_poa">
                    <PhoneInput
                        placeholder="Mobile"
                        onChange={handlePhoneNumber}
                        onBlur={handleBlurPhone}
                        specialLabel={false}
                        
                        country={'in'}
                    />
                </div>
                {toched.phoneCode && validateError.phoneNo && <p className="text-danger">{validateError.phoneNo}</p>}
            </div>*/}
        <div className="form-group">
          <label className="login_label">Mobile Number</label>
          <PhoneInput
            className="input-group regGroupInput mt-2"
            placeholder="Enter Mobile No"
            value={phoneCode + phoneNo}
            onChange={handlePhoneNumber}
            // onBlur={handleBlurPhone}
            specialLabel={false}
            enableSearch={true}
            country={"in"}
          />
          {/* {validateError.phoneNo && ( */}
          <p className="text-danger">
            {validateError && validateError.phoneNo}
          </p>
          {/* )} */}
        </div>
        <div className="form-group">
          <div className="label-flex">
            <label className="mb-0">Password</label>
            <Link to="/forgot-password" state={{ type: "mobile" }}>
              Forgot Password
            </Link>
          </div>
          <div className="input-group regGroupInput mt-2">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleChange}
              // onBlur={handleBlur}
            />{" "}
            <div className="input-group-text inp_grp_width">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setFormValue((el) => {
                    return { ...el, ...{ showPassword: !el.showPassword } };
                  });
                }}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showPassword },
                    { "bi-eye-slash": !showPassword }
                  )}
                  // aria-hidden="true"
                ></i>
              </Link>
            </div>
          </div>
          {/* {validateError.password && ( */}
          <p className="text-danger">
            {validateError && validateError.password}
          </p>
          {/* )} */}
        </div>
        {mailOtp && (
          <div className="form-group">
            <label>OTP</label>
            <div className="input-group regGroupInput mt-2">
              <input
                type="text"
                className="form-control"
                placeholder="OTP"
                name="otp"
                value={otp}
                onChange={handleChange}
                // onBlur={handleBlur}
              />
              <div className="input-group-text">
                {!loaders && (
                  <button class="input-group-text" onClick={handleSentOTP}>
                    {Timer == 0 ? "Resend Mail OTP" : "Send Mail OTP"}
                  </button>
                )}
                {loaders && <>{Timer} seconds </>}
              </div>
            </div>
            {/* {validateError.otp && ( */}
            <p className="text-danger">{validateError && validateError.otp}</p>
            {/* )} */}
          </div>
        )}
        {showTwoFA && (
          <div className="form-group">
            <div className="switch-group">
              <label htmlFor="flexSwitchCheckChecked">2FA Code</label>
              <div className="form-check form-switch">
                {/* <input 
                            className="form-check-input" 
                            type="checkbox" 
                            id="flexSwitchCheckChecked" 
                            name="is2FA" 
                            onClick={handle2FA} 
                            checked={is2FA}
                        /> */}
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Enter 2FA"
              name="twoFACode"
              value={twoFACode}
              onChange={handleChange}
            />
            {/* {validateError.twoFACode && ( */}
            <p className="text-danger">
              {validateError && validateError.twoFACode}
            </p>
            {/* )} */}
          </div>
        )}
        <div className="form-group mb-0">
          <button
            className="btn btn-green-big"
            onClick={handleFormSubmit}
            disabled={!isEmpty(validateError) || loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default MobileForm;
