import React, { useState, useEffect } from "react";

import SystemError from "./SystemError"
import TradeRelated from "./TradeRelated"
import Announcement from "./Announcement"
import TipOff from "./TipOff"

export default function Notification(props) {
  const { type } = props

  return (
    <div className="col-lg-8"> 
    {
      type && type == "system_error" &&
      <SystemError />
    }
    {
      type && type == "trade_relate" &&
      <TradeRelated />
    }
    {
      type && type == "announcement" &&
      <Announcement />
    }
    {
      type && type == "tip_off" &&
      <TipOff />
    }
  </div>
  );
}
