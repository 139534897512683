import React, { useEffect, useState } from "react";
import Header from "../Header.js";
import ProfileSideMenu from "../ProfileSideMenu";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../Footer.js";

import { PersonalDetails } from "./PersonalDet";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

// import actions
import { getBankDetails, bankUpdate } from "../../actions/users";

import validation from "./validation";

const initialFormValue = {
  bankName: "",
  branchName: "",
  bankType: "",
  bankAccNum: "",
  type: "bank",
  bankIfsc: "",
};

const BankDet = (props) => {
  const dispatch = useDispatch();

  const { editable } = props;

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [loader, setLoader] = useState(false);

  const { data, valid } = props;
  const { bankName, branchName, bankType, bankAccNum, bankIfsc } = formValue;

  // redux-state
  const { kycStatus } = useSelector((state) => state.account);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = validation(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    try {
      const { status, loading, message, error } = await bankUpdate(formValue);
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        fetchDetail();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    try {
      const { status, loading, result, message, error } =
        await getBankDetails();
      setLoader(loading);
      if (status == "success") {
        // console.log(result, "---------------result");
        let formData = {
          ...formValue,
          ...{
            bankName: result.bank.bankName,
            branchName: result.bank.branch,
            bankAccNum: result.bank.accountNumber,
            bankType: result.bank.accountType,
            bankIfsc: result.bank.ifscCode,
          },
        };
        setFormValue(formData);
      }
    } catch (err) {}
  };
  // console.log(kycStatus,editable,'------------------sconsols')

  return (
    <div className="form-card">
      <h3>
        <span>Bank Details</span>
      </h3>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Bank Name</label>
            <input
              type="text"
              className="form-control"
              name="bankName"
              value={bankName}
              // disabled
              onChange={handleChange}
              disabled={editable}
            />
            {/* {validateError && validateError.bankName && (
              <p className="text-danger">{validateError.bankName}</p>
            )} */}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Branch Name</label>
            <input
              type="text"
              className="form-control"
              name="branchName"
              // disabled
              value={branchName}
              onChange={handleChange}
              disabled={editable}
            />
            {/* {validateError && validateError.branchName && (
              <p className="text-danger">{validateError.branchName}</p>
            )} */}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Bank Account No.</label>
            <input
              type="text"
              className="form-control"
              name="bankAccNum"
              value={bankAccNum}
              // disabled
              onChange={handleChange}
              disabled={editable}
            />
            {/* {validateError && validateError.bankAccNum && (
              <p className="text-danger">{validateError.bankAccNum}</p>
            )} */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Account Type</label>

            <select
              className="form-select"
              onChange={handleChange}
              disabled={editable}
              name="bankType"
              value={bankType}
            >
              <option value="">Select Account Type</option>
              <option value="savings_account">Savings account</option>
              <option value="current_account">Current account</option>
            </select>
            {validateError && validateError.bankType && (
              <p className="text-danger">{validateError.bankType}</p>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Bank IFSC Code</label>
            <input
              type="text"
              className="form-control"
              name="bankIfsc"
              value={bankIfsc}
              // disabled
              onChange={handleChange}
              disabled={editable}
            />
            {/* {validateError && validateError.bankIfsc && (
              <p className="text-danger">{validateError.bankIfsc}</p>
            )} */}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        {!editable && kycStatus == "approved" && (
          <div className="form-group text-left mt-4">
            <button
              className="btn btn-green-big ms-0 me-0"
              onClick={handleSubmit}
            >
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankDet;
