import React, { useEffect, useState } from "react";

import { getVideoData } from "../../actions/users";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function VideoTutorials() {
  //state
  const [videoData, setVideoData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { status, loading, result, message, error } = await getVideoData();
    if (status == "success") {
      setVideoData(result);
    }
  };

  const handleClick = (key) => {
    try {
      alert();
      console.log(key);
    } catch (err) {
      console.log(err, "______error");
    }
  };

  return (
    <div className="col-lg-9 col-md-12">
      <div className="userContent">
        <h2>Video Tutorials</h2>
        <div className="row">
          {videoData &&
            videoData.length > 0 &&
            videoData.map((item, key) => {
              let video = item.video.slice(17, 35);
              // console.log(video, "_________-video");
              return (
                <div className="col-md-12 col-lg-6">
                  <div className="video_tutorials_panel">
                    <iframe
                      onClick={() => handleClick(key)}
                      id={"frame" + key}
                      width="100%"
                      height="250"
                      class="embed-responsive-item"
                      src={`${"https://www.youtube.com/embed/"}` + video}
                      title="YouTube video player"
                      frameborder="2"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen="true"
                    ></iframe>
                    {/* <button onclick = "stopVideo(body)"> stop </button> */}
                    <h3>{item.title}</h3>
                  </div>
                </div>
              );
            })}
          {/* <div className="col-md-12 col-lg-6">
                <div className="video_tutorials_panel">
                  <iframe width="100%" height="250" src="https://www.youtube-nocookie.com/embed/QQb_HhNiMKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <h3>Everything about Gainbot</h3>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="video_tutorials_panel">
                  <iframe width="100%" height="250" src="https://www.youtube-nocookie.com/embed/-Lq4hUHfflY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <h3>Complete GainBOT API binding tutorial</h3>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="video_tutorials_panel">
                  <iframe width="100%" height="250" src="https://www.youtube-nocookie.com/embed/QQb_HhNiMKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <h3>Everything about Gainbot</h3>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="video_tutorials_panel">
                  <iframe width="100%" height="250" src="https://www.youtube-nocookie.com/embed/-Lq4hUHfflY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <h3>Complete GainBOT API binding tutorial</h3>
                </div>
              </div>*/}
        </div>
      </div>
    </div>
  );
}
