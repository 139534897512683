import React, { useEffect } from "react";
import HeaderDashboard from "../components/HeaderDashboard.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";
import DataTable from 'react-data-table-component';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import MentorDet from "../components/Mentor"


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function MentorDetails() {

  return (
    <main>
      <ScrollToTopOnMount />
      <HeaderDashboard />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            <MentorDet />
          </div>
        </div>
      </div>
      <Footer />

    </main>

  );
}

