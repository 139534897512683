import React, { Fragment, useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import ShareModal from "../../components/ShareModal";
import { toFixed } from "../../lib/roundOf";
import $ from "jquery";
// import action
import {
  getOpenOrders,
  getTradeHistory,
  getPenOrders,
  cancelOrder,
  savestoploss,
  savestoplosszap,
} from "../../actions/Bottrade";

// import lib
import { firstCapital } from "../../lib/String";
import { dateTimeFormat } from "../../lib/dateFilter";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

// import context
import SocketContext from "../Context/SocketContext";

const historyColumns = [
  {
    name: "Pair",
    selector: (row) => row.pairName,
  },
  {
    name: "Created Date",
    selector: (row) => dateTimeFormat(row.createdAt),
    width: "170px",
  },
  {
    name: "Side",
    selector: (row) => (
      <span className={row.buyorsell == "buy" ? "greenText" : "redText"}>
        {row.orderVia == "clearSell"
          ? "Clear Sell"
          : row.buyorsell.toUpperCase()}
      </span>
    ),
  },
  {
    name: "Trade Amount",
    selector: (row) => toFixed(row.orderValue, 2),
    width: "200px",
  },
  {
    name: "Price",
    selector: (row) => toFixed(row.price, row.priceDecimal),
    width: "156px",
  },
  {
    name: "Quantity",
    selector: (row) => toFixed(row.quantity, row.quantityDecimal),
    width: "120px",
  },
  {
    name: "Status",
    selector: (row) => (
      <span className="greenText">
        {row.status == "cancel" ? firstCapital(row.status) : "Completed"}
      </span>
    ),
    width: "100px",
  },
];

export default function TradeHistory(props) {
  const socketContext = useContext(SocketContext);
  const { botType, pair, exchange } = props;

  const [data, setData] = useState();
  const [isShow, setShow] = useState(false);
  const [tradeData, setTradeData] = useState();
  const [indicateData, setIndicateData] = useState([]);
  const [isIndShow, setIndShow] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [pendingData, setPenData] = useState();
  const [loader, setLoader] = useState(false);
  const [shareId, setshareId] = useState();
  const [stoploss, setstoploss] = useState();
  const [stoplosstype, setstoplosstype] = useState();

  let pairArr = pair.split("-");
  const [seccurrency, Setseccurrency] = useState(pairArr[1]);

  const columns = [
    {
      name: "Pair",
      selector: (row) => row.pairName,
      width: "100px",
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "170px",
    },
    {
      name: "Type",
      selector: (row) => {
        if (row.BotType == "SMART") {
          return row.priceType;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Unrealized PNL",
      width: "150px",
      selector: (row) => (
        <div>
          <span className={row && row.pl < 0 ? "redText" : "greenText"}>
            {toFixed(row.pl, 2)} %
          </span>
          <a
            href="javascript:voidmain(0)"
            data-bs-toggle="modal"
            data-bs-target="#share_modal"
            onClick={() => shareModalclick(row)}
            // onClick={shareModalclick(row)}
          >
            {" "}
            <img
              src={require("../../assets/images/shareIcon.png")}
              className="img-fluid share_icon"
              alt=""
            />
          </a>
        </div>
      ),
    },
    {
      name: "Cycle/One-shot",
      selector: (row) => {
        if (row.BotType == "ZAP") {
          return <>-</>;
        } else {
          return row.tradeSetting.StrategyPeriod;
        }
      },
    },
    {
      name: "Panic Status",
      selector: (row) => {
        if (row.panicStatus) {
          return <>ON</>;
        } else {
          return <>OFF</>;
        }
      },
      width: "140px",
    },
    {
      name: "No.of Avg Calls",
      selector: (row) => {
        if (row.BotType == "ZAP") {
          return <>-</>;
        } else if (!isEmpty(row.noOfCount)) {
          return row.noOfCount;
        } else {
          return 0;
        }
      },
    },
    {
      name: "Avg Call",
      selector: (row) => {
        if (row.BotType == "ZAP") {
          return <>-</>;
        } else {
          return row.tradeSetting.AveragingCall == "Start" ? "ON" : "OFF";
        }
      },
    },
    {
      name: "Investment",
      width: "150px",
      selector: (row) => toFixed(row.investment, 4),
    },
    {
      name: "CMP",
      width: "150px",
      selector: (row) => toFixed(row.cmp, row.priceDecimal),
    },
    {
      name: "Entry Price",
      width: "150px",
      selector: (row) =>
        row.entryPrice[0] && toFixed(row.entryPrice[0].price, row.priceDecimal),
    },
    {
      name: "Current Status",
      width: "150px",
      selector: (row) => (
        <span className="greenText">{firstCapital("open")}</span>
      ),
    },
    {
      name: "Trade Strategy",
      selector: (row) => {
        return (
          <Fragment>
            <a href="#" onClick={() => handleView(row)}>
              View <i className="bi-eye" aria-hidden="true"></i>
            </a>
          </Fragment>
        );
      },
    },
  ];
  const pendingColumns = [
    {
      name: "Pair",
      selector: (row) => row.pairName,
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "170px",
    },
    {
      name: "Entry Price",
      selector: (row) =>
        row.TradeType == "UserPrice"
          ? toFixed(row.price, row.priceDecimal)
          : "-",
      width: "150px",
    },
    {
      name: "Current Status",
      selector: (row) => (
        <span className="greenText">{firstCapital("open")}</span>
      ),
    },
    {
      name: "Type",
      selector: (row) => {
        if (row && row.TradeType == "userPrice") {
          return "User Price";
        } else {
          return <>{row.TradeType}</>;
        }
      },
    },
    {
      name: "Cancel Order",
      selector: (row) => {
        return (
          <div>
            <button
              className="edit_btn"
              onClick={() => handleSubmit(row)}
              disabled={loader}
            >
              {loader && <div class="loader"></div>}
              Cancel
            </button>
          </div>
        );
      },
    },
    {
      name: "Trade Strategy",
      selector: (row) => {
        if (row && row.TradeType == "Indicators") {
          return (
            <Fragment>
              <a href="#" onClick={() => handleIndView(row)}>
                View <i className="bi-eye" aria-hidden="true"></i>
              </a>
            </Fragment>
          );
        } else {
          return <>-</>;
        }
      },
    },
  ];
  const handleSubmit = async (data) => {
    if (!window.confirm("Are you sure want to cancel the order ?")) {
      return;
    }
    setLoader(true);
    try {
      let reqData = {
        _id: data._id,
        liquidityId: data.liquidityId,
        userId: data.userId,
        tradeType: data.TradeType,
        pairName: data.pairName,
        exchange: exchange,
      };
      const { status, message } = await cancelOrder(reqData);
      setLoader(false);
      if (status == "success") {
        toastAlert("success", message);
        fetchPenDetail();
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      } else {
        toastAlert("error", message);
      }
    } catch (err) {}
  };
  useEffect(() => {
    fetchDetail();
    fetchHistoryDetail();
    fetchPenDetail();

    // socket
    let socketTicker = "socketTicker" + exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      // console.log(result, "socket result");
      setData((el) => {
        let pairList = [];
        el &&
          el.map((item) => {
            if (item.pairId == result.pairId) {
              let avgPrice = item.avgPrice / item.quantity;
              let profit =
                ((result.data.markPrice * item.quantity -
                  avgPrice * item.quantity) /
                  (avgPrice * item.quantity)) *
                100;

              let profitinprice =
                (result.data.markPrice * item.quantity -
                  avgPrice * item.quantity) /
                (avgPrice * item.quantity);

              let investment = 0;
              for (let data of item.entryPrice) {
                investment = investment + data.quantity * data.price;
              }

              pairList.push({
                ...item,
                cmp: result.data.markPrice,
                pl: profit ? profit : 0.0,
                investment: toFixed(investment, 4),
                profitinprice: profitinprice,
                StrategyPeriod: item.tradeSetting.StrategyPeriod,
                referralCode: item.referralCode,
              });
            } else {
              pairList.push(item);
            }
          });
        return pairList;
      });
    });

    $(".nav-item #home-tab").addClass("active");
    $(".tab-content div[class=tab-pane]").addClass("active");

    $(".nav-item #profile-tab").removeClass("active");
    $(".tab-content #profile").removeClass("active");
  }, [botType, pair, exchange]);
  useEffect(() => {
    socketContext.socket.on("runningOrder", (result) => {
      console.log(result, "Runn 8888");
      // if (result.pairId == tradePair.pairId) {
      let finalPair = pairData(pair);

      let pairList = [];
      for (let item of result.data) {
        if (
          finalPair == item.pairName &&
          botType.toUpperCase() == item.BotType &&
          exchange == item.exchange
        ) {
          let avgPrice = item.avgPrice / item.quantity;
          let profit =
            ((item.markPrice * item.quantity - avgPrice * item.quantity) /
              (avgPrice * item.quantity)) *
            100;
          let profitinprice =
            (item.markPrice * item.quantity - avgPrice * item.quantity) /
            (avgPrice * item.quantity);

          let investment = 0;
          for (let data of item.entryPrice) {
            investment = investment + data.quantity * data.price;
          }

          let cmp1 = item.markPrice * item.quantity;

          let cmp = item.markPrice * item.quantity - avgPrice * item.quantity;
          // console.log(item, investment, cmp, "000999i9----");
          pairList.push({
            ...item,
            currentvalue: investment + profit,
            cmp: item.markPrice,

            cmp1: cmp1,
            investment: investment ? toFixed(investment, 4) : 0.0,
            pl: profit ? profit : 0.0,
            profitinprice: profitinprice,
            StrategyPeriod:
              item && item.tradeSetting && item.tradeSetting.StrategyPeriod
                ? item.tradeSetting.StrategyPeriod
                : "",
            referralCode: item.referralCode,
          });
        }
      }

      setData(pairList);
      // }
    });
  }, [botType, pair, exchange]);
  useEffect(() => {
    socketContext.socket.on("tradeHistory", (result) => {
      let finalPair = pairData(pair);
      if (
        finalPair == result.pairName &&
        botType.toUpperCase() == result.BotType &&
        exchange == result.exchange
      ) {
        setHistoryData(result.data);
      }
    });
    socketContext.socket.on("pendingOrder", (result) => {
      let finalPair = pairData(pair);
      if (
        finalPair == result.pair &&
        botType.toUpperCase() == result.BotType &&
        exchange == result.exchange
      ) {
        setPenData(result.data);
      }
    });
  }, [botType, pair, exchange]);
  const handleView = async (data) => {
    setShow(true);
    setTradeData(data.tradeSetting);
    setstoploss(
      data.tradeSetting &&
        data.tradeSetting.Tradestrategy[0] &&
        data.tradeSetting.Tradestrategy[0].stopLoss
    );

    setstoplosstype(
      data.tradeSetting &&
        data.tradeSetting.Tradestrategy[0] &&
        data.tradeSetting.Tradestrategy[0].stopLossType
    );
  };
  const handleIndView = async (data) => {
    setIndShow(true);
    setIndicateData(data.tradeSetting.Indicator);
  };
  const onIndHide = async () => {
    setIndShow(false);
  };
  const handleClose = async () => {
    setShow(false);
  };
  const fetchDetail = async () => {
    try {
      let reqData = {
        botType,
        exchange,
        pair: pairData(pair),
      };
      const { status, result, pairDetails } = await getOpenOrders(reqData);
      if (status == "success") {
        let pairList = [];
        for (let item of result) {
          let avgPrice = item.avgPrice / item.quantity;
          let profit =
            ((pairDetails.markPrice * item.quantity -
              avgPrice * item.quantity) /
              (avgPrice * item.quantity)) *
            100;
          let profitinprice =
            (pairDetails.markPrice * item.quantity - avgPrice * item.quantity) /
            (avgPrice * item.quantity);

          let investment = 0;
          for (let data of item.entryPrice) {
            investment = investment + data.quantity * data.price;
          }

          let cmp1 = pairDetails.markPrice * item.quantity;

          let cmp =
            pairDetails.markPrice * item.quantity - avgPrice * item.quantity;
          // console.log(item, investment, cmp, "000999i9----");
          pairList.push({
            ...item,
            currentvalue: investment + profit,
            cmp: pairDetails.markPrice,

            cmp1: cmp1,
            investment: investment ? toFixed(investment, 4) : 0.0,
            pl: profit ? profit : 0.0,
            profitinprice: profitinprice,
            StrategyPeriod:
              item && item.tradeSetting && item.tradeSetting.StrategyPeriod
                ? item.tradeSetting.StrategyPeriod
                : "",
            referralCode: item.referralCode,
          });
        }

        setData(pairList);
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };
  const fetchPenDetail = async () => {
    try {
      let reqData = {
        botType,
        exchange,
        pair: pairData(pair),
      };
      const { status, result } = await getPenOrders(reqData);
      if (status == "success") {
        setPenData(result);
      }
    } catch (err) {}
  };
  const pairData = (data) => {
    let originalData = data.split("-");
    return originalData[0] + originalData[1];
  };
  const fetchHistoryDetail = async () => {
    try {
      let reqData = {
        botType,
        pair: pairData(pair),
        exchange,
      };
      const { status, loading, result, message, error } = await getTradeHistory(
        reqData
      );
      if (status == "success") {
        setHistoryData(result);
      }
    } catch (err) {}
  };
  const shareModalclick = async (row) => {
    if (!isEmpty(row)) {
      setshareId(row._id);
    }
    return;
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setstoploss(value);
  };

  const handleChangeLosstype = async (e) => {
    const { name, value } = e.target;
    setstoplosstype(value);
  };

  const handlestoploss = async (id) => {
    try {
      let reqData = {
        _id: id,
        stoploss: stoploss,
      };
      const { status, result, message } = await savestoploss(reqData);
      console.log(status, "122------8876");
      if (status == "success") {
        setShow(false);
        toastAlert("success", message);
        fetchDetail();
      } else {
        toastAlert("error", message);
      }
    } catch (err) {}
  };

  const handlestoplosszap = async (id) => {
    try {
      let reqData = {
        _id: id,
        stoploss: stoploss,
        stoplosstype: stoplosstype,
      };
      const { status, result, message } = await savestoplosszap(reqData);
      console.log(status, "122------8876");
      if (status == "success") {
        setShow(false);
        toastAlert("success", message);
        fetchDetail();
      } else {
        toastAlert("error", message);
      }
    } catch (err) {}
  };

  function TimeDisplay(val) {
    let text;
    switch (val) {
      case "1m":
        text = "1 Min";
        break;
      case "5m":
        text = "5 Min";
        break;
      case "15m":
        text = "15 Min";
        break;

      case "30m":
        text = "30 Min";
        break;

      case "1h":
        text = "1 Hour";
        break;

      case "4h":
        text = "4 Hours";
        break;

      case "1d":
        text = "1 Day";
        break;

      default:
        text = "";
    }
    return text;
  }

  return (
    <div className="trade_main_left_bottom">
      <div className="card trade_card trade_history_card">
        <div className="card-header">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Running
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Trade History
              </button>
            </li>
            {botType == "Smart" && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pending-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pending"
                  type="button"
                  role="tab"
                  aria-controls="pending"
                  aria-selected="false"
                >
                  Pending Order
                </button>
              </li>
            )}
          </ul>
          <h2>
            {botType == "Genie" && (
              <img
                src={require("../../assets/images/icon_genie.png")}
                alt="Icon"
                className="img-fluid"
              />
            )}

            {botType == "Smart" && (
              <img
                src={require("../../assets/images/icon_smart.png")}
                alt="Icon"
                className="img-fluid"
              />
            )}

            {botType == "Zap" && (
              <img
                src={require("../../assets/images/icon_zap.png")}
                alt="Icon"
                className="img-fluid"
              />
            )}

            {botType}
          </h2>
        </div>
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="primary_table">
                <DataTable columns={columns} data={data} pagination />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="primary_table">
                <DataTable
                  columns={historyColumns}
                  data={historyData}
                  pagination
                />
              </div>
              {/* <p className="text-center mt-5">No Records Found</p> */}
            </div>
            <div
              className="tab-pane fade"
              id="pending"
              role="tabpanel"
              aria-labelledby="pending-tab"
            >
              <div className="primary_table">
                <DataTable
                  columns={pendingColumns}
                  data={pendingData}
                  pagination
                />
              </div>
              {/* <p className="text-center mt-5">No Records Found</p> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isShow}
        onHide={handleClose}
        centered
        size="sz"
        className="modal fade primary_modal"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">Trade Strategy</h1>
        </Modal.Header>

        <Modal.Body>
          <div class="modal-body">
            {tradeData && tradeData.BotType == "ZAP" && (
              <>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Amount</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].Amount}
                  </span>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Stop Loss(Optional)</span>

                  <span className="f_16">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={stoploss && stoploss}
                        name="stoploss"
                        className="form-control"
                      />

                      <select
                        className="form-select"
                        name="losstype"
                        onChange={(e) => handleChangeLosstype(e)}
                        value={stoplosstype}
                      >
                        <option value="%">%</option>
                        <option value="USDT">
                          {seccurrency && seccurrency.toUpperCase()}
                        </option>
                      </select>

                      <div
                        className="input-group-text"
                        onClick={() =>
                          handlestoplosszap(tradeData && tradeData._id)
                        }
                      >
                        Save
                      </div>
                    </div>
                  </span>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Take Profit</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].takeProfit}{" "}
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].takeProfitType}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Sell Pull Back</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].sellPullback}{" "}
                    %
                  </span>
                </div>
              </>
            )}
            {tradeData && tradeData.BotType != "ZAP" && (
              <>
                {tradeData && tradeData.BotType == "SMART" && (
                  <>
                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <span className="f_16">Type</span>
                      <span className="f_16">
                        {tradeData && tradeData.priceType}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <span className="f_16">Stop Loss (%) (Optional)</span>

                      <span className="f_16">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            onChange={handleChange}
                            value={stoploss && stoploss}
                            name="stoploss"
                            className="form-control"
                          />
                          <div
                            className="input-group-text"
                            onClick={() =>
                              handlestoploss(tradeData && tradeData._id)
                            }
                          >
                            Save
                          </div>
                        </div>
                      </span>
                    </div>
                  </>
                )}
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Amount</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].firstbuyAmount}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Buy Pull Back</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].buyinCallback}{" "}
                    %
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Take Profit</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].takeprofitRatio}{" "}
                    %
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Sell Pull Back</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].takeprofitCallback}{" "}
                    %
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">No of Times</span>
                  <span className="f_16">
                    {tradeData &&
                      tradeData.Tradestrategy[0] &&
                      tradeData.Tradestrategy[0].margincallLimit}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <span className="f_16">Averaging call Drop</span>
                  <span className="f_16">Times</span>
                </div>
                {tradeData &&
                  tradeData.Tradestrategy[0] &&
                  tradeData.Tradestrategy[0].customAmountSetup.length > 0 &&
                  tradeData.Tradestrategy[0].customAmountSetup.map(
                    (item, i) => {
                      let ii = i + 1;
                      let conj = "";
                      if (ii == 1) {
                        conj = "st";
                      } else if (ii == 2) {
                        conj = "nd";
                      } else if (ii == 3) {
                        conj = "rd";
                      } else if (ii >= 4) {
                        conj = "th";
                      }
                      return (
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <span className="f_16">
                            {ii + conj + " Average call Drop : "}
                            {item.Ratio}%
                          </span>

                          <span className="f_16">{item.marginCall} Times</span>
                        </div>
                      );
                    }
                  )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isIndShow}
        onHide={onIndHide}
        centered
        size="md"
        className="modal fade primary_modal"
      >
        <Modal.Header closeButton>
          <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
            Indicators
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="accordion primary_accordian" id="indicators_list">
                  {indicateData.map((item, key) => {
                    // console.log(item, "-----------item");
                    let title = "";
                    if (
                      item.indicatorType == "RSI" ||
                      item.indicatorType == "BB"
                    ) {
                      title = item.indicatorType + "-" + item.indicator;
                    } else {
                      title = item.indicatorType;
                    }
                    return (
                      <Fragment key={`${item}~${key}`}>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id={"heading" + key}>
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapse" + key}
                              aria-expanded="true"
                              aria-controls={"collapse" + key}
                            >
                              {item.indicatorType == "RSI" ||
                              item.indicatorType == "BB"
                                ? item.indicatorType + "-" + item.indicator
                                : item.indicatorType}
                            </button>
                          </h2>
                          <div
                            id={"collapse" + key}
                            class="accordion-collapse collapse"
                            aria-labelledby={"heading" + key}
                            data-bs-parent="#indicators_list"
                          >
                            <div class="accordion-body">
                              <div className="indicators_panel">
                                <div>
                                  <label>
                                    Candle
                                    {item.indicatorType == "SMA" ||
                                    item.indicatorType == "EMA"
                                      ? " (CMP)"
                                      : " "}
                                  </label>
                                  <span className="greenText">
                                    {TimeDisplay(item.Candle)}
                                  </span>
                                </div>
                                {item.indicatorType == "MACD" && (
                                  <div>
                                    <label>Type</label>
                                    <span className="greenText">
                                      {item.type}
                                    </span>
                                  </div>
                                )}
                                {item.indicatorType !== "GoldenCross" &&
                                  item.indicatorType !== "DeathCross" &&
                                  item.type != "signal" && (
                                    <div>
                                      <label>
                                        Condition
                                        {item.indicatorType == "BB"
                                          ? " (CMP)"
                                          : item.indicatorType == "RSI"
                                          ? " (RSI)"
                                          : " "}
                                      </label>
                                      <span className="greenText">
                                        {item.Condition == "lessthan"
                                          ? "Less than"
                                          : item.Condition == "greaterthan"
                                          ? "Greater than"
                                          : item.Condition.toUpperCase()}
                                      </span>
                                    </div>
                                  )}
                                {(item.indicatorType == "SMA" ||
                                  item.indicatorType == "EMA") && (
                                  <div>
                                    <label>Length</label>
                                    <span className="greenText">
                                      {item.indicator}
                                    </span>
                                  </div>
                                )}
                                {item.indicatorType == "SMA/EMA Cross" && (
                                  <>
                                    <div>
                                      <label>
                                        {item.smaema1.toUpperCase()}
                                      </label>
                                      <span className="greenText">
                                        {item.indicator}
                                      </span>
                                    </div>
                                  </>
                                )}
                                {item.indicatorType == "SMA/EMA Cross" && (
                                  <>
                                    <div>
                                      <label>
                                        {item.smaema2.toUpperCase()}
                                      </label>
                                      <span className="greenText">
                                        {item.indicator1}
                                      </span>
                                    </div>
                                  </>
                                )}

                                {item.indicator == "2" && (
                                  <>
                                    <div>
                                      <label>Band</label>
                                      <span className="greenText">
                                        {firstCapital(item.band)} band
                                      </span>
                                    </div>
                                    {item.indicatorType == "BB-2" && (
                                      <div>
                                        <label>Price</label>
                                        <span className="greenText">
                                          {item.price}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}

                                {(item.indicatorType == "RSI" ||
                                  (item.indicatorType == "MACD" &&
                                    item.type == "histogram") ||
                                  item.indicatorType == "WMA") && (
                                  <div>
                                    <label>Value</label>
                                    <span className="greenText">
                                      {item.value}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                      // <div className="saved_strategies_panel">
                      //   <button data-bs-dismiss="modal" disabled>
                      //     {item.indicator}
                      //   </button>

                      // </div>
                    );
                    // return (
                    //   <Fragment key={`${item}~${key}`}>
                    //     <div class="accordion-item">
                    //       <h2 class="accordion-header" id={"heading" + key}>
                    //         <button
                    //           class="accordion-button"
                    //           type="button"
                    //           data-bs-toggle="collapse"
                    //           data-bs-target={"#collapse" + key}
                    //           aria-expanded="true"
                    //           aria-controls={"collapse" + key}
                    //         >
                    //           {title}
                    //         </button>
                    //       </h2>
                    //       <div
                    //         id={"collapse" + key}
                    //         class="accordion-collapse collapse"
                    //         aria-labelledby={"heading" + key}
                    //         data-bs-parent="#indicators_list"
                    //       >
                    //         <div class="accordion-body">
                    //           <div className="indicators_panel">
                    //             <div>
                    //               <label>Candle</label>
                    //               <input
                    //                 class="form-control indicators_inp"
                    //                 type="text"
                    //                 value={item.Candle}
                    //                 disabled={true}
                    //               />
                    //             </div>
                    //             {(item.indicatorType == "SMA" ||
                    //               item.indicatorType == "EMA") && (
                    //               <div>
                    //                 <label>Length</label>
                    //                 <span className="greenText">
                    //                   {item.indicator}
                    //                 </span>
                    //               </div>
                    //             )}
                    //             {item.indicatorType == "SMA/EMA Cross" && (
                    //               <>
                    //                 <div>
                    //                   <label>{item.smaema1}</label>
                    //                   <span className="greenText">
                    //                     {item.indicator}
                    //                   </span>
                    //                 </div>
                    //                 <div>
                    //                   <label>{item.smaema2}</label>
                    //                   <span className="greenText">
                    //                     {item.indicator1}
                    //                   </span>
                    //                 </div>
                    //               </>
                    //             )}
                    //             {item.indicatorType !== "GoldenCross" &&
                    //               item.indicatorType !== "DeathCross" &&
                    //               item.type != "signal" && (
                    //                 <div>
                    //                   <label>Condition</label>
                    //                   <input
                    //                     class="form-control indicators_inp"
                    //                     type="text"
                    //                     value={item.Condition}
                    //                     disabled={true}
                    //                   />
                    //                 </div>
                    //               )}
                    //             {title == "BB-2" && (
                    //               <>
                    //                 <div>
                    //                   <label>Band</label>
                    //                   <input
                    //                     class="form-control indicators_inp"
                    //                     type="text"
                    //                     value={item.band}
                    //                     disabled={true}
                    //                   />
                    //                 </div>
                    //                 <div>
                    //                   <label>Price</label>
                    //                   <input
                    //                     class="form-control indicators_inp"
                    //                     type="text"
                    //                     value={item.price}
                    //                     disabled={true}
                    //                   />
                    //                 </div>
                    //               </>
                    //             )}
                    //             {(item.indicatorType == "RSI" ||
                    //               (item.indicatorType == "MACD" &&
                    //                 item.type == "histogram") ||
                    //               item.indicatorType == "WMA") && (
                    //               <div>
                    //                 <label>Value</label>
                    //                 <input
                    //                   class="form-control indicators_inp"
                    //                   name="value"
                    //                   type="number"
                    //                   value={item.value}
                    //                   disabled={true}
                    //                 />
                    //               </div>
                    //             )}
                    //             {item.indicatorType == "MACD" && (
                    //               <div>
                    //                 <label>Type</label>
                    //                 <span className="greenText">
                    //                   {item.type}
                    //                 </span>
                    //               </div>
                    //             )}
                    //           </div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </Fragment>
                    // );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ShareModal shareId={shareId} ShareData={data} />
    </div>
  );
}
