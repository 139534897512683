import React, { useEffect } from "react";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Notification() {

  return (
    <div className="api_binding_card">
      <div className="api_binding_card_title">
        <h3>Notification</h3>
        <a href="javascipt:void(0)">See the instructions</a>
      </div>
      <div className="api_binding_card_content">
        <ol>
          <li>Please confirm that the API permissions has checked "Enable Spot & Margin Trading" Do not check "Withdraw" option.</li>
          <li>Please enter the correct API please do not enter special character.</li>
        </ol>
      </div>
    </div>

  );
}
