import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "../components/Header.js";
import WalletSideMenu from "../components/Wallet/WalletSideMenu";
import TransferWallet from "../components/Wallet/TransferWallet";
import WalletBalance from "../components/Wallet/WalletBalance";
import TransferHistory from "../components/Wallet/TransferHistory";
import TransferWalletIndian from "../components/Wallet/TransferWalletIndian";

import Footer from "../components/Footer.js";
import { MassetConversion } from "../actions/Wallet";
import { TransferList } from "../actions/Transfer";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function WalletTransfer() {
  const [TxnList, setTxnList] = useState([]);
  const [masset, Setmasset] = useState();
  // redux-state
  const { kycStatus, nationality } = useSelector((state) => state.account);
  const fetchMasset = async () => {
    const { status, loading, message, error, result } =
      await MassetConversion();
    console.log(result, "----------------------------31");
    Setmasset(result);
  };

  const fetchtransferHistory = async (reqData) => {
    const { status, loading, message, error, result } = await TransferList(
      reqData
    );
    setTxnList(result);
  };

  useEffect(() => {
    let reqData = {
      filter: "all",
    };
    fetchMasset();
    fetchtransferHistory(reqData);
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <WalletSideMenu />
              <TransferWallet
                nationality={nationality}
                masset={masset}
                fetchMasset={fetchMasset}
                fetchtransferHistory={fetchtransferHistory}
              />

              {/* <TransferWalletIndian /> */}
            </div>
            <div className="col-lg-8">
              <WalletBalance masset={masset} />
              <TransferHistory
                nationality={nationality}
                TxnList={TxnList}
                fetchtransferHistory={fetchtransferHistory}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
