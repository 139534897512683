import React, { useEffect, useState } from "react";
import HeaderDashboard from "../components/HeaderDashboard.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";
import DataTable from "react-data-table-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

//import api
import {
  getRevenueHistory,
  getProfitHistory,
  getTotalProfit,
} from "../actions/Bottrade";

//lib
import { momentFormat } from "../lib/dateFilter";
import { toFixed } from "../lib/roundOf";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const columns = [
  {
    name: "Pair",
    // sortable: true,
    selector: (row) => row.pairName,
  },
  {
    name: "Exchange",
    // sortable: true,
    selector: (row) => row.exchange,
  },
  {
    name: "BotType",
    // sortable: true,
    selector: (row) => row.botType,
  },
  {
    name: "Date & Time (UTC)",
    selector: (row) => momentFormat(row.createdAt, "YYYY-MM-DD HH:mm"),
    // sortable: true,
    width: "150px",
  },
  {
    name: "Profit",
    selector: (row) => (
      <span style={{ color: "#189846" }}>
        {row.nationality == "indian"
          ? " ₹ " + toFixed(row.profit, 6)
          : "$ " + toFixed(row.convert_profit, 6)}
      </span>
    ),
    width: "150px",
    // sortable: true,
  },
];

const columns_profit = [
  {
    name: "Date & Time (UTC)",
    selector: (row) => row._id,
    // sortable: true,
  },
  {
    name: "Profit",
    selector: (row) => (
      <span style={{ color: "#189846" }}>
        {" "}
        {row.nationality == "indian"
          ? " ₹ " + toFixed(row.inr, 6)
          : "$ " + toFixed(row.usd, 6)}
      </span>
    ),
    // sortable: true,
  },
];

export default function RevenueDetails() {
  //state
  const [revenueData, setRevenueData] = useState();
  const [profitData, setprofitData] = useState();
  const [totalprofit, setTotalProfit] = useState({});
  const [todayprofit, setTodayprofit] = useState({});

  const fetchrevenue = async () => {
    const { status, loading, message, error, result } =
      await getRevenueHistory();
    console.log(result, "resultresultresult");
    setRevenueData(result);
  };

  const fetchProfit = async () => {
    const { status, loading, message, error, result } =
      await getProfitHistory();
    // console.log(result, "resultresultresult");
    setprofitData(result);
  };

  const fetchTotalProfit = async () => {
    const { status, loading, message, error, result } = await getTotalProfit();
    console.log(result[0].today[0], "Total 123_______________result");
    setTodayprofit(result && result[0].today[0]);
    setTotalProfit(result && result[0].total[0]);
  };

  var settingsGeniePair = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchrevenue();
    fetchProfit();
    fetchTotalProfit();
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <HeaderDashboard />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="dashboardTwoColumn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="dashboardContent mt-2">
                      <div className="dashboardContentTitleBar p-0">
                        <ul
                          class="nav nav-tabs dashboardTabs"
                          id="pairTab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="revenue_details-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#revenue_details"
                              type="button"
                              role="tab"
                              aria-controls="revenue_details"
                              aria-selected="true"
                            >
                              Revenue Details
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="total_profit-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#total_profit"
                              type="button"
                              role="tab"
                              aria-controls="total_profit"
                              aria-selected="false"
                            >
                              Total Profit
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="dashboardPairDetails p-4">
                        <div className="revenue_flex">
                          <h4>
                            Today's Profit:{" "}
                            <span>
                              ${" "}
                              {todayprofit && todayprofit.todayusd
                                ? toFixed(todayprofit.todayusd, 4)
                                : 0.0}{" "}
                              <small>
                                (&#8377;{" "}
                                {todayprofit && todayprofit.todayinr
                                  ? toFixed(todayprofit.todayinr, 4)
                                  : 0.0}{" "}
                                )
                              </small>
                            </span>
                          </h4>
                          <h4>
                            Total Profit:{" "}
                            <span>
                              ${" "}
                              {totalprofit && totalprofit.totalusd
                                ? toFixed(totalprofit.totalusd, 4)
                                : 0.0}{" "}
                              <small>
                                (&#8377;{" "}
                                {totalprofit && totalprofit.totalinr
                                  ? toFixed(totalprofit.totalinr, 4)
                                  : 0.0}{" "}
                                )
                              </small>
                            </span>
                          </h4>
                        </div>
                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane fade show active"
                            id="revenue_details"
                            role="tabpanel"
                            aria-labelledby="revenue_details-tab"
                          >
                            <div className="primary_table">
                              <DataTable
                                columns={columns}
                                data={revenueData}
                                pagination
                              />
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="total_profit"
                            role="tabpanel"
                            aria-labelledby="total_profit-tab"
                          >
                            <div className="primary_table">
                              <DataTable
                                columns={columns_profit}
                                data={profitData}
                                pagination
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
