// import config
import axios , {handleResp}from '../config/axios'

// import constant
import {
  SET_KYC_VALIDATE,
} from "../constant";

import { setAccountData } from "./users"

export const kycInd = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/indkyc`,
            'data': data
        });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, 'error')
    dispatch(setKycValidate(err.response.data.errors))
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
}
}

export const kycNonInd = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/nonindkyc`,
            'data': data
        });
        dispatch(setAccountData(respData.data.result));
        return {
          status: "success",
          loading: false,
          message: respData.data.message,
          // userToken: respData.data.userToken,
        };

  } catch (err) {
    handleResp(err, 'error')
    dispatch(setKycValidate(err.response.data.errors))
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
}
}

export const setKycValidate = (data) => {
  return {
    type: SET_KYC_VALIDATE,
    data,
  };
}

export const getKycDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-kyc`,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};