import React, { useEffect } from "react";
import Header from "../components/Header.js";
import NotificationSideMenu from "../components/NotificationSideMenu";
import Footer from "../components/Footer.js";
import Notification from "../components/Notification";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function NotificationAnnouncement() {

  return (
<main>
	 <ScrollToTopOnMount />
          <Header />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <NotificationSideMenu />
                </div>
                <Notification type="announcement" />
              </div>
            </div>
          </div>
          <Footer />
    </main>

  );
}
