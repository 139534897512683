// import package
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import $ from "jquery";
// import component
import EmailForm from "./EmailForm";
import MobileForm from "./MobileForm";
import Logo from "../../components/Logo";

const LoginForm = () => {
  const location = useLocation();
  const { type } = location.state;

  console.log(type, "_________type");

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(".radio_inp_email").click(function () {
      $(".radio_inp_email").addClass("active");
      $(".radio_inp_mobile").removeClass("active");
    });
    $(".radio_inp_mobile").click(function () {
      $(".radio_inp_mobile").addClass("active");
      $(".radio_inp_email").removeClass("active");
    });
  }
  const createMarkup = (a) => {
    return { __html: a };
  };

  const handleClick = (e, tab) => {
    // console.log(tab, "______e");
  };

  return (
    <div className="row">
      <div className="col-md-6 col-lg-6 col-xl-5 mx-auto logo_beforeauth">
        <Logo/>
        <div className="crediantial-form">
          <h2>Forgot Password</h2>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              {type == "email" && (
                <button
                  className="nav-link active"
                  value="email"
                  disabled
                  id="nav-email-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-email"
                  type="button"
                  role="tab"
                  aria-controls="nav-email"
                  aria-selected="true"
                >
                  Email
                </button>
              )}
              {type == "mobile" && (
                <button
                  disabled
                  className="nav-link active"
                  value="mobile"
                  id="nav-mobNumber-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mobNumber"
                  type="button"
                  role="tab"
                  aria-controls="nav-mobNumber"
                  aria-selected="false"
                >
                  Mobile Number
                </button>
              )}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            {type == "email" && (
              <div
                className="tab-pane fade active show"
                id="nav-email"
                onClick={(e) => handleClick(e, "email")}
                role="tabpanel"
                aria-labelledby="nav-email-tab"
              >
                <EmailForm />
              </div>
            )}
            {type == "mobile" && (
              <div
                className="tab-pane fade active show"
                id="nav-mobNumber"
                onClick={(e) => handleClick(e, "mobile")}
                role="tabpanel"
                aria-labelledby="nav-mobNumber-tab"
              >
                <MobileForm />
              </div>
            )}
          </div>
          <h6 className="crediantila-bottom">
            <Link to="/signin">Login</Link>
          </h6>
        </div>
        <h6 className="crediantila-bottom">
          Don’t have an account? <Link to="/">Sign up here</Link>
        </h6>
      </div>
    </div>
  );
};

export default LoginForm;
