import React, { useEffect, useState } from "react";
import clsx from "classnames";
import { Link } from "react-router-dom";
import { getBalanceData, setBalanceData } from "../../actions/users";
import { SET_DASHBOARD_EXCHANGE } from "../../constant";
import { useDispatch } from "react-redux";

// import lib
import isEmpty from "../../lib/isEmpty";
const initialvalue = {
  showbinance: false,
  showkucoin: false,
  showbitmart: false,
};
export default function BotBalance() {
  //state
  const [balanceData, setBalance] = useState({});
  const [formValue, setFormValue] = useState(initialvalue);
  const { showbinance, showkucoin, showbitmart } = formValue;
  const [exchangetype, setExchangeType] = useState("Binance");
  const [loader, setLoader] = useState(false);
  const [eyeLoader, setEyeLoader] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (e, data) => {
    setExchangeType(data);

    // dispatch({
    //   type: SET_DASHBOARD_EXCHANGE,
    //   exchange: {
    //     exchangetype,
    //   },
    // });
  };

  useEffect(() => {
    fetchData();
  }, [exchangetype]);
  const eyeClick = async (e, data) => {
    try {
      e.preventDefault();
      setEyeLoader(true);
      let value = false;
      if (data == "showbinance") {
        value = !showbinance;
      }
      if (data == "showkucoin") {
        value = !showkucoin;
      }
      if (data == "showbitmart") {
        value = !showbitmart;
      }
      let formData = { ...formValue, ...{ [data]: value } };
      setFormValue(formData);
      if (
        value
        // ((exchangetype == "Binance" &&
        //   isEmpty(balanceData && balanceData.binanceStatus)) ||
        //   (exchangetype == "Kucoin" &&
        //     isEmpty(balanceData && balanceData.kucoinStatus)) ||
        //   (exchangetype == "BitMart" &&
        //     isEmpty(balanceData && balanceData.bitmartStatus)))
      ) {
        let reqData = {
          exchange: exchangetype,
        };
        const { status, result } = await setBalanceData(reqData);
        if (status == "success") {
          setBalance(result);
          setLoader(true);
          setEyeLoader(false);
        }
      } else {
        setEyeLoader(false);
      }
    } catch (err) {
      console.log(err, "----------50");
    }
  };
  const fetchData = async () => {
    dispatch({
      type: SET_DASHBOARD_EXCHANGE,
      exchange: {
        exchangetype,
      },
    });
    let reqData = { exchange: exchangetype };
    const { status, loading, result, message, error } = await getBalanceData(
      reqData
    );
    if (status == "success") {
      setBalance(result);
      setLoader(true);
    }
  };
  return (
    <div className="dashboardContent">
      <div className="dashboardContentTitleBar p-0">
        <ul
          class="nav nav-tabs nav-fill dashboardTabs"
          id="balaceTab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="binance-tab"
              data-bs-toggle="tab"
              data-bs-target="#binance"
              type="button"
              role="tab"
              aria-controls="binance"
              aria-selected="true"
              onClick={(e) => handleClick(e, "Binance")}
            >
              <img
                src={require("../../assets/images/bianaceLogo.png")}
                className="img-fluid"
                alt=""
              />
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="kucoin-tab"
              data-bs-toggle="tab"
              data-bs-target="#kucoin"
              type="button"
              role="tab"
              aria-controls="kucoin"
              aria-selected="false"
              onClick={(e) => handleClick(e, "Kucoin")}
            >
              <img
                src={require("../../assets/images/kucoinLogo.png")}
                className="img-fluid"
                alt=""
              />
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="bitmart-tab"
              data-bs-toggle="tab"
              data-bs-target="#bitmart"
              type="button"
              role="tab"
              aria-controls="bitmart"
              aria-selected="false"
              onClick={(e) => handleClick(e, "BitMart")}
            >
              <img
                src={require("../../assets/images/bitmartLogo.png")}
                className="img-fluid"
                alt=""
              />
            </button>
          </li>
        </ul>
      </div>
      <div className="dashboardBalanceDetails p-4">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="binance"
            role="tabpanel"
            aria-labelledby="binance-tab"
          >
            <span className="greenText">
              {" "}
              {balanceData.binStatus && "API Key Binded"}
            </span>
            {/* {balanceData && balanceData.binanceStatus && ( 
            <>*/}
            {!eyeLoader && (
              <Link
                className="ms-2"
                onClick={(e) => eyeClick(e, "showbinance")}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showbinance },
                    { "bi-eye-slash": !showbinance }
                  )}
                  aria-hidden="true"
                ></i>
              </Link>
            )}
            {/*   </>
            )} */}
            {balanceData &&
              balanceData.binanceStatus &&
              balanceData.binance &&
              balanceData.binance.length > 0 &&
              balanceData.binance.map((item, i) => {
                return (
                  <>
                    {showbinance == false ? (
                      <h2>********* {item.asset}</h2>
                    ) : (
                      <h2>
                        {item.free} {item.asset}
                      </h2>
                    )}
                  </>
                );
              })}
            {!balanceData.binStatus && loader && (
              <h2>Please Bind Your API Key</h2>
            )}
          </div>
          <div
            class="tab-pane fade"
            id="kucoin"
            role="tabpanel"
            aria-labelledby="kucoin-tab"
          >
            <span className="greenText">
              {balanceData && balanceData.kucoinStatus && "API Key Binded"}
            </span>
            {/* {balanceData && balanceData.kucoinStatus && (
              <> */}

            {!eyeLoader && (
              <Link className="ms-2" onClick={(e) => eyeClick(e, "showkucoin")}>
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showkucoin },
                    { "bi-eye-slash": !showkucoin }
                  )}
                  aria-hidden="true"
                ></i>
              </Link>
            )}
            {/* </>
            )} */}
            {balanceData &&
              balanceData.kucoinStatus &&
              balanceData.kucoin &&
              balanceData.kucoin.length > 0 &&
              balanceData.kucoin.map((item, i) => {
                return (
                  <>
                    {showkucoin == false ? (
                      <h2>********* {item.currency}</h2>
                    ) : (
                      <h2>
                        {item.available} {item.currency}
                      </h2>
                    )}
                    {/* <h2>
                    {item.balance} {item.currency}
                  </h2> */}
                  </>
                );
              })}
            {!balanceData.kucStatus && loader && (
              <h2>Please Bind Your API Key</h2>
            )}
            {/*<h2>1229.64 USDT</h2>
          <h2>10.02 BTC</h2>
          <h2>12.84 ETH</h2>*/}
          </div>
          <div
            class="tab-pane fade"
            id="bitmart"
            role="tabpanel"
            aria-labelledby="bitmart-tab"
          >
            <span className="greenText">
              {balanceData && balanceData.bitmartStatus && "API Key Binded"}
            </span>
            {/* {balanceData && balanceData.bitmartStatus && (
              <> */}

            {!eyeLoader && (
              <Link
                className="ms-2"
                onClick={(e) => eyeClick(e, "showbitmart")}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showbitmart },
                    { "bi-eye-slash": !showbitmart }
                  )}
                  aria-hidden="true"
                ></i>
              </Link>
            )}
            {/* </>
            )} */}
            {balanceData &&
              balanceData.bitmartStatus &&
              balanceData.bitmart &&
              balanceData.bitmart.length > 0 &&
              balanceData.bitmart.map((item, i) => {
                return (
                  <>
                    {showbitmart == false ? (
                      <h2>********* {item.currency}</h2>
                    ) : (
                      <h2>
                        {item.available} {item.currency}
                      </h2>
                    )}
                    {/* <h2>
                    {item.available} {item.currency}
                  </h2> */}
                  </>
                );
              })}
            {!balanceData.bitStatus && loader && (
              <h2>Please Bind Your API Key</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
