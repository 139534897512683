import React, { useEffect, useState } from "react";
import { MfeeList } from "../../actions/Wallet";
import DataTable from "react-data-table-component";

// import lib
import isEmpty from "../../lib/isEmpty";
import { dateTimeFormat } from "../../lib/dateFilter";
import { firstCapital } from "../../lib/String";
import { toFixed } from "../../lib/roundOf";

export default function WalletHistory() {
  // state
  const [TxnList, SetTxnList] = useState();

  const fetchwallethistory = async (reqData) => {
    const { status, loading, message, error, result } = await MfeeList(reqData);
    SetTxnList(result);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let reqData = {
      filter: value,
    };
    fetchwallethistory(reqData);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "150px",
    },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <>
            {row.coin == "INR" ? "₹ " : "$ "}
            {toFixed(row.amount, 6)}
          </>
        );
      },
    },
    {
      name: "Exchange",
      selector: (row) => {
        if (!isEmpty(row.exchange)) {
          return <>{row.exchange}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Bot Type",
      selector: (row) => {
        if (!isEmpty(row.botType)) {
          return <>{row.botType}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Pair Name",
      selector: (row) => {
        if (!isEmpty(row.pairName)) {
          return <>{row.pairName}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Type",
      selector: (row) => {
        console.log(row, "---------78");
        if (!isEmpty(row.typeofAsset)) {
          return <>{row.typeofAsset}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Fee Type",
      selector: (row) => {
        return (
          <>
            {row.type == "trade_profit"
              ? "Trade"
              : row.type == "cashback_add"
              ? "Cashback"
              : row.type == "ClearSell"
              ? "Clear Sell"
              : row.type == "transfer"
              ? "Transfer"
              : row.type == "auto_transfer"
              ? "Auto Transfer"
              : ""}
          </>
        );
      },
    },

    {
      name: "Category",
      selector: (row) => {
        let color = "";
        if (row.category == "credit") {
          color = "greenText";
        } else if (row.category == "debit") {
          color = "redText";
        }

        return <span className={color}>{firstCapital(row.category)}</span>;
      },
    },
  ];

  useEffect(() => {
    let reqData = {
      filter: "all",
    };
    fetchwallethistory(reqData);
  }, []);

  return (
    <div className="userContent mt-4">
      <div className="flexTitle">
        <h2>M-Fee & M-Asset History</h2>
        <select className="form-select filterSelect" onChange={handleChange}>
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="week">Last week</option>
          <option value="month">Last 1 month</option>
          <option value="year">Last year</option>
        </select>
      </div>
      <div className="WalletBalance ">
        <div class="table-responsive">
          {/* <table class="table table-bordered customTable">
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
          </tr>

          {TxnList &&
            TxnList.length > 0 &&
            TxnList.map((item, key) => {
              let status = item.Status;
              let assetType = item.assetType;
              let color = "";
              let acolor = "";
              if (status == "Pending") {
                color = "yellowText";
              } else if (status == "Rejected") {
                color = "redText";
              } else if (status == "Approved" || status == "Completed") {
                color = "greenText";
              }

              if (assetType == "Deposit") {
                acolor = "greenText";
              } else if (assetType == "Transfer") {
                acolor = "yellowText";
              } else if (assetType == "Withdraw") {
                acolor = "redText";
              }

              return (
                <tr>
                  <td>{dateTimeFormat(item.createdAt)}</td>
                  <td>{item.amount}</td>
                  <td className={acolor}>{item.assetType}</td>
                  <td className={color}>{item.Status}</td>
                </tr>
              );
            })}
        </table> */}
          <div className="dashboardContent">
            <div className="primary_table">
              <DataTable columns={columns} data={TxnList} pagination />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
