// import package
import React, { useCallback, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import browser from "browser-detect";
import Checkbox from "rc-checkbox";
import { useNavigate } from "react-router-dom";

// import action
import { forgotPassword } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getLang } from "../../lib/localStorage";

let initialFormValue = {
  phoneCode: "",
  phoneNo: "",
  otp: "",
  email: "",
};

const EmailForm = () => {
  let navigate = useNavigate();
  // states
  const [formValue, setFormValue] = useState(initialFormValue);
  const [reCaptcha, setReCaptcha] = useState("");
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();

  const { phoneCode, phoneNo, otp, email } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // if (isEmpty(reCaptcha)) {
    //     toastAlert('error', 'Invalid ReCaptcha', 'forgotPassword');
    //     return
    // }

    setLoader(true);
    let reqData = {
      phoneCode,
      phoneNo,
      otp,
      email,
      reCaptcha,
      type: "email",
    };
    let { status, loading, error, message, result } = await forgotPassword(
      reqData
    );
    setLoader(loading);
    setReCaptcha("");
    if (status) {
      setFormValue(initialFormValue);
      setToched({});
      setValidateError({});
      toastAlert("success", message, "forgotPassword");
    } else {
      toastAlert("error", message, "forgotPassword");
    }
    if (!isEmpty(error)) {
      setValidateError(error);
    }
  };

  const handleReCaptcha = useCallback((token) => {
    if (isEmpty(reCaptcha)) {
      setReCaptcha(token);
    }
  }, []);

  useEffect(() => {
    // setValidateError(validation(formValue))
  }, [reCaptcha]);

  return (
    <Fragment>
      <form>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Email"
            name="email"
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {validateError.email && (
            <p className="text-danger">{validateError.email}</p>
          )}
        </div>
        <div className="form-group mb-0">
          <button
            className="btn btn-green-big"
            onClick={handleFormSubmit}
            disabled={!isEmpty(validateError) || loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default EmailForm;
