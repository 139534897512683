import React, { useEffect } from "react";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const ContactUs = (props) => {
  return (
    <div className="form-card">
      <h3>
        <span>Contact Us</span>
      </h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="contact_sec">
            <a
              style={{ color: "#008ccc" }}
              href="mailto:support@UGainIndia.com"
              className="text-end d-block"
            >
              Support
            </a>
            <p>
              Please contact our customer service if you have any questions.
            </p>
            <p className="mb-0">
              <span>Email: </span>
              <a
                style={{ color: "#008ccc" }}
                href="mailto:support@UGainIndia.com"
              >
                support@ugainindia.com
              </a>
            </p>
            <p>
              <span>Telegram: </span>
              <a
                href="https://t.me/UGainIndia"
                style={{ color: "#008ccc" }}
                target="_blank"
              >
                @UGainIndia
              </a>
            </p>
            <p>
              You also can direct @UGainIndia (Telegram) if you have any issues
              with trading error code.
            </p>
            <a
              style={{ color: "#008ccc" }}
              href="https://t.me/UGainIndia"
              target="_blank"
            >
              GainBOT Official(Channel)
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
