import React, { useEffect } from "react";
import Header from "../components/Header.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";

import BindingPage from "../components/ApiBinding"

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function ApiBinding() {

  return (
<main>
	 <ScrollToTopOnMount />
          <Header />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-12 d-none d-lg-block">
                  <ProfileSideMenu />
                </div>
                <BindingPage />
              </div>
            </div>
          </div>
          <Footer />
    </main>

  );
}
