import React, { useEffect } from "react";


export default function DepositWallet() {

  return (
    <div className="userContent mt-4">
<h2>Upload Deposit Proof</h2>
<div className="walletForm">
<div className="form-group">
<label>Amount</label>
<div className="input-group mb-3">
        <input type="text" className="form-control" />
        <div className="input-group-text">
            INR
        </div>
    </div>
</div>
<div className="form-group">
<label>Purpose</label>
<select className="form-select">
    <option selected value="1">Subscription</option>
    <option selected value="1">Top-up</option>
</select>
</div>
<div className="form-group">
<label>Bank/Wallet</label>
<select className="form-select">
    <option selected>Select</option>
    <option value="1">Bank 1</option>
    <option value="2">Bank 2</option>
    <option value="3">Bank 3</option>
    <option value="4">UPI</option>
    <option value="4">Wallet</option>
</select>
</div>
<div className="form-group">
    <label>Bank/Wallet Details</label>
    <div className="bankDetailBox">
        <ul>
        <li>
            <span className="bdLabel">Bank Name</span>
            <span className="bdDetails">IDFC</span>
        </li>
        <li>
            <span className="bdLabel">Accout Number</span>
            <span className="bdDetails">10089235708</span>
        </li>
        <li>
            <span className="bdLabel">Account Holder Name</span>
            <span className="bdDetails">Ugain India</span>
        </li>
        <li>
            <span className="bdLabel">Account Type</span>
            <span className="bdDetails">Current</span>
        </li>
        <li>
            <span className="bdLabel">IFSC Code</span>
            <span className="bdDetails">IDFB0020153</span>
        </li>
        <li>
            <span className="bdLabel">Bank Branch</span>
            <span className="bdDetails">Noida Sector 77</span>
        </li>
        </ul>
    </div>
    {/* <div className="qrCodeBox">
        <img src={require("../assets/images/qr-code.png")} className="img-fluid" alt="" />
    </div>
    <div className="copyCodeBox">
        <p>ugainindia@icici</p>
        <a href="#"><img src={require("../assets/images/copy-icon.png")} className="img-fluid" alt="" /></a>
    </div> */}
</div>
<div className="form-group">
    <label>Screenshot Upload</label>
    <div className="input-group mb-3">
        <input type="text" className="form-control" />
        <div className="input-group-text green-bg">
        <div className="fileUploadButton">
            <img src={require("../assets/images/uploadIcon.png")} className="img-fluid" alt="" />
            <input type="file" name="file" />
        </div>
        </div>
    </div>
</div>
<div className="form-group mt-5">
    <button className="btn btn-green-big mx-auto mb-0">Submit</button>
</div>
</div>
</div>
  );
}
