import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import clsx from "classnames";
// import lib
import { firstCapital } from "../lib/String";
import { dateTimeFormat } from "../lib/dateFilter";
import { toastAlert } from "../lib/toastAlert";
// import action
import { logout } from "../actions/users";

import { getUserData } from "../actions/users";
const initialvalue = {
  showId: false,
  showEmail: false,
};
export default function ProfileSideMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = { bot: "Smart", exchange: "Binance", pair: "BTC-USDT" };
  // state
  const [userEmail, setEmail] = useState();
  // const [accountData, setUserData] = useState({});
  const [formValue, setFormValue] = useState(initialvalue);
  const { showId, showEmail } = formValue;
  // redux-state
  const accountData = useSelector((state) => state.account);

  // function
  useEffect(() => {
    // fetchData()
    if (accountData && accountData.email) sortName(accountData.email);
  }, [accountData]);

  // const fetchData = async () => {
  //   const { status, loading, result, message, error } = await getUserData(dispatch);
  //   console.log(status,'----------21',result)
  //   if (status == 'success') {
  //       setUserData(result)
  //   }
  // }

  const sortName = (email) => {
    let splitData = email.split("@");
    let emailData = firstCapital(splitData[0]);
    if (emailData.length > 15) emailData = emailData.substring(0, 15);
    setEmail(emailData);
  };

  // console.log(accountData,'------------------accountData')

  const logoutUser = (e) => {
    e.preventDefault();
    logout(navigate, dispatch);
    toastAlert("success", "Logout Successfully");
  };

  return (
    <div
      className="collapse navbar-collapse profileSideMenu"
      id="navbarSupportedContent"
    >
      <div className="profileSideMenuTop">
        <h3>
          {firstCapital(accountData.firstName)}
          {accountData.lastName}
        </h3>
        <div className="profileSideMenuTop_eye">
          <p className="mb-0">
            {showEmail == false ? (
              <a href="#">****************</a>
            ) : (
              <a href="#">{accountData && accountData.email}</a>
            )}
          </p>
          <Link
            onClick={(e) => {
              e.preventDefault();
              setFormValue((el) => {
                return {
                  ...el,
                  ...{ showEmail: !el.showEmail },
                };
              });
            }}
          >
            <i
              className={clsx(
                "bi",
                { "bi-eye": showEmail },
                { "bi-eye-slash": !showEmail }
              )}
              aria-hidden="true"
            ></i>
          </Link>
        </div>
        <div className="kyc_verify">
          <img
            src={require("../assets/images/kyc_verified_icon.png")}
            alt="Icon"
            className="me-2"
          />{" "}
          <span>{firstCapital(accountData && accountData.kycStatus)}</span>
        </div>
        <div className="profileSideMenuTop_eye">
          <p className="mb-0">
            {showId == false ? (
              <small>UserId: **********</small>
            ) : (
              <small>UserId: {accountData && accountData.userId}</small>
            )}{" "}
          </p>
          <Link
            onClick={(e) => {
              e.preventDefault();
              setFormValue((el) => {
                return {
                  ...el,
                  ...{ showId: !el.showId },
                };
              });
            }}
          >
            <i
              className={clsx(
                "bi",
                { "bi-eye": showId },
                { "bi-eye-slash": !showId }
              )}
              aria-hidden="true"
            ></i>
          </Link>
        </div>
        <p className="mb-0">
          <small>
            Last Login Time:{" "}
            {accountData &&
              accountData.loginhistory &&
              dateTimeFormat(
                accountData.loginhistory[accountData.loginhistory.length - 1]
                  .createdDate
              )}
          </small>
        </p>
      </div>
      <div className="profileSideMenuBottom">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/dashboard">
              <img
                src={require("../assets/images/menuIcon-7.png")}
                alt="Icon"
              />{" "}
              Dashboard
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/wallet">
              <img
                src={require("../assets/images/menuIcon-12.png")}
                alt="Icon"
              />{" "}
              Wallet
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/profile">
              <img
                src={require("../assets/images/menuIcon-1.png")}
                alt="Icon"
              />{" "}
              My Profile
            </NavLink>
          </li>
          {/* <li className="nav-item">
                    <NavLink className="nav-link"  to="/#"><img src={require("../assets/images/menuIcon-4.png")} alt="Icon" /> Settings</NavLink>
                </li> */}
          <li className="nav-item">
            <NavLink className="nav-link" to="/security">
              <img
                src={require("../assets/images/menuIcon-2.png")}
                alt="Icon"
              />{" "}
              Security
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/notification-system-errors">
              <img
                src={require("../assets/images/menuIcon-3.png")}
                alt="Icon"
              />{" "}
              Notification
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/kyc">
              <img
                src={require("../assets/images/menuIcon-11.png")}
                alt="Icon"
              />{" "}
              KYC Verification
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/api-binding">
              <img
                src={require("../assets/images/menuIcon-10.png")}
                alt="Icon"
              />{" "}
              API Binding
            </NavLink>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              target="_blank"
              href="https://ugainindia.com/Userguide.html"
            >
              <img
                src={require("../assets/images/menuIcon-14.png")}
                alt="Icon"
              />{" "}
              User’s Guide
            </a>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/video-tutorials">
              <img
                src={require("../assets/images/menuIcon-15.png")}
                alt="Icon"
              />{" "}
              Video Tutorials
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/referral">
              <img
                src={require("../assets/images/menuIcon-16.png")}
                alt="Icon"
              />{" "}
              Invite a Friend
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              state={{ type: data }}
              to="/strategy-setting"
            >
              <img
                src={require("../assets/images/menuIcon-10.png")}
                alt="Icon"
              />{" "}
              Strategy Settings
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#" onClick={logoutUser}>
              <img
                src={require("../assets/images/menuIcon-6.png")}
                alt="Icon"
              />{" "}
              Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
