import React, { useState, useEffect } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";
import clsx from "classnames";
import { Link } from "react-router-dom";

// import action
import { changePassword, logout, sentEmailOtp } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
  code: "",
  twoFACode: "",
  showoldPassword: false,
  showPassword: false,
  showConfirmPassword: false,
};

export default function ChangePassword() {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.account);

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [optStatus, setOtpStatus] = useState(false);
  const [resend, setResend] = useState(false);
  const [showTwoFA, setShowTowFA] = useState(false);
  const [Timer, setTimer] = useState(300);
  const [loaders, setLoaders] = useState(false);

  const {
    oldPassword,
    password,
    showoldPassword,
    showPassword,
    showConfirmPassword,
    twoFACode,
    code,
    confirmPassword,
  } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      oldPassword,
      password,
      confirmPassword,
      type: "changePassword",
      code,
      twoFACode,
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      let { status, loading, error, message } = await changePassword(reqData);
      setLoader(loading);
      console.log(status, "----------83");
      if (status == "success") {
        setFormValue(initialFormValue);
        setLoaders(false);
        toastAlert("success", message, "changePassword");
        setTimeout(() => {
          logout(dispatch);
        }, 1000);
      } else if (status == "TWO_FA") {
        setShowTowFA(true);
        toastAlert("error", message, "changePassword");
      } else {
        if (error) {
          setValidateError(error);
        } else if (message) {
          toastAlert("error", message, "changePassword");
        }
      }
    } catch (err) {}
  };

  const handleSentOTP = async (e) => {
    e.preventDefault();
    let reqData = {
      type: "keyVerification",
    };
    try {
      let { status, loading, error, message } = await sentEmailOtp(reqData);
      if (status == "success") {
        toastAlert("success", message, "mobileForm");
        setLoaders(true);
        callinterval();
      } else {
        if (error) {
          setValidateError(error);

          return;
        }
        toastAlert("error", message, "mobileForm");
      }
    } catch (err) {}
  };

  async function callinterval() {
    var ts = 300;
    setTimer(ts);
    let setInt = setInterval(() => {
      ts = ts - 1;
      if (ts == 0) {
        setTimer(ts);
        clearInterval(setInt);
        setLoaders(false);
      } else {
        setTimer(ts);
      }
    }, 1000);
  }

  return (
    <div className="form-card">
      <h3>
        <span>Change Password</span>
      </h3>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label>Current Password</label>
                <div className="input-group regGroupInput mt-2">
                  <input
                    type={showoldPassword ? "text" : "password"}
                    className="form-control"
                    name="oldPassword"
                    value={oldPassword}
                    onChange={handleChange}
                  />{" "}
                  <div className="input-group-text inp_grp_width">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setFormValue((el) => {
                          return {
                            ...el,
                            ...{ showoldPassword: !el.showoldPassword },
                          };
                        });
                      }}
                    >
                      <i
                        className={clsx(
                          "bi",
                          { "bi-eye": showoldPassword },
                          { "bi-eye-slash": !showoldPassword }
                        )}
                        // aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                {validateError.oldPassword && (
                  <p className="text-danger">{validateError.oldPassword}</p>
                )}
                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label>New Password</label>
                <div className="input-group regGroupInput mt-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                  <div className="input-group-text inp_grp_width">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setFormValue((el) => {
                          return {
                            ...el,
                            ...{ showPassword: !el.showPassword },
                          };
                        });
                      }}
                    >
                      <i
                        className={clsx(
                          "bi",
                          { "bi-eye": showPassword },
                          { "bi-eye-slash": !showPassword }
                        )}
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>

                  {/* Developer Note: Insert Error Message here code here */}
                </div>
                {validateError.password && (
                  <p className="text-danger">{validateError.password}</p>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label>Confirm New Password</label>
                <div className="input-group regGroupInput mt-2">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                  />
                  <div className="input-group-text inp_grp_width">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setFormValue((el) => {
                          return {
                            ...el,
                            ...{ showConfirmPassword: !el.showConfirmPassword },
                          };
                        });
                      }}
                    >
                      <i
                        className={clsx(
                          "bi",
                          { "bi-eye": showConfirmPassword },
                          { "bi-eye-slash": !showConfirmPassword }
                        )}
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                  {/* Developer Note: Insert Error Message here code here */}
                </div>
                {validateError.confirmPassword && (
                  <p className="text-danger">{validateError.confirmPassword}</p>
                )}
                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
            <div class="form-group">
              <label>Email Verification code</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Please enter Verification Code"
                  name="code"
                  onChange={handleChange}
                  value={code}
                />

                <div className="input-group-text">
                  {!loaders && (
                    <button class="input-group-text" onClick={handleSentOTP}>
                      {Timer == 0 ? "Resend Mail OTP" : "Send Mail OTP"}
                    </button>
                  )}
                  {loaders && <>{Timer} seconds </>}
                </div>
              </div>
              {validateError && validateError.code && (
                <p className="text-danger">{validateError.code}</p>
              )}
            </div>
            {showTwoFA && (
              <div class="form-group">
                <label>2FA Code </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please enter 2FA Code"
                    name="twoFACode"
                    onChange={handleChange}
                    value={twoFACode}
                  />
                </div>
                {validateError && validateError.twoFACode && (
                  <p className="text-danger">{validateError.twoFACode}</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="notes_section">
            <h5>Notes</h5>
            <p>For better security, your new password should be</p>
            <ul>
              <li>Minimum 6 characters long.</li>
              <li>Maximum 18 characters long.</li>
              <li>Atleast one UPPERCASE letter like ABC...XYZ.</li>
              <li>Atleast one LOWERCASE letter like abc...xyz.</li>
              <li>Atleast one digit like 123...890.</li>
              <li>Atleast one special character like ~!@#$%^&*()[].</li>
            </ul>
          </div>
        </div>
        <div className="form-group text-left mt-4">
          <button
            type="button"
            onClick={handleFormSubmit}
            className="btn btn-green-big ms-0 me-0"
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
