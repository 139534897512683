import React, { useEffect } from "react";


export default function WalletBalance() {

  return (
    <div className="userContent mt-4 mt-lg-0">
    <h2>Wallet Balance</h2>
    <div className="WalletBalance">
    <div class="alert alert-danger" role="alert">
      Your withdrawal request is rejected due to low withdraw balance.
    </div>
        <div className="row">
        <div className="col-lg-6">
            <div className="wbCard">
            <h6>M- Asset</h6>
            <h2>5,500.50 <small>USDT</small></h2>
            <h4>= 4,38,931.65  INR</h4>
            </div>
        </div>
        <div className="col-lg-6">
            <div className="wbCard">
            <h6>M- Fee</h6>
            <h2>74,583.50 <small>USDT</small></h2>
            <h4>= 62,79,930.70  INR</h4>
            </div>
        </div>
        </div>
    </div>
    </div>
  );
}
