// import package
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import browser from "browser-detect";
import Checkbox from "rc-checkbox";
import { useNavigate } from "react-router-dom";
import clsx from "classnames";

// import action
import { resetPassword } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import Logo from "../../components/Logo";

const initialFormValue = {
  password: "",
  confirmPassword: "",
  showPassword: "",
  showConfirmPassword: "",
};

const EmailForm = () => {
  const navigate = useNavigate();
  const { authToken } = useParams();
  // states
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();

  const { password, confirmPassword, showPassword, showConfirmPassword } =
    formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      password,
      confirmPassword,
      authToken,
    };
    let { status, loading, error, message } = await resetPassword(reqData);
    setLoader(loading);
    if (status == "success") {
      setFormValue(initialFormValue);
      setToched({});
      setValidateError(validation(initialFormValue));
      navigate("/signin");
      toastAlert("success", message, "resetPassword");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "resetPassword");
    }
  };

  useEffect(() => {
    setValidateError(validation(formValue));
  }, []);

  return (
    <Fragment>
      <div className="col-md-6 col-lg-6 col-xl-5 mx-auto">
        <Logo />
        <div className="crediantial-form">
          <h2>Reset Password</h2>
          <div className="tab-content" id="nav-tabContent">
            <div className="form-group">
              <div className="label-flex">
                <label className="mb-0">Password</label>
              </div>
              <div className="input-group regGroupInput mt-2">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
                <div className="input-group-text inp_grp_width">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return { ...el, ...{ showPassword: !el.showPassword } };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showPassword },
                        { "bi-eye-slash": !showPassword }
                      )}
                      // aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
              {toched &&
                validateError &&
                toched.password &&
                validateError.password && (
                  <p className="text-danger">{validateError.password}</p>
                )}
            </div>
            <div className="form-group">
              <div className="label-flex">
                <label className="mb-0">Confirm Password</label>
              </div>
              <div className="input-group regGroupInput mt-2">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
                <div className="input-group-text inp_grp_width">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return {
                          ...el,
                          ...{ showConfirmPassword: !el.showConfirmPassword },
                        };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showConfirmPassword },
                        { "bi-eye-slash": !showConfirmPassword }
                      )}
                      // aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
              {toched &&
                validateError &&
                toched.confirmPassword &&
                validateError.confirmPassword && (
                  <p className="text-danger">{validateError.confirmPassword}</p>
                )}
            </div>
            <div className="form-group mb-0">
              <button
                className="btn btn-green-big"
                onClick={handleFormSubmit}
                disabled={!isEmpty(validateError)}
              >
                {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailForm;
