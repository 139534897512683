import React, { useEffect } from "react";
import Header from "../components/Header.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";

import ReferralPage from "../components/Referral"

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Referral() {

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            <ReferralPage />
            {/*<div className="col-lg-9 col-md-12">
              <div className="userContent">              
                <h2>Invite a Friend</h2>
                 <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="referral_panel">
                        <a href="https://ugainindia.com/" target="_blank" className="d-block">https://ugainindia.com/</a>
                        <h3>A Partner of Your Happiness</h3>
                        <img src={require("../assets/images/qr_code_large.png")} alt="Icon" className="img-fluid" />
                        <div class="form-group">
                          <label>Invitation code</label>
                          <div class="input-group">
                            <input type="text" class="form-control" value="UGN68011"/>
                            <button class="input-group-text"><img src={require("../assets/images/copy_icon_white.png")} alt="QR" className="img-fluid" /></button>                            
                          </div>
                        </div>
                        <div>
                          <h4>http://ugainindia.com/register?invitation=UGN68011</h4>
                          <button className="invite_btn"><img src={require("../assets/images/copy_icon_white.png")} alt="QR" className="img-fluid" /></button>
                        </div>
                        <div class="form-group">
                              <label>Share this on</label>
                        </div>
                        <ul className="social_share_list justify-content-center align-items-center">
                          <li><a href="javascript:void(0)" target="_blank"><img src={require("../assets/images/fb_icon.png")} className="img-fluid" alt="Icon" /></a></li>
                          <li><a href="javascript:void(0)" target="_blank"><img src={require("../assets/images/whatsapp_icon.png")} className="img-fluid" alt="Icon" /></a></li>
                          <li><a href="javascript:void(0)" target="_blank"><img src={require("../assets/images/telegram_icon.png")} className="img-fluid" alt="Icon" /></a></li>
                        </ul>
                      </div>
                    </div>                    
                  </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      <Footer />
    </main>

  );
}
