import React, { useEffect, useState } from "react";

import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

import {
  getAllowNot,
  allowNotify,
  getTipoffs,
  setNotiStatus,
} from "../../actions/users";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { SET_DASHBOARD_BOTTYPE } from "../../constant";
import config from "../../config";

export default function Tipoffs() {
  // state
  const [allowNot, setAllowNot] = useState();
  const [notData, setNotData] = useState([]);
  const [autoLoader, setAutoLoader] = useState(false);
  const [exchange, setExchange] = useState("Binance");
  const [BotType, setBotType] = useState("All");

  const navigate = useNavigate();
  useEffect(() => {
    fetchAllow();
    setNotification();
  }, [exchange]);
  const setNotification = async () => {
    try {
      let reqData = { type: "systemError" };
      const { status, result, loading, message, error } = await setNotiStatus(
        reqData
      );
    } catch (err) {}
  };

  const [page, setpagecount] = useState(1);
  const [count, setCount] = useState(0);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const noOfPages = Math.ceil(count / 10);
  const pagesArr = [...new Array(noOfPages)];

  const onPrevPage = () => {
    setpagecount(page - 1);
  };
  const gotoPage = (data) => {
    setpagecount(data);
  };

  const onNextPage = () => {
    setpagecount(page + 1);
  };
  const onPageSelect = (pageNo) => {
    setpagecount(pageNo);
  };

  useEffect(() => {
    let reqdata = {
      page,
      limit: 10,
      exchange: exchange,
      BotType: BotType,
    };
    fetchNotification(reqdata);
  }, [page, exchange]);

  const fetchAllow = async () => {
    try {
      const { status, result, loading, message, error } = await getAllowNot();
      setAutoLoader(false);
      if (status == "success") {
        setAllowNot(result.tipNotify);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  const fetchNotification = async (reqdata) => {
    try {
      const { status, result, message } = await getTipoffs(reqdata);
      setAutoLoader(false);
      if (status == "success") {
        setNotData(result.systemData);
        setCount(result.count);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  const handleAuto = async (e) => {
    try {
      e.preventDefault();
      setAutoLoader(true);
      const { checked } = e.target;
      let reqData = {
        allowNotify: checked,
        type: "tip_off",
      };
      const { status, result, loading, message, error } = await allowNotify(
        reqData
      );
      setAutoLoader(false);
      if (status == "success") {
        toastAlert("success", message);
        setAllowNot(result.systemNotify);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  const handleChange = (e) => {
    setExchange(e.target.value);

    let reqdata = {
      page,
      limit: 10,
      exchange: e.target.value,
      BotType: BotType,
    };
    fetchNotification(reqdata);
  };

  const handleChangebot = (e) => {
    setBotType(e.target.value);

    let reqdata = {
      page,
      limit: 10,
      BotType: e.target.value,
      exchange: exchange,
    };
    fetchNotification(reqdata);
  };
  useEffect(() => {
    if (noOfPages === page) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (page === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, page]);

  console.log(exchange, "---0 9 9 9 9");
  return (
    <div className="userContent">
      <div className="text-end mb-2">
        <button className="edit_btn" onClick={() => navigate("/dashboard")}>
          Go back
        </button>
      </div>
      <div className="flexTitle">
        <h2>Tip-Offs</h2>

        <div className="d-flex">
          <label class="label_large me-3">Allow Notification</label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              checked={allowNot}
              onClick={(e) => handleAuto(e)}
            />
          </div>
        </div>
      </div>

      <div className="chartTitle">
        <h4>Exchange :</h4>
        <div className="d-flex mt-1">
          <select className="form-select" onChange={handleChangebot}>
            <option value="All">All</option>
            <option value="Zap">Zap</option>
            <option value="Smart">Smart</option>
          </select>
          <span>&nbsp;</span>
          <select className="form-select" onChange={handleChange}>
            <option value="Binance">Binance</option>
            <option value="Kucoin">Kucoin</option>
            <option value="BitMart">BitMart</option>
          </select>
        </div>
      </div>

      {/* <div class="dashboardBalanceDetails">
        <h2>Exchange : {exchange}</h2>
      </div> */}

      <div className="notification_panel">
        {allowNot &&
          notData &&
          notData.length > 0 &&
          notData.map((item, i) => {
            return (
              // trade/BTC-USDT/Binance/Smart
              <div>
                <h5>Date: {dateTimeFormat(item.createdAt)}</h5>
                <span>&nbsp;</span>{" "}
                <h4>
                  <span>MINI BOT : {item && item.botType.toUpperCase()}</span>
                </h4>
                <h4>
                  <a
                    href={`${config.FRONT_URL}/trade/${item?.pairId?.firstCurrencySymbol}-${item?.pairId?.secondCurrencySymbol}/${exchange}/${item?.botType}`}
                  >
                    <p>#{item.pairName}</p>
                  </a>
                  <p>CMP - Price: {item.price}</p>
                  <p>CandleStick view :</p>
                  {item.signal &&
                    item.signal.length > 0 &&
                    item.signal.map((itemsignal, i) => {
                      let color = "";
                      if (
                        itemsignal.signal == "STRONG BUY" ||
                        itemsignal.signal == "BUY"
                      ) {
                        color = "green";
                      } else if (
                        itemsignal.signal == "STRONG SELL" ||
                        itemsignal.signal == "SELL"
                      ) {
                        color = "red";
                      }
                      return (
                        <p>
                          {itemsignal.time} :{" "}
                          <span style={{ color: color }}>
                            {itemsignal.signal}
                          </span>
                        </p>
                      );
                    })}
                  <p>Pivot :</p>
                  <p>
                    R3 : {item.pivot && item.pivot[0].r3} (
                    <span style={{ color: "green" }}>Target 3</span>)
                  </p>
                  <p>
                    R2 : ${item.pivot && item.pivot[0].r2} (
                    <span style={{ color: "green" }}>Target 2</span>)
                  </p>
                  <p>
                    R1 : ${item.pivot && item.pivot[0].r1}(
                    <span style={{ color: "green" }}>Target 1</span>)
                  </p>
                  <p>P : {item.pivot && item.pivot[0].pivot}</p>
                  <p>S1 : {item.pivot && item.pivot[0].s1}</p>
                  <p>S2 : {item.pivot && item.pivot[0].s2}</p>
                  <p>
                    S3 : ${item.pivot && item.pivot[0].s3} (
                    <span style={{ color: "red" }}>Stop Loss</span>)
                  </p>
                  {/* {item.exchange} {item.pairName}  {item.botmsg} */}
                  {/* <p>Exchange : {item.exchange}</p> */}
                  {/* {ReactHtmlParser(item.botmsg)} */}
                </h4>
              </div>
            );
          })}
      </div>
      <br />
      {allowNot && noOfPages > 1 ? (
        <ul class="pagination">
          {/* <li class="page-item">
            <button
              class="page-link"
              onClick={onPrevPage}
              disabled={!canGoBack}
            >
              {"<<"}
            </button>
          </li>
          {pagesArr.map((num, index) => (
            <li class="page-item">
              <button class="page-link" onClick={() => onPageSelect(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
          <li class="page-item">
            <button
              class="page-link"
              onClick={onNextPage}
              disabled={!canGoNext}
            >
              {">>"}
            </button>
          </li> */}
          <span>
            Page{" "}
            <strong>
              {page} - {pagesArr.length}
            </strong>{" "}
          </span>
          &nbsp;&nbsp;
          <button onClick={() => gotoPage(1)} disabled={!canGoBack}>
            {"<<"}
          </button>{" "}
          <button onClick={onPrevPage} disabled={!canGoBack}>
            {"<"}
          </button>{" "}
          <button onClick={onNextPage} disabled={!canGoNext}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(noOfPages)} disabled={!canGoNext}>
            {">>"}
          </button>{" "}
        </ul>
      ) : null}
    </div>
  );
}
