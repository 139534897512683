import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { useSelector } from "react-redux";

//importapi
import { Dashboardchart } from "../../../src/actions/Bottrade";
import { toFixed } from "../../lib/roundOf";
import isEmpty from "../../lib/isEmpty";
export default function PNL() {
  const [filter, setfilter] = useState("Today");
  const [filterlabel, setfilterlabel] = useState("Today");
  const [series, setSeries] = useState({});
  const [datas, setData] = useState({});
  const [options, setOptions] = useState({});

  //redux
  let { exchangetype } = useSelector((state) => state.exchange);
  let { botType } = useSelector((state) => state.bot);
  const tradeThemeData = useSelector((state) => state.tradeThemeReducers);
  console.log(tradeThemeData, "pnl theme");

  const handleChange = (e) => {
    // console.log(e.target.value, "-----value");
    setfilter(e.target.value);

    let filterLabel =
      e.target.value == "Today"
        ? "Today"
        : e.target.value == "Week"
        ? "7 Days"
        : e.target.value == "2Week"
        ? "Last 2 Week "
        : e.target.value == "Month"
        ? "Current Month"
        : "";

    setfilterlabel(filterLabel);
  };

  const fetchChart = async () => {
    try {
      let reqData = {
        filter,
        exchange: exchangetype,
        bot: botType,
      };
      const { status, loading, result, message, error } = await Dashboardchart(
        reqData
      );
      console.log(status, "_____-status");

      if (status == "success") {
        // console.log(result, "_____-result");
        let dashData = [],
          dashAmount = [];

        if (result && result.profit && result.profit.nationality == "indian") {
          let profit = 0;
          let loss = 0;
          if (!isEmpty(result.profit)) {
            profit = parseFloat(result.profit.inr);
          }

          if (!isEmpty(result.loss)) {
            loss = parseFloat(result.loss.inr);
          }

          dashAmount.push(profit);
          dashAmount.push(loss);
        } else if (
          result &&
          result.loss &&
          result.loss.nationality == "nonindian"
        ) {
          let profit = 0;
          let loss = 0;
          if (!isEmpty(result.profit)) {
            profit = parseFloat(result.profit.usd);
          }

          if (!isEmpty(result.loss)) {
            loss = parseFloat(result.loss.usd);
          }

          dashAmount.push(profit);
          dashAmount.push(loss);
        } else {
          dashAmount.push(0);
          dashAmount.push(0);
        }
        // else {
        //   dashAmount.push(0);
        //   dashAmount.push(0);
        //   console.log(result, "---------92");
        //   if (
        //     result &&
        //     result.totalusdt &&
        //     result.totalusdt.nationality == "indian"
        //   ) {
        //     console.log("kiii-9999");
        //     setData({
        //       totalamount: result && result.totalusdt && result.totalusdt.inr,
        //       nationality: result.totalusdt.nationality,
        //     });
        //   } else {
        //     setData({
        //       totalamount: result && result.totalusdt && result.totalusdt.usd,
        //       nationality:
        //         result && result.totalusdt && result.totalusdt.nationality,
        //     });
        //   }
        // }
        if (
          result &&
          result.totalusdt &&
          result.totalusdt.nationality == "indian"
        ) {
          setData({
            totalamount: result && result.totalusdt && result.totalusdt.inr,
            nationality: result.totalusdt.nationality,
            durationProfit:
              result && result.durationProfit && result.durationProfit.inr,
          });
        } else {
          setData({
            totalamount: result && result.totalusdt && result.totalusdt.usd,
            nationality:
              result && result.totalusdt && result.totalusdt.nationality,
            durationProfit:
              result && result.durationProfit && result.durationProfit.usd,
          });
        }

        console.log(dashAmount, "______________dashAmount");
        // console.log(dashData, "______________________dashData");

        setOptions({
          ...options,

          chart: {
            type: "bar",
            foreColor: tradeThemeData == "dark" ? "#000000" : "#ffffff",
            background: tradeThemeData == "dark" ? "#ffffff" : "#0a0e2f",
            height: 250,
            toolbar: {
              show: false,
              tools: {
                download: false, // download option disabled
              },
            },
          },

          theme: {
            mode: "dark",
            palette: "palette1",
            // monochrome: {
            //   enabled: false,
            //   color: "#255aee",
            //   shadeTo: "dark",
            //   shadeIntensity: 0.65,
            // },
          },

          plotOptions: {
            bar: {
              colors: {
                // ranges: [
                //   {
                //     from: 100,
                //     to: 0,
                //     color: "#008000",
                //   },

                //   {
                //     from: 0,
                //     to: 100,
                //     color: "#008000",
                //   },

                //   {
                //     from: -100,
                //     to: -10,
                //     color: "#FF001B",
                //   },
                //   {
                //     from: -10,
                //     to: -0,
                //     color: "#FF001B",
                //   },
                // ],

                ranges: [
                  {
                    from: -(1 / 0), //infinity
                    to: 0,
                    color: "#FF001B",
                  },
                  {
                    from: 0.1,
                    to: 1 / 0, //infinity
                    color: "#008000",
                  },
                ],
              },
              columnWidth: "25%",
            },
          },
          dataLabels: {
            enabled: false,
          },

          yaxis: {
            title: {
              text: "Profit & Loss (UTC)",
            },

            labels: {
              formatter: function (y) {
                // console.log(y, "yyyyyyyyy");
                //return y.toFixed(2) + "%";
                return y.toFixed(4);
              },
            },
          },
          xaxis: {
            type: "string",
            categories: [filterlabel + " P&L"],
            labels: {
              rotate: -90,
            },
          },
        });
        if (dashAmount[0] == 0 && dashAmount[1] == 0) {
          setSeries([]);
        } else {
          setSeries([
            {
              name: "Profit",
              data: [dashAmount[0]],

              color: "green",
            },

            {
              name: "Loss",
              data: [dashAmount[1]],
              color: "red",
            },
          ]);
        }
      }
    } catch (err) {
      console.log(err, "_____________error");
    }
  };

  useEffect(() => {
    fetchChart();
  }, [filter, exchangetype, botType, tradeThemeData]);

  console.log(datas, "datasdatasdatas");
  return (
    <div className="col-md-6">
      <div className="userContent">
        <div className="flexTitle">
          <h2>Profit &amp; Loss (PNL)</h2>
          <Link to="/revenue-details" className="">
            Revenue Details
          </Link>
        </div>
        <div className="chartTitle">
          <h4>Duration</h4>
          <div className="form-group mb-0">
            <select className="form-select" onChange={handleChange}>
              <option value="Today">Today</option>
              <option value="Week">7 days</option>
              <option value="2Week">Last 2 Week</option>
              <option value="Month">Current Month</option>
            </select>
          </div>
        </div>
        <div className="dashboardBalanceDetails">
          <h2>
            Total Profit:{" "}
            {isEmpty(datas.totalamount)
              ? "0.00"
              : datas && toFixed(datas.totalamount, 4)}{" "}
            {datas.nationality == "indian" ? "INR" : "USD"}
          </h2>
        </div>
        <div className="dashboardBalanceDetails">
          <h2>
            {filterlabel} Profit:{" "}
            {isEmpty(datas.durationProfit)
              ? "0.00"
              : datas && toFixed(datas.durationProfit, 4)}{" "}
            {datas.nationality == "indian" ? "INR" : "USD"}
          </h2>
        </div>
        <div className="dashboardChartBox">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={250}
          />
        </div>
      </div>
    </div>
  );
}
