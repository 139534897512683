import React, { Fragment, useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import context
import SocketContext from "../Context/SocketContext";
import { useSelector } from "react-redux";
import ShareModal from "../../components/ShareModal";
//import api
import {
  getDashTradeHistory,
  getDashRunHistory,
  getDashPenOrders,
} from "../../actions/Bottrade";

//import lib
import { toFixed } from "../../lib/roundOf";
import { firstCapital } from "../../lib/String";
import { firstOnCap } from "../../lib/String";
import { dateTimeFormat } from "../../lib/dateFilter";
import isEmpty from "../../lib/isEmpty";

const BotBalance = () => {
  const columns = [
    {
      name: "Pair",
      selector: (row) => (
        <div>
          <a
            href={`trade/${row.firstCurrency}-${row.secondCurrency}/${
              row.exchange
            }/${firstOnCap(row.BotType)}`}
          >
            {row.pairName}
          </a>
        </div>
      ),
    },
    {
      name: "Mini Bots",
      selector: (row) => <>{row.BotType}</>,
    },
    {
      name: "Type",
      selector: (row) => {
        if (row.BotType == "SMART") {
          return row.priceType;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "170px",
    },
    {
      name: "Avg Status",
      selector: (row) => {
        if (row.BotType == "ZAP") {
          return <>-</>;
        } else {
          return row.tradeSetting.AveragingCall == "Start" ? "ON" : "OFF";
        }
      },
    },
    {
      name: "Run Status",
      selector: (row) => {
        if (row.BotType == "ZAP") {
          return <>-</>;
        } else if (row.panicStatus) {
          return <>Stop</>;
        } else {
          return (
            <>
              {row && row.tradeSetting && row.tradeSetting.RunStatus
                ? row.tradeSetting.RunStatus
                : ""}
            </>
          );
        }
      },
      width: "140px",
    },
    {
      name: "Panic Status",
      selector: (row) => {
        if (row.panicStatus) {
          return <>ON</>;
        } else {
          return <>OFF</>;
        }
      },
      width: "140px",
    },
    {
      name: "Cycle / One-Shot",
      selector: (row) => {
        if (row.BotType == "ZAP") {
          return <>-</>;
        } else {
          return (
            <>
              {row && row.tradeSetting && row.tradeSetting.StrategyPeriod
                ? row.tradeSetting.StrategyPeriod
                : ""}
            </>
          );
        }
      },
      width: "150px",
    },
    {
      name: "Investment",
      // sortable: true,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <span>{toFixed(row.investment, 4)}</span>
          </>
        );
      },
    },
    {
      name: "Current Value of Investment",
      selector: (row) => <span>{toFixed(row.currentvalue, 4)} </span>,
      // sortable: true,
      width: "200px",
    },
    {
      name: "Realised / UnRealised P&L",
      selector: (row) => (
        <div>
          <span className={row.pl > 0 ? "greenText" : "redText"}>
            {toFixed(row.pl, 2)} %
          </span>
          <a
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target="#share_modal"
            // onClick={(row) => shareModalclick(row)}
            onClick={() => shareModalclick(row)}
          >
            {" "}
            <img
              src={require("../../assets/images/shareIcon.png")}
              className="img-fluid share_icon"
              alt=""
            />
          </a>
        </div>
      ),
      // sortable: true,
      width: "195px",
    },
    // {
    //   name: "Share",
    //   selector: (row) => row.share,
    // },
  ];

  const historyColumns = [
    {
      name: "Pair",
      selector: (row) => row.pairName,
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "170px",
    },
    {
      name: "Side",
      selector: (row) => (
        <span className={row.buyorsell == "buy" ? "greenText" : "redText"}>
          {row.orderVia == "clearSell"
            ? "Clear Sell"
            : row.buyorsell.toUpperCase()}
        </span>
      ),
    },
    {
      name: "Trade Amount",
      selector: (row) => toFixed(row.orderValue, 2),
      width: "200px",
    },
    {
      name: "Price",
      selector: (row) => toFixed(row.price, row.priceDecimal),
    },
    {
      name: "Quantity",
      selector: (row) => toFixed(row.quantity, row.quantityDecimal),
    },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <span className="greenText">{firstCapital(row.status)}</span>
    //   ),
    // },
  ];

  const pendingColumns = [
    {
      name: "Pair",

      selector: (row) => (
        <div>
          <a
            href={`trade/${row.firstCurrency}-${row.secondCurrency}/${
              row.exchange
            }/${firstOnCap(row.BotType)}`}
          >
            {row.pairName}
          </a>
        </div>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "170px",
    },
    {
      name: "Entry Price",
      selector: (row) =>
        row.TradeType == "UserPrice"
          ? toFixed(row.price, row.priceDecimal)
          : "-",
      width: "150px",
    },
    {
      name: "Current Status",
      selector: (row) => (
        <span className="greenText">{firstCapital("open")}</span>
      ),
    },
    {
      name: "Type",
      selector: (row) => {
        if (row && row.TradeType == "userPrice") {
          return "User Price";
        } else {
          return <>{row.TradeType}</>;
        }
      },
    },
    // {
    //   name: "Cancel Order",
    //   selector: (row) => {
    //     return (
    //       <div>
    //         <button
    //           className="edit_btn"
    //           onClick={() => handleSubmit(row)}
    //           disabled={loader}
    //         >
    //           {loader && <div class="loader"></div>}
    //           Cancel
    //         </button>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Trade Strategy",
    //   selector: (row) => {
    //     if (row && row.TradeType == "Indicators") {
    //       return (
    //         <Fragment>
    //           <a href="#" onClick={() => handleIndView(row)}>
    //             View <i className="bi-eye" aria-hidden="true"></i>
    //           </a>
    //         </Fragment>
    //       );
    //     } else {
    //       return <>-</>;
    //     }
    //   },
    // },
  ];

  //state //
  const [RunData, setRunningData] = useState();
  const [historyData, setHistoryData] = useState();
  const [pendingData, setPenData] = useState();

  const socketContext = useContext(SocketContext);
  let { exchangetype } = useSelector((state) => state.exchange);
  let { botType } = useSelector((state) => state.bot);
  const [shareId, setshareId] = useState();

  //function
  const fetchHistoryDetail = async () => {
    try {
      let reqData = {
        exchange: exchangetype,
        bot: botType,
      };
      const { status, loading, result, message, error } =
        await getDashTradeHistory(reqData);
      if (status == "success") {
        setHistoryData(result);
      }
    } catch (err) {
      console.log(err, "----error");
    }
  };

  const fetchRunningDetail = async () => {
    try {
      let reqData = {
        exchange: exchangetype,
        bot: botType,
      };
      const { status, loading, result, message, error } =
        await getDashRunHistory(reqData);
      if (status == "success") {
        let pairList = [];
        for (let item of result) {
          let avgPrice = item.avgPrice / item.quantity;
          let profit =
            ((item.markPrice * item.quantity - avgPrice * item.quantity) /
              (avgPrice * item.quantity)) *
            100;
          let profitinprice =
            (item.markPrice * item.quantity - avgPrice * item.quantity) /
            (avgPrice * item.quantity);

          let investment = 0;
          for (let data of item.entryPrice) {
            investment = investment + data.quantity * data.price;
          }

          let cmp1 = item.markPrice * item.quantity;

          let cmp = item.markPrice * item.quantity - avgPrice * item.quantity;
          // console.log(item, investment, cmp, "000999i9----");
          pairList.push({
            ...item,
            //  currentvalue: investment + profit,
            currentvalue: item.quantity * item.markPrice,
            cmp: toFixed(item.markPrice, 4),
            priceType: item.priceType,
            cmp1: cmp1,
            investment: investment ? investment : 0.0,
            pl: profit ? profit : 0.0,
            profitinprice: profitinprice,
            StrategyPeriod:
              item && item.tradeSetting && item.tradeSetting.StrategyPeriod
                ? item.tradeSetting.StrategyPeriod
                : "",
            referralCode: item.referralCode,
          });
        }

        setRunningData(pairList);
      }
    } catch (err) {
      console.log(err, "----error");
    }
  };

  const fetchPenDetail = async () => {
    try {
      let reqData = {
        exchange: exchangetype,
        bot: botType,
      };

      const { status, result } = await getDashPenOrders(reqData);
      if (status == "success") {
        setPenData(result);
      }
    } catch (err) {}
  };

  // useEffect(() => {
  //   socketContext.socket.on("dashRunHistory", (result) => {
  //     console.log(result, "----------261");
  //     setRunningData(result.data);
  //   });
  // }, [botType, exchangetype]);

  useEffect(() => {
    fetchHistoryDetail();
    fetchRunningDetail();
    if (botType == "SMART") {
      fetchPenDetail();
    }

    // socket
    let socketTicker = "socketTicker" + exchangetype;
    socketContext &&
      socketContext[socketTicker] &&
      socketContext[socketTicker].on("marketPrice", (result) => {
        setRunningData((el) => {
          let pairList = [];
          el &&
            el.map((item) => {
              if (item.pairId == result.pairId) {
                let avgPrice = item.avgPrice / item.quantity;
                let profit =
                  ((result.data.markPrice * item.quantity -
                    avgPrice * item.quantity) /
                    (avgPrice * item.quantity)) *
                  100;
                let profitinprice =
                  (result.data.markPrice * item.quantity -
                    avgPrice * item.quantity) /
                  (avgPrice * item.quantity);

                let investment = 0;
                for (let data of item.entryPrice) {
                  investment = investment + data.quantity * data.price;
                }

                let cmp1 = result.data.markPrice * item.quantity;

                let cmp =
                  result.data.markPrice * item.quantity -
                  avgPrice * item.quantity;

                pairList.push({
                  ...item,
                  // currentvalue: investment + profit,
                  currentvalue: item.quantity * result.data.markPrice,
                  cmp: result.data.markPrice,

                  cmp1: cmp1,
                  investment: investment ? investment : 0.0,
                  pl: profit ? profit : 0.0,
                  profitinprice: profitinprice,
                  StrategyPeriod:
                    item &&
                    item.tradeSetting &&
                    item.tradeSetting.StrategyPeriod
                      ? item.tradeSetting.StrategyPeriod
                      : "",
                  referralCode: item.referralCode,
                });
              } else {
                pairList.push(item);
              }
            });
          return pairList;
        });
      });

    socketContext.socket.on("runningOrder", (result) => {
      // console.log(result, "------result");
      // if (result.pairId == tradePair.pairId) {
      setRunningData(result.data);
      // }
    });
    socketContext.socket.on("dashRunHistory", (result) => {
      setRunningData(result.data);
    });
  }, [botType, exchangetype]);

  // console.log(RunData, "________-RunData");

  const shareModalclick = async (row) => {
    if (!isEmpty(row)) {
      setshareId(row._id);
    }
  };

  return (
    // <div className="dashboardContent mt-4">
    //   <div className="dashboardContentTitleBar">
    //     <h3>Trades</h3>
    //   </div>
    //   <div className="primary_table">
    //     <DataTable columns={columns} data={data} pagination />
    //   </div>
    // </div>
    <div className="trade_main_left_bottom">
      <div className="card trade_card trade_history_card">
        <div className="card-header">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Running
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Trade History
              </button>
            </li>

            {botType == "SMART" && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pending-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pending"
                  type="button"
                  role="tab"
                  aria-controls="pending"
                  aria-selected="false"
                >
                  Pending Order
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="primary_table">
                <DataTable columns={columns} data={RunData} pagination />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="primary_table">
                <DataTable
                  columns={historyColumns}
                  data={historyData}
                  pagination
                />
              </div>
              {/* <p className="text-center mt-5">No Records Found</p> */}
            </div>

            <div
              className="tab-pane fade"
              id="pending"
              role="tabpanel"
              aria-labelledby="pending-tab"
            >
              <div className="primary_table">
                <DataTable
                  columns={pendingColumns}
                  data={pendingData}
                  pagination
                />
              </div>
              {/* <p className="text-center mt-5">No Records Found</p> */}
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(shareId) && (
        <ShareModal shareId={shareId} ShareData={RunData} />
      )}
    </div>
  );
};

BotBalance.propTypes = {
  row: PropTypes.any,
  state: PropTypes.any,
};

export default BotBalance;
