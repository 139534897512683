import React, { useState, useEffect } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";
import { Country, State, City } from "country-state-city";
import { MenuItem, Select } from "@material-ui/core";

import ProfileSideMenu from "../ProfileSideMenu";

import { getKycDetails } from "../../actions/users";

// import lib
import isEmpty from "../../lib/isEmpty";

import KycNonInd from "./KycNonInd";
import KycInd from "./KycInd";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  nationality: "",
  country: "",
  state: "",
  stateStatus: "",
  city: "",
  countryName: "",
  stateName: "",
};
let countryCodeSave = "";

export default function Kyc() {
  const dispatch = useDispatch();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const [kycValid, setKycValid] = useState({});

  const [stateArray, setstateArray] = useState([]);
  const [cityArray, setcityArray] = useState([]);
  const [kycState, setKycState] = useState([]);
  const { nationality, country, stateStatus, state, city } = formValue;

  const countryArr = [];
  const cityArr = [];
  const stateArr = [];
  const countryDetails = Country.getAllCountries();
  const stateDetails = State.getAllStates();
  const cityDetails = City.getAllCities();
  // redux-state
  const { kycStatus } = useSelector((state) => state.account);
  // const kycValid = useSelector(state => state.kycValidate);

  // function
  countryDetails.map((data, i) => {
    countryArr.push({
      value: data.isoCode,
      label: data.name,
    });
  });

  const updateStates = (countryCode) => {
    countryCodeSave = countryCode;
    const stateFillter = stateDetails.filter((data) => {
      return data.countryCode == countryCode;
    });
    stateFillter.map((data, i) => {
      stateArr.push({
        value: data.isoCode,
        name: data.name,
        label: data.name,
      });
    });

    // sort by name ascending order
    stateArr.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    //end

    setstateArray(stateArr);
  };

  if (isEmpty(stateArray)) {
    formValue.stateStatus = "empty";
  } else {
    formValue.stateStatus = "notempty";
  }

  const updateCity = (countryCode, stateCode) => {
    const cityillter = cityDetails.filter((data) => {
      return data.stateCode == stateCode && data.countryCode == countryCodeSave;
    });
    cityillter.map((data, i) => {
      cityArr.push({
        value: data.name,
        // stateCode: data.stateCode,
        label: data.name,
        name: data.name,
      });
      setcityArray(cityArr);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { status, loading, result, message, error } = await getKycDetails();
    console.log(result, "hi 112313");
    if (status == "success") {
      // console.log(result.kycStatus, result.nationality, "lllo 098");
      //alert(result.kycStatus, result.nationality);
      let nationalitykyc = result.nationality;

      setKycState(result.userId.kycStatus);
      setFormValue((prev) => {
        return {
          ...prev,
          nationality: nationalitykyc,
        };
      });
    }
  };

  const handleNation = (value) => {
    let formData = { ...formValue, ...{ nationality: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleCountry = (e) => {
    const { name, value } = e.target;
    var getCountry = countryArr.find((e) => e.value == value);
    updateStates(value);
    formValue.country = getCountry.value;
    formValue.city = "";
    formValue.countryName = getCountry.label;
    // let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formValue);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    var getState = stateArray.find((e) => e.value == value);
    updateCity(country, value);
    // if (name == "state" || name == "city") {
    //   if (/[^a-zA-Z]/.test(value)) return;
    // }
    formValue.state = getState.value;
    formValue.city = "";
    formValue.stateName = getState.label;
    // let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formValue);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleCity = (e) => {
    const { name, value } = e.target;

    var getCity = cityArray.find((e) => e.value == value);

    let formData = { ...formValue, ...{ [name]: getCity.name } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  useEffect(() => {
    setKycState(kycStatus);
  }, [kycStatus]);

  return (
    <main>
      <ScrollToTopOnMount />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            {kycState && kycState == "pending" && (
              <div className="col-lg-9 col-md-12">
                <div className="userContent">
                  <h2 className="kyc_success">Your KYC has been submitted</h2>
                </div>
              </div>
            )}
            {kycState && kycState == "approved" && (
              <div className="col-lg-9 col-md-12">
                <div className="userContent">
                  <h2 className="kyc_success">Your KYC has been verified</h2>
                </div>
              </div>
            )}
            {kycState && ["new", "rejected"].includes(kycState) && (
              <div className="col-lg-9 col-md-12">
                <div className="userContent">
                  <h2>KYC Verification</h2>
                  <div className="form-card">
                    <h3>
                      <span>Country</span>
                    </h3>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Nationality</label>
                          {/* Developer Note: Need to show all country list */}
                          <CountryDropdown
                            value={nationality}
                            onChange={handleNation}
                            // onBlur={handleBlurCountry}
                            className="country_dropdown form-control"
                            disabled={kycState == "rejected" ? true : false}
                            // disabled={editForm}
                          />
                          {kycValid.nationality && (
                            <p className="text-danger">
                              {kycValid.nationality}
                            </p>
                          )}
                          {/* Developer Note: Insert Error Message here code here */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Country of Residence</label>
                          {/* <CountryDropdown
                            value={country}
                            onChange={handleCountry}
                            // onBlur={handleBlurCountry}
                            className="country_dropdown form-control"
                            // disabled={editForm}
                          /> */}
                          <Select
                            name="country"
                            value={country}
                            onChange={handleCountry}
                            className="country_dropdown form-control w-80"
                          >
                            <MenuItem value={""}>Select country</MenuItem>
                            {countryArr.map((data, i) => {
                              return (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {kycValid.country && (
                            <p className="text-danger">{kycValid.country}</p>
                          )}
                          {/* Developer Note: Insert Error Message here code here */}
                        </div>
                      </div>
                      {!isEmpty(stateArray) && (
                        <>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>State / Province</label>
                              {/* <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            value={state}
                            name="state"
                          /> */}
                              <Select
                                name="state"
                                value={state}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <MenuItem value={""}>Select state</MenuItem>

                                {stateArray.map((data, i) => {
                                  return (
                                    <MenuItem value={data.value}>
                                      {data.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {kycValid.state && (
                                <p className="text-danger">{kycValid.state}</p>
                              )}
                              {/* Developer Note: Insert Error Message here code here */}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>City</label>
                              {/* <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            value={city}
                            name="city"
                          /> */}

                              <Select
                                name="city"
                                value={city}
                                onChange={handleCity}
                                className="form-control w-100"
                              >
                                {cityArray.length > 0 &&
                                  cityArray.map((data, i) => {
                                    return (
                                      <MenuItem value={data.name} key={i}>
                                        {data.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              {kycValid.city && (
                                <p className="text-danger">{kycValid.city}</p>
                              )}
                              {/* Developer Note: Insert Error Message here code here */}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {nationality == "India" && (
                    <KycInd data={formValue} valid={setKycValid} />
                  )}
                  {!isEmpty(nationality) && nationality != "India" && (
                    <KycNonInd data={formValue} valid={setKycValid} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
