import React, { useEffect } from "react";
import DataTable from "react-data-table-component";

// import lib
import isEmpty from "../../lib/isEmpty";
import { dateTimeFormat } from "../../lib/dateFilter";
import { firstCapital } from "../../lib/String";
export default function DepositHistory(props) {
  const { TxnList, nationality, fetchTransactionHistory } = props;

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let reqData = {
      filter: value,
    };
    fetchTransactionHistory(reqData);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "150px",
    },
    {
      name: "Amount (INR)",
      selector: (row) => {
        return <>{row.amount}</>;
      },
      width: "150px",
    },
    {
      name: "Deposit Type",
      selector: (row) => {
        if (row.assetType == "Deposit") {
          return <span color="greenText">{row.depositType}</span>;
        } else {
          return <></>;
        }
      },
      width: "150px",
    },
    {
      name: "Payment Type",
      selector: (row) => {
        if (row.BankType) {
          return <>{firstCapital(row.BankType)}</>;
        } else {
          return <>Wallet</>;
        }
      },
      width: "150px",
    },
    {
      name: "Coin",
      selector: (row) => {
        if (row && row.tokenCurrenySymbol) {
          return <>{row && row.tokenCurrenySymbol}</>;
        } else {
          return <>-</>;
        }
      },
      width: "150px",
    },
    {
      name: "Wallet Address/Bank",
      selector: (row) => {
        if (row.BankType == "") {
          return <>{row.walletAddress}</>;
        } else if (row.BankType == "bank") {
          return <>{row.bank}</>;
        } else {
          return <>-</>;
        }
      },
      width: "350px",
    },
    {
      name: "Reason",
      selector: (row) => {
        if (row.assetType == "Deposit" && row.Status == "Rejected") {
          return <span className="redText">{row.reason}</span>;
        } else {
          return <>-</>;
        }
      },
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        let color = "";
        if (row.Status == "Pending") {
          color = "yellowText";
        } else if (row.Status == "Rejected") {
          color = "redText";
        } else if (row.Status == "Approved") {
          color = "greenText";
        }
        return <span className={color}>{row.Status}</span>;
      },
      width: "150px",
    },
  ];

  const noncolumns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "150px",
    },
    {
      name: "Payment Type",
      selector: (row) => {
        return <>Wallet</>;
      },
      width: "150px",
    },
    {
      name: "Token Type",
      selector: (row) => {
        if (!isEmpty(row && row.tokenType)) {
          return <span color="greenText">{row.depositType}</span>;
        } else {
          return <>-</>;
        }
      },
      width: "150px",
    },
    {
      name: "Wallet Address",
      selector: (row) => <>{row.walletAddress}</>,
      width: "350px",
    },
    {
      name: "Amount (USDT)",
      selector: (row) => {
        return <>{row.amount}</>;
      },
      width: "150px",
    },
    {
      name: "Deposit Type",
      selector: (row) => {
        if (row.assetType == "Deposit") {
          return <span color="greenText">{row.depositType}</span>;
        } else {
          return <>-</>;
        }
      },
      width: "150px",
    },
    {
      name: "Reason",
      selector: (row) => {
        if (row.assetType == "Deposit" && row.Status == "Rejected") {
          return <span className="redText">{row.reason}</span>;
        } else {
          return <span>-</span>;
        }
      },
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        let color = "";
        if (row.Status == "Pending") {
          color = "yellowText";
        } else if (row.Status == "Rejected") {
          color = "redText";
        } else if (row.Status == "Approved") {
          color = "greenText";
        }
        return <span className={color}>{row.Status}</span>;
      },
      width: "150px",
    },
  ];

  return (
    <>
      <div className="userContent mt-4">
        <div className="flexTitle">
          <h2>Deposit Record</h2>
          <select className="form-select filterSelect" onChange={handleChange}>
            <option value="all">All</option>
            <option value="today">Today</option>
            <option value="week">Last week</option>
            <option value="month">Last 1 month</option>
            <option value="year">Last year</option>
          </select>
        </div>
        <div class="table-responsive">
          <div class="table-responsive">
            {/* {nationality == "nonindian" && (
              <table class="table table-bordered customTable">
                <tr>
                  <th>Date</th>
                  <th>Address</th>
                  <th>Amount (USDT)</th>
                  <th>Status</th>
                </tr>

                {TxnList &&
                  TxnList.length > 0 &&
                  TxnList.map((item, key) => {
                    let status = item.Status;
                    let color = "";
                    if (status == "Pending") {
                      color = "yellowText";
                    } else if (status == "Rejected") {
                      color = "redText";
                    } else if (status == "Approved") {
                      color = "greenText";
                    }

                    return (
                      <tr>
                        <td>{dateTimeFormat(item.createdAt)}</td>
                        <td>{item.walletAddress}</td>
                        <td>{item.amount}</td>
                        <td className={color}>{item.Status}</td>
                      </tr>
                    );
                  })}
                {TxnList && TxnList.length <= 0 && <div>No Record</div>}
              </table>
            )} */}
            <div className="dashboardContent mt-4">
              <div className="primary_table">
                {nationality == "indian" && (
                  <DataTable columns={columns} data={TxnList} pagination />
                )}
                {nationality == "nonindian" && (
                  <DataTable columns={noncolumns} data={TxnList} pagination />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
