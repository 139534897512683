// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  if (value.type == "2FA") {
    return twoFA(value);
  } else if (value.type == "changePassword") {
    return changePassword(value);
  }
};

const twoFA = (value) => {
  let errors = {};

  if (isEmpty(value.code)) {
    errors.code = "Please Enter 2FA Code";
  } else if (isNaN(value.code) || value.code.length > 6) {
    errors.code = "Invalid Code";
  }

  if (value.checkValue == false) {
    errors.checkValue = "Please select the backup code";
  }

  return errors;
};

const changePassword = (value) => {
  let errors = {},
    passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;

  if (isEmpty(value.oldPassword)) {
    errors.oldPassword = "Please Enter Old Password";
  }

  if (isEmpty(value.password)) {
    errors.password = "Please Enter Password";
  } else if (value.password.length > 18) {
    errors.password = "Minimum 6 and Maximum 18";
  } else if (value.password.length < 6) {
    errors.password = "Minimum 6 and Maximum 18";
  } else if (!passwordRegex.test(value.password)) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character";
  }

  if (isEmpty(value.confirmPassword)) {
    errors.confirmPassword = "Please Enter Confirm Password";
  } else if (value.confirmPassword.length > 18) {
    errors.confirmPassword = "Minimum 6 and Maximum 18";
  } else if (value.confirmPassword.length < 6) {
    errors.confirmPassword = "Minimum 6 and Maximum 18";
  } else if (
    !isEmpty(value.confirmPassword) &&
    value.password != value.confirmPassword
  ) {
    errors.confirmPassword = "Password and Confirm Password Must Match";
  } else if (!passwordRegex.test(value.confirmPassword)) {
    errors.confirmPassword =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character";
  }

  if (isEmpty(value.code)) {
    errors.code = "Kindly Send and Verify Your Code";
  }

  // if (isEmpty(value.twoFACode)) {
  //   errors.twoFACode = "You have to enable 2FA";
  // } else 
  

  return errors;
};
export default validation;
