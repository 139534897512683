import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// import actions
import { getScholarDet } from "../../actions/users";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";
import { firstCapital } from "../../lib/String";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const columns = [
  {
    name: "Email",
    // sortable: true,
    width: "200px",
    selector: (row) => row.email,
  },
  {
    name: "UserId",
    width: "150px",
    // sortable: true,
    selector: (row) => row.userId,
  },
  {
    name: "Position",
    width: "150px",
    selector: (row) => firstCapital(row.Position),
    // sortable: true,
  },

  {
    name: "Status",
    width: "150px",
    selector: (row) => firstCapital(row.status),
    // sortable: true,
  },

  {
    name: "Expiry Status",
    width: "150px",
    selector: (row) => 
    new Date(row.expiryDate) < new Date() ? dateTimeFormat(row.expiryDate):dateTimeFormat(row.expiryDate)+ " Expired "
    // sortable: true,
  },


  {
    name: "Date",
    width: "150px",
    selector: (row) => dateTimeFormat(row.Date),
    // sortable: true,
  },
];

export default function ScholarDetails() {
  const queryParams = new URLSearchParams(window.location.search);
  const level = queryParams.get("level");

  // state
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    try {
      let reqData = {
        level: level,
      };
      const { status, result } = await getScholarDet(
        reqData
      );
      if (status == "success") {
        setData(result);
      }
    } catch (err) {}
  };

  return (
    <div className="col-lg-9 col-md-12">
      <div className="dashboardTwoColumn">
        <div className="row">
          <div className="col-md-12">
            <div className="dashboardContent mt-2">
              <div className="dashboardContentTitleBar p-0">
                <ul
                  class="nav nav-tabs dashboardTabs"
                  id="pairTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="revenue_details-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#revenue_details"
                      type="button"
                      role="tab"
                      aria-controls="revenue_details"
                      aria-selected="true"
                    >
                      Scholar Details
                    </button>
                  </li>
                </ul>
              </div>
              <div className="dashboardPairDetails p-4">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="revenue_details"
                    role="tabpanel"
                    aria-labelledby="revenue_details-tab"
                  >
                    <div className="primary_table">
                      <DataTable columns={columns} data={data} pagination />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
