// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;
  let mobileRegex = /^\d+$/;

  if (isEmpty(value.email)) {
    errors.email = "Please Enter Email";
  } else if (!emailRegex.test(value.email)) {
    errors.email = "Invalid Email";
  }

  if (isEmpty(value.phoneCode)) {
    errors.phoneCode = "Please Enter PhoneCode";
  }

  if (isEmpty(value.phoneNo)) {
    errors.phoneNo = "Please Enter Mobile Number";
  }

  if (value.phoneCode == "91") {
    if (value.phoneNo.length < 10) {
      errors.phoneNo = "Invalid Mobile Number";
    }
  }

  // if(value.isReferral){
  // if(isEmpty(value.referenceCode)){
  //     errors.referenceCode = "Please Enter Referral Code"
  // }
  // }
  // if (isEmpty(value.otp)) {
  //     errors.otp = "Please Enter Mobile Verification Code"
  // } else if (isNaN(value.otp)) {
  //     errors.otp = "Mobile Code Only Numberic"
  // } else if (value.otp.length > 6) {
  //     errors.otp = "Mobile Code Otp Only 6 Numbers Allowed"
  // }

  if (isEmpty(value.password)) {
    errors.password = "Please Enter Password";
  } else if (value.password.length < 6 || value.password.length > 18) {
    errors.password = "Password Should be Minimum 6 and Maximum 18";
  } else if (!passwordRegex.test(value.password)) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character";
  }

  if (isEmpty(value.confirmPassword)) {
    errors.confirmPassword = "Please Enter Confirm Password";
  } else if (
    !isEmpty(value.confirmPassword) &&
    value.password != value.confirmPassword
  ) {
    errors.confirmPassword = "Password and Confirm Password Must Match";
  }

  return errors;
};

export default validation;
