import { SET_DASHBOARD_EXCHANGE } from "../constant";

const initialState = {
  Exchange: "",
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_EXCHANGE:
      return {
        ...action.exchange,
      };
    default:
      return state;
  }
};

export default Dashboard;
