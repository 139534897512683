// import package
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import browser from "browser-detect";
import Checkbox from "rc-checkbox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import action
import { forgotPassword, sentOTP } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getLang } from "../../lib/localStorage";

let initialFormValue = {
  phoneCode: "",
  phoneNo: "",
  otp: "",
  email: "",
};

const MobileForm = () => {
  let navigate = useNavigate();
  // states
  const [formValue, setFormValue] = useState(initialFormValue);
  const [reCaptcha, setReCaptcha] = useState("");
  const [optStatus, setOtpStatus] = useState(false);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();

  const { phoneCode, phoneNo, otp, email } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (value) {
      setValidateError({});
      setOtpStatus(true);
    }
    // setValidateError(validation(formData))
  };

  // const handleBlur = (e) => {
  //     const { name } = e.target;
  //     setToched({ ...toched, ...{ [name]: true } })
  // }

  const handleSentOTP = async (e) => {
    e.preventDefault();

    let reqData = {
      phoneCode,
      phoneNo,
      type: "forgot",
    };
    let { status, loading, error, message } = await sentOTP(reqData);
    if (status == "success") {
      toastAlert("success", message, "mobileForm");
      setOtpStatus(true);
    } else {
      if (error) {
        console.log(error, "-----------185");
        setValidateError(error);
        return;
      }
      toastAlert("error", message, "mobileForm");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    let reqData = {
      phoneCode,
      phoneNo,
      otp,
      type: "mobile",
    };
    let { status, loading, error, message, result } = await forgotPassword(
      reqData
    );
    setLoader(loading);
    setReCaptcha("");
    console.log(error, "--------92", message, status);
    if (status == "success") {
      setFormValue(initialFormValue);
      setToched({});
      // setValidateError(validation(initialFormValue))
      toastAlert("success", message, "forgotPassword");
      navigate("/reset-password/" + result);
    } else {
      toastAlert("error", message, "forgotPassword");
    }
    if (error) {
      setValidateError(error);
    }
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let newPhoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        phoneCode: dialCode,
        phoneNo: newPhoneNo.slice(dialCode.length),
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo: value } };
      setOtpStatus(true);
    }
    if (value && country) {
      setValidateError({});
    }
    setFormValue(formData);
    // setValidateError(validation(formData))
  };

  return (
    <Fragment>
      <form>
        {/*<div className="form-group">
                <label>Mobile Number</label>
                <div className="input_box_poa">
                    <PhoneInput
                        placeholder="Mobile"
                        onChange={handlePhoneNumber}
                        specialLabel={false}
                        
                        country={'in'}
                    />
                    {
                        !optStatus && 
                            <button
                                onClick={handleSentOTP}
                                disabled={validateError && validateError.phoneCode}
                                className="otp_btn"
                            >
                                Send Code
                            </button>
                        
                    }
                </div>
                {validateError && validateError.phoneNo && <p className="text-danger">{validateError.phoneNo}</p>}
            </div>*/}
        <div className="form-group">
          <label className="login_label">Mobile Number</label>
          <PhoneInput
            className="input-group regGroupInput mt-2"
            placeholder="Enter Mobile No"
            value={phoneCode + phoneNo}
            onChange={handlePhoneNumber}
            specialLabel={false}
            enableSearch={true}
            country={"in"}
          />
          <div className="input-group regGroupInput mt-2">
            {!optStatus && (
              <div className="auth_otp_btn">
                {" "}
                <button
                  onClick={handleSentOTP}
                  disabled={validateError && validateError.phoneCode}
                  className="new_bot_btn"
                >
                  Send Mobile OTP
                </button>
              </div>
            )}
          </div>

          {validateError && validateError.phoneNo && (
            <p className="text-danger">{validateError.phoneNo}</p>
          )}
        </div>
        {optStatus && optStatus && (
          <div className="form-group">
            <label>OTP</label>
            <input
              type="text"
              className="form-control"
              placeholder="OTP"
              name="otp"
              value={otp}
              onChange={handleChange}
            />
            {validateError && validateError.otp && (
              <p className="text-danger">{validateError.otp}</p>
            )}
          </div>
        )}
        <div className="form-group mb-0">
          <button
            className="btn btn-green-big"
            onClick={handleFormSubmit}
            disabled={!isEmpty(validateError) || loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default MobileForm;
