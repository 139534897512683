// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value, data) => {
  if (data.nationality == "India") {
    return indianValid(value, data);
  } else {
    return nonIndianValid(value, data);
  }
};

export const indianValid = (value, data) => {
  let errors = {},
    imageError = {};
  let regex = /^[A-Z0-9]+$/;
  let number = /^[0-9]+$/;
  let letter = /^[A-Z]+$/;
  console.log(data, "0111111111");
  let imageFormat = /\.(jpg|JPG|jpeg|JPEG|pdf|PDF)$/;
  // let ifscregex = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/

  console.log(value, "----------------------------value");
  if (isEmpty(data.nationality)) {
    errors.nationality = "Nationality Field Is Required";
  }
  if (isEmpty(data.country)) {
    errors.country = "Country Field Is Required";
  }
  if (data.stateStatus != "empty") {
    if (isEmpty(data.state)) {
      errors.state = "State / Province Field Is Required";
    }
    if (isEmpty(data.city)) {
      errors.city = "City Field Is Required";
    }
  }

  if (isEmpty(value.personName)) {
    errors.name = "Name Field Is Required";
  }
  if (isEmpty(value.idProof)) {
    errors.idProof = "Identity proof Field Is Required";
  }
  if (isEmpty(value.idProofNum)) {
    errors.idProofNum = "Identity Proof Number Field Is Required";
  } else if (value.idProofNum.length > 18) {
    errors.idProofNum = "Please Enter Correct Identity Proof Number";
  }

  if (isEmpty(value.PAN)) {
    errors.PAN = "PAN / TIN  Number Field Is Required";
  } else if (value.PAN.length > 12) {
    errors.PAN = "Please Enter Correct PAN / TIN Number";
  } else if (number.test(value.PAN) || letter.test(value.PAN)) {
    errors.PAN = "Invalid  PAN / TIN Number";
  } else if (!regex.test(value.PAN)) {
    errors.PAN = "Invalid  PAN / TIN Number";
  }

  if (isEmpty(value.bankName)) {
    errors.bankName = "Name Field Is Required";
  }
  if (isEmpty(value.branchName)) {
    errors.branchName = "Branch Name Field Is Required";
  }
  if (isEmpty(value.bankAccNum)) {
    errors.bankAccNum = "Account Number Field Is Required";
  } else if (value.bankAccNum.length < 9 || value.bankAccNum.length > 18) {
    errors.bankAccNum = "Enter Correct Account Number";
  }
  if (isEmpty(value.bankType)) {
    errors.bankType = "Account Type Field Is Required";
  }
  if (isEmpty(value.bankIfsc)) {
    errors.bankIfsc = "IFSC Number Field Is Required";
  } else if (number.test(value.bankIfsc) || letter.test(value.bankIfsc)) {
    errors.bankIfsc = "Invalid IFSC Code";
  } else if (!regex.test(value.bankIfsc)) {
    errors.bankIfsc = "Invalid IFSC Code";
  }

  if (value.frontImage && value.frontImage.size) {
    if (value.frontImage.size > 10000000) {
      // 10 MB
      errors.frontImage = "Please Select Less than 10MB";
    } else if (!imageFormat.test(value.frontImage.name)) {
      errors.frontImage = "Please Select only JPG/JPEG/PDF";
    }
  } else {
    errors.frontImage = "Front Image Field Is Required";
  }

  // if ((isEmpty(value.frontName)) || (imageError && imageError.frontImage)) {
  //     errors.frontName = "Please Enter Front Name and Select Front Image"
  // }

  if (value.backImage && value.backImage.size) {
    if (value.backImage.size > 10000000) {
      // 10 MB
      errors.backImage = "Please Select Less than 10MB";
    } else if (!imageFormat.test(value.backImage.name)) {
      errors.backImage = "Please Select only JPG/JPEG/PDF";
    }
  } else {
    errors.backImage = "Back Image Field Is Required";
  }

  // if ((isEmpty(value.backName)) || (imageError && imageError.backImage)) {
  //     errors.backName = "Please Enter Back Name and Select Back Image"
  // }

  // if (value.panImage && value.panImage.size) {
  //   if (value.panImage.size > 10000000) {
  //     // 10 MB
  //     imageError.panImage = "Please Select Less than 10MB";
  //   } else if (!imageFormat.test(value.panImage.name)) {
  //     imageError.panImage = "Please Select only JPG/JPEG/PDF";
  //   }
  // } else if (isEmpty(value.panImage)) {
  //   imageError.panImage = "PAN Image Field Is Required";
  // }

  if (value.panImage && value.panImage.size) {
    if (value.panImage.size > 10000000) {
      // 10 MB
      errors.panImage = "Please Select Less than 10MB";
    } else if (!imageFormat.test(value.panImage.name)) {
      errors.panImage = "Please Select only JPG/JPEG/PDF";
    }
  } else if (!isEmpty(value.panName) && isEmpty(value.panImage)) {
    errors.panImage = "PAN Image Field Is Required";
  }

  if (isEmpty(value.panName) && value.panImage == "") {
    errors.panName = "PAN Number and PAN Image Field Is Required";
  } else if (isEmpty(value.panName) && !imageError.panImage) {
    errors.panName = "Please Enter PAN Number";
  } else if (number.test(value.panName) || letter.test(value.panName)) {
    errors.panName = "Invalid PAN Number";
  } else if (!regex.test(value.panName)) {
    errors.panName = "Invalid PAN Number";
  } else if (value.PAN != value.panName) {
    errors.panName = "PAN Number must be same";
  } else if (
    !isEmpty(value.panName) &&
    !isEmpty(imageError && imageError.panImage)
  ) {
    errors.panName = imageError.panImage;
  }

  if (isEmpty(value.bankDoc)) {
    errors.bankDoc = "Bank Document Field Is Required";
  }

  if (!isEmpty(value.walletNetwork)) {
    if (isEmpty(value.walletAddress)) {
      errors.walletAddress = "Wallet Address Field Is Required";
    }
  }

  if (value.selfiImage == "") {
    errors.selfiImage = "Please Take a Selfie";
  }

  if (value.docImage && value.docImage.size) {
    if (value.docImage.size > 10000000) {
      // 10 MB
      errors.docImage = "Please Select Less than 10MB";
    } else if (!imageFormat.test(value.docImage.name)) {
      errors.docImage = "Please Select only JPG/JPEG/PDF";
    }
  } else {
    errors.docImage = "Document Image Field Is Required";
  }

  // if ((isEmpty(value.docName)) || (imageError && imageError.docImage)) {
  //     errors.docName = "Please Enter Document Name and Select Document Image"
  // }
  console.log(errors, "-----------180");
  return errors;
};

export const nonIndianValid = (value, data) => {
  let errors = {},
    imageError = {};
  let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;
  console.log(data, "0222222222");

  if (isEmpty(data.nationality)) {
    errors.nationality = "Nationality Field Is Required";
  }
  if (isEmpty(data.country)) {
    errors.country = "Country Field Is Required";
  }
  if (data.stateStatus != "empty") {
    if (isEmpty(data.state)) {
      errors.state = "State Field Is Required";
    }
    if (isEmpty(data.city)) {
      errors.city = "City Field Is Required";
    }
  }

  if (isEmpty(value.personName)) {
    errors.name = "Name Field Is Required";
  }
  // if (isEmpty(value.idProof)) {
  //   errors.idProof = "Identity Proof Field Is Required";
  // }
  // if (isEmpty(value.idProofNum)) {
  //   errors.idProofNum = "Identity Number Field Is Required";
  // }
  if (isEmpty(value.walletNetwork)) {
    errors.walletNetwork = "Network Field Is Required";
  }
  if (isEmpty(value.walletAddress)) {
    errors.walletAddress = "Wallet Address Field Is Required";
  }

  // if (value.walletProof && value.walletProof.size) {
  //   if (value.walletProof.size > 10000000) {
  //     // 10 MB
  //     imageError.walletProof = "Please Select Less than 10MB";
  //   } else if (!imageFormat.test(value.walletProof.name)) {
  //     imageError.walletProof = "Please Select only JPG/JPEG/PNG/PDF";
  //   }
  // } else {
  //   imageError.walletProof = "Wallet Image Field Is Required";
  // }

  // if (isEmpty(value.walletName) || (imageError && imageError.walletProof)) {
  //   errors.walletName = "Wallet Name and Wallet Image Field Is Required";
  // }

  if (value.walletProof && value.walletProof.size) {
    if (value.walletProof.size > 10000000) {
      // 10 MB
      errors.walletProof = "Please Select Less than 10MB";
    } else if (!imageFormat.test(value.walletProof.name)) {
      errors.walletProof = "Please Select only JPG/JPEG/PNG/PDF";
    }
  } else if (isEmpty(value.walletProof)) {
    errors.walletProof = "Wallet Image Field Is Required";
  }

  // if (isEmpty(value.walletName) && value.walletProof == "") {
  //   errors.walletName = "Wallet Name and Wallet Image Field Is Required";
  // } else if (isEmpty(value.walletName) && !imageError.walletProof) {
  //   errors.walletName = "Wallet Name Field Is Required";
  // } else if (
  //   !isEmpty(value.walletName) &&
  //   !isEmpty(imageError && imageError.walletProof)
  // ) {
  //   errors.walletName = imageError.walletProof;
  // }

  // if (value.idCardProof && value.idCardProof.size) {
  //   if (value.idCardProof.size > 10000000) {
  //     // 10 MB
  //     errors.idCardProof = "Please Select Less than 10MB";
  //   } else if (!imageFormat.test(value.idCardProof.name)) {
  //     errors.idCardProof = "Please Select only JPG/JPEG/PNG/PDF";
  //   }
  // } else if (isEmpty(value.idCardProof)) {
  //   errors.idCardProof = "IDCard Image Field Is Required";
  // }

  // if (isEmpty(value.idCardName) && value.idCardProof == "") {
  //   errors.idCardName = "IDCard Name and IDCard Image Field Is Required";
  // } else if (isEmpty(value.idCardName) && !imageError.idCardProof) {
  //   errors.idCardName = "IDCard Name Field Is Required";
  // } else if (
  //   !isEmpty(value.idCardName) &&
  //   !isEmpty(imageError && imageError.idCardProof)
  // ) {
  //   errors.idCardName = imageError.idCardProof;
  // }

  // if (value.idCardProof && value.idCardProof.size) {
  //   if (value.idCardProof.size > 10000000) {
  //     // 10 MB
  //     imageError.idCardProof = "Please Select Less than 10MB";
  //   } else if (!imageFormat.test(value.idCardProof.name)) {
  //     imageError.idCardProof = "Please Select only JPG/JPEG/PNG/PDF";
  //   }
  // } else {
  //   imageError.idCardProof = "IDCard Image Field Is Required";
  // }

  // if (isEmpty(value.idCardName) || (imageError && imageError.idCardProof)) {
  //   errors.idCardName = "IDCard Name and IDCard Image Field Is Required";
  // }

  // if (value.selfiImage == "") {
  //   errors.selfiImage = "Please Take a Selfie";
  // }
  return errors;
};
export default validation;
