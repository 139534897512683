import React, { useContext } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
// import Home from "views/home";
// import TokenContext from "./Tokencontext";
import isLogin from "../../lib/isLogin";

function AuthRoute({ children }) {
  let location = useLocation();
  if (isLogin() == false) {
    return <Navigate to="/signin" />;
  }
  return children;
}

export default AuthRoute;