import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "classnames";

import { useNavigate } from "react-router-dom";
// import config
import config from "../../config";

// import lib
import { toastAlert } from "../../lib/toastAlert";

export default function Referral() {
  const navigate = useNavigate();
  // redux
  const accountData = useSelector((state) => state.account);
  useEffect(() => {
    if (accountData.kycStatus == "new" || accountData.kycStatus == "rejected") {
      toastAlert("error", "Please Submit Your KYC", "kycsection");
      navigate("/dashboard");
    }

    if (accountData.kycStatus == "pending") {
      toastAlert("error", "Your KYC Not Approved", "kycsection");
      navigate("/dashboard");
    }
  }, []);
  return (
    <div className="col-lg-9 col-md-12">
      <div className="userContent">
        <h2>Invite a Friend</h2>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="referral_panel">
              {/* <a
                href="https://ugainindia.com/"
                target="_blank"
                className="d-block"
              >
                {config.FRONT_URL}
              </a> */}

              <div className="share_modal_body_right">
                <img
                  src={require("../../assets/images/logo.png")}
                  className="img-fluid"
                  alt="API Logo"
                />
              </div>

              <h3>Trading Buddy !</h3>
              {/*<img src={require("../../assets/images/qr_code_large.png")} alt="Icon" className="img-fluid" />*/}
              <img
                src={`https://chart.googleapis.com/chart?chs=166x166&chld=L|0&cht=qr&chl=${
                  accountData && accountData.referralCode
                }`}
                alt="Icon"
                className="img-fluid"
              />
              <div class="form-group">
                <label>Invitation code</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    value={accountData && accountData.referralCode}
                  />
                  {/*<button class="input-group-text"><img src={require("../../assets/images/copy_icon_white.png")} alt="QR" className="img-fluid" /></button>                            */}
                  <button className="input-group-text">
                    <CopyToClipboard
                      text={accountData && accountData.referralCode}
                      onCopy={() => {
                        toastAlert("success", "Copied!", "twoFa");
                      }}
                    >
                      <a href="#" className="btn btnType1 py-0 my-0 px-2">
                        <img
                          src={require("../../assets/images/copy_icon_white.png")}
                          alt="QR"
                          className="img-fluid"
                        />
                      </a>
                    </CopyToClipboard>
                  </button>
                </div>
              </div>
              <div>
                {/*<h4>http://ugainindia.com/register?invitation=UGN68011</h4>*/}
                <h4>{`${config.FRONT_URL}/?${
                  accountData && accountData.referralCode
                }`}</h4>
                {/*<button className="invite_btn"><img src={require("../../assets/images/copy_icon_white.png")} alt="QR" className="img-fluid" /></button>*/}
                <button className="invite_btn">
                  <CopyToClipboard
                    text={`${config.FRONT_URL}/?${
                      accountData && accountData.referralCode
                    }`}
                    onCopy={() => {
                      toastAlert("success", "Copied!", "twoFa");
                    }}
                  >
                    <a href="#" className="btn btnType1 py-0 my-0 px-2">
                      <img
                        src={require("../../assets/images/copy_icon_white.png")}
                        alt="QR"
                        className="img-fluid"
                      />
                    </a>
                  </CopyToClipboard>
                </button>
              </div>
              <div class="form-group">
                <label>Share this on</label>
              </div>
              <ul className="social_share_list justify-content-center align-items-center">
                <li>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${
                      config.FRONT_URL
                    }/?${accountData && accountData.referralCode}`}
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/fb_icon.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://api.whatsapp.com/send?text=${
                      config.FRONT_URL
                    }/?${accountData && accountData.referralCode}`}
                    data-action="share/whatsapp/share"
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/whatsapp_icon.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={` https://telegram.me/share/url?url=${
                      config.FRONT_URL
                    }/?${accountData && accountData.referralCode}`}
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/telegram_icon.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
