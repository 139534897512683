import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

// import action
import { getAllStrategy, manyTradeSetting } from "../../actions/Bottrade";

// import lib
import { toastAlert } from "../../lib/toastAlert";

export default function BatchSetting(props) {
  // props
  const { isShow, onHide, viewData, Params } = props;
  const [data, setData] = useState("");

  const fetchTradeSetting = async () => {
    try {
      const { status, result } = await getAllStrategy();
      if (status == "success") {
        setData(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  useEffect(() => {
    fetchTradeSetting();
  }, []);

  const handleClick = async (name) => {
    try {
      let pairData = Params.pair.replace(/\-/g, "");
      let reqData = {
        pair: pairData,
        exchange: Params.exchange,
        bot: Params.bot,
        viewData: viewData,
        name: name,
      };
      const { status, message } = await manyTradeSetting(reqData);
      if (status == "success") {
        toastAlert("success", message);
        onHide();
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  return (
    <Modal
      show={isShow}
      onHide={onHide}
      centered
      size="md"
      className="modal fade primary_modal"
    >
      <Modal.Header closeButton>
        <h1 className="modal-title fs-5">Trade Setting</h1>
      </Modal.Header>

      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="p-0 search_inp_div">
                <input
                  type="text"
                  placeholder="Seach Strategy"
                  className="form-control search_inp"
                />
                <i className="bi bi-search"></i>
              </div>
              {data &&
                data.length > 0 &&
                data.map((item, key) => {
                  return (
                    <div className="saved_strategies_panel">
                      <button
                        data-bs-dismiss="modal"
                        onClick={() => handleClick(item.Name)}
                      >
                        {item.Name}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
