import React, { useState, useEffect } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";

import ProfileSideMenu from "../ProfileSideMenu";

import { getUserData } from "../../actions/users";

// import lib
import isEmpty from "../../lib/isEmpty";

import TwoFA from "./2Fa";
import ChangePassword from "./ChangePassword";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  nationality: "",
  country: "",
  state: "",
  city: "",
};

export default function Kyc() {
  const dispatch = useDispatch();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [kycStatus, setKycStatus] = useState({});
  const [kycValid, setKycValid] = useState({});

  const { nationality, country, state, city } = formValue;

  // redux-state
  // const { kycStatus } = useSelector(state => state.account);
  // const kycValid = useSelector(state => state.kycValidate);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { status, loading, result, message, error } = await getUserData();
    if (status == "success") {
    
      setKycStatus(result.kycStatus);
    }
  };
  const handleCountry = (value) => {
    let formData = { ...formValue, ...{ country: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleNation = (value) => {
    let formData = { ...formValue, ...{ nationality: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  console.log(kycStatus, "------------------------kycStatus");
  return (
    <div className="page-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-12 d-none d-lg-block">
            <ProfileSideMenu />
          </div>
          <div className="col-lg-9 col-md-12">
            <div className="userContent">
              <h2>Security</h2>
              <TwoFA />
              <ChangePassword />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
