import React, { useEffect } from "react";


export default function TransferWalletIndian() {

  return (
    <div className="userContent mt-4">
        <h2>Transfer to M-Fee</h2>
        <div className="walletForm">
        <div className="form-group">
        <div className="flexLabel">
            <label>Amount</label>
            <span>M-Asset Balance: 3000.52 INR</span>
        </div>
        <div className="input-group mb-3">
                <input type="text" className="form-control" />
                <div className="input-group-text">
                    INR
                </div>
            </div>
        </div>
        <div className="form-group">
                <div className="switch-group">
                    <label htmlFor="flexSwitchCheckChecked">Auto Transfer</label>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                    </div>
                </div>
                <div className="greenNote border-top-0 pt-0"> Note: Amount - INR (Do not transfer below 500 INR)</div>
            </div>
        <div className="form-group mt-0">
            <button className="btn btn-green-big mx-auto mb-0">Transfer to M-Fee</button>
        </div>
        </div>
    </div>
  );
}
