// import lib
import isEmpty from "../../lib/isEmpty";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";

const validation = (value) => {
  // console.log(value, "_________-000");
  if (value.type == "personal") {
    return personalValid(value);
  } else if (value.type == "bank") {
    return bankValid(value);
  } else if (value.type == "wallet") {
    return WalletValid(value);
  }
};

export const personalValid = (value) => {
  let errors = {};

  console.log(value, "_--------------011");

  if (isEmpty(value.firstName)) {
    errors.firstName = "First Name Field Is Required";
  }
  if (isEmpty(value.lastName)) {
    errors.lastName = "Last Name Field Is Required";
  }
  if (isEmpty(value.address)) {
    errors.address = "Address Field Is Required";
  }
  if (isEmpty(value.country)) {
    errors.country = "Country Field Is Required";
  }
  if (value.stateStatus != "empty") {
    if (isEmpty(value.state)) {
      errors.state = "State / Province Field Is Required";
    }
    if (isEmpty(value.city)) {
      errors.city = "City Field Is Required";
    }
  }
  if (isEmpty(value.postalCode)) {
    errors.postalCode = "Postal / Zip Code Field Is Required";
  } else if (
    postcodeValidatorExistsForCountry(value.country) &&
    !postcodeValidator(value.postalCode, value.country)
  ) {
    errors.postalCode = "Invalid Postal Code";
  }

  return errors;
};

export const bankValid = (value) => {
  let errors = {};
  if (isEmpty(value.bankName)) {
    errors.bankName = "Bank Name Field Is Required";
  }
  if (isEmpty(value.branchName)) {
    errors.branchName = "Branch Name Field Is Required";
  }
  if (isEmpty(value.bankType)) {
    errors.bankType = "Account Type Field Is Required";
  }
  if (isEmpty(value.bankAccNum)) {
    errors.bankAccNum = "Account Number Field Is Required";
  }
  if (isEmpty(value.bankIfsc)) {
    errors.bankIfsc = "IFSC Code Field Is Required";
  }

  return errors;
};

export const WalletValid = (value) => {
  let errors = {};
  if (isEmpty(value.walletAddress)) {
    errors.walletAddress = "Wallet Address Field Is Required";
  }
  if (isEmpty(value.walletNetwork)) {
    errors.walletNetwork = "Wallet Network  Field Is Required";
  }

  return errors;
};
export default validation;
