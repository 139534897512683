import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

import validation from "./validation";

import { kycNonInd, setKycValidate } from "../../actions/userKyc";
import { getUserData } from "../../actions/users";
import { CameraFeed } from "./camera-feed";
import { adminwalletlist } from "../../actions/Wallet";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  personName: "",
  idProof: "",
  idProofNum: "",
  walletNetwork: "",
  //walletNetwork: "trc20",
  walletAddress: "",
  walletName: "",
  walletProof: "",
  idCardName: "",
  idCardProof: "",
  selfiImage: "",
};

const KycNonInd = (props) => {
  const dispatch = useDispatch();
  // console.log(props, "_---------props");

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [kycWallet, setKycWallet] = useState();
  const [loader, setLoader] = useState(false);
  const [adminwallet, setadminwallet] = useState();

  const { data, valid } = props;
  const {
    personName,
    idProof,
    idProofNum,
    walletNetwork,
    walletAddress,
    walletName,
    walletProof,
    idCardName,
    idCardProof,
    selfiImage,
  } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value, "------------------------01");
    if (name == "idProofNum") {
      if (/-?\d{20,20}/g.test(value)) return;
    }
    if (name == "walletAddress") {
      if (/[^a-zA-Z0-9]/.test(value)) return;
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    // if (name == "walletProof") {
    //   setKycWallet(URL.createObjectURL(files[0]));
    // }
    let imageValue = URL.createObjectURL(e.target.files[0]),
      showName,
      imageName;
    // console.log(imageValue,showName,imageName,'_________-imageValue')
    if (name == "walletProof") {
      showName = "showFront";
      imageName = "walletName";
    }
    if (name == "idCardProof") {
      showName = "showBack";
      imageName = "idCardName";
    }

    let formData = {
      ...formValue,
      ...{
        [name]: files[0],
        [showName]: imageValue,
        [imageName]: files[0].name,
      },
    };
    // let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const fetchadminwallet = async () => {
    const { status, loading, message, error, result } = await adminwalletlist();

    setadminwallet(result);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let validationError = validation(formValue, data);
    if (!isEmpty(validationError)) {
      valid(validationError);
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    let splitValue = walletNetwork.split("-");
    try {
      const formData = new FormData();
      formData.append("nationality", data.nationality);
      formData.append("country", data.country);
      formData.append("countryName", data.countryName);
      formData.append("state", data.state);
      formData.append("stateName", data.stateName);
      formData.append("city", data.city);
      formData.append("personName", personName);
      formData.append("idProof", idProof);
      formData.append("idProofNum", idProofNum);
      formData.append("walletNetwork", splitValue[0]);
      formData.append("currencySymbol", splitValue[1]);
      formData.append("walletAddress", walletAddress);
      formData.append("walletName", walletName);
      formData.append("walletProof", walletProof);
      formData.append("idCardName", idCardName);
      formData.append("idCardProof", idCardProof);
      formData.append("selfiImage", selfiImage);
      formData.append("stateStatus", data.stateStatus);

      // console.log(formData, "_--------formData");
      const { status, loading, message, error } = await kycNonInd(
        formData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        valid({});
        toastAlert("success", message, "kycsection");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };
  // Upload to capture image
  const uploadImage = async (file) => {
    let name = "selfiImage";
    let formData = { ...formValue, ...{ [name]: file } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  useEffect(() => {
    fetchadminwallet();
  }, [data.stateStatus]);

  // console.log(validateError, "___--validationError");
  return (
    <main>
      <ScrollToTopOnMount />
      <div className="form-card">
        <h3>
          <span>Personal Details</span>
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="personName"
                value={personName}
                onChange={handleChange}
              />
              {validateError && validateError.name && (
                <p className="text-danger">{validateError.name}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="form-group">
              <label>Identity Proof</label>
              <select
                className="form-select"
                onChange={handleChange}
                name="idProof"
                value={idProof}
              >
                <option value="">Select Proof document</option>
                <option value="aadhar_card">Adhaar card</option>
                <option value="passport">Passport</option>
                <option value="driving_license">Driving License</option>
                <option value="voter_id">Voter’s ID</option>
              </select>
              {validateError && validateError.idProof && (
                <p className="text-danger">{validateError.idProof}</p>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Identity Proof Number</label>
              <input
                type="text"
                className="form-control"
                name="idProofNum"
                value={idProofNum}
                onChange={handleChange}
              />
              {validateError && validateError.idProofNum && (
                <p className="text-danger">{validateError.idProofNum}</p>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <div className="form-card">
        <h3>
          <span>Wallet Address Details</span>
        </h3>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>Choose Network</label>
              {/* Developer Note: Change appropriate select option below, refer project document or get it from PC team */}
              <select
                className="form-select"
                onChange={handleChange}
                name="walletNetwork"
                value={walletNetwork}
              >
                <option value="">Select Network</option>

                {adminwallet &&
                  adminwallet.length > 0 &&
                  adminwallet.map((item, key) => {
                    return (
                      <>
                        <option
                          key={key}
                          value={item.tokenType + "-" + item.currencySymbol}
                        >
                          {item.currencySymbol}
                          {item.currencySymbol == "USDT"
                            ? " - " + item.tokenType.toUpperCase()
                            : ""}
                          {/* {item.tokenType.toUpperCase()} */}
                        </option>
                      </>
                    );
                  })}
                {/* <option value="bep20">USDT (BSC BEP 20)</option>

                <option value="trc20">USDT (TRC 20)</option>

                <option value="OHO">OHO </option> */}
              </select>
              {validateError && validateError.walletNetwork && (
                <p className="text-danger">{validateError.walletNetwork}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-8">
            <div className="form-group">
              <label>Wallet Address</label>
              <input
                type="text"
                className="form-control"
                name="walletAddress"
                value={walletAddress}
                onChange={handleChange}
              />
              {validateError && validateError.walletAddress && (
                <p className="text-danger">{validateError.walletAddress}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
        </div>
      </div>
      <div className="form-card">
        <h3>
          <span>Upload Documents</span>
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Wallet Address Screenshot</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="walletName"
                  value={walletName}
                  onChange={handleChange}
                />
                <div className="input-group-text green-bg">
                  <div className="fileUploadButton">
                    <img
                      src={require("../../assets/images/uploadIcon.png")}
                      className="img-fluid"
                      alt=""
                    />
                    <input
                      type="file"
                      name="walletProof"
                      onChange={handleFile}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className={"text-success"}>
                  Max. 10MB size, jpg, jpeg, png,pdf Allowed
                </label>
                {/*<img src={showDoc && showDoc} className="img-fluid" alt="" />*/}
              </div>

              {validateError && validateError.walletName && (
                <p className="text-danger">{validateError.walletName}</p>
              )}
              {validateError && validateError.walletProof && (
                <p className="text-danger">{validateError.walletProof}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="form-group">
              <label>ID Card</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="idCardName"
                  value={idCardName}
                  onChange={handleChange}
                />
                <div className="input-group-text green-bg">
                  <div className="fileUploadButton">
                    <img
                      src={require("../../assets/images/uploadIcon.png")}
                      className="img-fluid"
                      alt=""
                    />
                    <input
                      type="file"
                      name="idCardProof"
                      onChange={handleFile}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className={"text-success"}>
                  Max. 10MB size, jpg, jpeg, png, pdf Allowed
                </label>
              </div>

              {validateError && validateError.idCardName && (
                <p className="text-danger">{validateError.idCardName}</p>
              )}
              {validateError && validateError.idCardProof && (
                <p className="text-danger">{validateError.idCardProof}</p>
              )}
            </div>
          </div> */}
          <div className="col-lg-6">
            {/* Developer Note: Need to hide below div section before selectting wallet address screenshot line Number 132 to 134 */}
            <div className="form-group">
              {/*<img src={require("../../assets/images/upload-img-sample-1.jpg")} className="img-fluid" alt="" />*/}
              {/*{kycWallet && (
                <img src={kycWallet} className="img-fluid" alt="" />
              )}*/}
            </div>
            {/* Developer Note: And need to show after selecting appropriate  wallet address screenshot image*/}
          </div>
        </div>
      </div>
      {/* <div className="form-card">
        <h3>
          <span>Capture Live Photo</span>
        </h3>
        <div className="row">
          <CameraFeed className="img-fluid" sendFile={uploadImage} />
        </div>
        {validateError && validateError.selfiImage && (
          <p className="text-danger">{validateError.selfiImage}</p>
        )}
      </div> */}
      <div className="form-group text-left mt-4">
        <button
          className="btn btn-green-big ms-0 me-0"
          onClick={handleSubmit}
          disabled={loader}
        >
          {loader && <i class="fas fa-spinner fa-spin"></i>}
          Submit
        </button>
      </div>
    </main>
  );
};

export default KycNonInd;
