import React, { useEffect } from "react";
import Header from "../components/Header.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function KycIndian() {

  return (
<main>
	 <ScrollToTopOnMount />
          <Header />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-md-3 d-none d-lg-block">
                  <ProfileSideMenu />
                </div>
                <div className="col-md-9">
                  <div className="userContent">
                    <h2>KYC VERIFICATION</h2>
                    <div className="form-card">
                      <h3><span>Country</span></h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Nationality</label>
                            {/* Developer Note: Need to show all country list */}
                            <select className="form-select">
                              <option selected>Select Country</option>
                              <option value="1">India</option>
                              <option value="2">China</option>
                              <option value="3">Australia</option>
                            </select>
                            {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Country of Residence</label>
                            <select className="form-select">
                              <option selected>Select Country</option>
                              <option value="1">India</option>
                              <option value="2">China</option>
                              <option value="3">Australia</option>
                            </select>
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>State / Province</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>City</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-card">
                      <h3><span>Personal Details</span></h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Identity Proof</label>
                            {/* Developer Note: Change appropriate select option below, refer project document or get it from PC team */}
                            <select className="form-select">
                              <option selected>Select Proof document</option>
                              <option value="1">Adhaar card</option>
                              <option value="2">Passport</option>
                              <option value="3">Driving License</option>
                              <option value="3">Voter’s ID</option>
                            </select>
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Identity Proof Number</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>PAN / TIN Number</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-card">
                      <h3><span>Bank Details</span></h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Bank Name</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Branch Name</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Bank Account No.</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Account Type</label>
                            {/* Developer Note: Change appropriate select option below, refer project document or get it from PC team */}
                            <select className="form-select">
                              <option selected>Select account type</option>
                              <option value="1">Savings account</option>
                              <option value="2">Current account</option>
                            </select>
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Bank IFSC Code</label>
                            <input type="text" className="form-control" />
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-card">
                      <h3><span>Upload Documents</span></h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Identity Card (Front Side)</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" />
                              <div className="input-group-text green-bg">
                                <div className="fileUploadButton">
                                  <img src={require("../assets/images/uploadIcon.png")} className="img-fluid" alt="" />
                                  <input type="file" name="file" />
                                </div>
                              </div>
                            </div>
                             {/* Developer Note: Insert Error Message here code here */}
                             {/* <div className="form-group">
                            <img src={require("../assets/images/upload-img-sample-1.jpg")} className="img-fluid" alt="" />
                          </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Identity Card (Back Side)</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" />
                              <div className="input-group-text green-bg">
                                <div className="fileUploadButton">
                                  <img src={require("../assets/images/uploadIcon.png")} className="img-fluid" alt="" />
                                  <input type="file" name="file" />
                                </div>
                              </div>
                            </div>
                             {/* Developer Note: Insert Error Message here code here */}
                             {/* <div className="form-group">
                            <img src={require("../assets/images/upload-img-sample-1.jpg")} className="img-fluid" alt="" />
                          </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>PAN / TIN</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" />
                              <div className="input-group-text green-bg">
                                <div className="fileUploadButton">
                                  <img src={require("../assets/images/uploadIcon.png")} className="img-fluid" alt="" />
                                  <input type="file" name="file" />
                                </div>
                              </div>
                            </div>
                             {/* Developer Note: Insert Error Message here code here */}
                             {/* <div className="form-group">
                            <img src={require("../assets/images/upload-img-sample-1.jpg")} className="img-fluid" alt="" />
                          </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Bank Document</label>
                            <select className="form-select">
                              <option selected>Cancelled Cheque</option>
                              <option value="1">Bank Statement</option>
                              <option value="2">Passbook</option>
                            </select>
                             {/* Developer Note: Insert Error Message here code here */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Bank Document Upload</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" />
                              <div className="input-group-text green-bg">
                                <div className="fileUploadButton">
                                  <img src={require("../assets/images/uploadIcon.png")} className="img-fluid" alt="" />
                                  <input type="file" name="file" />
                                </div>
                              </div>
                            </div>
                             {/* Developer Note: Insert Error Message here code here */}
                             {/* <div className="form-group">
                            <img src={require("../assets/images/upload-img-sample-1.jpg")} className="img-fluid" alt="" />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-card">
                      <h3><span>Capture Live Photo</span></h3>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="form-group captureFrame">
                            {/* Developer Note: After clicking below image need to show camera preview screen instead of below image tag*/}
                            <img src={require("../assets/images/live-capture-screen.jpg")} className="img-fluid" alt="" />
                            {/* Developer Note: Insert screen capture vide code here*/}
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <ul className="noteList">
                            <li>- Look straight at the camera</li>
                            <li>- Light or neutral background</li>
                            <li>- No Sunglasses, Hat or a Headband</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-left mt-4">
                      <button className="btn btn-green-big ms-0 me-0">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
    </main>

  );
}
