import React, { useEffect } from "react";
import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";

export default function WithdrawHistory(props) {
  const { TxnList, nationality, fetchWithdrawHistory } = props;

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let reqData = {
      filter: value,
    };
    fetchWithdrawHistory(reqData);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "150px",
    },
    {
      name: "Bank Name",
      selector: (row) => <>{row.bank}</>,
      width: "150px",
    },
    {
      name: "Acc.No.",
      selector: (row) => <>{row.accountNumber}</>,
      width: "200px",
    },
    {
      name: "Amount (INR)",
      selector: (row) => {
        return <>{row.amount}</>;
      },
      width: "150px",
    },
    {
      name: "Reason",
      selector: (row) => {
        if (row.assetType == "Withdraw" && row.Status == "Rejected") {
          return <span className="redText">{row.reason}</span>;
        } else {
          return <>-</>;
        }
      },
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        let color = "";
        if (row.Status == "Pending") {
          color = "yellowText";
        } else if (row.Status == "Rejected") {
          color = "redText";
        } else if (row.Status == "Approved") {
          color = "greenText";
        }
        return <span className={color}>{row.Status}</span>;
      },
      width: "150px",
    },
  ];

  const noncolumns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "150px",
    },
    {
      name: "Address",
      selector: (row) => <>{row.walletAddress}</>,
      width: "300px",
    },
    {
      name: "Amount (USDT)",
      selector: (row) => {
        return <>{row.amount}</>;
      },
      width: "150px",
    },
    {
      name: "Reason",
      selector: (row) => {
        if (row.assetType == "Withdraw" && row.Status == "Rejected") {
          return <span className="redText">{row.reason}</span>;
        } else {
          return <></>;
        }
      },
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        let color = "";
        if (row.Status == "Pending") {
          color = "yellowText";
        } else if (row.Status == "Rejected") {
          color = "redText";
        } else if (row.Status == "Approved") {
          color = "greenText";
        }
        return <span className={color}>{row.Status}</span>;
      },
      width: "150px",
    },
  ];

  return (
    <div className="userContent mt-4">
      <div className="flexTitle">
        <h2>Withdrawal Record</h2>
        <select className="form-select filterSelect" onChange={handleChange}>
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="week">Last week</option>
          <option value="month">Last 1 month</option>
          <option value="year">Last year</option>
        </select>
      </div>
      <div class="table-responsive">
        {/* {nationality == "nonindian" && (
          <table class="table table-bordered customTable">
            <tr>
              <th>Date</th>
              <th>Address</th>
              <th>Amount (USDT)</th>
              <th>Status</th>
            </tr>
            {TxnList &&
              TxnList.length > 0 &&
              TxnList.map((item, key) => {
                let status = item.Status;
                let color = "";
                if (status == "Pending") {
                  color = "yellowText";
                } else if (status == "Rejected") {
                  color = "redText";
                } else if (status == "Approved") {
                  color = "greenText";
                }

                return (
                  <tr>
                    <td>{dateTimeFormat(item.createdAt)}</td>
                    <td>{item.walletAddress}</td>
                    <td>{item.amount}</td>
                    <td className={color}>{item.Status}</td>
                  </tr>
                );
              })}
          </table>
        )} */}

        {/* {nationality == "indian" && (
          <table class="table table-bordered customTable">
            <tr>
              <th>Date</th>
              <th>Bank Name</th>
              <th>Acc.No.</th>

              <th>Amount (INR)</th>
              <th>Status</th>
            </tr>
            {TxnList &&
              TxnList.length > 0 &&
              TxnList.map((item, key) => {
                let status = item.Status;
                let color = "";
                if (status == "Pending") {
                  color = "yellowText";
                } else if (status == "Rejected") {
                  color = "redText";
                } else if (status == "Approved") {
                  color = "greenText";
                }

                return (
                  <tr>
                    <td>{dateTimeFormat(item.createdAt)}</td>
                    <td>{item.bank}</td>
                    <td>{item.accountNumber}</td>
                    <td>{item.amount}</td>

                    <td className={color}>{item.Status}</td>
                  </tr>
                );
              })}
          </table>
        )} */}
        <div className="dashboardContent mt-4">
          <div className="primary_table">
            {nationality == "indian" && (
              <DataTable columns={columns} data={TxnList} pagination />
            )}
            {nationality == "nonindian" && (
              <DataTable columns={noncolumns} data={TxnList} pagination />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
