import React, { useState, useEffect, useContext } from "react";
import { toFixed } from "../lib/roundOf";

import config from "../config";
import isEmpty from "../lib/isEmpty";
import { useLocation, useParams } from "react-router-dom";
import { getShareModal } from "../actions/Bottrade";
import SocketContext from "./Context/SocketContext";

function useQuery() {
  const { search } = useLocation();
  let splitSearch = search.split("?");
  return splitSearch[1];
}

const ShareModalDownload = () => {
  const query = useQuery();
  const [ShareData, setShareData] = useState();
  const socketContext = useContext(SocketContext);

  useEffect(() => {
    fetchDetail();
  }, []);
  //   console.log(typeof query, "___________query");
  //function
  const fetchDetail = async () => {
    try {
      let reqData = {
        id: query,
      };
      const { status, loading, result, message, error } = await getShareModal(
        reqData
      );
      if (status == "success") {
        // console.log(result, "______result");
        setShareData(result);
      }
    } catch (err) {
      console.log(err, "----error");
    }
  };

  //   console.log(ShareData, "____________ShareData");

  useEffect(() => {
    socketContext.socket.on("marketPrice", (result) => {
      //   console.log(result, "___________tesult");
      setShareData((el) => {
        let pairList = [];
        el &&
          el.map((item) => {
            if (item.pairId == result.pairId) {
              let avgPrice = item.avgPrice / item.quantity;
              let profit =
                ((result.data.markPrice * item.quantity -
                  avgPrice * item.quantity) /
                  (avgPrice * item.quantity)) *
                100;
              let profitinprice =
                (result.data.markPrice * item.quantity -
                  avgPrice * item.quantity) /
                (avgPrice * item.quantity);

              let investment =
                result.data.markPrice * item.quantity -
                item.price * item.quantity;
              pairList.push({
                ...item,
                inverstment: investment,
                cmp: toFixed(result.data.markPrice, 2),
                pl: profit ? profit : 0.0,
                profitinprice: profitinprice,
                StrategyPeriod:
                  item && item.tradeSetting && item.tradeSetting.StrategyPeriod
                    ? item.tradeSetting.StrategyPeriod
                    : "",
                referralCode: item.referralCode,
              });
            } else {
              pairList.push(item);
            }
          });
        return pairList;
      });
    });

    socketContext.socket.on("runningOrder", (result) => {
      // if (result.pairId == tradePair.pairId) {
      setShareData(result.data);
      // }
    });
  }, []);

  let item = {};

  if (!isEmpty(query)) {
    item = ShareData && ShareData.find((el) => el._id == query.toString());
  }
  //   console.log(item, "item");

  return (
    <>
      {!isEmpty(item) && (
        <div className="share_modal primary_modal" id="share_page_modal">
          <div className="modal-content">
            <div className="modal-body" style={{ padding: "15px" }}>
              <div className="share_modal_body_top">
                <div className="share_modal_body_left">
                  {item &&
                    item.tradeSetting &&
                    item.tradeSetting.exchange == "Binance" && (
                      <img
                        src={require("../assets/images/bianaceLogo.png")}
                        className="img-fluid"
                        alt="API Logo"
                      />
                    )}

                  {item &&
                    item.tradeSetting &&
                    item.tradeSetting.exchange == "Bitmart" && (
                      <img
                        src={require("../assets/images/bitmartLogo.png")}
                        className="img-fluid"
                        alt="API Logo"
                      />
                    )}

                  {item &&
                    item.tradeSetting &&
                    item.tradeSetting.exchange == "Kucoin" && (
                      <img
                        src={require("../assets/images/kucoinLogo.png")}
                        className="img-fluid"
                        alt="API Logo"
                      />
                    )}

                  <div className="mt-3">
                    {item &&
                      item.tradeSetting &&
                      item.tradeSetting.BotType != "Zap" &&
                      item.StrategyPeriod == "cycle"
                      ? (
                        <img
                          src={require("../assets/images/cycle_icon.png")}
                          className="img-fluid"
                          alt="Cycle"
                        />
                      )
                      : (
                        <img
                          src={require("../assets/images/oneshot_icon.png")}
                          className="img-fluid"
                          alt="OneShot"
                        />
                      )}
                  </div>

                  <div className="share_trade_details_panel">
                    <div>
                      <h3 className={item && item.pl < 0 ? "redText" : "greenText"}>
                        {" "}
                        {item && item.pl && toFixed(item.pl, 4)} %
                      </h3>
                      <h4>
                        {item && item.profitinprice > 0
                          ? "+" + toFixed(item.profitinprice, 4)
                          : toFixed(item.profitinprice, 4)}{" "}
                        USDT
                      </h4>
                    </div>
                    <div className="d-block">
                      <h5>
                        <label>Entry Price</label>{" "}
                        <span>
                          {item &&
                            item.entryPrice &&
                            item.entryPrice[0] &&
                            item.entryPrice[0].price}
                          USDT
                        </span>
                      </h5>
                      <h5>
                        <label>Market Price</label>{" "}
                        <span>{item && toFixed(item.cmp, 4)} USDT</span>
                      </h5>
                    </div>
                    <div>
                      <img
                        src={
                          "https://chart.googleapis.com/chart?chs=100x100&chld=L|0&cht=qr&chl=" +
                          config.FRONT_URL +
                          "?" +
                          item.referralCode
                        }
                        className="img-fluid me-3"
                        alt="API Logo"
                      />
                      <div>
                        <label>Referral Code</label>
                        <h2>{item && item.referralCode}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="share_modal_body_right">
                  <img
                    src={require("../assets/images/popup_logo.png")}
                    className="img-fluid"
                    alt="API Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareModalDownload;
