import React, { useEffect, useState, useContext } from "react";
import Header from "../components/Header.js";
import WalletSideMenu from "../components/Wallet/WalletSideMenu";
import Footer from "../components/Footer.js";
import WalletBalance from "../components/Wallet/WalletBalance";
import MfeeHistory from "../components/Wallet/Mfeehistory";
import { MassetConversion } from "../actions/Wallet";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Wallet() {
  const [masset, Setmasset] = useState();

  const fetchMasset = async () => {
    const { result } = await MassetConversion();
    // console.log(result, "ABCCBCBCB");
    Setmasset(result);
  };

  useEffect(() => {
    fetchMasset();
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <WalletSideMenu />
            </div>
            <div className="col-lg-8">
              <WalletBalance masset={masset} />
              <MfeeHistory />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
