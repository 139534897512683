import React, { useState, useEffect } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "classnames";
import Checkbox from "rc-checkbox";

import ProfileSideMenu from "../ProfileSideMenu";

import { getUserData } from "../../actions/users";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { twoFAStatus } from "../../lib/displayStatus";

import validation from "./validation";

// import action
import {
  get2faCode,
  update2faCode,
  disabled2faCode,
} from "../../actions/users";

export default function Kyc() {
  const dispatch = useDispatch();

  // state
  const [secretData, setSecretData] = useState();
  const [loader, setLoader] = useState();
  const [code, setCode] = useState("");
  const [validateError, setValidateError] = useState({});
  const [checkValue, setCheckValue] = useState(false);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
      return;
    }
    setCode(value);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setCheckValue(checked);
  };

  const fetchTwoFA = async () => {
    try {
      const { status, loading, message, result } = await get2faCode();
      if (status == "success") {
        setSecretData(result);
      }
    } catch (err) {}
  };

  const disableTwoFa = async (data) => {
    setLoader(true);
    let reqData = {
      secret: secretData.secret,
      uri: secretData.uri,
      code: code,
      type: "2FA",
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      const { status, loading, error, message, result } = await disabled2faCode(
        reqData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "twoFA");
        setCode("");
        setSecretData(result);
      } else {
        if (error) {
          setValidateError(error);
        } else if (error) {
          toastAlert("error", message, "twoFA");
        }
      }
    } catch (err) {}
  };

  const enableTwoFa = async () => {
    setLoader(true);

    let reqData = {
      secret: secretData.secret,
      uri: secretData.uri,
      code: code,
      checkValue: checkValue,
      type: "2FA",
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      const { status, loading, error, message, result } = await update2faCode(
        reqData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "twoFA");
        setCode("");
        setSecretData(result);
      } else {
        if (error) {
          setValidateError(error);
        } else if (error) {
          toastAlert("error", message, "twoFA");
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchTwoFA();
  }, []);

  return (
    <div className="form-card">
      <h3>
        <span>Two Factor Authentication</span>
      </h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="tfa_status">
            <p className="mb-0">
              Status:{" "}
              <span
                className={
                  secretData && secretData.twoFaStatus == "disabled"
                    ? "text-danger"
                    : "text-success"
                }
              >
                {secretData && twoFAStatus(secretData.twoFaStatus, "status")}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label>Your 32 Digit Security Code</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    value={secretData && secretData.secret}
                    disabled
                  />
                  {/*<button class="input-group-text"><img src={require("../../assets/images/copy_icon_white.png")} alt="QR" className="img-fluid" /></button>*/}
                  <button className="input-group-text">
                    <CopyToClipboard
                      text={secretData && secretData.secret}
                      onCopy={() => {
                        toastAlert("success", "Copied!", "twoFa");
                      }}
                    >
                      <a href="#" className="btn btnType1 py-0 my-0 px-2">
                        <img
                          src={require("../../assets/images/copy_icon_white.png")}
                          alt="QR"
                          className="img-fluid"
                        />
                      </a>
                    </CopyToClipboard>
                  </button>
                </div>
                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label>Enter 6 Digit 2FA Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={code}
                  onChange={handleChange}
                />
                {validateError.code && (
                  <p className="text-danger">{validateError.code}</p>
                )}
                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group text-center">
            <label>Scan the QR Code</label>
            <img
              src={secretData && secretData.imageUrl}
              alt="QR"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="notes_section">
            <h5>Notes</h5>
            <p>
              "Enable" Two Factor Authentication as an additional security
              measure, to protect your funds and to withdraw. Please use Google
              Authenticator which you can{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
              >
                Download Here
              </a>
            </p>
            <h5 className="mt-4">How to Enable</h5>
            <ul>
              <li>
                After downloading the app, open it and scan the QR code. You’ll
                have 6 digit code Enter that 6 digit code into 2FA code box,
                then click Enable.
              </li>
              <li>
                Write down the 32-digit code on a white paper for security in
                case you forgot the 2FA code to withdraw funds.
              </li>
            </ul>
          </div>
        </div>
        <div className="form-check">
          {secretData && secretData.twoFaStatus == "disabled" && (
            <div>
              <Checkbox
                name="checkValue"
                onChange={handleCheckBox}
                checked={checkValue}
                hidden={true}
              />{" "}
              <label className="form-check-label">
                {" "}
                <p>I have backup the code</p>
              </label>
              {validateError.checkValue && (
                <p className="text-danger">{validateError.checkValue}</p>
              )}
            </div>
          )}
        </div>
        <div className="form-group text-left mt-4">
          {secretData && secretData.twoFaStatus == "disabled" && (
            <button
              className="btn btn-green-big ms-0 me-0"
              type="button"
              onClick={enableTwoFa}
            >
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Enable {/* {twoFAStatus(secretData.twoFaStatus, 'button')} */}
            </button>
          )}
          {secretData && secretData.twoFaStatus == "enabled" && (
            <button
              className="btn btn-green-big ms-0 me-0"
              type="button"
              onClick={disableTwoFa}
            >
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Disable {/* {twoFAStatus(secretData.twoFaStatus, 'button')} */}
            </button>
          )}
          {/*<button className="btn btn-green-big ms-0 me-0">Enable</button>*/}
        </div>
      </div>
    </div>
  );
}
