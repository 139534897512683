import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

import validation from "./validation";

import { kycInd, setKycValidate } from "../../actions/userKyc";
import { CameraFeed } from "./camera-feed";
import { adminwalletlist } from "../../actions/Wallet";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  personName: "",
  idProof: "",
  idProofNum: "",
  PAN: "",
  bankName: "",
  branchName: "",
  bankType: "",
  bankAccNum: "",
  bankIfsc: "",
  frontName: "",
  frontImage: "",
  showFront: "",
  showBack: "",
  showPan: "",
  showDoc: "",
  backName: "",
  backImage: "",
  panName: "",
  panImage: "",
  bankDoc: "",
  showWallet: "",
  docName: "",
  docImage: "",
  selfiImage: "",
  walletNetwork: "",
  walletAddress: "",
  walletProof: "",
  walletName: "",
};

const KycInd = (props) => {
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [loader, setLoader] = useState(false);
  console.log(props, "_---------props");
  const { data, valid } = props;
  const {
    personName,
    idProof,
    idProofNum,
    PAN,
    bankName,
    branchName,
    bankType,
    bankAccNum,
    bankIfsc,
    frontName,
    frontImage,
    backName,
    backImage,
    panName,
    panImage,
    bankDoc,
    docName,
    docImage,
    selfiImage,
    walletNetwork,
    walletAddress,
    walletName,
    walletProof,
    showFront,
    showBack,
    showWallet,
    showPan,
    showDoc,
  } = formValue;
  const [adminwallet, setadminwallet] = useState();

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "idProofNum") {
      if (/-?\d{20,20}/g.test(value)) return;
    }
    if (name == "PAN") {
      if (/-?\d{15,15}/g.test(value)) return;
    }
    if (name == "bankName" || name == "branchName") {
      if (/[^a-zA-Z ]/.test(value)) return;
    }

    if (name == "bankAccNum") {
      if (/[^0-9]/.test(value)) return;
    }
    if (
      name == "bankIfsc" ||
      name == "idProofNum" ||
      name == "PAN" ||
      name == "panName"
    ) {
      if (/[^a-zA-Z0-9]/.test(value)) return;
    }

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const fetchadminwallet = async () => {
    const { status, loading, message, error, result } = await adminwalletlist();

    setadminwallet(result);
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let imageValue = URL.createObjectURL(e.target.files[0]),
      showName,
      imageName;
    console.log(name, "_________-imageValue", files[0]);
    if (name == "frontImage") {
      showName = "showFront";
      imageName = "frontName";
    }
    if (name == "walletProof") {
      showName = "showWallet";
      imageName = "walletName";
    }
    if (name == "backImage") {
      showName = "showBack";
      imageName = "backName";
    }
    if (name == "panImage") {
      showName = "showPan";
    }
    if (name == "docImage") {
      showName = "showDoc";
      imageName = "docName";
    }
    let formData = {
      ...formValue,
      ...{
        [name]: files[0],
        [showName]: imageValue,
        [imageName]: files[0].name,
      },
    };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let validationError = validation(formValue, data);
    console.log(validateError, "-----------137");
    if (!isEmpty(validationError)) {
      console.log("------------139");
      valid(validationError);
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    let splitValue = [];
    if (!isEmpty(walletNetwork)) {
      splitValue = walletNetwork.split("-");
    }
    try {
      console.log(splitValue, "------186");
      const formData = new FormData();
      formData.append("nationality", data.nationality);
      formData.append("country", data.country);
      formData.append("countryName", data.countryName);
      formData.append("state", data.state);
      formData.append("stateName", data.stateName);
      formData.append("stateStatus", data.stateStatus);
      formData.append("city", data.city);
      formData.append("personName", personName);
      formData.append("idProof", idProof);
      formData.append("idProofNum", idProofNum);
      formData.append("PAN", PAN);
      formData.append("bankName", bankName);
      formData.append("branchName", branchName);
      formData.append("bankAccNum", bankAccNum);
      formData.append("bankType", bankType);
      formData.append("bankIfsc", bankIfsc);
      formData.append("frontName", frontName);
      formData.append("frontImage", frontImage);
      formData.append("backName", backName);
      formData.append("backImage", backImage);
      formData.append("panName", panName);
      formData.append("panImage", panImage);
      formData.append("bankDoc", bankDoc);
      formData.append("docName", docName);
      formData.append("docImage", docImage);
      formData.append(
        "walletNetwork",
        splitValue.length > 0 ? splitValue[0] : ""
      );
      formData.append(
        "currencySymbol",
        splitValue.length > 0 && splitValue[1] ? splitValue[1] : ""
      );
      formData.append(
        "walletAddress",
        !isEmpty(walletAddress) ? walletAddress : ""
      );
      formData.append("walletName", !isEmpty(walletName) ? walletName : "");
      formData.append("walletProof", walletProof);
      formData.append("selfiImage", selfiImage);
      console.log(formData, "--------------------formData");
      const { status, loading, message, error } = await kycInd(
        formData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        valid({});
        toastAlert("success", message, "kycsection");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {
      console.log(err, "---------240");
    }
  };

  // Upload to capture image
  const uploadImage = async (file) => {
    let name = "selfiImage";
    let formData = { ...formValue, ...{ [name]: file } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  useEffect(() => {
    fetchadminwallet();
  }, []);

  console.log(walletNetwork, "----------------------walletNetwork");
  return (
    <main>
      <ScrollToTopOnMount />
      <div className="form-card">
        <h3>
          <span>Personal Details</span>
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="personName"
                value={personName}
                onChange={handleChange}
              />
              {validateError && validateError.name && (
                <p className="text-danger">{validateError.name}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Identity Proof</label>
              {/* Developer Note: Change appropriate select option below, refer project document or get it from PC team */}
              <select
                className="form-select"
                onChange={handleChange}
                name="idProof"
                value={idProof}
              >
                <option value="">Select Document Proof </option>
                <option value="aadhar_card">Aadhaar card</option>
                <option value="passport">Passport</option>
                <option value="driving_license">Driving License</option>
                <option value="voter_id">Voter’s ID</option>
              </select>
              {validateError && validateError.idProof && (
                <p className="text-danger">{validateError.idProof}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Identity Proof Number</label>
              <input
                type="text"
                className="form-control"
                name="idProofNum"
                value={idProofNum}
                onChange={handleChange}
              />
              {validateError && validateError.idProofNum && (
                <p className="text-danger">{validateError.idProofNum}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>PAN/TIN Number</label>
              <input
                type="text"
                className="form-control"
                name="PAN"
                value={PAN}
                onChange={handleChange}
              />
              {validateError && validateError.PAN && (
                <p className="text-danger">{validateError.PAN}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
        </div>
      </div>
      <div className="form-card">
        <h3>
          <span>Bank Details</span>
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Bank Name</label>
              <input
                type="text"
                className="form-control"
                name="bankName"
                value={bankName}
                onChange={handleChange}
              />
              {validateError && validateError.bankName && (
                <p className="text-danger">{validateError.bankName}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Branch</label>
              <input
                type="text"
                className="form-control"
                name="branchName"
                value={branchName}
                onChange={handleChange}
              />
              {validateError && validateError.branchName && (
                <p className="text-danger">{validateError.branchName}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Bank Account No.</label>
              <input
                type="text"
                className="form-control"
                name="bankAccNum"
                value={bankAccNum}
                onChange={handleChange}
              />
              {validateError && validateError.bankAccNum && (
                <p className="text-danger">{validateError.bankAccNum}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Account Type</label>
              {/* Developer Note: Change appropriate select option below, refer project document or get it from PC team */}
              <select
                className="form-select"
                onChange={handleChange}
                name="bankType"
                value={bankType}
              >
                <option value="">Select Account Type </option>
                <option value="savings_account">Savings account</option>
                <option value="current_account">Current account</option>
              </select>
              {validateError && validateError.bankType && (
                <p className="text-danger">{validateError.bankType}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Bank IFSC Code</label>
              <input
                type="text"
                className="form-control"
                name="bankIfsc"
                value={bankIfsc}
                onChange={handleChange}
              />
              {validateError && validateError.bankIfsc && (
                <p className="text-danger">{validateError.bankIfsc}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
        </div>
      </div>
      <div className="form-card">
        <h3>
          <span>Wallet Address Details</span>
        </h3>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>Choose Network</label>
              {/* Developer Note: Change appropriate select option below, refer project document or get it from PC team */}
              <select
                className="form-select"
                onChange={handleChange}
                name="walletNetwork"
                value={walletNetwork}
              >
                <option value="">Select Network</option>

                {adminwallet &&
                  adminwallet.length > 0 &&
                  adminwallet.map((item, key) => {
                    return (
                      <>
                        <option
                          key={key}
                          value={item.tokenType + "-" + item.currencySymbol}
                        >
                          {item.currencySymbol}
                          {item.currencySymbol == "USDT"
                            ? " - " + item.tokenType.toUpperCase()
                            : ""}
                          {/* {item.tokenType.toUpperCase()} */}
                        </option>
                      </>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="form-group">
              <label>Wallet Address</label>
              <input
                type="text"
                className="form-control"
                name="walletAddress"
                value={walletAddress}
                onChange={handleChange}
              />
            </div>
            {validateError && validateError.walletAddress && (
              <p className="text-danger">{validateError.walletAddress}</p>
            )}
          </div>
        </div>
      </div>
      <div className="form-card">
        <h3>
          <span>Upload Documents</span>
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Identity Card (Front Side)</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="frontName"
                  value={frontName}
                  // onChange={handleChange}
                />

                <div className="input-group-text green-bg">
                  <div className="fileUploadButton">
                    <img
                      src={require("../../assets/images/uploadIcon.png")}
                      className="img-fluid"
                      alt=""
                    />
                    <input
                      type="file"
                      name="frontImage"
                      onChange={handleFile}
                    />
                  </div>
                </div>
              </div>
              {validateError && validateError.frontName && (
                <p className="text-danger">{validateError.frontName}</p>
              )}
              {validateError && validateError.frontImage && (
                <p className="text-danger">{validateError.frontImage}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
              <div className="form-group">
                <label className={"text-success"}>
                  Max. 10MB size, jpg, jpeg, pdf Allowed
                </label>
              </div>
              {/*<img src={showFront && showFront} className="img-fluid" alt="" />*/}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Identity Card (Back Side)</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="backName"
                  value={backName}
                  // onChange={handleChange}
                />
                <div className="input-group-text green-bg">
                  <div className="fileUploadButton">
                    <img
                      src={require("../../assets/images/uploadIcon.png")}
                      className="img-fluid"
                      alt=""
                    />
                    <input type="file" name="backImage" onChange={handleFile} />
                  </div>
                </div>
              </div>
              {validateError && validateError.backName && (
                <p className="text-danger">{validateError.backName}</p>
              )}
              {validateError && validateError.backImage && (
                <p className="text-danger">{validateError.backImage}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
              <div className="form-group">
                <label className={"text-success"}>
                  Max. 10MB size, jpg, jpeg, pdf Allowed
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>PAN / TIN Number and Image</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="panName"
                  value={panName}
                  onChange={handleChange}
                />
                <div className="input-group-text green-bg">
                  <div className="fileUploadButton">
                    <img
                      src={require("../../assets/images/uploadIcon.png")}
                      className="img-fluid"
                      alt=""
                    />
                    <input type="file" name="panImage" onChange={handleFile} />
                  </div>
                </div>
              </div>
              {validateError && validateError.panName && (
                <p className="text-danger">{validateError.panName}</p>
              )}
              {validateError && validateError.panImage && (
                <p className="text-danger">{validateError.panImage}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
              <div className="form-group">
                <label>{panImage && panImage.name}</label>
                <label className={"text-success"}>
                  Max. 10MB size, jpg, jpeg, pdf Allowed
                </label>
                {/*<img src={showPan && showPan} className="img-fluid" alt="" />*/}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Bank Document Type</label>
              <select
                className="form-select"
                onChange={handleChange}
                name="bankDoc"
                value={bankDoc}
              >
                <option value="">Select Document Type</option>
                <option value="bank_statement">Bank Statement</option>
                <option value="passbook">Passbook</option>
                <option value="cancelled_cheque">Cancelled Cheque</option>
              </select>
              {validateError && validateError.bankDoc && (
                <p className="text-danger">{validateError.bankDoc}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Bank Document Upload</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="docName"
                  value={docName}
                  // onChange={handleChange}
                />
                <div className="input-group-text green-bg">
                  <div className="fileUploadButton">
                    <img
                      src={require("../../assets/images/uploadIcon.png")}
                      className="img-fluid"
                      alt=""
                    />
                    <input type="file" name="docImage" onChange={handleFile} />
                  </div>
                </div>
              </div>
              {validateError && validateError.docName && (
                <p className="text-danger">{validateError.docName}</p>
              )}
              {validateError && validateError.docImage && (
                <p className="text-danger">{validateError.docImage}</p>
              )}
              {/* Developer Note: Insert Error Message here code here */}
              <div className="form-group">
                <label className={"text-success"}>
                  Max. 10MB size, jpg, jpeg, pdf Allowed
                </label>
              </div>
            </div>
          </div>
          {!isEmpty(walletNetwork) && (
            <div className="col-lg-6">
              <div className="form-group">
                <label>Wallet Address Screenshot</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="walletName"
                    value={walletName}
                    onChange={handleChange}
                  />
                  <div className="input-group-text green-bg">
                    <div className="fileUploadButton">
                      <img
                        src={require("../../assets/images/uploadIcon.png")}
                        className="img-fluid"
                        alt=""
                      />
                      <input
                        type="file"
                        name="walletProof"
                        onChange={handleFile}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className={"text-success"}>
                    Max. 10MB size, jpg, jpeg, png,pdf Allowed
                  </label>
                  {/*<img src={showDoc && showDoc} className="img-fluid" alt="" />*/}
                </div>

                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-card">
        <h3>
          <span>Capture Live Photo</span>
        </h3>
        <div className="row">
          <CameraFeed className="img-fluid" sendFile={uploadImage} />
        </div>
        {validateError && validateError.selfiImage && (
          <p className="text-danger">{validateError.selfiImage}</p>
        )}
      </div>
      <div className="form-group text-left mt-4">
        <button
          className="btn btn-green-big ms-0 me-0"
          onClick={handleSubmit}
          disabled={loader}
        >
          {loader && <i class="fas fa-spinner fa-spin"></i>}
          Submit
        </button>
      </div>
    </main>
  );
};

export default KycInd;
