import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header.js";
import WalletSideMenu from "../components/Wallet/WalletSideMenu";
import WalletBalance from "../components/Wallet/WalletBalance";
import WithdrawWallet from "../components/Wallet/WithdrawWallet";
import WithdrawHistory from "../components/Wallet/WithdrawHistory";
import WithdrawWalletIndian from "../components/Wallet/WithdrawWalletIndian";

import Footer from "../components/Footer.js";

import { WithdrawList } from "../actions/Withdraw";
import { MassetConversion } from "../actions/Wallet";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function WalletWithdraw() {
  // state
  const [TxnList, setTxnList] = useState([]);
  const [masset, Setmasset] = useState();

  // redux-state
  const { kycStatus, nationality } = useSelector((state) => state.account);

  const fetchWithdrawHistory = async (reqData) => {
    const { status, loading, message, error, result } = await WithdrawList(reqData);
    setTxnList(result);
  };

  const fetchMasset = async () => {
    const { status, loading, message, error, result } =
      await MassetConversion();
    Setmasset(result);
  };

  useEffect(() => {
    let reqData = {
      filter: "all"
    }
    fetchWithdrawHistory(reqData);
    fetchMasset();
  }, []);
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <WalletSideMenu />
              {nationality == "nonindian" && (
                <WithdrawWallet
                  fetchWithdrawHistory={fetchWithdrawHistory}
                  fetchMasset={fetchMasset}
                />
              )}
              {nationality == "indian" && (
                <WithdrawWalletIndian
                  fetchWithdrawHistory={fetchWithdrawHistory}
                  fetchMasset={fetchMasset}
                />
              )}
            </div>
            <div className="col-lg-8">
              <WalletBalance masset={masset} fetchMasset={fetchMasset} />
              <WithdrawHistory TxnList={TxnList} nationality={nationality} fetchWithdrawHistory={fetchWithdrawHistory} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
