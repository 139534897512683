import React, { useEffect, useState, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Modal } from "react-bootstrap";
import config from "../../config";

// import action
import {
  getBatchPos,
  operateSetting,
  getAllStrategy,
} from "../../actions/Bottrade";

import TradeStrategy from "./tradeStrategyModal";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";

// import context
import SocketContext from "../Context/SocketContext";

export default function BatchSetting(props) {
  const socketContext = useContext(SocketContext);

  // props
  const { isShow, onHide, Params } = props;
  const [PairListData, setPairListData] = useState();
  const [PairList, setPairList] = useState();
  const [searchby, setsearchby] = useState("USDT");
  const [data, setData] = useState("");
  const [tradeModal, setTradeModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchbyinput, setsearchbyinput] = useState();
  const [sortby, Setsortby] = useState("asc");
  const [viewData, setViewData] = useState([]);
  const [allCheck, setAllCheck] = useState(false);

  //function
  const handleCheck = (e) => {
    try {
      const { name, checked } = e.target;
      let newData = viewData;
      let condition = newData.find((el) => el == name);
      if (!isEmpty(condition)) {
        let newData = [...viewData];
        newData.splice(newData.indexOf(condition), 1);
        setAllCheck(false);
        setViewData(newData);
      } else {
        newData = [...viewData];
        newData.push(e.target.name);
        setViewData(newData);
      }
    } catch (err) {}
  };
  const handleClose = () => {
    setTradeModal(false);
    onHide();
  };
  const handleAllCheck = (e) => {
    try {
      const { name, checked } = e.target;
      setAllCheck(checked);
      let checkArr = PairList;
      if (checked) {
        let newData = [];
        checkArr &&
          checkArr.map((item, i) => {
            let pairName = item.firstCurrency + item.secondCurrency;
            newData.push(pairName);
          });
        setViewData(newData);
      } else {
        setViewData([]);
      }
    } catch (err) {}
  };
  const handleOperate = async (e) => {
    try {
      const { name } = e.target;
      if (isEmpty(viewData)) {
        toastAlert("error", "Kindly Select Your Pairs");
        return;
      }
      if (name == "tradeSetting") {
        setTradeModal(true);
        return;
      }
      if (!isEmpty(name)) {
        setLoader(true);
        let reqData = {
          pair: viewData,
          exchange: Params.exchange,
          bot: Params.bot,
          type: name,
        };
        const { status, message } = await operateSetting(reqData);
        if (status == "success") {
          setLoader(false);
          toastAlert("success", message);
          fetchBatchPos();
          onHide();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setLoader(false);
          toastAlert("error", message);
        }
      }
    } catch (err) {}
  };
  const fetchBatchPos = async () => {
    let pairData = Params.pair.replace(/\-/g, "");
    let reqData = {
      pair: pairData,
      exchange: Params.exchange,
      bot: Params.bot,
    };
    const { status, result } = await getBatchPos(reqData);
    if (status == "success") {
      setPairListData(result);
      setPairList(result);
    }
  };

  useEffect(() => {
    fetchBatchPos();
    let socketTicker = "socketTicker" + Params.exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      setPairList((el) => {
        let pairList = [];
        if (el) {
          el.map((item) => {
            if (item.pairId == result.pairId) {
              let avarage = item.avgPrice / item.quantity;

              let returnrate_per =
                ((result.data.markPrice * item.quantity -
                  avarage * item.quantity) /
                  (avarage * item.quantity)) *
                100;

              let profit =
                result.data.markPrice * item.quantity - avarage * item.quantity;
              console.log(profit, "-----------profit", result.data.markPrice);
              console.log(item, "--------priceDecimal");
              pairList.push({
                ...item,

                profit: profit ? profit : 0.0,
                returnrate_per: returnrate_per ? returnrate_per : 0.0,

                markPrice: toFixed(result.data.markPrice, item.priceDecimal),
                change: toFixed(result.data.change, 2),
              });
            } else {
              pairList.push(item);
            }
          });
        }
        return pairList;
      });
    });

    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, []);
  useEffect(() => {
    socketContext.socket.on("batchOpenPos", (result) => {
      setPairList(result.data);
      setPairListData(result.data);
    });
  }, []);
  const gotoTrade = async (pair) => {
    const url = "/trade/" + pair + "/" + Params.exchange + "/" + Params.bot;
    window.location.href = url;
  };

  const OnclicksortBy = async (key) => {
    let sort = "";
    if (sortby == "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    let sortedPairlist = "";
    if (sort == "asc") {
      if (key == "change" || key == "markPrice") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(b[key]) - parseFloat(a[key]) // asc
        );
      } else if (key == "quantity") {
        sortedPairlist = PairList.sort(
          (a, b) =>
            parseFloat(b.Orders.quantity) - parseFloat(a.Orders.quantity) // desc
        );
      } else if (key == "profit") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(b.Orders.profit) - parseFloat(a.Orders.profit) // desc
        );
      }
    }

    if (sort == "desc") {
      if (key == "change" || key == "markPrice") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(a[key]) - parseFloat(b[key]) // desc
        );
      } else if (key == "quantity") {
        sortedPairlist = PairList.sort(
          (a, b) =>
            parseFloat(a.Orders.quantity) - parseFloat(b.Orders.quantity) // desc
        );
      } else if (key == "profit") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(a.Orders.profit) - parseFloat(b.Orders.profit) // desc
        );
      }
    }

    setPairList(sortedPairlist);
    Setsortby(sort);
  };

  const searchbysymbol = async (symbol) => {
    var searcharr = [];
    setsearchby(symbol);
    if (PairListData && PairListData.length > 0) {
      for (var i = 0; i < PairListData.length; i++) {
        var temppair =
          PairListData[i].firstCurrency + "/" + PairListData[i].secondCurrency;
        if (temppair.indexOf(symbol.toUpperCase()) !== -1) {
          searcharr.push(PairListData[i]);
        }
        if (i == PairListData.length - 1) {
          setPairList(searcharr);
        }
      }
    }
  };
  const searchpair = (e) => {
    e.preventDefault();
    setsearchbyinput(e.target.value);
    var searcharr = [];
    if (PairListData && PairListData.length > 0) {
      for (var i = 0; i < PairListData.length; i++) {
        var temppair =
          PairListData[i].firstCurrency + "/" + PairListData[i].secondCurrency;
        if (temppair.indexOf(e.target.value.toUpperCase()) !== -1) {
          searcharr.push(PairListData[i]);
        }
        if (i == PairListData.length - 1) {
          setPairList(searcharr);
        }
      }
    }
  };

  return (
    <div>
      <TradeStrategy
        isShow={tradeModal}
        Params={Params}
        viewData={viewData}
        onHide={handleClose}
      />
      <Modal
        show={isShow}
        onHide={onHide}
        centered
        size="lg"
        className="modal fade primary_modal"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">Batch Setting</h1>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-body">
            <div className="batch_settings_modal_panel">
              <div className="coin_details">
                <div className="card trade_card">
                  <div className="card-header">
                    <h2>Coins</h2>
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => searchbysymbol("USDT")}
                          className={
                            searchby == "USDT" ? "nav-link active" : "nav-link"
                          }
                          id="batch_one_tab"
                          type="button"
                        >
                          USDT
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => searchbysymbol("BTC")}
                          className={
                            searchby == "BTC" ? "nav-link active" : "nav-link"
                          }
                          id="batch_two_tab"
                          type="button"
                          role="tab"
                        >
                          BTC
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={() => searchbysymbol("ETH")}
                          className={
                            searchby == "ETH" ? "nav-link active" : "nav-link"
                          }
                          id="batch_three_tab"
                          type="button"
                        >
                          ETH
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="search_inp_div">
                        <input
                          type="text"
                          placeholder="Seach Pair"
                          value={searchbyinput}
                          onChange={searchpair}
                          className="form-control search_inp"
                        />
                        <i className="bi bi-search"></i>
                      </div>
                      <div
                        className="tab-pane fade show active"
                        id="batch_one"
                        role="tabpanel"
                        aria-labelledby="batch_one_tab"
                      >
                        <div className="pair_table_head_batch">
                          <div onClick={() => OnclicksortBy("quantity")}>
                            Qty
                          </div>
                          <div onClick={() => OnclicksortBy("markPrice")}>
                            Price
                          </div>
                          <div onClick={() => OnclicksortBy("profit")}>
                            Profit
                          </div>
                          <div onClick={() => OnclicksortBy("change")}>
                            24H Chg
                          </div>
                        </div>
                        <div className="pair_table_body">
                          <Scrollbars
                            style={{ height: 328 }}
                            renderTrackVertical={({ style, ...props }) => (
                              <div
                                className="track_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  right: "2px",
                                  bottom: "2px",
                                  top: "2px",
                                  borderRadius: "10px",
                                  width: "5px",
                                }}
                              />
                            )}
                            renderThumbVertical={({ style, ...props }) => (
                              <div
                                className="thumb_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  width: "5px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                }}
                              />
                            )}
                          >
                            {PairList &&
                              PairList.length > 0 &&
                              PairList.map((item, index) => {
                                let pairName =
                                  item.firstCurrency + item.secondCurrency;
                                let checkData = viewData.find(
                                  (el) => el == pairName
                                );
                                if (searchby == item.secondCurrency) {
                                  return (
                                    <div className="d-flex">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value={pairName}
                                        name={pairName}
                                        checked={
                                          isEmpty(checkData) ? false : true
                                        }
                                        onChange={(e) => handleCheck(e)}
                                        id="flexCheckDefault"
                                      />
                                      <div className="pair_table_bodyrow">
                                        <div className="pair_table_bodyrow_top">
                                          <div>
                                            <img
                                              src={
                                                config.API_URL +
                                                "/images/currency/" +
                                                item.image
                                              }
                                              alt={item.image}
                                              className="img-fluid"
                                            />
                                            <h3>
                                              {item.firstCurrency}
                                              <span>
                                                /{item.secondCurrency}
                                              </span>
                                            </h3>
                                          </div>
                                          <div>
                                            <span className="badge bg_yellow trade_type">
                                              {item.strategy}
                                            </span>
                                          </div>
                                          <div
                                            className={
                                              item && item.returnrate_per < 0
                                                ? "badge bg_red per_change"
                                                : "badge bg_green per_change"
                                            }
                                          >
                                            {item &&
                                              toFixed(item.returnrate_per, 4)}
                                            %
                                          </div>
                                        </div>
                                        <div className="pair_table_bodyrow_bottom">
                                          <div className="pair_table_bodyrow_bottom_left">
                                            <div>
                                              <label>Quantity:</label>
                                              <label>
                                                {toFixed(item.quantity, 6)}
                                              </label>
                                            </div>
                                            <div>
                                              <label>Price:</label>
                                              <label>{item.markPrice}</label>
                                            </div>
                                          </div>
                                          <div className="pair_table_bodyrow_bottom_right">
                                            <div>
                                              <label>Profit($):</label>
                                              <label
                                                className={
                                                  item && item.profit < 0
                                                    ? "redText"
                                                    : "greenText"
                                                }
                                              >
                                                {item &&
                                                  toFixed(item.profit, 4)}
                                              </label>
                                            </div>
                                            <div>
                                              <label>Chg 24H(%):</label>
                                              <label
                                                className={
                                                  item.change > 0
                                                    ? "greenText"
                                                    : "redText"
                                                }
                                              >
                                                {item.change}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </Scrollbars>
                        </div>
                      </div>
                      {/* <div
                      className="tab-pane fade"
                      id="batch_two"
                      role="tabpanel"
                      aria-labelledby="batch_two_tab"
                    >
                      <div className="pair_table">
                        <div className="pair_table_head">
                          <div>Qty</div>
                          <div>Price</div>
                          <div>Profit</div>
                          <div>24H Chg</div>
                        </div>
                        <div className="pair_table_body">
                          <Scrollbars
                            style={{ height: 300 }}
                            renderTrackVertical={({ style, ...props }) => (
                              <div
                                className="track_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  right: "2px",
                                  bottom: "2px",
                                  top: "2px",
                                  borderRadius: "10px",
                                  width: "5px",
                                }}
                              />
                            )}
                            renderThumbVertical={({ style, ...props }) => (
                              <div
                                className="thumb_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  width: "5px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                }}
                              />
                            )}
                          ></Scrollbars>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="batch_three"
                      role="tabpanel"
                      aria-labelledby="batch_three_tab"
                    >
                      <div className="pair_table">
                        <div className="pair_table_head">
                          <div>Qty</div>
                          <div>Price</div>
                          <div>Profit</div>
                          <div>24H Chg</div>
                        </div>
                        <div className="pair_table_body">
                          <Scrollbars
                            style={{ height: 300 }}
                            renderTrackVertical={({ style, ...props }) => (
                              <div
                                className="track_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  right: "2px",
                                  bottom: "2px",
                                  top: "2px",
                                  borderRadius: "10px",
                                  width: "5px",
                                }}
                              />
                            )}
                            renderThumbVertical={({ style, ...props }) => (
                              <div
                                className="thumb_vertical"
                                {...props}
                                style={{
                                  ...style,
                                  width: "5px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                }}
                              />
                            )}
                          ></Scrollbars>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="batch_settings_modal_right">
                <div className="operate_settings">
                  <h3>Operate only for selected coin</h3>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={allCheck}
                      onChange={handleAllCheck}
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      All
                    </label>
                  </div>
                </div>
                <div className="batch_settings_btn_grp">
                  <div>
                    {/* {Params.bot == "Smart" && (
                      <button
                        onClick={(e) => handleOperate(e)}
                        name="tradeSetting"
                      >
                        <img
                          src={require("../../assets/images/batch_setting_icon_01.png")}
                          alt="Icon"
                        />
                        Trade Setting
                      </button>
                    )} */}

                    <button
                      onClick={(e) => handleOperate(e)}
                      disabled={loader}
                      name="sellCmp"
                    >
                      <img
                        src={require("../../assets/images/batch_setting_icon_02.png")}
                        alt="Icon"
                      />
                      {!loader && "Sell at CMP"}
                      {loader && <div class="loader"></div>}
                    </button>
                  </div>
                  <div>
                    <button onClick={(e) => handleOperate(e)} name="start">
                      <img
                        src={require("../../assets/images/batch_setting_icon_03.png")}
                        alt="Icon"
                      />
                      Start
                    </button>
                    <button onClick={(e) => handleOperate(e)} name="pause">
                      <img
                        src={require("../../assets/images/batch_setting_icon_04.png")}
                        alt="Icon"
                      />
                      Pause
                    </button>
                  </div>
                  <div>
                    <button onClick={(e) => handleOperate(e)} name="oneShot">
                      <img
                        src={require("../../assets/images/batch_setting_icon_05.png")}
                        alt="Icon"
                      />
                      One-Shot
                    </button>
                    <button onClick={(e) => handleOperate(e)} name="cycle">
                      <img
                        src={require("../../assets/images/batch_setting_icon_06.png")}
                        alt="Icon"
                      />
                      Cycle
                    </button>
                  </div>
                  <div>
                    <button onClick={(e) => handleOperate(e)} name="startAvg">
                      <img
                        src={require("../../assets/images/icon_04.png")}
                        alt="Icon"
                      />
                      Start Averaging
                    </button>
                    <button onClick={(e) => handleOperate(e)} name="stopAvg">
                      <img
                        src={require("../../assets/images/batch_setting_icon_04.png")}
                        alt="Icon"
                      />
                      Stop Averaging
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
