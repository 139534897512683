export const firstCapital = (value) => {
  if (typeof value !== "string") return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
  return "";
};

export const firstOnCap = (value) => {
  if (typeof value !== "string") return "";
  let data = value.charAt(0).toUpperCase();
  value = value.toLowerCase();
  let finData = data + value.slice(1);
  return finData;
  return "";
};
