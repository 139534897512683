import React, { useEffect, useState } from "react";
import Header from "../Header.js";
import ProfileSideMenu from "../ProfileSideMenu";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../Footer.js";

import PersonalDet from "./PersonalDet";
import BankDet from "./BankDet";
import WalletDet from "./WalletDet";

// import lib
import { firstCapital } from "../../lib/String";
import { dateTimeFormat } from "../../lib/dateFilter";
import isEmpty from "../../lib/isEmpty";

// import actions
import { getKycDetails } from "../../actions/userKyc";
import { fetchMember } from "../../actions/users";

import Team from "./Team";
import TeamIncome from "./TeamIncome";
import ContactUs from "./Contactus";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Profile() {
  const dispatch = useDispatch();

  // state
  const [editable, setEditable] = useState(true);
  const [kycData, setKycData] = useState({});
  const [remDays, setRemDays] = useState({});

  // redux-state
  const account = useSelector((state) => state.account);

  const handleChange = () => {
    setEditable(false);
  };
  useEffect(() => {
    fetchDetail();
    memberList();
  }, []);

  const fetchDetail = async () => {
    try {
      const { status, loading, result, message, error } = await getKycDetails();
      if (status == "success") {
        setKycData(result);
      }
    } catch (err) {}
  };

  const memberList = async () => {
    try {
      const { status, loading, result, message, error } = await fetchMember();
      if (status == "success") {
        setRemDays(result);
      }
    } catch (err) {}
  };
  console.log(kycData, account, "Details");
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="userContent">
                <div className="flexTitle profileTitleFlex">
                  <h2>My Profile</h2>
                  <button className="edit_btn" onClick={handleChange}>
                    <i className="bi bi-pencil-fill"></i> Edit
                  </button>
                </div>
                <PersonalDet editable={editable} />
                {kycData &&
                  kycData.nationality == "India" &&
                  ["pending", "approved"].includes(account.kycStatus) && (
                    <>
                      <BankDet editable={editable} />
                      <WalletDet editable={editable} />
                    </>
                  )}
                {kycData &&
                  kycData.nationality != "India" &&
                  // ["pending", "approved"].includes(kycData.nonIndian.status) &&
                  account &&
                  ["pending", "approved"].includes(account.kycStatus) && (
                    <WalletDet editable={editable} />
                  )}
                <div className="form-card">
                  <h3>
                    <span>Member Center</span>
                  </h3>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="member_box">
                        <div>
                          <img
                            src={require("../../assets/images/gb_icon.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                          <div>
                            <h4>
                              GainBOT {firstCapital(account && account.rank)}
                            </h4>
                            <p className="mb-0">
                              {remDays && dateTimeFormat(remDays.subscriDate)}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="mb-0">Rank</p>
                          <h5>
                            {!isEmpty(account && account.rank)
                              ? firstCapital(account && account.rank)
                              : "-"}
                          </h5>
                        </div>
                        <div>
                          <p className="mb-0">Team</p>
                          <h5>
                            {remDays && remDays.total > 0 ? remDays.total : 0}
                          </h5>
                        </div>
                        <div>
                          <p className="mb-0">Remaining Days</p>
                          <h5>{remDays && remDays.remainingDays}</h5>
                        </div>
                        {/* <button className="renew_btn">Renew</button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <Team />

                <TeamIncome />

                <ContactUs />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
