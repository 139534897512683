import React, { useEffect } from "react";
import { Link,NavLink,useLocation  } from "react-router-dom";
import $ from "jquery"

export default function WalletSideMenu() {

    const location = useLocation();
console.log(location.pathname,'locationlocation')
    useEffect(() => {  
        loadScript();
          },[]);

    function loadScript() {
                dropdown('#mobileMenuDrop');
           function dropdown(e){
               var obj = $(e+'.dropdown');
               var btn = obj.find('.btn-selector');
               var dd = obj.find('ul');
               var opt = dd.find('li');
               
                   obj.on("click", function() {
                       dd.show();
                   }).on("mouseleave", function() {
                       dd.hide();
                   })
                   
                   opt.on("click", function() {
                       dd.hide();
                       var txt = $(this).text();
                       opt.removeClass("active");
                       $(this).addClass("active");
                       btn.text(txt);
                   });
           }
       
         }

  return (
    <div className="walletSideMenu">
        <div className="d-none d-lg-block">
        <div className="walletSideMenuBottom">
            <ul className="navbar-nav">
            <li className="nav-item">
                <NavLink to="/wallet" className="nav-link"><span className="walletMenuIcon1"></span> Wallet</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/wallet-deposit" className="nav-link"><span className="walletMenuIcon2"></span> Deposit</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/wallet-withdraw" className="nav-link"><span className="walletMenuIcon3"></span> Withdraw</NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/wallet-transfer" className="nav-link"><span className="walletMenuIcon4"></span> Transfer</NavLink>
            </li>
            </ul>
        </div>
        </div>
        <div className="d-block d-lg-none">
        <div id="mobileMenuDrop" class="dropdown">
            {location.pathname=="/wallet"?
            <Link to="" className="nav-link active"><span className="flexIconLeft"><span className="walletMenuIcon1"></span> Wallet</span></Link>
            :location.pathname=="/wallet-deposit"?
            <Link to="" className="nav-link active"><span className="flexIconLeft"><span className="walletMenuIcon2"></span> Deposit</span></Link>
            :location.pathname=="/wallet-withdraw"?
            <Link to="" className="nav-link active"><span className="flexIconLeft"><span className="walletMenuIcon3"></span> Withdraw</span></Link>
            :location.pathname=="/wallet-transfer"?
            <Link to="" className="nav-link active"><span className="flexIconLeft"><span className="walletMenuIcon4"></span> Transfer</span></Link>
            :<Link to="/wallet" className="nav-link"><span className="flexIconLeft"><span className="walletMenuIcon1"></span> Wallet</span></Link>
  }
            <ul>
                <li><NavLink to="/wallet" className="nav-link"><span className="flexIconLeft"><span className="walletMenuIcon1"></span> Wallet</span></NavLink></li>
                <li><NavLink to="/wallet-deposit" className="nav-link"><span className="flexIconLeft"><span className="walletMenuIcon2"></span> Deposit</span></NavLink></li>
                <li><NavLink to="/wallet-withdraw" className="nav-link"><span className="flexIconLeft"><span className="walletMenuIcon3"></span> Withdraw</span></NavLink></li>
                <li><NavLink to="/wallet-transfer" className="nav-link"><span className="flexIconLeft"><span className="walletMenuIcon4"></span> Transfer</span></NavLink></li>
            </ul>
        </div>
        </div>
    </div>
  );
}
