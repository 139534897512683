import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// import component
import RegisterForm from "../components/RegisterForm"

// import config
import config from '../config';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Register() {

  return (
<section className="crediantial-layout">
	 <ScrollToTopOnMount />
          <div className="container">
          <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA_SITE_KEY}>
            <RegisterForm />
            </GoogleReCaptchaProvider>
          </div>
    </section>

  );
}
