import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

// import actions
import { getTeamIncome } from "../../actions/users";

// import lib
import isEmpty from "../../lib/isEmpty";
import { dateTimeFormat } from "../../lib/dateFilter";
import { toFixed } from "../../lib/roundOf";
import { firstCapital } from "../../lib/String";

const TeamIncome = (props) => {
  // state
  const [data, setData] = useState([]);
  const [teamName, setTeamName] = useState("actIncome");
  const [convert, setConvert] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPageAuto, setCurrentPageAuto] = useState(1);
  const [totalAutoItems, setTotAuto] = useState(0);
  const [itemAutoPer, setItemAutoPer] = useState(10);

  useEffect(() => {
    fetchDetail(teamName);
  }, [itemAutoPer, currentPageAuto, itemsPerPage, currentPage]);

  const fetchDetail = async (type) => {
    try {
      let reqQuery = {
        type: type,
        limit: type == "autoPool" ? itemAutoPer : itemsPerPage,
        skip:
          type == "autoPool"
            ? (currentPageAuto - 1) * itemAutoPer
            : (currentPage - 1) * itemsPerPage,
      };
      const { status, result } = await getTeamIncome(reqQuery);
      if (status == "success") {
        setData(result.data[0].referralData);
        setConvert(result.data[0].convertData[0]);
        setTotalItems(result.totalItems);
        if (type == "autoPool") {
          setTotAuto(result.totalItems);
        }
      }
    } catch (err) {}
  };
  const columns = [
    // {
    //   name: "From Email",
    //   selector: (row) => row.email,
    //   // sortable: true,
    //   width: "200px",
    // },
    {
      name: "Name",
      selector: (row) => {
        if (!isEmpty(row && row.userInfo && row.userInfo.Name)) {
          return <>{firstCapital(row.userInfo.Name)}</>;
        } else {
          return <>-</>;
        }
      },
      // sortable: true,
      width: "200px",
    },
    {
      name: "Country",
      selector: (row) => {
        if (!isEmpty(row && row.userKycInfo && row.userKycInfo.countryName)) {
          return <>{row.userKycInfo.countryName}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Category",
      // sortable: true,
      selector: (row) => row.revenueCategory,
    },
    {
      name: "Mentor Type",
      selector: (row) =>
        !isEmpty(row.mentorType) ? firstCapital(row.mentorType) : "-",
      // sortable: true,
    },
    {
      name: "Scholar Type",
      selector: (row) =>
        !isEmpty(row.scholarType) ? firstCapital(row.scholarType) : "-",
      // sortable: true,
    },
    {
      name: "UserId",
      selector: (row) => row.uniqueId,
      // sortable: true,
      width: "150px",
    },
    {
      name: "Amount",
      selector: (row) => {
        if (row.coin == "INR") {
          return <>{toFixed(row.convert_INR, 6)}</>;
        } else {
          return <>{toFixed(row.convert_USD, 6)}</>;
        }
      },
      width: "200px",
      // sortable: true,
    },
    {
      name: "Coin",
      selector: (row) => row.coin,
      // sortable: true,
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      // sortable: true,
      width: "200px",
    },
  ];
  const autoColumns = [
    // {
    //   name: "From Email",
    //   selector: (row) => row.email,
    //   // sortable: true,
    //   width: "200px",
    // },
    {
      name: "Name & Country",
      selector: (row) => {
        let firstName = !isEmpty(row && row.userInfo && row.userInfo.Name)
          ? row.userInfo.Name + ", "
          : "";
        let countryName = !isEmpty(
          row && row.userKycInfo && row.userKycInfo.countryName
        )
          ? row.userKycInfo.countryName
          : "";
        return (
          <>
            {!isEmpty(firstName) ? firstCapital(firstName) : "-"}
            {!isEmpty(countryName) ? countryName : "-"}
          </>
        );
      },
      // sortable: true,
      width: "200px",
    },
    {
      name: "Mentor Type",
      selector: (row) =>
        !isEmpty(row.mentorType) ? firstCapital(row.mentorType) : "-",
      // sortable: true,
    },
    {
      name: "UserId",
      selector: (row) => row.uniqueId,
      // sortable: true,
      width: "150px",
    },
    {
      name: "Amount",
      selector: (row) => {
        if (row.coin == "INR") {
          return <>{toFixed(row.convert_INR, 6)}</>;
        } else {
          return <>{toFixed(row.convert_USD, 6)}</>;
        }
      },
      width: "200px",
      // sortable: true,
    },
    {
      name: "Coin",
      selector: (row) => row.coin,
      // sortable: true,
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      // sortable: true,
      width: "200px",
    },
  ];
  const handleChange = async (e) => {
    const { name } = e.target;
    setTeamName(name);
    setItemsPerPage(10);
    setCurrentPage(1);
    setItemAutoPer(10);
    setCurrentPageAuto(1);
    fetchDetail(name);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePageChangeAuto = (page) => {
    setCurrentPageAuto(page);
  };
  return (
    <div className="form-card">
      <h3>
        <span>Team Income</span>
      </h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="flexTitle">
            <ul
              className="nav nav-tabs dashboardTabs"
              id="pairTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="activation_income-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#activation_income"
                  type="button"
                  role="tab"
                  aria-controls="activation_income"
                  aria-selected="true"
                  name="actIncome"
                  onClick={(e) => handleChange(e)}
                >
                  Activation Income
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="trading_income-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#trading_income"
                  type="button"
                  role="tab"
                  aria-controls="trading_income"
                  aria-selected="false"
                  name="tradeIncome"
                  onClick={(e) => handleChange(e)}
                >
                  Trading Income
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="auto_pool-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#auto_pool"
                  type="button"
                  role="tab"
                  aria-controls="auto_pool"
                  aria-selected="false"
                  name="autoPool"
                  onClick={(e) => handleChange(e)}
                >
                  Auto Pool Income
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="total_income-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#total_income"
                  type="button"
                  role="tab"
                  aria-controls="total_income"
                  aria-selected="false"
                >
                  Total Income
                </button>
              </li> */}
            </ul>
            <div>
              <p className="m-0 greenText">
                {convert && convert.convert_USD
                  ? toFixed(convert.convert_USD, 6)
                  : "0.000000"}{" "}
                USDT
              </p>
              <p className="m-0 greenText">
                {data && convert && convert.convert_INR
                  ? toFixed(convert.convert_INR, 2)
                  : "0.00"}{" "}
                INR
              </p>
            </div>
          </div>
        </div>
        <div className="dashboardPairDetails p-4">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="activation_income"
              role="tabpanel"
              aria-labelledby="activation_income-tab"
            >
              <div className="text-center">
                <div className="primary_table">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalItems}
                    paginationPerPage={itemsPerPage}
                    onChangeRowsPerPage={setItemsPerPage}
                    onChangePage={(page) => handlePageChange(page)}
                  />
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="trading_income"
              role="tabpanel"
              aria-labelledby="trading_income-tab"
            >
              <div className="text-center">
                <div className="primary_table">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalItems}
                    paginationPerPage={itemsPerPage}
                    onChangeRowsPerPage={setItemsPerPage}
                    onChangePage={(page) => handlePageChange(page)}
                  />
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="auto_pool"
              role="tabpanel"
              aria-labelledby="auto_pool-tab"
            >
              <div className="text-center">
                <div className="primary_table">
                  <DataTable
                    columns={autoColumns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalAutoItems}
                    paginationPerPage={itemAutoPer}
                    onChangeRowsPerPage={setItemAutoPer}
                    onChangePage={(page) => handlePageChangeAuto(page)}
                  />
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="total_income"
              role="tabpanel"
              aria-labelledby="total_income-tab"
            >
              {/* <div className="text-center">
                <img
                  src={require("../../assets/images/nouser_icon.png")}
                  className="img-fluid mb-2"
                  alt="Icon"
                />
                <p>There is no member in your Team</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamIncome;
