// import config
import axios, {
  setAuthorization,
  removeAuthorization,
  handleResp,
} from "../config/axios";
import config from "../config";
import { removeAuthToken, setAuthToken } from "../lib/localStorage";

// import action
// import { setTradeTheme } from "./commonAction";
import {
  setTheme as setThemeLocal,
  changeTradeTheme as changeTradeThemeLocal,
} from "../lib/localStorage";
import { toastAlert } from "../lib/toastAlert";

// import constant
import {
  SET_USER_ACCOUNT,
  SET_TRADE_THEME,
  REMOVE_AUTHENTICATION,
} from "../constant";

// import action
import { decodeJwt } from "./jsonWebToken";
// import { getAssetData } from "./walletAction";

export const createUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/register`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const logout = (navigate, dispatch) => {
  removeAuthToken();
  removeAuthorization();
  dispatch({
    type: REMOVE_AUTHENTICATION,
    authData: {
      isAuth: false,
    },
  });
  // window.location.href = "/signin"
  navigate("/signin");

  console.log("11111111111111111111111111");
  // toastAlert("success", "Logout Successfully", "logout");

  // dispatch({
  //   type: RESET_NOTICE,
  // });
};

export const setTheme = async (dispatch, theme) => {
  localStorage.setItem("tradeTheme", theme);
  await setThemeLocal(theme);
  dispatch({
    type: SET_TRADE_THEME,
    theme,
  });
};

export const getTradeTheme = () => {
  let theme = localStorage.getItem("tradeTheme");
  return theme;
};

export const setTradeTheme = async (dispatch, theme) => {
  localStorage.setItem("tradeTheme", theme);

  await changeTradeThemeLocal(theme);
  dispatch({
    type: SET_TRADE_THEME,
    theme,
  });
  return true;
};
export const getPanicDet = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-panic`,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getNotifCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-noti-count`,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const changePanic = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/change-panic`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const forgotPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/forgotPassword`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getProfileDetail = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-profile`,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getScholarDet = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-scholar`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getTeamIncome = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-team-income`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getBalanceData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-balance`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};
export const setBalanceData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/set-balance`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getAllowNot = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-allow-not`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getBankDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-bank`,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};
export const getSystemErr = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-system-err`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};
export const setNotiStatus = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/set-noti-status`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};
export const getAnnouncement = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-announcement`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getTradenotifi = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-tradenotifi`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const ProfileUpdate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/profile-update`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const allowNotify = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/allow-data`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const bankUpdate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/bank-update`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const WalletUpdate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/wallet-update`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const sentOTP = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/sentRegOTP`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const sentForgotOTP = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/sentOTP`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const ResentOTP = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/resendotp`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const sentEmailOtp = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/sent-email-otp`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const userEmailActivation = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/confirm-mail`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const getGeoInfoData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.getGeoInfo}`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/login`,
      data,
    });
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }
    if (respData.data.status == "OTP") {
      return {
        status: "OTP",
        loading: false,
        message: respData.data.message,
      };
    }

    setAuthorization(respData.data.token);
    decodeJwt(respData.data.token, dispatch);
    setAuthToken(respData.data.token);
    dispatch(setAccountData(respData.data.result));
    setTradeTheme(dispatch, "light");
    // getAssetData(dispatch);
    // setTradeThemeLocal(respData.data.userSetting.theme);
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
      userSetting: respData.data.userSetting,
    };
    // }
  } catch (err) {
    handleResp(err, "error");
    try {
      return {
        status: "failed",
        loading: false,
        message: err.response.data.message,
        error: err.response.data.errors,
        authToken: err.response.data.authToken,
      };
    } catch (err) {
      handleResp(err, "error");
      return {
        status: "failed",
        loading: false,
      };
    }
  }
};

export const getUserData = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/userData`,
    });
    dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getKycDetails = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/kycDetails`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const fetchMember = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/fetch-member`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getMentorCounts = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/mentor-counts`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getMentorDet = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-mentor`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getBindData = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/bindOneData`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getVideoData = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/video-data`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const setAccountData = (data) => {
  return {
    type: SET_USER_ACCOUNT,
    data,
  };
};

export const resetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/resetPassword`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const get2faCode = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/security/2fa`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const update2faCode = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/api/security/2fa`,
      data,
    });
    // dispatch(update2FaStatus(respData.data.result.twoFaStatus));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/changePassword`,
      data,
    });
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    console.log(err.response.data, "---------898");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const disabled2faCode = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "patch",
      url: `/api/security/2fa`,
      data,
    });
    // dispatch(update2FaStatus(respData.data.result.twoFaStatus));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const editKuBind = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/editku-bind`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const editBitBind = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/editbit-bind`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const editBind = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/edit-bind`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const deleteBind = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/delete-bind`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const createBind = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/create-bind`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const KucoinBind = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/create-bind-kucoin`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const BitmartBind = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/create-bind-bitmart`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const UploadShareModal = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/shareModalImage`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getshareData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/shareModalImage`,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTipoffs = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-tip-offs`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};

export const getsiteDetails = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/getsiteDetails`,
      params: data,
    });
    // dispatch(setAccountData(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
};
