import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import config from "../../config";

// import action
import {
  getpairs,
  getPosition,
  reCycleSellOrder,
} from "../../actions/Bottrade";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed, toFixedDown } from "../../lib/roundOf";

import BatchSetting from "./BatchSetting";
// import context
import SocketContext from "../Context/SocketContext";
import { SET_LANGUAGE_OPTION } from "../../constant";

export default function TradePairs(props) {
  const history = useNavigate();

  const socketContext = useContext(SocketContext);

  const { params } = props;
  const { botType, pair, exchange } = props;
  const [PairListData, setPairListData] = useState();
  const [PairList, setPairList] = useState();
  const [batchModal, setBatchModal] = useState(false);
  const [hideBal, setHideBal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchby, setsearchby] = useState("USDT");
  const [searchbyinput, setsearchbyinput] = useState();
  const [sortby, Setsortby] = useState("asc");
  const [gotoStatus, SetgotoStatus] = useState(0);

  const fetchpairs = async (currency) => {
    const { result } = await getpairs({
      exchange: params.exchange,
      bot: params.bot,
      pairName: splitData(params.pair),
      currency: currency,
    });
    setPairListData(result);
    setPairList(result);
  };

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };
  const handleChange = async (e) => {
    const { checked } = e.target;
    localStorage.setItem("hideBalance", checked);
    setHideBal(checked);
  };
  const handleModal = async () => {
    let pairData = params.pair.replace(/\-/g, "");
    let reqData = {
      pair: pairData,
      exchange: params.exchange,
      bot: params.bot,
    };
    const { status, result } = await getPosition(reqData);
    if (status == "success") {
      if (result && result.length > 0) {
        setBatchModal(true);
      } else {
        toastAlert("error", "Currently There is no Order");
        return;
      }
    } else {
      toastAlert("error", "Currently There is no Order");
      return;
    }
  };
  const handleClose = () => {
    setBatchModal(false);
  };

  useEffect(() => {
    let pairData = params.pair.split("-");
    setsearchby(pairData[1]);

    fetchpairs(pairData[1]);
    let socketTicker = "socketTicker" + params.exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      setPairList((el) => {
        let pairList = [];
        if (el) {
          el.map((item) => {
            if (item._id == result.pairId) {
              let returnrate_per =
                ((result.data.markPrice * item.Orders.quantity -
                  item.Orders.avgprice * item.Orders.quantity) /
                  (item.Orders.avgprice * item.Orders.quantity)) *
                100;

              let profit =
                result.data.markPrice * item.Orders.quantity -
                item.Orders.avgprice * item.Orders.quantity;
              pairList.push({
                ...item,

                Orders: {
                  ...item.Orders,
                  profit: profit ? toFixed(profit, 4) : "-",
                  returnrate_per: returnrate_per ? returnrate_per : 0.0,
                },
                // markPrice: toFixedDown(result.data.markPrice, 8),
                markPrice: result.data.markPrice,
                change: toFixedDown(result.data.change, 3),
              });
            } else {
              pairList.push(item);
            }
          });
        }
        return pairList;
      });
    });
    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, [botType, pair, exchange]);
  useEffect(() => {
    socketContext.socket.on("pairsData", (result) => {
      setPairListData(result);
      setPairList(result);
    });
  }, [botType, pair, exchange]);
  useEffect(() => {
    if (localStorage.getItem("hideBalance") == "true") {
      setHideBal(true);
    }
  }, [hideBal]);

  useEffect(() => {
    if (gotoStatus != 0) {
      fetchpairs("USDT");
    }
  });

  const gotoTrade = async (pair) => {
    SetgotoStatus(1);

    const url = "/trade/" + pair + "/" + params.exchange + "/" + params.bot;
    //history("/trade/" + pair + "/" + params.exchange + "/" + params.bot);
    window.location.href = url;
  };

  const OnclicksortBy = async (key) => {
    // alert(key + sortby);
    let sort = "";
    if (sortby == "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    let sortedPairlist = "";
    if (sort == "asc") {
      if (key == "change" || key == "markPrice") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(b[key]) - parseFloat(a[key]) // asc
        );
      } else if (key == "quantity") {
        sortedPairlist = PairList.sort(
          (a, b) =>
            parseFloat(b.Orders.quantity) - parseFloat(a.Orders.quantity) // desc
        );
      } else if (key == "profit") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(b.Orders.profit) - parseFloat(a.Orders.profit) // desc
        );
      }
    }

    if (sort == "desc") {
      if (key == "change" || key == "markPrice") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(a[key]) - parseFloat(b[key]) // desc
        );
      } else if (key == "quantity") {
        sortedPairlist = PairList.sort(
          (a, b) =>
            parseFloat(a.Orders.quantity) - parseFloat(b.Orders.quantity) // desc
        );
      } else if (key == "profit") {
        sortedPairlist = PairList.sort(
          (a, b) => parseFloat(a.Orders.profit) - parseFloat(b.Orders.profit) // desc
        );
      }
    }

    setPairList(sortedPairlist);
    Setsortby(sort);
  };

  const searchbysymbol = async (symbol) => {
    await fetchpairs(symbol);
    setsearchby(symbol);

    // var searcharr = [];
    // setsearchby(symbol);
    // if (PairListData && PairListData.length > 0) {
    //   for (var i = 0; i < PairListData.length; i++) {
    //     var temppair =
    //       PairListData[i].firstCurrencySymbol +
    //       "/" +
    //       PairListData[i].secondCurrencySymbol;
    //     if (temppair.indexOf(symbol.toUpperCase()) !== -1) {
    //       searcharr.push(PairListData[i]);
    //     }
    //     if (i == PairListData.length - 1) {
    //       setPairList(searcharr);
    //     }
    //   }
    // }
  };
  const handleSubmit = async (value) => {
    try {
      if (value.Orders.quantity < 0) {
        toastAlert("error", "Order Doesn't Exist");
      }
      setLoader(true);
      let reqData = {
        pairName: value.tikerRoot,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        amount: value.Orders.quantity,
        type: "reCycle",
      };
      const { status, message } = await reCycleSellOrder(reqData);
      setLoader(false);
      if (status == "success") {
        toastAlert("success", message);
      } else {
        toastAlert("error", message);
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const searchpair = (e) => {
    e.preventDefault();
    setsearchbyinput(e.target.value);
    var searcharr = [];
    if (PairListData && PairListData.length > 0) {
      for (var i = 0; i < PairListData.length; i++) {
        var temppair =
          PairListData[i].firstCurrencySymbol +
          "/" +
          PairListData[i].secondCurrencySymbol;
        if (temppair.indexOf(e.target.value.toUpperCase()) !== -1) {
          searcharr.push(PairListData[i]);
        }
        if (i == PairListData.length - 1) {
          setPairList(searcharr);
        }
      }
    }
  };

  return (
    <div className="coin_details">
      <BatchSetting isShow={batchModal} Params={params} onHide={handleClose} />
      <div className="card trade_card">
        <div className="card-header">
          <h2>Coins</h2>
        </div>
        <div className="card-header">
          <input
            type="checkbox"
            checked={hideBal}
            onChange={handleChange}
            id="hidezerobalance"
          ></input>
          <label className="ms-1" for="hidezerobalance">
            Hide 0 Quantity
          </label>
        </div>
        <div className="card-body">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                onClick={() => searchbysymbol("USDT")}
                className={searchby == "USDT" ? "nav-link active" : "nav-link"}
                id="coin_one-tab"
                type="button"
                // data-bs-toggle="tab"
                // data-bs-target="#coin_one"
                // role="tab"
                // aria-controls="coin_one"
                // aria-selected="true"
              >
                USDT
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => searchbysymbol("BTC")}
                className={searchby == "BTC" ? "nav-link active" : "nav-link"}
                id="coin_two-tab"
                type="button"

                // data-bs-toggle="tab"
                // data-bs-target="#coin_two"
                // role="tab"
                // aria-controls="coin_two"
                // aria-selected="false"
              >
                BTC
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => searchbysymbol("ETH")}
                className={searchby == "ETH" ? "nav-link active" : "nav-link"}
                type="button"
                id="coin_three-tab"
                // data-bs-toggle="tab"
                // data-bs-target="#coin_three"
                // role="tab"
                // aria-controls="coin_three"
                // aria-selected="false"
              >
                ETH
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="search_inp_div">
              <input
                type="text"
                placeholder="Search Pair"
                className="form-control search_inp"
                name="symbol"
                value={searchbyinput}
                onChange={searchpair}
              />
              <i className="bi bi-search"></i>
            </div>
            <div
              className="tab-pane fade show active"
              id="coin_one"
              role="tabpanel"
              aria-labelledby="coin_one-tab"
            >
              <div className="pair_table">
                <div className="pair_table_head">
                  <div onClick={() => OnclicksortBy("quantity")}>Qty</div>
                  <div onClick={() => OnclicksortBy("markPrice")}>Price</div>
                  <div onClick={() => OnclicksortBy("profit")}>Profit</div>
                  <div onClick={() => OnclicksortBy("change")}>24H Chg</div>
                </div>
                <div className="pair_table_body">
                  <Scrollbars
                    style={{ height: 328 }}
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        className="track_vertical"
                        {...props}
                        style={{
                          ...style,
                          right: "2px",
                          bottom: "2px",
                          top: "2px",
                          borderRadius: "10px",
                          width: "5px",
                        }}
                      />
                    )}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        className="thumb_vertical"
                        {...props}
                        style={{
                          ...style,
                          width: "5px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                        }}
                      />
                    )}
                  >
                    {PairList &&
                      PairList.length > 0 &&
                      PairList.map((item, key) => {
                        let cond = item.BotType.find((el) => el == params.bot);
                        // if (
                        //   item.isGenie == true &&
                        //   params &&
                        //   params.bot == "Genie" &&
                        //   (item.firstCurrencySymbol == searchby ||
                        //     item.secondCurrencySymbol == searchby)
                        // ) {
                        //   cond = true;
                        // } else if (
                        //   params &&
                        //   params.bot != "Genie" &&
                        //   (item.firstCurrencySymbol == searchby ||
                        //     item.secondCurrencySymbol == searchby)
                        // ) {
                        //   cond = true;
                        // }
                        if (
                          cond &&
                          searchby == item.secondCurrencySymbol &&
                          !hideBal
                        ) {
                          return (
                            <>
                              <div
                                className={
                                  item.firstCurrencySymbol +
                                    "-" +
                                    item.secondCurrencySymbol ==
                                  params.pair
                                    ? "pair_table_bodyrow active"
                                    : "pair_table_bodyrow"
                                }
                              >
                                <div className="pair_table_bodyrow_top">
                                  <div
                                    onClick={() =>
                                      gotoTrade(
                                        item.firstCurrencySymbol +
                                          "-" +
                                          item.secondCurrencySymbol
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        !isEmpty(item.firstCurrencyId.image)
                                          ? config.API_URL +
                                            "/images/currency/" +
                                            item.firstCurrencyId.image
                                          : require("../../assets/images/defaultCoin.jpeg")
                                      }
                                      alt={item.firstCurrencyId.image}
                                      width="26px"
                                      className="img-fluid"
                                    />
                                    <h3>
                                      {item.firstCurrencySymbol}
                                      <span>/{item.secondCurrencySymbol}</span>
                                    </h3>
                                  </div>
                                  <div>
                                    {params.bot != "Zap" && (
                                      <>
                                        {!isEmpty(
                                          item.Orders && item.Orders.quantity
                                        ) &&
                                          item.Orders.quantity > 0 && (
                                            <span className="badge bg_blue trade_type">
                                              {item.Orders.StrategyPeriod}
                                            </span>
                                          )}
                                        {/* {!isEmpty(item.Orders.StrategyPeriod) &&
                                          item.Orders.StrategyPeriod ==
                                            "cycle" && (
                                            <span className="badge bg_blue trade_type">
                                              Cycle
                                            </span>
                                          )} */}
                                        {!loader && (
                                          <i
                                            onClick={() => handleSubmit(item)}
                                            className="bi bi-recycle"
                                          ></i>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {item.Orders &&
                                    item.Orders.returnrate_per != "" && (
                                      <div
                                        className={
                                          item.Orders &&
                                          item.Orders.returnrate_per < 0
                                            ? "badge bg_red per_change"
                                            : "badge bg_green per_change"
                                        }
                                      >
                                        {item.Orders &&
                                          toFixed(
                                            item.Orders.returnrate_per,
                                            4
                                          )}
                                        %
                                      </div>
                                    )}
                                </div>
                                <div className="pair_table_bodyrow_bottom">
                                  <div className="pair_table_bodyrow_bottom_left">
                                    <div>
                                      <label>Quantity:</label>
                                      <label>
                                        {item.Orders &&
                                          toFixed(item.Orders.quantity, 6)}
                                      </label>
                                    </div>
                                    <div>
                                      <label>Price:</label>
                                      <label>
                                        {toFixed(
                                          item.markPrice,
                                          item.priceDecimal
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom_right">
                                    <div>
                                      <label>Profit:</label>
                                      {item.Orders &&
                                        item.Orders.profit == "-" && (
                                          <label>{item.Orders.profit}</label>
                                        )}
                                      {item.Orders &&
                                        item.Orders.profit != "-" && (
                                          <label
                                            className={
                                              item.Orders.profit < 0
                                                ? "redText"
                                                : "greenText"
                                            }
                                          >
                                            {item.Orders.profit}
                                          </label>
                                        )}
                                    </div>
                                    <div>
                                      <label>Chg 24H(%):</label>
                                      <label
                                        className={
                                          item.change > 0
                                            ? "greenText"
                                            : "redText"
                                        }
                                      >
                                        {toFixed(item.change, 3)}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else if (
                          cond &&
                          searchby == item.secondCurrencySymbol &&
                          hideBal &&
                          item.Orders.quantity > 0
                        ) {
                          return (
                            <>
                              <div
                                className={
                                  item.firstCurrencySymbol +
                                    "-" +
                                    item.secondCurrencySymbol ==
                                  params.pair
                                    ? "pair_table_bodyrow active"
                                    : "pair_table_bodyrow"
                                }
                              >
                                <div className="pair_table_bodyrow_top">
                                  <div
                                    onClick={() =>
                                      gotoTrade(
                                        item.firstCurrencySymbol +
                                          "-" +
                                          item.secondCurrencySymbol
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        !isEmpty(item.firstCurrencyId.image)
                                          ? config.API_URL +
                                            "/images/currency/" +
                                            item.firstCurrencyId.image
                                          : require("../../assets/images/defaultCoin.jpeg")
                                      }
                                      alt={item.firstCurrencyId.image}
                                      className="img-fluid"
                                      width="26px"
                                    />
                                    <h3>
                                      {item.firstCurrencySymbol}
                                      <span>/{item.secondCurrencySymbol}</span>
                                    </h3>
                                  </div>
                                  <div>
                                    {params.bot != "Zap" && (
                                      <>
                                        {!isEmpty(
                                          item.Orders && item.Orders.quantity
                                        ) &&
                                          item.Orders.quantity > 0 && (
                                            <span className="badge bg_blue trade_type">
                                              {item.Orders.StrategyPeriod}
                                            </span>
                                          )}
                                        {!loader && (
                                          <i
                                            onClick={() => handleSubmit(item)}
                                            className="bi bi-recycle"
                                          ></i>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {item.Orders &&
                                    item.Orders.returnrate_per != "" && (
                                      <div
                                        className={
                                          item.Orders &&
                                          item.Orders.returnrate_per < 0
                                            ? "badge bg_red per_change"
                                            : "badge bg_green per_change"
                                        }
                                      >
                                        {item.Orders &&
                                          toFixed(
                                            item.Orders.returnrate_per,
                                            4
                                          )}
                                        %
                                      </div>
                                    )}
                                </div>
                                <div className="pair_table_bodyrow_bottom">
                                  <div className="pair_table_bodyrow_bottom_left">
                                    <div>
                                      <label>Quantity:</label>
                                      <label>
                                        {item.Orders &&
                                          toFixed(item.Orders.quantity, 6)}
                                      </label>
                                    </div>
                                    <div>
                                      <label>Price:</label>
                                      <label>
                                        {toFixed(
                                          item.markPrice,
                                          item.priceDecimal
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom_right">
                                    <div>
                                      <label>Profit:</label>
                                      <label
                                        className={
                                          item.Orders && item.Orders.profit < 0
                                            ? "redText"
                                            : "greenText"
                                        }
                                      >
                                        {item.Orders &&
                                          toFixed(item.Orders.profit, 4)}
                                      </label>
                                    </div>
                                    <div>
                                      <label>Chg 24H(%):</label>
                                      <label
                                        className={
                                          item.change > 0
                                            ? "greenText"
                                            : "redText"
                                        }
                                      >
                                        {toFixed(item.change, 3)}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}
                  </Scrollbars>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="coin_two"
              role="tabpanel"
              aria-labelledby="coin_two-tab"
            >
              <div className="pair_table">
                <div className="pair_table_head">
                  <div>Qty</div>
                  <div>Price</div>
                  <div>Profit</div>
                  <div onClick={() => OnclicksortBy("24h")}>24H Chg</div>
                </div>
                <div className="pair_table_body">
                  <Scrollbars
                    style={{ height: 328 }}
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        className="track_vertical"
                        {...props}
                        style={{
                          ...style,
                          right: "2px",
                          bottom: "2px",
                          top: "2px",
                          borderRadius: "10px",
                          width: "5px",
                        }}
                      />
                    )}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        className="thumb_vertical"
                        {...props}
                        style={{
                          ...style,
                          width: "5px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                        }}
                      />
                    )}
                  ></Scrollbars>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="coin_three"
              role="tabpanel"
              aria-labelledby="coin_three-tab"
            >
              <div className="pair_table">
                <div className="pair_table_head">
                  <div>Qty</div>
                  <div>Price</div>
                  <div>Profit</div>
                  <div>24H Chg</div>
                </div>
                <div className="pair_table_body">
                  <Scrollbars
                    style={{ height: 328 }}
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        className="track_vertical"
                        {...props}
                        style={{
                          ...style,
                          right: "2px",
                          bottom: "2px",
                          top: "2px",
                          borderRadius: "10px",
                          width: "5px",
                        }}
                      />
                    )}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        className="thumb_vertical"
                        {...props}
                        style={{
                          ...style,
                          width: "5px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                        }}
                      />
                    )}
                  ></Scrollbars>
                </div>
              </div>
            </div>
            {params && params.bot != "Zap" && (
              <div className="batch_settings">
                {/* <p>Binance <i className="bi bi-eye"></i></p> */}
                <p data-bs-toggle="modal" onClick={handleModal}>
                  <i className="bi bi-arrow-clockwise"></i> Batch Setting
                </p>
              </div>
            )}

            {/* <div className="choose_pair">
              <img src={require("../../assets/images/usdt.png")} alt="Icon" className="img-fluid" />
              <p>USDT: 1</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
