// import config
import axios, { handleResp } from "../config/axios";

export const TransferList = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/transfer`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getAutoTransfer = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/get-auto-transfer`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const autoTransfer = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/auto-transfer`,
      data: data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const transferrequest = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/transfer`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const transferrequestindian = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/transferindian`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const autotransferasset = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/autotransfer`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
