// import package
import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import SocketContext from "../Context/SocketContext";
import { logout } from "../../actions/users";
import { useNavigate } from "react-router-dom";
// import action
import { getUserData, setTradeTheme } from "../../actions/users";

const HelperRoute = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const socketContext = useContext(SocketContext);
  // redux-state
  const { isAuth } = useSelector((state) => state.auth);
  let theme = localStorage.getItem("tradeTheme");

  // function

  useEffect(() => {
    setTradeTheme(dispatch, theme);
    if (isAuth) {
      getUserData(dispatch);
    }
  }, [isAuth]);

  useEffect(() => {
    socketContext.socket.on("FORCE_LOGOUT", (result) => {
      console.log(result, "__result");
      let token = localStorage.getItem("user_token");
      if (result && token != result) {
        logout(history, dispatch);
      }
    });
  }, []);
  return <div />;
};

export default HelperRoute;
