import React, { useEffect, useState } from "react";
import { walletList } from "../../actions/Wallet";
import DataTable from "react-data-table-component";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";

export default function WalletHistory() {
  // state
  const [TxnList, SetTxnList] = useState();

  const fetchwallethistory = async (reqData) => {
    const { status, loading, message, error, result } = await walletList(
      reqData
    );
    SetTxnList(result);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let reqData = {
      filter: value,
    };
    fetchwallethistory(reqData);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "150px",
    },
    {
      name: "Amount",
      selector: (row) => {
        return <>{row.amount}</>;
      },
    },
    {
      name: "Type",
      selector: (row) => {
        let color = "";
        if (row.assetType == "Deposit") {
          color = "greenText";
        } else if (row.assetType == "Transfer") {
          color = "yellowText";
        } else if (row.assetType == "Withdraw") {
          color = "redText";
        }

        return <span className={color}>{row.assetType}</span>;
      },
    },
    {
      name: "Transfer Type",
      width: "150px",
      selector: (row) => {
        if (row.assetType == "Transfer") {
          return <>{row.transferType}</>;
        } else if (
          row.Status == "Approved" &&
          row.depositType == "Subscription"
        ) {
          return <>-</>;
        } else return <>Masset</>;
      },
    },
    {
      name: "Deposit Type",
      width: "150px",
      selector: (row) => {
        if (row.assetType == "Deposit") {
          return <span>{row.depositType}</span>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Reason",
      selector: (row) => {
        if (
          (row.assetType == "Deposit" || row.assetType == "Withdraw") &&
          row.Status == "Rejected"
        ) {
          return <span className="redText">{row.reason}</span>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "Status",
      selector: (row) => {
        let color = "";
        if (row.Status == "Pending") {
          color = "yellowText";
        } else if (row.Status == "Rejected") {
          color = "redText";
        } else if (row.Status == "Approved" || row.Status == "Completed") {
          color = "greenText";
        }
        return <span className={color}>{row.Status}</span>;
      },
    },
  ];

  useEffect(() => {
    let reqData = {
      filter: "all",
    };
    fetchwallethistory(reqData);
  }, []);

  return (
    <div className="userContent mt-4">
      <div className="flexTitle">
        <h2>History</h2>
        <select className="form-select filterSelect" onChange={handleChange}>
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="week">Last week</option>
          <option value="month">Last 1 month</option>
          <option value="year">Last year</option>
        </select>
      </div>
      <div className="WalletBalance ">
        <div class="table-responsive">
          {/* <table class="table table-bordered customTable">
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
          </tr>

          {TxnList &&
            TxnList.length > 0 &&
            TxnList.map((item, key) => {
              let status = item.Status;
              let assetType = item.assetType;
              let color = "";
              let acolor = "";
              if (status == "Pending") {
                color = "yellowText";
              } else if (status == "Rejected") {
                color = "redText";
              } else if (status == "Approved" || status == "Completed") {
                color = "greenText";
              }

              if (assetType == "Deposit") {
                acolor = "greenText";
              } else if (assetType == "Transfer") {
                acolor = "yellowText";
              } else if (assetType == "Withdraw") {
                acolor = "redText";
              }

              return (
                <tr>
                  <td>{dateTimeFormat(item.createdAt)}</td>
                  <td>{item.amount}</td>
                  <td className={acolor}>{item.assetType}</td>
                  <td className={color}>{item.Status}</td>
                </tr>
              );
            })}
        </table> */}
          <div className="dashboardContent">
            <div className="primary_table">
              <DataTable columns={columns} data={TxnList} pagination />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
