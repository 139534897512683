import React, { useEffect, useState } from "react";

import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { WithdrawValid_NonIndia } from "./validation";

import {
  transferrequest,
  transferrequestindian,
  autoTransfer,
  getAutoTransfer,
} from "../../actions/Transfer";
import { WithdrawList } from "../../actions/Withdraw";
import { currencybysymbol, MassetConversion } from "../../actions/Wallet";

const initialFormValue = {
  amount: "",
};
export default function TransferWallet(props) {
  const { nationality, masset, fetchMasset, fetchtransferHistory } = props;
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [loader, setLoader] = useState(false);
  const [checkTrans, setCheckTrans] = useState(false);
  const [autoLoader, setAutoLoader] = useState(false);
  const [currencyDetails, setcurrencyDetails] = useState();

  const { amount } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = WithdrawValid_NonIndia(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    try {
      let reqData = {
        amount,
        nationality,
      };

      const { status, loading, message, error } = await transferrequest(
        reqData
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        setFormValue(initialFormValue);
        fetchtransferHistory();
        fetchMasset();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  const handleSubmitindian = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = WithdrawValid_NonIndia(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    try {
      let reqData = {
        amount,
        nationality,
      };

      const { status, loading, message, error } = await transferrequestindian(
        reqData
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        setFormValue(initialFormValue);
        fetchtransferHistory();
        fetchMasset();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };
  useEffect(() => {
    fetchAuto();
  }, []);
  const fetchAuto = async () => {
    try {
      const { status, result, loading, message, error } =
        await getAutoTransfer();
      setAutoLoader(false);
      if (status == "success") {
        setCheckTrans(result.autoTransfer);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };
  const handleAuto = async (e) => {
    try {
      e.preventDefault();
      setAutoLoader(true);
      const { checked } = e.target;
      let reqData = {
        autoTransfer: checked,
      };
      const { status, result, loading, message, error } = await autoTransfer(
        reqData
      );
      setAutoLoader(false);
      if (status == "success") {
        toastAlert("success", message);
        setCheckTrans(result.autoTransfer);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  const fetchcurrency = async () => {
    let currency = "USDT";
    if (nationality == "indian") {
      currency = "INR";
    }
    const { status, loading, message, error, result } = await currencybysymbol({
      currency: currency,
    });
    console.log(result, "resultresultresult");
    if (result) {
      setcurrencyDetails(result);
    }
  };

  useEffect(() => {
    fetchcurrency();
  }, [nationality]);

  return (
    <div className="userContent mt-4">
      <h2>Transfer to M-Fee</h2>
      {nationality == "nonindian" && (
        <div className="walletForm">
          <div className="form-group">
            <div className="flexLabel">
              <label>Amount</label>
              <span>
                M-Asset Balance:{" "}
                {masset && parseFloat(masset.massetBalanceUSDT).toFixed(2)} USDT
              </span>
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                name="amount"
                value={amount}
                onChange={handleChange}
                className="form-control"
              />
              <div className="input-group-text">USDT</div>
            </div>
            {validateError && validateError.amount && (
              <p className="text-danger">{validateError.amount}</p>
            )}
          </div>
          <div className="form-group">
            <div className="switch-group">
              <label htmlFor="flexSwitchCheckChecked">Auto Transfer</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checkTrans}
                  onClick={handleAuto}
                />
              </div>
            </div>
            <div className="greenNote border-top-0 pt-0">
              {" "}
              Note: Amount - USDT (Do not transfer below{" "}
              {currencyDetails && currencyDetails.minimumTransfer} USDT)
            </div>
          </div>
          <div className="form-group mt-0">
            <button
              onClick={handleSubmit}
              className="btn btn-green-big mx-auto mb-0"
            >
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Transfer to M-Fee
            </button>
          </div>
        </div>
      )}
      {nationality == "indian" && (
        <div className="walletForm">
          <div className="form-group">
            <div className="flexLabel">
              <label>Amount</label>
              {masset && parseFloat(masset.massetBalanceINR).toFixed(2)} INR
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                name="amount"
                value={amount}
                onChange={handleChange}
                className="form-control"
              />
              <div className="input-group-text">INR</div>
            </div>
            {validateError && validateError.amount && (
              <p className="text-danger">{validateError.amount}</p>
            )}
          </div>
          <div className="form-group">
            <div className="switch-group">
              <label htmlFor="flexSwitchCheckChecked">Auto Transfers</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checkTrans}
                  onClick={handleAuto}
                />
              </div>
            </div>
            <div className="greenNote border-top-0 pt-0">
              {" "}
              Note: Amount - INR (Do not transfer below{" "}
              {currencyDetails && currencyDetails.minimumTransfer} INR)
            </div>
          </div>
          <div className="form-group mt-0">
            <button
              onClick={handleSubmitindian}
              className="btn btn-green-big mx-auto mb-0"
            >
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Transfer to M-Fee
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
