import React, { useEffect, useState } from "react";

import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";

import {
  getAllowNot,
  allowNotify,
  getTradenotifi,
  setNotiStatus,
} from "../../actions/users";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter";
import { useNavigate } from "react-router-dom";

export default function TradeRelate() {
  // state
  const [allowNot, setAllowNot] = useState();
  const [notData, setNotData] = useState([]);
  const [autoLoader, setAutoLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllow();
    fetchNotification();
    setNotification();
  }, []);
  const setNotification = async () => {
    try {
      let reqData = { type: "tradeRelated" };
      const { status, result, loading, message, error } = await setNotiStatus(
        reqData
      );
    } catch (err) {}
  };

  const [page, setpagecount] = useState(1);
  const [count, setCount] = useState(0);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const noOfPages = Math.ceil(count / 10);
  const pagesArr = [...new Array(noOfPages)];
  let pagedata = 1;
  const onPrevPage = () => {
    setpagecount(page - 1);
  };

  const gotoPage = (data) => {
    setpagecount(data);
  };

  const onNextPage = () => {
    setpagecount(page + 1);
  };
  const onPageSelect = (pageNo) => {
    setpagecount(pageNo);
  };

  useEffect(() => {
    let reqdata = {
      page,
      limit: 10,
    };
    fetchNotification(reqdata);
  }, [page]);

  const fetchAllow = async () => {
    try {
      const { status, result, loading, message, error } = await getAllowNot();
      setAutoLoader(false);
      if (status == "success") {
        setAllowNot(result.tradeNotify);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  const fetchNotification = async (reqdata) => {
    try {
      const { status, result, loading, message, error } = await getTradenotifi(
        reqdata
      );
      setAutoLoader(false);
      if (status == "success") {
        setNotData(result.TradenotifiData);
        setCount(result.count);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };
  const handleAuto = async (e) => {
    try {
      e.preventDefault();
      setAutoLoader(true);
      const { checked } = e.target;
      let reqData = {
        allowNotify: checked,
        type: "trade_relate",
      };
      const { status, result, loading, message, error } = await allowNotify(
        reqData
      );
      setAutoLoader(false);
      if (status == "success") {
        toastAlert("success", message);
        setAllowNot(result.tradeNotify);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (noOfPages === page) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (page === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, page]);
  return (
    <div className="userContent">
      <div className="text-end mb-2">
        <button className="edit_btn" onClick={() => navigate("/dashboard")}>
          Go back
        </button>
      </div>
      <div className="flexTitle">
        <h2>Trade</h2>
        <div className="d-flex">
          <label class="label_large me-3">Allow Notification</label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              checked={allowNot}
              onClick={(e) => handleAuto(e)}
            />
          </div>
        </div>
      </div>

      <div className="notification_panel">
        {allowNot &&
          notData &&
          notData.length > 0 &&
          notData.map((item, i) => {
            let orderDet =
              item.orderVia == "clearSell"
                ? "Clear Sell Order Filled. "
                : "Order Filled. ";
            let execDet =
              " QTY " +
              toFixed(item.quantity, 6) +
              " PRICE " +
              toFixed(item.price, 6) +
              " AMOUNT " +
              toFixed(item.orderValue, 6);
            if (item.orderVia == "clearSell") {
              //   execDet =
              //     toFixed(item.price, 6) + " AT " + toFixed(item.quantity, 6);

              execDet =
                " QTY " +
                toFixed(item.quantity, 6) +
                " PRICE " +
                toFixed(item.price, 6) +
                " AMOUNT " +
                toFixed(item.quantity * item.price, 6);
            }
            let description =
              item.exchange +
              " " +
              item.firstCurrency +
              "/" +
              item.secondCurrency +
              " " +
              item.buyorsell.toUpperCase() +
              " " +
              orderDet +
              execDet;
            return (
              <div>
                <h4
                  style={{ color: item.buyorsell == "buy" ? "green" : "red" }}
                >
                  {description.toUpperCase()}
                </h4>
                <h5>Date {dateTimeFormat(item.orderDate)}</h5>
              </div>
            );
          })}
      </div>
      <br />
      {allowNot && noOfPages > 1 ? (
        <ul class="pagination">
          {/* <li class="page-item">
            <button
              class="page-link"
              onClick={onPrevPage}
              disabled={!canGoBack}
            >
              {"<<"}
            </button>
          </li> */}
          <span>
            Page{" "}
            <strong>
              {page} of {noOfPages}
            </strong>{" "}
          </span>
          &nbsp;&nbsp;
          <button onClick={() => gotoPage(1)} disabled={!canGoBack}>
            {"<<"}
          </button>{" "}
          <button onClick={onPrevPage} disabled={!canGoBack}>
            {"<"}
          </button>{" "}
          <button onClick={onNextPage} disabled={!canGoNext}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(noOfPages)} disabled={!canGoNext}>
            {">>"}
          </button>{" "}
          {/* {pagesArr.map((num, index) => (
            <>
              <li class="page-item">
                <button
                  class="page-link"
                  onClick={() => onPageSelect(index + 1)}
                >
                  {"<"}
                </button>
              </li>
            </>

          ))} */}
          {/* <li class="page-item">
            <button
              class="page-link"
              onClick={onNextPage}
              disabled={!canGoNext}
            >
              {">>"}
            </button>
          </li> */}
        </ul>
      ) : null}
    </div>
  );
}
