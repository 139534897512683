// import package
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import config from "../config";
// import { getsiteDetails } from "../actions/users";

const Logo = () => {
  // const [logo, setLogo] = useState();

  // useEffect(() => {
  //   fetchLogo();
  // }, []);

  // const fetchLogo = async () => {
  //   try {
  //     const { status, result } = await getsiteDetails();
  //     if (status == "success") {
  //       console.log(result, "resultresult");
  //       setLogo(result.emailLogo);
  //     }
  //   } catch (err) {}
  // };

  return (
    <a href="/dashboard" className="navbar-brand">
      <img
        src={config.API_URL + "/settings/logo.png"}
        className="img-fluid"
        alt=""
      />
    </a>
  );
};

export default Logo;
