import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import validation from "./validation";
import { UploadNonIndDepoist } from "../../actions/Deposit";
import {
  adminwalletlist,
  currencyDetails,
  getTrans,
} from "../../actions/Wallet";
import QRCode from "qrcode.react";

const initialFormValue = {
  amount: "",
  depositType: "",
  tokenType: "",
  docName: "",
  currencySymbol: "",
  docImage: "",
  nationality: "nonindian",
};

export default function DepositWallet(props) {
  const dispatch = useDispatch();
  const { data, fetchTransactionHistory } = props;

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [transData, setTransData] = useState({});
  const [loader, setLoader] = useState(false);

  const {
    amount,
    depositType,
    tokenType,
    currencySymbol,
    docName,
    docImage,
    nationality,
  } = formValue;

  // state
  const [adminwallet, setadminwallet] = useState();
  const [WalletDetails, setWalletDetails] = useState({});
  const [currencyData, setCurrencyData] = useState({});

  const fetchadminwallet = async () => {
    const { status, loading, message, error, result } = await adminwalletlist();
    let selectedwallet = result.find((el) => el.tokenType == tokenType);
    setWalletDetails(selectedwallet);

    setadminwallet(result);
  };

  const fetchCurrency = async () => {
    const { status, loading, message, error, result } = await currencyDetails();
    if (status == "success") {
      result &&
        result.map((item, i) => {
          if (item.coin == "USDT") {
            setCurrencyData(item);
          }
        });
    }
  };

  const fetchTrans = async () => {
    const { status, loading, message, error, result } = await getTrans();
    if (status == "success") {
      setTransData(result);
    }
  };
  useEffect(() => {
    fetchadminwallet();
    fetchTrans();
    fetchCurrency();
  }, []);

  // console.log(transData, "------transData");

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "depositType" && value == "Subscription") {
      let formData = {
        ...formValue,
        ...{ [name]: value, amount: currencyData.Subscription },
      };
      setFormValue(formData);
      return;
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleChangetokentype = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    let splitValue = value.split("-");
    let selectedwallet = adminwallet.find(
      (el) =>
        el.tokenType == splitValue[0] && el.currencySymbol == splitValue[1]
    );
    setWalletDetails(selectedwallet);

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let formData = {
      ...formValue,
      ...{ [name]: files[0], docName: files[0].name },
    };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = validation(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    try {
      let splitValue = tokenType.split("-");
      // console.log(splitValue,'-----------splitValue')
      const formData = new FormData();
      formData.append("depositType", depositType);
      formData.append("tokenType", splitValue[0]);
      formData.append("currencySymbol", splitValue[1]);
      formData.append("amount", amount);
      formData.append("docName", docName);
      formData.append("docImage", docImage);

      const { status, loading, message, error } = await UploadNonIndDepoist(
        formData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        setFormValue(initialFormValue);
        fetchTransactionHistory();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  // console.log(WalletDetails, adminwallet, "____-WalletDetails");

  return (
    <div className="userContent mt-4">
      <h2>Upload Deposit Proof</h2>
      <div className="walletForm">
        <div className="form-group">
          <label>Purpose</label>
          <select
            name="depositType"
            onChange={handleChange}
            className="form-select"
            value={depositType}
          >
            <option value="">Select Purpose</option>
            {transData == "subscribe" && (
              <option selected value="Top-Up">
                Top-up
              </option>
            )}
            {transData == "unsubscribe" && (
              <option selected value="Subscription">
                Subscription
              </option>
            )}
          </select>

          {validateError && validateError.depositType && (
            <p className="text-danger">{validateError.depositType}</p>
          )}
        </div>
        <div className="form-group">
          <label>Amount</label>
          <div className="input-group mb-3">
            <input
              type="text"
              name="amount"
              value={amount}
              onChange={handleChange}
              className="form-control"
              disabled={depositType == "Subscription" && "true"}
            />
            <div className="input-group-text">USDT</div>
          </div>
          {validateError && validateError.amount && (
            <p className="text-danger">{validateError.amount}</p>
          )}
        </div>
        <div className="form-group">
          <label>Coin</label>
          <select
            onChange={handleChangetokentype}
            name="tokenType"
            value={tokenType}
            className="form-select"
          >
            <option value="">Select</option>
            {adminwallet &&
              adminwallet.length > 0 &&
              adminwallet.map((item, key) => {
                return (
                  <>
                    <option
                      key={key}
                      value={item.tokenType + "-" + item.currencySymbol}
                    >
                      {item.currencySymbol}
                      {item.currencySymbol == "USDT"
                        ? " - " + item.tokenType.toUpperCase()
                        : ""}
                      {/* {item.tokenType.toUpperCase()} */}
                    </option>
                  </>
                );
              })}
            {/* <option selected value="trc20">
              USDT TRC 20
            </option>
            <option selected value="bep20">
              BSC BEP 20
            </option> */}
          </select>

          {validateError && validateError.tokenType && (
            <p className="text-danger">{validateError.tokenType}</p>
          )}
        </div>
        {!isEmpty(tokenType) && (
          <div className="form-group">
            <label>Wallet Details</label>
            {/* <div className="bankDetailBox">
        <ul>
        <li>
            <span className="bdLabel">Bank Name</span>
            <span className="bdDetails">IDFC</span>
        </li>
        <li>
            <span className="bdLabel">Accout Number</span>
            <span className="bdDetails">10089235708</span>
        </li>
        <li>
            <span className="bdLabel">Account Holder Name</span>
            <span className="bdDetails">Ugain India</span>
        </li>
        <li>
            <span className="bdLabel">Account Type</span>
            <span className="bdDetails">Current</span>
        </li>
        <li>
            <span className="bdLabel">IFSC Code</span>
            <span className="bdDetails">IDFB0020153</span>
        </li>
        <li>
            <span className="bdLabel">Bank Branch</span>
            <span className="bdDetails">Noida Sector 77</span>
        </li>
        </ul>
    </div> */}
            <div className="qrCodeBox">
              <QRCode
                size="300"
                className="img-fluid"
                value={WalletDetails.address}
              />

              {/* <img
              src={require("../../assets/images/qr-code.png")}
              className="img-fluid"
              alt=""
            /> */}
            </div>
            <div className="copyCodeBox">
              <p>{WalletDetails.address}</p>
              <CopyToClipboard
                text={WalletDetails.address}
                onCopy={() => {
                  toastAlert("success", "Copied!", "address");
                }}
              >
                <a href="javacript:void(0)">
                  <img
                    src={require("../../assets/images/copy-icon.png")}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </CopyToClipboard>
            </div>
          </div>
        )}
        <div className="form-group">
          <label>Screenshot Upload</label>
          <div className="input-group mb-3">
            <input
              type="text"
              name="docName"
              value={docName}
              // onChange={handleChange}
              className="form-control"
            />
            <div className="input-group-text green-bg">
              <div className="fileUploadButton">
                <img
                  src={require("../../assets/images/uploadIcon.png")}
                  className="img-fluid"
                  alt=""
                />
                <input type="file" name="docImage" onChange={handleFile} />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className={"text-success"}>
              Max. 10MB size, jpg, jpeg, png, pdf Allowed
            </label>
          </div>
          {/* <div className="form-group">
            <label>{docImage && docImage.name}</label>
          </div>*/}
          {validateError && validateError.docName && (
            <p className="text-danger">{validateError.docName}</p>
          )}

          {validateError && validateError.docImage && (
            <p className="text-danger">{validateError.docImage}</p>
          )}
        </div>
        <div className="text-danger">
          {" "}
          Note: Amount - USDT (Do not Top-Up below{" "}
          {currencyData && currencyData.minimumTopUp} USDT)
        </div>
        <div className="form-group mt-5">
          <button
            onClick={handleSubmit}
            className="btn btn-green-big mx-auto mb-0"
            disabled={loader}
          >
            {!loader && <i class="fas fa-spinner fa-spin"></i>}
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
