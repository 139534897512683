import React, { useState, useEffect, Fragment, useContext } from "react";
import ExchangeBalance from "./ExchangeBalance.js";
import { Modal } from "react-bootstrap";
import {
  getTradeSetting,
  SaveSmartTradeSetting,
  getpairssingle,
  getStatusTrade,
  AddStrategyPeriod,
  getStrategyPeriod,
  saveStopLoss,
  changeTradeStatus,
  StartSmartTradeSetting,
  comManBuyOrder,
  comManSellOrder,
  getTotQuant,
  geniesmartclearSell,
} from "../../actions/Bottrade";

import validation from "./validation";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed, toFixedDown } from "../../lib/roundOf";
// import context
import SocketContext from "../Context/SocketContext";
import $ from "jquery";
const Datas = {
  firstbuyAmount: "",
  margincallLimit: "",
  takeprofitRatio: "",
  takeprofitCallback: "",
  buyinCallback: "",
  marginCall: "",
  Ratio: "",
  AverageCallDrop: "",
};

const initialFormvalue = {
  Name: "",
  StrategyPeriod: "cycle",
  AveragingCall: "Start",
};
const InitialDatas = {
  priceType: "CMP",
  Price: "",
};

const buySell = {
  buyAmount: 0,
  sellAmount: 0,
};
export default function SmartSettings(props) {
  const { params, record, inputData } = props;
  let pairArr = params.pair.split("-");
  const stopLossForm = {
    stopLoss: "",
    lossType: "%", //pairArr[1],
    formType: "smaStop",
  };
  const socketContext = useContext(SocketContext);
  const [seccurrency, Setseccurrency] = useState(pairArr[1]);
  const [confModal, setConfModal] = useState(false);
  const [modalName, setModalName] = useState();
  const [BSForm, setBSForm] = useState(buySell);
  const [tradedata, setTradeData] = useState(Datas);
  const [totalQuant, setTotQuant] = useState(0);
  const [inputFields, setInputFields] = useState([]);
  const [stLossForm, setStLossForm] = useState(stopLossForm);
  const [formValue, setFormValue] = useState(initialFormvalue);
  const [buyLoader, setBuyLoader] = useState(false);
  const [sellLoader, setSellLoader] = useState(false);
  const [clrSellLoad, setClrLoad] = useState(false);
  const [validErr, setValidErr] = useState({});
  const [data, setData] = useState("");
  const [RunStatus, setRunStatus] = useState();
  const [PairList, setPairList] = useState();
  const [checkdata, setcheckdata] = useState(InitialDatas);
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [loadermodal, setLoaderModal] = useState();
  const [posPerc, setPosPerc] = useState();
  const { stopLoss, lossType } = stLossForm;
  const { priceType, Price } = checkdata;
  const { buyAmount, sellAmount } = BSForm;

  const {
    firstbuyAmount,
    margincallLimit,
    takeprofitRatio,
    takeprofitCallback,
    buyinCallback,
    AverageCallDrop,
  } = tradedata;

  const { Name, StrategyPeriod, AveragingCall } = formValue;

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }
    setInputFields([]);
    let dataArr = [];
    for (let i = 0; i < value; i++) {
      dataArr.push({
        marginCall: "",
        Ratio: "",
      });
    }
    setInputFields(dataArr);
    setTradeData({ ...tradedata, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };
  const handleStopChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name != "lossType") {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    }
    let formData = { ...stLossForm, ...{ [name]: value } };
    setStLossForm(formData);
    setValidateError(validation(formData));
  };
  const handleStopSubmit = async () => {
    try {
      let reqValue = {
        currentPrice: PairList[0].markPrice,
        lossType,
        stopLoss,
        formType: "smaStop",
        inputFields: inputFields,
      };
      let validationError = validation(reqValue);
      if (!isEmpty(validationError)) {
        $(".trade_new_strategy_settings").css({ display: "none" });
        $(".custom_amt_setup_panel").css({ display: "block" });
        setValidateError(validationError);
        return;
      } else {
        // fetchTradeSetting();
        $(".trade_new_strategy_settings").css({ display: "block" });
        $(".custom_amt_setup_panel").css({ display: "none" });
      }
      // let reqData = {
      //   pairId: data.type == "SpotPair" ? data._id : data.pairId,
      //   exchange: params.exchange,
      //   BotType: params.bot.toUpperCase(),
      //   stopLoss,
      //   lossType,
      // };
      // const { status, message } = await saveStopLoss(reqData);
      // if (status == "success") {
      //   $(".trade_new_strategy_settings").css({ display: "block" });
      //   $(".custom_amt_setup_panel").css({ display: "none" });
      //   // toastAlert("success", message, "save");
      //   setValidateError({});
      //   fetchTradeSetting();
      // } else {
      //   $(".trade_new_strategy_settings").css({ display: "none" });
      //   $(".custom_amt_setup_panel").css({ display: "block" });
      //   toastAlert("error", message, "error");
      // }
    } catch (err) {
      console.log(err, "---------------111");
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    // if (
    //   name == "takeprofitRatio" ||
    //   name == "takeprofitCallback" ||
    //   name == "buyinCallback"
    // ) {
    //   let str = value.toString();
    //   let convertStr = str.split(".");
    //   if (
    //     convertStr[1] &&
    //     convertStr[1].length > 2 &&
    //     process.env.REACT_APP_MODE === "demo"
    //   ) {
    //     return;
    //   }
    // }
    setTradeData({ ...tradedata, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };

  const handleFormValue = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };

  const handleCheckData = (e) => {
    let { name, value } = e.target;
    if (name == "Price") {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
      let str = value.toString();
      let convertStr = str.split(".");
      let decimal = !isEmpty(PairList && PairList.secondFloatDigit)
        ? PairList.secondFloatDigit
        : 8;
      if (convertStr[1] && convertStr[1].length > decimal) {
        return;
      }
    }
    setcheckdata({ ...checkdata, ...{ [name]: value } });
    if (value) {
      setValidErr({});
    }
  };

  const handleOnChange = (index, e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }
    // if (name == "marginCall") {
    //   let str = value.toString();
    //   let convertStr = str.split(".");
    //   if (
    //     convertStr[1] &&
    //     convertStr[1].length > 2 &&
    //     process.env.REACT_APP_MODE === "demo"
    //   ) {
    //     return;
    //   }
    // }
    const values = [...inputFields];
    values[index][name] = value;
    setInputFields(values);
    if (value) {
      setValidErr({});
    }
  };
  const onHide = async () => {
    setConfModal(false);
  };
  const handleConfModal = async (e) => {
    try {
      setLoaderModal(true);

      const { name } = e.target;
      let reqData = {
        pairId: data.type == "SpotPair" ? data._id : data.pairId,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
      };
      if (name == "StrategyPeriod") {
        reqData.StrategyPeriod =
          StrategyPeriod == "cycle" ? "one-shot" : "cycle";
      } else if (name == "AveragingCall") {
        reqData.AveragingCall = AveragingCall == "Start" ? "Stop" : "Start";
      }
      console.log(reqData, "---------262");

      const { status, message, result } = await AddStrategyPeriod(reqData);
      if (status == "success") {
        setFormValue(result);
        toastAlert("success", message, "save");
        fetchStatusTrade();
        setConfModal(false);
        setLoaderModal(false);
      } else {
        toastAlert("error", message, "error");
        setLoaderModal(false);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleClick = async (name) => {
    try {
      // const { name } = e.target;
      setModalName(name);
      setConfModal(true);
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleBSChange = async (e) => {
    const { name, value } = e.target;
    let formData = { ...BSForm, ...{ [name]: value } };
    setBSForm(formData);
    setPosPerc(0);
    setValidateError({});
  };
  const handlesave = async () => {
    try {
      setLoader(true);
      if (priceType == "Indicators" && inputData.length <= 0) {
        toastAlert("error", "Kindly Add Your Indicators");
        setLoader(false);
        return;
      }
      let valData = {
        takeprofitRatio: tradedata.takeprofitRatio,
        takeprofitCallback: tradedata.takeprofitCallback,
        buyinCallback: tradedata.buyinCallback,
        firstbuyAmount: tradedata.firstbuyAmount,
        Price: Price,
        formType: "smartSetting",
        priceType: priceType,
        margincallLimit: tradedata.margincallLimit,
      };
      let validationError = validation(valData);
      if (!isEmpty(validationError)) {
        setValidErr(validationError);
        setLoader(false);
        return;
      }
      console.log(tradedata, "-------------363");

      let tradeArr = [];
      tradeArr.push({
        firstbuyAmount: tradedata.firstbuyAmount,
        margincallLimit: tradedata.margincallLimit,
        takeprofitRatio: tradedata.takeprofitRatio,
        takeprofitCallback: tradedata.takeprofitCallback,
        buyinCallback: tradedata.buyinCallback,
        AverageCallDrop: tradedata.AverageCallDrop,
        stopLoss: stopLoss, //tradedata.stopLoss,
        stopLossType: lossType, // tradedata.stopLossType,
        customAmountSetup: inputFields,
      });
      console.log(record, "---------158");
      let reqData = {
        pairId: data.type == "SpotPair" ? data._id : data.pairId,
        pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
        firstCurrencyId: data.firstCurrencyId,
        firstCurrency: data.firstCurrencySymbol
          ? data.firstCurrencySymbol
          : data.firstCurrency,
        secondCurrencyId: data.secondCurrencyId,
        secondCurrency: data.secondCurrencySymbol
          ? data.secondCurrencySymbol
          : data.secondCurrency,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        Name: Name ? Name : record.Name,
        priceType,
        Price: priceType == "CMP" ? PairList[0].markPrice : Price,
        RiskType: record.RiskType ? record.RiskType : "",
        strategyId: record._id,
        Indicator: inputData,
        tradesetting: tradeArr,
        StrategyPeriod,
        AveragingCall,
        formStatus: "Save",
      };
      console.log(reqData, "reqData ABCC 123");

      const { status, message, error } = await SaveSmartTradeSetting(reqData);
      setLoader(false);

      if (status == "success") {
        toastAlert("success", message, "save");
        setValidErr({});
      } else {
        if (error) {
          setValidErr(error);
        }
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------errror192222");
    }
  };

  const fetchStrategyPeriod = async () => {
    try {
      let pairdata = params.pair.replace(/\-/g, "");
      let reqdata = {
        pair: pairdata,
        exchange: params.exchange,
        bot: params.bot,
      };
      const { status, result } = await getStrategyPeriod(reqdata);
      if (status == "success") {
        let formdata = {};
        formdata.StrategyPeriod = result.StrategyPeriod;
        // result.StrategyPeriod == "cycle" ? "one-shot" : "cycle";
        formdata.AveragingCall = result.AveragingCall;
        // result.AveragingCall == "Start" ? "Stop" : "Start";
        console.log("-----------------------126", formdata);
        setFormValue(formdata);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const fetchPairSetting = async () => {
    try {
      console.log("------------------fetchPairList");
      let pairdata = params.pair.replace(/\-/g, "");
      let reqdata = {
        pair: pairdata,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
      };

      const { status, type, result } = await getTradeSetting(reqdata);
      if (status == "success") {
        result.type = type;
        setData(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const fetchTradeSetting = async (reset = false) => {
    try {
      // console.log("------------------fetchTradeList");
      let pairdata = params.pair.replace(/\-/g, "");
      let reqdata = {
        pair: pairdata,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        reset: reset,
      };

      const { status, type, result } = await getTradeSetting(reqdata);
      if (status == "success") {
        result.type = type;
        setData(result);
        if (!isEmpty(result.Tradestrategy[0])) {
          setTradeData(result.Tradestrategy[0]);
          setInputFields(result.Tradestrategy[0].customAmountSetup);
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handlereset = async () => {
    setcheckdata(InitialDatas);
    let formData = { ...formValue, Name: "" };
    setFormValue(formData);
    fetchTradeSetting(true);
  };

  const fetchpairs = async () => {
    const { result } = await getpairssingle({
      exchange: params.exchange,
      pair: params.pair,
      bot: params.bot,
    });
    setPairList(result);
  };
  const fetchStatusTrade = async () => {
    try {
      let pairdata = params.pair.replace(/\-/g, "");
      let reqData = {
        pairName: pairdata,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
      };
      const { status, result } = await getStatusTrade(reqData);

      if (status == "success") {
        if (result && result.RunStatus) {
          setRunStatus(result.RunStatus);
          setcheckdata({ ...checkdata, ...{ priceType: result.priceType } });
        } else {
          setRunStatus("");
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleSubmit = async () => {
    try {
      setLoader(true);
      if (priceType == "Indicators" && inputData.length <= 0) {
        toastAlert("error", "Kindly Add Your Indicators");
        setLoader(false);
        return;
      }
      let valData = {
        takeprofitRatio: tradedata.takeprofitRatio,
        takeprofitCallback: tradedata.takeprofitCallback,
        buyinCallback: tradedata.buyinCallback,
        firstbuyAmount: tradedata.firstbuyAmount,
        Price: Price,
        formType: "smartSetting",
        priceType: priceType,
        margincallLimit: tradedata.margincallLimit,
      };
      let validationError = validation(valData);
      if (!isEmpty(validationError)) {
        setValidErr(validationError);
        setLoader(false);
        return;
      }
      console.log(tradedata, "-------------363");
      let tradeArr = [];
      tradeArr.push({
        firstbuyAmount: tradedata.firstbuyAmount,
        margincallLimit: tradedata.margincallLimit,
        takeprofitRatio: tradedata.takeprofitRatio,
        takeprofitCallback: tradedata.takeprofitCallback,
        buyinCallback: tradedata.buyinCallback,
        AverageCallDrop: tradedata.AverageCallDrop,
        stopLoss: stopLoss, //tradedata.stopLoss,
        stopLossType: lossType, // tradedata.stopLossType,
        customAmountSetup: inputFields,
      });
      let pairName = params.pair.replace(/\-/g, "");

      let reqData = {
        pairId: data.type == "SpotPair" ? data._id : data.pairId,
        pairName: pairName,
        firstCurrencyId: data.firstCurrencyId,
        firstCurrency: data.firstCurrencySymbol
          ? data.firstCurrencySymbol
          : data.firstCurrency,
        secondCurrencyId: data.secondCurrencyId,
        secondCurrency: data.secondCurrencySymbol
          ? data.secondCurrencySymbol
          : data.secondCurrency,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        Name: Name ? Name : record.Name,
        priceType,
        Price: priceType == "CMP" ? PairList[0].markPrice : Price,
        RiskType: record.RiskType ? record.RiskType : "",
        strategyId: record._id,
        Indicator: inputData,
        tradesetting: tradeArr,
        StrategyPeriod,
        AveragingCall,
        formStatus: "Start",
      };
      console.log(reqData, "reqData ABCC 123");

      const { status, message, error } = await StartSmartTradeSetting(reqData);
      setLoader(false);

      if (status == "success") {
        toastAlert("success", message, "save");
        fetchStatusTrade();
      } else {
        if (error) {
          setValidErr(error);
        }
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------324");
    }
  };
  const splitOne = (data, type) => {
    let check = data.split("-");
    if (type == "first") {
      return check[0];
    }
    if (type == "second") {
      return check[1];
    }
  };
  const handleStop = async () => {
    try {
      if (isEmpty(RunStatus)) {
        toastAlert("error", "Kindly Save Your Trade Setting", "error");
        return;
      }
      let pairData = params.pair.replace(/\-/g, "");
      let reqData = {
        pair: pairData,
        BotType: params.bot.toUpperCase(),
        exchange: params.exchange,
      };
      const { status, message, result } = await changeTradeStatus(reqData);
      if (status == "success") {
        setRunStatus(result.RunStatus);
        toastAlert("success", message, "save");
        fetchStatusTrade();
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "--------322");
    }
  };
  const handleBuySubmit = async () => {
    try {
      setBuyLoader(true);
      let pairdata = params.pair.replace(/\-/g, "");
      let reqData = {
        pairName: pairdata,
        amount: buyAmount,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        formType: "buyGenie",
      };
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setBuyLoader(false);
        return;
      }
      const { status, loading, message, error, result } = await comManBuyOrder(
        reqData
      );
      setBuyLoader(false);
      if (status == "success") {
        toastAlert("success", message, "save");
        fetchStatusTrade();
        fetTotalBal();
        setBSForm(buySell);
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const handleSellSubmit = async () => {
    try {
      setSellLoader(true);
      let orgValue = (totalQuant * 100) / 100,
        orgPerc = 0;
      if (parseFloat(sellAmount) == parseFloat(orgValue)) {
        orgPerc = 100;
      }
      let pairdata = params.pair.replace(/\-/g, "");
      let reqData = {
        pairName: pairdata,
        amount: sellAmount,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        formType: "sellGenie",
        percentage: orgPerc > 0 ? orgPerc : posPerc,
      };
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setSellLoader(false);
        fetchStatusTrade();
        return;
      }
      const { status, loading, message, error, result } = await comManSellOrder(
        reqData
      );
      setSellLoader(false);
      if (status == "success") {
        toastAlert("success", message, "save");
        fetTotalBal();
        setBSForm(buySell);
        fetchStatusTrade();
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  useEffect(() => {
    if (record) {
      let formData = { ...formValue, Name: record.Name };
      setFormValue(formData);
      setTradeData(record.Tradestrategy[0]);
      setInputFields(record.Tradestrategy[0].customAmountSetup);
      fetchPairSetting();
    } else {
      fetchTradeSetting();
      fetchStrategyPeriod();
    }
    // fetchStrategyPeriod();
    fetchpairs();
    fetchStatusTrade();
    let socketTicker = "socketTicker" + params.exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      setPairList((el) => {
        let pairList = [];
        el &&
          el.map((item) => {
            if (item._id == result.pairId) {
              pairList.push({
                ...item,
                markPrice: toFixed(result.data.markPrice, item.priceDecimal),
                change: toFixed(result.data.change, 2),
              });
            } else {
              pairList.push(item);
            }
          });
        return pairList;
      });
    });

    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, [record, params.exchange, params.bot, params.pair]);
  useEffect(() => {
    socketContext.socket.on("runningOrder", (result) => {
      fetchStatusTrade();
    });
  }, [params.exchange, params.bot, params.pair]);
  useEffect(() => {
    fetTotalBal();
  }, [totalQuant, params.exchange, params.bot, params.pair]);
  const fetTotalBal = async () => {
    try {
      let pairData = params.pair.replace(/\-/g, "");
      let reqData = {
        pair: pairData,
        botType: params.bot,
        exchange: params.exchange,
      };
      const { status, result } = await getTotQuant(reqData);
      if (status == "success") {
        if (!isEmpty(result)) {
          setTotQuant(result.quantity);
        }
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  const handlePercent = async (value) => {
    let quantity = totalQuant,
      orgValue = (quantity * value) / 100;
    setPosPerc(value);
    let formData = { ...BSForm, ...{ sellAmount: toFixedDown(orgValue, 6) } };
    setBSForm(formData);
  };

  const buysell_modalClick = async () => {
    setBSForm({ buyAmount: 0, sellAmount: 0 });
    fetTotalBal();
  };

  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };

  const handlesellClear = async () => {
    setLoader(true);
    const { bot, exchange, pair } = params;
    let reqData = {
      bot: bot.toUpperCase(),
      exchange: exchange,
      pair: splitData(pair),
    };
    const { status, loading, message, error } = await geniesmartclearSell(
      reqData
    );
    setLoader(false);
    if (status == "success") {
      toastAlert("success", message, "genieorderplace");
      setFormValue(initialFormvalue);
      setcheckdata(InitialDatas);
      setValidateError({});
      fetchStatusTrade();
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "zaporderplace");
    }
  };
  return (
    <div className="trade_main_right">
      {/* Trade New Strategy Settings */}
      <div className="trade_new_strategy_settings">
        <div className="card trade_card">
          <div className="card-header">
            <h2>
              <img
                src={require("../../assets/images/icon_smart.png")}
                alt="Icon"
                className="img-fluid"
              />
              Smart
            </h2>
            {/* <p>
              <i className="bi bi-x-circle strategy_settings_close"></i>
            </p> */}
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="form-group">
                <label>Exchange</label>

                <ExchangeBalance params={params} />
              </div>
              {/* <div className="form-group">
              <label>Balance</label>
              <div className="input-group">
                <input type="text" className="form-control" />
                <div className="input-group-text">USDT</div>
              </div>                            
            </div> */}
              {/* <div className="alert info_alert alert-dismissible fade show mt-2" role="alert">
              <img src={require("../../assets/images/info_icon.png")} alt="Icon"/>
              <p>The first buy in amount is calculated according to the currency pair, principle and trade unit.</p>                            
            </div> */}
              {/* <div className="new_strategy_settings_panel">
          <div>
            <label>Initial Buying Amount</label>                          
            <input type="text" className="form-control new_strategy_inp" />
          </div>
          <div>
            <label className="label_large">Indicators</label>
            <div className="d-flex align-items-center">
              <span className="edit_indicators" data-bs-toggle="modal" data-bs-target="#indicators_modal"><i class="bi bi-pencil-fill"></i> Edit</span>
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
              </div>
            </div>
          </div>
          <div>
            <label className="label_large">Custom Amount</label>
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
            </div>
          </div>
          <div>
            <label>No. of Averaging Calls</label>
            <input type="text" className="form-control new_strategy_inp" />
          </div>
          <div>
            <label>Take Profit Ratio</label>
            <input type="text" className="form-control new_strategy_inp" />
          </div>
          <div>
            <label>Sell Pull-Back Rate</label>
            <input type="text" className="form-control new_strategy_inp" />
          </div>
          <div id="custom_amt_setup">
            <label className="label_large">Custom Amount Setup</label>
            <i class="bi bi-chevron-right"></i>
          </div>
          <div>
            <label>Buy Pull-Back Rate</label>
            <input type="text" className="form-control new_strategy_inp" />
          </div>
        </div> 
        <button className="primary_btn">Pre Defined Strategies</button> 
        <button className="primary_btn primary_blue_btn">Start</button>*/}
            </div>
          </div>
        </div>
        <div className="trade_options_card">
          <div>
            <span
              className="edit_indicators"
              data-bs-toggle="modal"
              data-bs-target="#buy_modal"
            >
              <img
                src={require("../../assets/images/icon_01.png")}
                alt="Icon"
              />
              <h3>Buy</h3>
            </span>
          </div>
          <div>
            <span
              className="edit_indicators"
              data-bs-toggle="modal"
              data-bs-target="#sell_modal"
              onClick={() => buysell_modalClick()}
            >
              <img
                src={require("../../assets/images/icon_02.png")}
                alt="Icon"
              />
              <h3>Sell</h3>
            </span>
          </div>
          <div>
            <span
              name="StrategyPeriod"
              className="edit_indicators"
              onClick={() => handleClick("StrategyPeriod")}
            >
              <img
                src={
                  StrategyPeriod == "cycle"
                    ? require("../../assets/images/batch_setting_icon_05.png")
                    : require("../../assets/images/batch_setting_icon_06.png")
                }
                alt="Icon"
                value={StrategyPeriod}
              />

              {/* {!isEmpty(StrategyPeriod) ? (
              <h3>{StrategyPeriod}</h3>
            ) : (
              <h3>one-shot</h3>
            )} */}
              <h3>{StrategyPeriod == "cycle" ? "one-shot" : "cycle"}</h3>
            </span>
          </div>
          <div>
            <span
              name="AveragingCall"
              className="edit_indicators"
              onClick={() => handleClick("AveragingCall")}
            >
              <img
                src={
                  AveragingCall == "Start"
                    ? require("../../assets/images/pause_icon.png")
                    : require("../../assets/images/icon_04.png")
                }
                alt="Icon"
                value={AveragingCall}
              />
              <h3>
                {" "}
                {AveragingCall == "Stop" ? "Start" : "Stop"}Averaging Call
              </h3>
            </span>
          </div>
        </div>
        <button
          id="strategy_btn"
          className="strategy_btn"
          data-bs-toggle="modal"
          data-bs-target="#predefined_strategy_modal"
        >
          Strategy Settings <i className="bi bi-gear"></i>
        </button>
        <div className="card trade_card">
          <div className="card-body">
            <div className="card_body_padding">
              <div className="new_strategy_settings_panel">
                <div>
                  <label>Strategy Name</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp w-100 mt-1"
                    name="Name"
                    disabled
                    // onChange={handleFormValue}
                    value={Name}
                  />
                  <span className="text-danger">{validErr.Name}</span>
                </div>
                <div>
                  <label>First Buying Price</label>
                  <select
                    className="form-select new_strategy_inp w-100 mt-1"
                    name="priceType"
                    value={priceType}
                    onChange={handleCheckData}
                  >
                    <option value="userPrice">Users Price</option>
                    <option value="CMP">CMP</option>
                    <option value="Indicators">Indicators</option>
                  </select>
                  <span className="text-danger">{validErr.priceType}</span>
                </div>
                {priceType == "userPrice" && (
                  <>
                    <div>
                      <label>Price</label>
                      <input
                        type="text"
                        className="form-control new_strategy_inp w-50 mt-1"
                        name="Price"
                        onChange={handleCheckData}
                        value={Price}
                      />
                    </div>

                    <span className="text-danger">{validErr.Price}</span>
                  </>
                )}
                {priceType == "CMP" && (
                  <div>
                    <label>Current Market Price</label>
                    <input
                      type="text"
                      className="form-control new_strategy_inp w-50 mt-1"
                      value={
                        PairList &&
                        PairList.length > 0 &&
                        toFixed(PairList[0].markPrice, PairList[0].priceDecimal)
                      }
                      disabled
                    />
                  </div>
                )}
                <div>
                  <label>Initial Buying Amount</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                    name="firstbuyAmount"
                    onChange={handleChange}
                    value={tradedata.firstbuyAmount}
                  />
                </div>
                <span className="text-danger">{validErr.firstbuyAmount}</span>
                {priceType == "Indicators" && (
                  <div>
                    <label>Indicators</label>
                    <div className="d-flex align-items-center">
                      <span
                        className="edit_indicators"
                        data-bs-toggle="modal"
                        data-bs-target="#indicators_modal"
                      >
                        <i class="bi bi-pencil-fill"></i> Edit
                      </span>
                      {/* <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                        />
                      </div> */}
                    </div>
                  </div>
                )}
                {/* <div>
                  <label>Custom Amount</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                </div> */}
                <div>
                  <label>No. of Averaging Calls</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                    name="margincallLimit"
                    onChange={handleInputChange}
                    value={tradedata.margincallLimit}
                  />
                </div>
                <span className="text-danger">{validErr.margincallLimit}</span>

                <div>
                  <label>Take Profit Ratio</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                    name="takeprofitRatio"
                    value={tradedata.takeprofitRatio}
                    onChange={handleChange}
                  />
                </div>
                <span className="text-danger">{validErr.takeprofitRatio}</span>
                <div>
                  <label>Sell Pull-Back Rate</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                    name="takeprofitCallback"
                    value={tradedata.takeprofitCallback}
                    onChange={handleChange}
                  />
                </div>
                <span className="text-danger">
                  {validErr.takeprofitCallback}
                </span>

                <div id="custom_amt_setup">
                  <label>Custom Amount Setup</label>
                  <i class="bi bi-chevron-right"></i>
                </div>
                <span className="text-danger">
                  {validErr.customAmountSetup}
                </span>
                <div>
                  <label>Buy Pull-Back Rate</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                    name="buyinCallback"
                    value={tradedata.buyinCallback}
                    onChange={handleChange}
                  />
                </div>
                <span className="text-danger">{validErr.buyinCallback}</span>

                {/* <div>
                  <label>RiskType</label>
                  <select
                    className="form-select new_strategy_inp w-50 mt-6"
                    name="RiskType"
                    value={RiskType}
                    onChange={handleChange}
                  >
                    <option value="conservative">Conservative</option>
                    <option value="steady">Steady</option>
                    <option value="radical">Radical</option>
                  </select>
                </div>
                <div>
                  <label>StrategyPeriod</label>
                  <select
                    className="form-select new_strategy_inp w-50 mt-2"
                    onChange={handleChange}
                  >
                    <option value="cycle">Cycle</option>
                    <option value="one-shot">One-shot</option>
                  </select>
                </div> */}
              </div>
              {/* <button className="primary_btn">Pre Defined Strategies</button> */}
              <button
                disabled={loader}
                className="primary_btn"
                onClick={handlesave}
              >
                {loader && <div class="loader"></div>}
                Save
              </button>
              {((RunStatus && RunStatus == "Pause") ||
                RunStatus == "New" ||
                isEmpty(RunStatus)) && (
                <button
                  className="primary_btn primary_blue_btn"
                  onClick={handleSubmit}
                  disabled={loader}
                >
                  {loader && <div class="loader"></div>}
                  Start
                </button>
              )}
              {RunStatus && RunStatus == "Start" && (
                <button
                  className="primary_btn primary_blue_btn"
                  onClick={handleStop}
                >
                  Pause
                </button>
              )}
              <button
                className="primary_btn primary_blue_btn"
                onClick={handlereset}
                disabled={loader}
              >
                {" "}
                <i className="bi bi-arrow-clockwise"></i>
                {/* {loader && <div class="loader"></div>} */}
                Reset Strategy
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Amount Setup */}
      <div className="custom_amt_setup_panel" style={{ display: "none" }}>
        <div className="card trade_card">
          <div className="card-header custom_amt_setup_back">
            <h2>
              <i class="bi bi-chevron-left me-2"></i> Custom Amount Setup
            </h2>
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="custom_amt_setup_div">
                <div className="row">
                  <div className="col-lg-6  col-md-6 col-6">
                    <label>Averaging call drop</label>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <label>Times</label>
                  </div>
                </div>
                {inputFields.map((data, index) => {
                  return (
                    <Fragment key={`${data}~${index}`}>
                      <div className="row">
                        {/* <label>{index} Call</label> */}
                        <div className="col-lg-6  col-md-6 col-6">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              value={data.Ratio}
                              onChange={(e) => handleOnChange(index, e)}
                              name="Ratio"
                            />
                            <div class="input-group-text inp_grp_width">%</div>
                            {validErr &&
                              validErr.tradeSetting &&
                              validErr.tradeSetting.length > 0 && (
                                <span className="text-danger">
                                  {validErr.tradeSetting[index] &&
                                    validErr.tradeSetting[index].Ratio &&
                                    validErr.tradeSetting[index].Ratio}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6  col-md-6 col-6">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              value={data.marginCall}
                              onChange={(e) => handleOnChange(index, e)}
                              name="marginCall"
                            />
                            <div class="input-group-text inp_grp_width">
                              Times
                            </div>
                            {validErr &&
                              validErr.tradeSetting &&
                              validErr.tradeSetting.length > 0 && (
                                <span className="text-danger">
                                  {validErr.tradeSetting[index] &&
                                    validErr.tradeSetting[index].marginCall &&
                                    validErr.tradeSetting[index].marginCall}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <label>Stop Loss (Optional)</label>
                    <div class="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="stopLoss"
                        value={stopLoss}
                        onChange={handleStopChange}
                      />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                    {/* <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="stopLoss"
                        value={stopLoss}
                        onChange={handleStopChange}
                      />
                      <div className="input-group-text select-input-group">
                        <select
                          className="form-select"
                          name="lossType"
                          onChange={handleStopChange}
                          value={lossType}
                        >
                          <option value="usdt">
                            {seccurrency && seccurrency.toUpperCase()}
                          </option>
                          <option value="%">%</option>
                        </select>
                      </div> 
                    </div>*/}
                    {validateError && validateError.stopLoss && (
                      <p className="text-danger">{validateError.stopLoss}</p>
                    )}
                    {validateError && validateError.lossType && (
                      <p className="text-danger">{validateError.lossType}</p>
                    )}
                  </div>
                </div>
              </div>
              <button className="primary_btn" onClick={handleStopSubmit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Buy Modal PopUp */}
      <div
        class="modal fade primary_modal"
        id="buy_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="buy_modal_label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="buy_modal_label">
                Buy
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    onChange={handleBSChange}
                    value={buyAmount}
                    name="buyAmount"
                    className="form-control"
                  />
                  <div className="input-group-text">
                    {splitOne(params.pair, "second")}
                  </div>
                </div>
                {validateError && validateError.buyAmount && (
                  <p className="text-danger">{validateError.buyAmount}</p>
                )}
              </div>
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={handleBuySubmit}
                  disabled={buyLoader}
                >
                  {buyLoader && <div class="loader"></div>}
                  {!buyLoader && "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sell Modal PopUp */}
      <div
        class="modal fade primary_modal"
        id="sell_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="sell_modal_label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="sell_modal_label">
                Sell
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    onChange={handleBSChange}
                    value={sellAmount}
                    name="sellAmount"
                    className="form-control"
                  />
                  <div className="input-group-text">
                    {splitOne(params.pair, "first")}
                  </div>
                </div>
                {validateError && validateError.sellAmount && (
                  <p className="text-danger">{validateError.sellAmount}</p>
                )}
              </div>
              <div
                className="input-group-text"
                onClick={() => handlePercent("25")}
              >
                25%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("50")}
              >
                50%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("75")}
              >
                75%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("100")}
              >
                100%
              </div>{" "}
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={handleSellSubmit}
                  disabled={sellLoader}
                >
                  {sellLoader && <div class="loader"></div>}
                  {!sellLoader && "Sell"}
                </button>
              </div>
              <div className="text-center">
                <button
                  class="primary_btn primary_red_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={() => handlesellClear()}
                  disabled={clrSellLoad}
                >
                  {clrSellLoad && <div class="loader"></div>}
                  {!clrSellLoad && "Clear Sell"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={confModal}
        onHide={onHide}
        centered
        size="md"
        className="modal fade primary_modal"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">
            Are you sure want to Change Your Setting ?
          </h1>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <button
              class="primary_btn primary_blue_btn w-25"
              data-bs-dismiss="modal"
              onClick={onHide}
            >
              Cancel
            </button>{" "}
            <button
              disabled={loadermodal}
              class="primary_btn primary_blue_btn w-25"
              data-bs-dismiss="modal"
              onClick={handleConfModal}
              name={modalName}
            >
              {loadermodal && <div class="">Loading ...</div>}
              {!loadermodal && "OK"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
