import React, { useState, useContext, useEffect } from "react";
import ExchangeBalance from "./ExchangeBalance.js";

import validation from "./validation";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

// import context
import SocketContext from "../Context/SocketContext";

// import action
import {
  zapOrderPlace,
  currentMarketPrice,
  getPositionData,
  zapclearSell,
} from "../../actions/Bottrade";

export default function ZapSettings(props) {
  const socketContext = useContext(SocketContext);
  const { params } = props;
  let pairArr = params.pair.split("-");

  const initialFormValue = {
    amount: "",
    stoploss: "",
    losstype: "%",
    takeprofit: "",
    profittype: "%",
    ratio: "",
    formType: "zap",
  };

  // state
  const [firstcurrency, Setfirstcurrency] = useState(pairArr[0]);

  const [seccurrency, Setseccurrency] = useState(pairArr[1]);

  const [netamount, setnetamount] = useState(0.0);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [positionData, setPosition] = useState();
  const [loader, setLoader] = useState();
  const [markPrice, setMarkPrice] = useState();
  const [validateError, setValidateError] = useState({});

  const {
    amount,
    stoploss,
    formType,
    takeprofit,
    ratio,
    losstype,
    profittype,
  } = formValue;

  // function
  const handleChange = (e) => {
    console.log("---------------59handlechange");
    e.preventDefault();
    const { name, value } = e.target;
    if (name != "losstype" && name != "profittype") {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    }
    if (name == "amount") {
      getcurrentmarktprice(value);
    }

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError({});
  };
  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };
  const handleFormSubmit = async () => {
    setLoader(true);
    const { bot, exchange, pair } = params;
    let reqData = {
      bot: bot,
      exchange: exchange,
      pair: splitData(pair),
      amount: amount,
      stoploss: stoploss,
      takeprofit: takeprofit,
      ratio: ratio,
      losstype: losstype,
      profittype: profittype,
      positionData,
      formType,
      currentPrice: markPrice,
      side: !isEmpty(positionData) ? "sell" : "buy",
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    const { status, message, error } = await zapOrderPlace(reqData);
    setLoader(false);
    if (status == "success") {
      toastAlert("success", message, "zaporderplace");
      console.log(initialFormValue, "-------109");
      setFormValue(initialFormValue);
      setnetamount(0.0);
      setValidateError({});
      fetchPosition();
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "zaporderplace");
    }
  };
  const handlesellClear = async () => {
    setLoader(true);
    const { bot, exchange, pair } = params;
    let reqData = {
      bot: bot,
      exchange: exchange,
      pair: splitData(pair),
    };
    const { status, loading, message, error } = await zapclearSell(reqData);
    setLoader(false);
    if (status == "success") {
      toastAlert("success", message, "zaporderplace");
      setFormValue(initialFormValue);
      setnetamount(0.0);
      setValidateError({});
      fetchPosition();
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "zaporderplace");
    }
  };

  const getcurrentmarktprice = async (amount) => {
    const { bot, exchange, pair } = params;
    let reqData = {
      bot: bot,
      exchange: exchange,
      pair: splitData(pair),
      amount: amount,
    };

    const { status, loading, result, error } = await currentMarketPrice(
      reqData
    );
    setLoader(false);
    if (status == "success") {
      setnetamount(result ? result.netamount : 0.0);
    } else {
    }
  };
  const fetchPosition = async () => {
    const { bot, exchange, pair } = params;
    let reqData = {
      bot: bot,
      exchange: exchange,
      pair: splitData(pair),
    };
    const { status, loading, result, error } = await getPositionData(reqData);
    if (status == "success") {
      setPosition(result);
      if (!isEmpty(result && result.initialAmount)) {
        let formData = {
          ...formValue,
          ...{ amount: result && result.initialAmount },
        };
        console.log(formData, "-------------152");
        setFormValue(formData);
        getcurrentmarktprice(result && result.initialAmount);
      }
    } else {
    }
  };

  useEffect(() => {
    fetchPosition();
    let socketTicker = "socketTicker" + params.exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      let pairName = splitData(params.pair);
      if (result.pairName == pairName) {
        // setMarkPrice(result.data.markPrice);
      }
    });
    socketContext.socket.on("runningOrder", (result) => {
      // if (result.pairId == tradePair.pairId) {
      fetchPosition();
      setFormValue(initialFormValue);
      // }
    });

    setnetamount(0.0);
    setFormValue(initialFormValue);
  }, [params.exchange, params.bot, params.pair]);

  return (
    <div className="trade_main_right">
      {/* Trade Main Settings */}
      <div className="trade_main_settings">
        <div className="card trade_card">
          <div className="card-header">
            <h2>
              <img
                src={require("../../assets/images/icon_zap.png")}
                alt="Icon"
                className="img-fluid"
              />
              Zap
            </h2>
            {/* <p>
              <i className="bi bi-x-circle strategy_settings_close"></i>
            </p> */}
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="form-group">
                <label>Exchange</label>

                <ExchangeBalance params={params} />
              </div>
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="amount"
                    value={
                      isEmpty(positionData) ? amount : positionData.quantity
                    }
                    onChange={(e) => handleChange(e)}
                    disabled={!isEmpty(positionData) ? true : false}
                  />
                  <div className="input-group-text">
                    {isEmpty(positionData) ? pairArr[1] : pairArr[0]}
                  </div>
                </div>
                {isEmpty(positionData) && (
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <span className="f_16">Net Amount</span>
                    <span className="f_16">
                      {parseFloat(netamount).toFixed(8)} {pairArr[0]}
                    </span>
                  </div>
                )}
                {validateError && validateError.amount && (
                  <p className="text-danger">{validateError.amount}</p>
                )}
              </div>
              {isEmpty(positionData) && (
                <>
                  <div className="form-group">
                    <label>Stop Loss</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="stoploss"
                        value={stoploss}
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="input-group-text select-input-group">
                        <select
                          className="form-select"
                          name="losstype"
                          onChange={(e) => handleChange(e)}
                          value={losstype}
                        >
                          <option value="%">%</option>
                          <option value="usdt">
                            {seccurrency && seccurrency.toUpperCase()}
                          </option>
                        </select>
                      </div>
                    </div>
                    {validateError && validateError.stoploss && (
                      <p className="text-danger">{validateError.stoploss}</p>
                    )}
                    {validateError && validateError.losstype && (
                      <p className="text-danger">{validateError.losstype}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Take Profit</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="takeprofit"
                        value={takeprofit}
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="input-group-text select-input-group">
                        <select
                          className="form-select"
                          name="profittype"
                          onChange={(e) => handleChange(e)}
                          value={profittype}
                        >
                          <option value="%">%</option>
                          <option value="usdt">
                            {seccurrency && seccurrency.toUpperCase()}
                          </option>
                        </select>
                      </div>
                    </div>
                    {validateError && validateError.takeprofit && (
                      <p className="text-danger">{validateError.takeprofit}</p>
                    )}
                    {validateError && validateError.profitloss && (
                      <p className="text-danger">{validateError.profitloss}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Sell Pull Back Ratio</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="ratio"
                        value={ratio}
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="input-group-text">%</div>
                    </div>
                    {validateError && validateError.ratio && (
                      <p className="text-danger">{validateError.ratio}</p>
                    )}
                  </div>
                </>
              )}

              <div>
                {/* <button class="primary_btn">Pre Defined Strategies</button> */}
                <button
                  class="primary_btn primary_blue_btn"
                  onClick={() => handleFormSubmit()}
                  disabled={loader}
                >
                  {loader && <div class="loader"></div>}
                  {!loader && isEmpty(positionData) && "Submit"}
                  {!loader && !isEmpty(positionData) && "Sell"}
                </button>
                {!isEmpty(positionData) && (
                  <button
                    class="primary_btn primary_red_btn"
                    onClick={() => handlesellClear()}
                    disabled={loader}
                  >
                    {loader && <div class="loader"></div>}
                    {!loader && !isEmpty(positionData) && "Clear Sell"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Trade Strategy Settings */}
      {/* <div className="trade_strategy_settings" style={{display: "none"}}>
      <div className="card trade_card">
        <div className="card-header">
          <h2>Strategy Settings</h2>
          <p><i className="bi bi-x-circle strategy_settings_close"></i></p>
        </div>
        <div className="card-body">
          <div className="card_body_padding">
            <button className="primary_btn" id="add_new_strategy">Add New Strategy</button>
            <div className="p-0 search_inp_div">
              <input type="text" placeholder="Seach Strategy" className="form-control search_inp" />
              <i className="bi bi-search"></i>
            </div>
            <div className="saved_strategies_panel">
              <h2>Saved Strategies</h2>
              <button>Droid</button>
              <button>Gynoid</button>
              <button>Cyborg</button>
              <button>Cobot</button>
              <button>Drone</button>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* Trade New Strategy Settings */}
      <div className="trade_new_strategy_settings" style={{ display: "none" }}>
        <div className="card trade_card">
          <div className="card-header">
            <h2>Smart</h2>
            <p>
              <i className="bi bi-x-circle strategy_settings_close"></i>
            </p>
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="form-group">
                <label>Exchange</label>
                <select className="form-select">
                  <option value="1">Binance</option>
                  <option value="2">Kraken</option>
                  <option value="3">Huobi</option>
                </select>
              </div>
              <div className="form-group">
                <label>Balance</label>
                <div className="input-group">
                  <input type="text" className="form-control" />
                  <div className="input-group-text">USDT</div>
                </div>
              </div>
              {/* <div className="alert info_alert alert-dismissible fade show mt-2" role="alert">
              <img src={require("../assets/images/info_icon.png")} alt="Icon"/>
              <p>The first buy in amount is calculated according to the currency pair, principle and trade unit.</p>                            
            </div> */}
              <div className="new_strategy_settings_panel">
                <div>
                  <label>Initial Buying Amount</label>
                  {/* <h6>15.0</h6> */}
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div>
                  <label className="label_large">Indicators</label>
                  <div className="d-flex align-items-center">
                    <span
                      className="edit_indicators"
                      data-bs-toggle="modal"
                      data-bs-target="#indicators_modal"
                    >
                      <i class="bi bi-pencil-fill"></i> Edit
                    </span>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label className="label_large">Custom Amount</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                </div>
                <div>
                  <label>No. of Averaging Calls</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div>
                  <label>Take Profit Ratio</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div>
                  <label>Sell Pull-Back Rate</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div id="custom_amt_setup">
                  <label className="label_large">Custom Amount Setup</label>
                  <i class="bi bi-chevron-right"></i>
                </div>
                <div>
                  <label>Buy Pull-Back Rate</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
              </div>
              <button className="primary_btn">Pre Defined Strategies</button>
              <button className="primary_btn primary_blue_btn">Start</button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Amount Setup */}
      <div className="custom_amt_setup_panel" style={{ display: "none" }}>
        <div className="card trade_card">
          <div className="card-header custom_amt_setup_back">
            <h2>
              <i class="bi bi-chevron-left me-2"></i> Custom Amount Setup
            </h2>
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="custom_amt_setup_div">
                <div className="row">
                  <div className="col-lg-6  col-md-6 col-6">
                    <label>Averaging call drop</label>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <label>Amount</label>
                  </div>
                </div>
                <div className="row">
                  <label>First Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>2nd Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>3rd Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>4th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>5th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>6th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>7th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="primary_btn custom_amt_setup_back">
                Save & Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
