import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { getpairssingle } from "../../actions/Bottrade";
// import lib
import config from "../../config";
import isEmpty from "../../lib/isEmpty";
import { toFixed, toFixedDown } from "../../lib/roundOf";

// import context
import SocketContext from "../Context/SocketContext";
export default function MarketPrice(props) {
  const socketContext = useContext(SocketContext);
  const { params } = props;
  // console.log(params, "---14");
  const pairArr = params.pair.split("-");
  const [PairList, setPairList] = useState();
  if (
    !(
      ["Binance", "BitMart", "Kucoin"].includes(params.exchange) &&
      ["Zap", "Genie", "Smart"].includes(params.bot)
    )
  ) {
    const url = "/dashboard";
    window.location.href = url;
  }

  const fetchpairs = async () => {
    const { result } = await getpairssingle(params);
    if (!result || result[0].status != "active") {
      const url = "/trade/BTC-USDT/" + params.exchange + "/" + params.bot;
      window.location.href = url;
    }

    // console.log(result, "redsult single");
    setPairList(result);
  };
  useEffect(() => {
    socketContext.socket.on("SinglePairOrder", (result) => {
      // if (result.pairId == pairData(pair)) {
      setPairList(result.data);
      // }
    });
  }, []);

  //
  useEffect(() => {
    fetchpairs();
    let socketTicker = "socketTicker" + params.exchange;
    socketContext[socketTicker].on("marketPrice", (result) => {
      setPairList((el) => {
        // console.log(el, "----------------51", result);
        let pairList = [];
        el &&
          el.map((item) => {
            if (item._id == result.pairId) {
              // console.log(result, "---------42");
              let returnrate_per =
                ((result.data.markPrice * item.Orders.quantity -
                  item.Orders.avgprice * item.Orders.quantity) /
                  (item.Orders.avgprice * item.Orders.quantity)) *
                100;

              pairList.push({
                ...item,
                Orders: {
                  ...item.Orders,
                  returnrate_per: returnrate_per ? returnrate_per : 0.0,
                },
                markPrice: result.data.markPrice,
                change: result.data.change,
              });
            } else {
              console.log(item, "---------73");
              pairList.push(item);
            }
          });
        return pairList;
      });
    });

    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, [params.pair]);

  return (
    <>
      {PairList &&
        PairList.length > 0 &&
        PairList[0].BotType.includes(params.bot) && (
          <div className="trade_topbar">
            <div>
              {PairList && (
                <img
                  src={
                    !isEmpty(PairList[0].firstCurrencyId.image)
                      ? config.API_URL +
                        "/images/currency/" +
                        PairList[0].firstCurrencyId.image
                      : require("../../assets/images/defaultCoin.jpeg")
                  }
                  alt="Icon"
                  className="img-fluid"
                />
              )}
              <h3>
                {pairArr && pairArr[0]}
                <span>/ {pairArr && pairArr[1]}</span>
              </h3>
            </div>

            {PairList &&
              PairList.length > 0 &&
              PairList.map((item, key) => {
                let markPriceval = item.markPrice.toString();
                let markPriceArr = markPriceval.split(".");

                let cmpdecimal = 0;
                if (!isEmpty(markPriceArr[1])) {
                  //cmpdecimal = Number(markPriceArr[1].length);
                  cmpdecimal = 6;
                }
                return (
                  <>
                    <div>
                      <p>Investment ({pairArr && pairArr[1]})</p>
                      <h3>
                        {item &&
                          item.Orders.investment.toFixed(item.priceDecimal)}
                      </h3>
                    </div>
                    <div>
                      <p>Quantity ({pairArr && pairArr[0]})</p>
                      <h3>
                        {item &&
                          toFixed(item.Orders.quantity, item.quantityDecimal)}
                      </h3>
                    </div>
                    <div>
                      <p>Average Price ({pairArr && pairArr[1]})</p>
                      <h3>
                        {" "}
                        {item &&
                          item.Orders.avgprice &&
                          toFixed(item.Orders.avgprice, item.priceDecimal)}
                      </h3>
                    </div>
                    <div>
                      <p>Current Price ({pairArr && pairArr[1]})</p>
                      {/* <h3>{toFixed(item.markPrice, item.secondFloatDigit)}</h3> */}
                      <h3>{toFixed(item.markPrice, item.priceDecimal)}</h3>
                    </div>
                    {params.bot != "Zap" && (
                      <div>
                        <p>No. of Averaging Calls</p>
                        <h3>
                          {item &&
                            item.Orders.avgCall &&
                            toFixed(item.Orders.avgCall, item.priceDecimal)}
                        </h3>
                      </div>
                    )}
                    <div>
                      <p>Return Rate</p>
                      <h3
                        className={
                          item && item.Orders.returnrate_per < 0
                            ? "redText"
                            : "greenText"
                        }
                      >
                        {item &&
                          item.Orders.returnrate_per &&
                          toFixed(item.Orders.returnrate_per, 4)}{" "}
                        %
                      </h3>
                    </div>
                  </>
                );
              })}
          </div>
        )}
    </>
  );
}
