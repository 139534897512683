// import package
import io from "socket.io-client";

// import lib
import config from "./index";

const socket = io(config.SOCKET_URL);
const socketTickerBinance = io(config.SOCKET_TICKERBINANCE_URL);
const socketTickerBitMart = io(config.SOCKET_TICKERBITMART_URL);
const socketTickerKucoin = io(config.SOCKET_TICKERKUCOIN_URL);

const createSocketUser = (userId) => {
  socket.emit("CREATEROOM", userId);
};

socket.on("userAsset", function (data) {
  // alert()
});

// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export {
  socket,
  socketTickerBinance,
  socketTickerBitMart,
  socketTickerKucoin,
  createSocketUser,
};
