import React, { useEffect } from "react";


export default function WithdrawWallet() {

  return (
<div className="userContent mt-4">
<h2>Withdrawal Request</h2>
<div className="walletForm">
<div className="form-group">
<label>Amount</label>
<div className="input-group mb-3">
        <input type="text" className="form-control" />
        <div className="input-group-text">
            INR
        </div>
    </div>
</div>
<div className="form-group">
    <div className="withdrawDetailBox">
        <ul>
        <li>
            <span className="bdLabel">Available:</span>
            <span className="bdDetails">1000 INR</span>
        </li>
        <li>
            <span className="bdLabel">Transaction Fee:</span>
            <span className="bdDetails">200 INR</span>
        </li>
        <li>
            <span className="bdLabel">Arrival Quantity:</span>
            <span className="bdDetails">800 INR</span>
        </li>
        </ul>
        <div className="greenNote">
        Amount - INR (Do not send below INR 1000)
        </div>
    </div>
</div>
<div className="form-group mb-0">
    <button className="btn btn-green-big mx-auto mb-0">Submit</button>
</div>
</div>
</div>
);
}