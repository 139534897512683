// import package
import moment from "moment";

// import lib
import isEmpty from "./isEmpty";

export const dateTimeFormat = (dateTime, format = "YYYY-MM-DD HH:mm") => {
  try {
    if (!isEmpty(dateTime)) {
      let newDateTime = new Date(dateTime);
      if (format.includes("YYYY")) {
        format = format.replace("YYYY", newDateTime.getFullYear());
      }

      if (format.includes("MM")) {
        let month = newDateTime.getMonth() + 1;
        month = month > 9 ? month : `0${month}`;
        format = format.replace("MM", month);
      }

      if (format.includes("DD")) {
        let date = newDateTime.getDate();
        date = date > 9 ? date : `0${date}`;
        format = format.replace("DD", date);
      }

      if (format.includes("HH")) {
        let hour = newDateTime.getHours();
        hour = hour > 9 ? hour : `0${hour}`;
        format = format.replace("HH", hour);
      }

      if (format.includes("mm")) {
        let minute = newDateTime.getMinutes();
        minute = minute > 9 ? minute : `0${minute}`;
        format = format.replace("mm", minute);
      }

      return format;
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

export const momentFormat = (dateTime, format = "YYYY-MM-DD HH:mm") => {
  try {
    if (!isEmpty(dateTime)) {
      // let newDateTime = new Date(dateTime);
      // return moment(newDateTime).format(format)
      //   let now = new Date(dateTime);
      const now = moment(dateTime); // Create a Moment object representing the current time
      const desiredUtcOffset = 0; // For example, setting UTC offset to -4 hours (240 minutes)

      now.utcOffset(desiredUtcOffset); // Set the UTC offset on the Moment object
      return moment(now).format(format);
    }
    return "";
  } catch (err) {
    console.log(err, "--------------67");
    return "";
  }
};
