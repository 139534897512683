import React, { useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import $ from "jquery";
import { TransferList } from "../actions/Transfer";
import isEmpty from "../lib/isEmpty";
export default function NewBotModal() {
  const params = useParams();

  // console.log(params, "looo-123213");
  const history = useNavigate();

  // useEffect(() => {
  //   loadScript();
  // }, []);

  // function loadScript() {
  //   $(document).ready(function (e) {
  //     $("#new_bot_modal").on("hidden.bs.modal", function () {
  //       window.location.href = "/trade";
  //     });
  //   });
  // }

  const gotoTrade = async (botType) => {
    let exchange = params.exchange;
    if (isEmpty(exchange)) {
      exchange = "Binance";
    }

    window.$("#new_bot_modal").modal("hide");
    window.location.href="/trade/BTC-USDT/" + exchange + "/" + botType;
    

   // history("/trade/BTC-USDT/" + exchange + "/" + botType);
    //window.location.reload();
  };

  return (
    <>
      {/* New Bot Modal */}
      <div
        class="modal fade primary_modal"
        id="new_bot_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="new_bot_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="new_bot_modalLabel">
                Mini Bot
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="new_bot_panel">
                <a href="javascript:void(0" onClick={() => gotoTrade("Genie")}>
                  <div className="new_bot_panel_content">
                    <img
                      src={require("../assets/images/icon_genie.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    <h4>Genie</h4>
                  </div>
                  <img
                    src={require("../assets/images/icon_start.png")}
                    alt="Icon"
                    className="img-fluid icon_start"
                  />
                </a>
                <a href="javascript:void(0" onClick={() => gotoTrade("Smart")}>
                  <div className="new_bot_panel_content">
                    <img
                      src={require("../assets/images/icon_smart.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    <h4>Smart</h4>
                  </div>
                  <img
                    src={require("../assets/images/icon_start.png")}
                    alt="Icon"
                    className="img-fluid icon_start"
                  />
                </a>
                <a href="javascript:void(0" onClick={() => gotoTrade("Zap")}>
                  <div className="new_bot_panel_content">
                    <img
                      src={require("../assets/images/icon_zap.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    <h4>Zap</h4>
                  </div>
                  <img
                    src={require("../assets/images/icon_start.png")}
                    alt="Icon"
                    className="img-fluid icon_start"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
