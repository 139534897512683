import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "../components/Header.js";
import WalletSideMenu from "../components/Wallet/WalletSideMenu";
import WalletBalance from "../components/Wallet/WalletBalance";
import DepositWallet from "../components/Wallet/DepositWallet";
import DepositHistory from "../components/Wallet/DepositHistory";

import DepositWalletIndian from "../components/Wallet/DepositWalletIndian";

import Footer from "../components/Footer.js";

import { DepositList } from "../actions/Deposit";
import { MassetConversion } from "../actions/Wallet";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function WalletDeposit() {
  // state
  const [TxnList, setTxnList] = useState([]);
  const [masset, Setmasset] = useState();

  // redux-state
  const { kycStatus, nationality } = useSelector((state) => state.account);

  const fetchTransactionHistory = async (reqData) => {
    const { status, loading, message, error, result } = await DepositList(reqData);
    setTxnList(result);
  };

  const fetchMasset = async () => {
    const { status, loading, message, error, result } =
      await MassetConversion();
    Setmasset(result);
  };

  useEffect(() => {
    let reqData = {
      filter: "all"
    }
    fetchTransactionHistory(reqData);
    fetchMasset();
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <WalletSideMenu />
              {nationality == "nonindian" && (
                <DepositWallet
                  fetchTransactionHistory={fetchTransactionHistory}
                />
              )}
              {nationality == "indian" && (
                <DepositWalletIndian
                  fetchTransactionHistory={fetchTransactionHistory}
                />
              )}
            </div>
            <div className="col-lg-8">
              <WalletBalance masset={masset} />
              <DepositHistory TxnList={TxnList} nationality={nationality} fetchTransactionHistory={fetchTransactionHistory}/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
