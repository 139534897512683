let key = {};

if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://productionapi.ugaininternational.com";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "UGNA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://productionapi.ugaininternational.com",

    SOCKET_TICKERBINANCE_URL: `https://productionbinance.ugaininternational.com`,
    SOCKET_TICKERBITMART_URL: `https://productionbitmart.ugaininternational.com`,
    SOCKET_TICKERKUCOIN_URL: `https://productionkucoin.ugaininternational.com`,
    FRONT_URL: "https://gainbot.ugaininternational.com",
    ADMIN_URL: "https://controls.ugaininternational.com",
    SOCKET_URL: "https://productionapi.ugaininternational.com",
    getGeoInfo: "https://ipapi.co/json/",
    ipBind: "148.113.0.159",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://gainbotv2api.wealwin.com";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "UGNA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://gainbotv2api.wealwin.com",
    FRONT_URL: "https://gainbotv2.wealwin.com",
    ADMIN_URL: "https://gainbotv2.wealwin.com/admin",
    ipBind: "172.105.40.100",
    SOCKET_URL: "https://gainbotv2api.wealwin.com",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "UGNA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://gainbotv2api.wealwin.com",
    FRONT_URL: "https://gainbotv2.wealwin.com",
    ADMIN_URL: "https://gainbotv2.wealwin.com/admin",
    SOCKET_URL: "https://gainbotv2api.wealwin.com",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.REACT_APP_MODE === "stagging") {
  console.log("Set stagging Config");
  const API_URL = "https://gainbotv2testapi.alwin.io";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "UGNA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://gainbotv2testapi.alwin.io",
    FRONT_URL: "https://gainbotv2test.alwin.io",
    ADMIN_URL: "https://gainbotv2test.alwin.io/admin",
    ipBind: "172.105.40.100",
    SOCKET_URL: "https://gainbotv2testapi.alwin.io",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.REACT_APP_MODE === "client_stagging") {
  console.log("Set client_staggin Config");
  const API_URL = "https://stagingapi.ugaininternational.com";

  key = { 
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "UGNA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://stagingapi.ugaininternational.com",

    SOCKET_TICKERBINANCE_URL: `https://stagingbinance.ugaininternational.com`,
    SOCKET_TICKERBITMART_URL: `https://stagingbitmart.ugaininternational.com`,
    SOCKET_TICKERKUCOIN_URL: `https://stagingkucoin.ugaininternational.com`,
    FRONT_URL: "https://staging.ugaininternational.com",
    ADMIN_URL: "https://stagingcontrols.ugaininternational.com",
    SOCKET_URL: "https://stagingapi.ugaininternational.com",
    getGeoInfo: "https://ipapi.co/json/",
    ipBind: "103.236.108.25",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost"; // 'http://localhost' 'http://192.168.0.124';  //http://192.168.29.63:3000/  http://192.168.0.124
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    REFERRAL_CODE: "UGNA1234",
    API_URL: `${API_URL}:2053`,
    FRONT_URL: "http://localhost:3000", //'http://localhost:3000',
    ADMIN_URL: "http://localhost:3001/admin",
    SOCKET_URL: `${API_URL}:2053`,
    SOCKET_TICKERBINANCE_URL: `${API_URL}:2054`,
    SOCKET_TICKERBITMART_URL: `${API_URL}:2056`,
    SOCKET_TICKERKUCOIN_URL: `${API_URL}:2057`,
    ipBind: "172.105.40.100",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
}

export default {
  ...key,
  ...{ SITE_DETAIL: require("./siteConfig").default },
};
