import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { WithdrawValid_NonIndia } from "./validation";

import { withdrawrequest } from "../../actions/Withdraw";
import { currencybysymbol, MassetConversion } from "../../actions/Wallet";

const initialFormValue = {
  amount: "",
  nationality: "nonindian",
};
export default function WithdrawWallet(props) {
  const dispatch = useDispatch();
  const { data, fetchWithdrawHistory, fetchMasset } = props;

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [loader, setLoader] = useState(false);
  const [currencyDetails, setcurrencyDetails] = useState();
  const [massetDetails, setmassetDetails] = useState({
    balance: 0,
    fee: 0,
    finalbalance: 0,
  });
  const { amount, nationality } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = WithdrawValid_NonIndia(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    try {
      let reqData = {
        amount,
        nationality,
      };

      const { status, loading, message, error } = await withdrawrequest(
        reqData
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        setFormValue(initialFormValue);
        fetchWithdrawHistory();
        fetchMasset();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  const fetchcurrency = async () => {
    const { status, loading, message, error, result } = await currencybysymbol({
      currency: "USDT",
    });
    // console.log(result, "resultresultresult");
    if (result) {
      setcurrencyDetails(result);
      await fetchMassetDetails(result);
    }
  };

  const fetchMassetDetails = async (currencyDetails) => {
    const { status, loading, message, error, result } = await MassetConversion(
      {}
    );
    // console.log(result.massetBalanceUSDT.toFixed(2) * 2 /100, "resultdatadatadatadatadata");

    // console.log(currencyDetails, "---------------------currencyDetails");

    if (!isEmpty(result)) {
      let fee;
      let datafee;

      let feepercentage_fee = (amount * result.massetBalanceUSDT) / 100;
      let fee_amount_fee = currencyDetails.withdrawFeeAmount;

      let feepercentage = (amount * currencyDetails.withdrawFee) / 100;
      let fee_amount = currencyDetails.withdrawFeeAmount;

      if (feepercentage_fee >= fee_amount_fee) {
        fee = feepercentage_fee;
      } else if (fee_amount_fee >= feepercentage_fee) {
        fee = fee_amount_fee;
      }

      if (feepercentage >= fee_amount) {
        datafee = feepercentage;
      } else if (fee_amount >= feepercentage) {
        datafee = fee_amount;
      }
      // if (currencyDetails.feeType == "percentage") {
      //   fee = (result.massetBalanceUSDT * currencyDetails.withdrawFee) / 100;
      // } else {
      //   fee = currencyDetails.withdrawFee;
      // }
      // if (currencyDetails.feeType == "percentage") {
      //   datafee = (amount * currencyDetails.withdrawFee) / 100;
      // } else {
      //   datafee = currencyDetails.withdrawFee;
      // }

      let finalbalance = parseFloat(result.massetBalanceUSDT - fee.toFixed(3));
      let balancedata = parseFloat(amount - datafee.toFixed(3));
      let data = {
        balance: result.massetBalanceUSDT.toFixed(3),
        fee: amount == 0 ? fee.toFixed(4) : datafee.toFixed(4),
        finalbalance:
          amount == 0 ? finalbalance.toFixed(3) : balancedata.toFixed(3),
      };
      // console.log(data, "datadatadatadatadata");
      // console.log(finalbalance, "--------------finalbalance");
      setmassetDetails(data);
    }
  };

  useEffect(() => {
    fetchcurrency();
  }, [amount]);

  // console.log(toFixedDown(massetDetails.fee, 5), "-----massetDetails");

  return (
    <div className="userContent mt-4">
      <h2>Withdrawal Request</h2>
      <div className="walletForm">
        <div className="form-group">
          <label>Amount</label>
          <div className="input-group mb-3">
            <input
              type="text"
              name="amount"
              value={amount}
              onChange={handleChange}
              className="form-control"
            />
            <div className="input-group-text">USDT</div>
          </div>
          {validateError && validateError.amount && (
            <p className="text-danger">{validateError.amount}</p>
          )}
        </div>
        <div className="form-group">
          <div className="withdrawDetailBox">
            <ul>
              {/* <li>
                <span className="bdLabel">Fee:</span>
                {currencyDetails && currencyDetails.feeType == "percentage" ? (
                  <span className="bdDetails">
                    {" "}
                    {currencyDetails &&
                      parseFloat(currencyDetails.withdrawFee)}{" "}
                    %
                  </span>
                ) : (
                  <span className="bdDetails">
                    {" "}
                    {currencyDetails &&
                      parseFloat(currencyDetails.withdrawFee)}{" "}
                  </span>
                )}
              </li> */}
              <li>
                <span className="bdLabel">Available:</span>
                <span className="bdDetails">
                  {massetDetails && parseFloat(massetDetails.balance)} USDT
                </span>
              </li>
              <li>
                <span className="bdLabel">Transaction Fee:</span>
                <span className="bdDetails">
                  {massetDetails && parseFloat(massetDetails.fee)} USDT
                </span>
              </li>
              <li>
                <span className="bdLabel">Arrival Quantity:</span>
                <span className="bdDetails">
                  {" "}
                  {massetDetails && parseFloat(massetDetails.finalbalance)} USDT
                </span>
              </li>
            </ul>
            <div className="greenNote">
              Note: Amount - USDT (Do not send below{" "}
              {currencyDetails && currencyDetails.minimumWithdraw} USDT)
            </div>
          </div>
        </div>
        <div className="form-group mb-0">
          <button
            onClick={handleSubmit}
            className="btn btn-green-big mx-auto mb-0"
          >
            {" "}
            {loader && <i class="fas fa-spinner fa-spin"></i>}Submit
          </button>
        </div>
      </div>
    </div>
  );
}
