import React, { useEffect } from "react";
import Header from "../components/Header.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";

import Kyc from '../components/Kyc'

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function KycPage() {

  return (
<main>
	 <ScrollToTopOnMount />
          <Header />
          <Kyc />
          <Footer />
    </main>

  );
}

