import React, { useEffect, useState } from "react";
import Header from "../Header.js";
import ProfileSideMenu from "../ProfileSideMenu";
import Footer from "../Footer.js";
import { useSelector, useDispatch } from "react-redux";

import { PersonalDetails } from "./PersonalDet";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

// import actions
import { getBankDetails, WalletUpdate } from "../../actions/users";
import { adminwalletlist } from "../../actions/Wallet";

import validation from "./validation";

const initialFormValue = {
  walletNetwork: "",
  walletAddress: "",
  currencySymbol: "",
  type: "wallet",
};

const WalletDet = (props) => {
  const { editable } = props;
  const { kycStatus } = useSelector((state) => state.account);

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setvalidateError] = useState();
  const [loader, setLoader] = useState(false);
  const { walletNetwork, currencySymbol, walletAddress } = formValue;
  const [adminwallet, setadminwallet] = useState();

  const fetchadminwallet = async () => {
    const { status, loading, message, error, result } = await adminwalletlist();
    setadminwallet(result);
  };

  useEffect(() => {
    fetchadminwallet();
    fetchDetail();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "walletAddress") {
      if (/[^a-zA-Z0-9]/.test(value)) return;
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setvalidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = validation(formValue);
    if (!isEmpty(validationError)) {
      setvalidateError(validationError);
      setLoader(false);
      return;
    }
    let splitValue = walletNetwork.split("-");
    let reqdata = {
      walletNetwork: splitValue[0],
      currencySymbol: splitValue[1],
      walletAddress,
    };
    try {
      const { status, loading, message, error } = await WalletUpdate(reqdata);
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        setvalidateError({});
        fetchDetail();
      } else {
        if (error) {
          setvalidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  const fetchDetail = async () => {
    try {
      const { status, loading, result, message, error } =
        await getBankDetails();
      if (status == "success") {
        let formData = {
          ...formValue,
          ...{
            walletNetwork:
              result.wallet.type + "-" + result.wallet.currencySymbol,
            walletAddress: result.wallet.address,
          },
        };
        setFormValue(formData);
      }
    } catch (err) {}
  };
  return (
    <div className="form-card">
      <h3>
        <span>Wallet Address Details</span>
      </h3>
      <div className="row">
        <div className="col-lg-4">
          <div className="form-group">
            <label>Choose Network</label>
            <select
              className="form-select"
              onChange={handleChange}
              name="walletNetwork"
              disabled={editable}
              value={walletNetwork}
            >
              <option value="">Select Network</option>

              {adminwallet &&
                adminwallet.length > 0 &&
                adminwallet.map((item, key) => {
                  return (
                    <>
                      <option
                        key={key}
                        value={item.tokenType + "-" + item.currencySymbol}
                      >
                        {item.currencySymbol}
                        {item.currencySymbol == "USDT"
                          ? " - " + item.tokenType.toUpperCase()
                          : ""}
                        {/* {item.tokenType.toUpperCase()} */}
                      </option>
                    </>
                  );
                })}
            </select>
            {validateError && validateError.walletNetwork && (
              <p className="text-danger">{validateError.walletNetwork}</p>
            )}
          </div>
        </div>
        <div className="col-lg-8">
          <div className="form-group">
            <label>Wallet Address</label>
            <input
              type="text"
              className="form-control"
              name="walletAddress"
              value={walletAddress}
              disabled={editable}
              onChange={handleChange}
            />
            {validateError && validateError.walletAddress && (
              <p className="text-danger">{validateError.walletAddress}</p>
            )}
          </div>
        </div>
        {!editable && kycStatus == "approved" && (
          <div className="form-group text-left mt-4">
            <button
              className="btn btn-green-big ms-0 me-0"
              onClick={handleSubmit}
            >
              {loader && <i class="fas fa-spinner fa-spin"></i>}
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletDet;
