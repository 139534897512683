import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getpairssingle } from "../actions/Bottrade";
import { toFixed } from "../lib/roundOf";
import config from "../config";
import isEmpty from "../lib/isEmpty";
import * as htmlToImage from "html-to-image";
import { toBlob } from "html-to-image";
import { UploadShareModal, getshareData } from "../actions/users";
import $ from "jquery";
import jsPDF from "jspdf";
// import context
import SocketContext from "./Context/SocketContext";
export default function ShareModal(props) {
  const socketContext = useContext(SocketContext);
  const [PairListmodal, setPairListmodal] = useState();
  const navigate = useNavigate();
  const { ShareData, shareId } = props;
  const reportTemplateRef = useRef(null);

  let item = {};
  if (!isEmpty(shareId)) {
    console.log(shareId, "---------23");
    item = ShareData && ShareData.find((el) => el._id.toString() == shareId);
  }
  const domEl = useRef(null);

  // setData(item);
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
      unit: "px",
      format: [842, 598],
    });

    // Adding the fonts
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  const [image, setImage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { status, loading, message, result, error } = await getshareData();
      if (status == "success") {
        if (!isEmpty(result)) {
          setImage(result);
        }
      } else {
      }
    } catch (err) {
      console.log(err, "------error");
    }
  };

  const downloadImage = async () => {
    const dataUrl = await toBlob(domEl.current);
    const data = {
      files: [
        new File([dataUrl], "image.png", {
          type: dataUrl.type,
        }),
      ],
      title: "Image",
      text: "image",
    };

    // console.log(data, "_data");
    try {
      const formData = new FormData();
      formData.append("shareImage", data.files[0]);

      const { status, loading, message, result, error } =
        await UploadShareModal(formData);
      if (status == "success") {
        // console.log(result);
        setImage(result);
      } else {
      }
    } catch (err) {
      console.log(err, "------error");
    }
    // download image
    // const link = document.createElement("a");
    // link.download = "html-to-img.png";

    // link.href = dataUrl;
    // link.height = "1500 px";
    // link.click();
  };
  return (
    <>
      {/* Share Modal */}

      {!isEmpty(item) && (
        <div>
          <div
            class="modal fade primary_modal share_modal"
            id="share_modal"
            ref={domEl}
            tabindex="-1"
            aria-labelledby="share_modalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div className="share_modal_body_top">
                    <div className="share_modal_body_left">
                      {item &&
                        item.tradeSetting &&
                        item.tradeSetting.exchange == "Binance" && (
                          <img
                            src={require("../assets/images/bianaceLogo.png")}
                            className="img-fluid"
                            alt="API Logo"
                          />
                        )}

                      {item &&
                        item.tradeSetting &&
                        item.tradeSetting.exchange == "BitMart" && (
                          <img
                            src={require("../assets/images/bitmartLogo.png")}
                            className="img-fluid"
                            alt="API Logo"
                          />
                        )}

                      {item &&
                        item.tradeSetting &&
                        item.tradeSetting.exchange == "Kucoin" && (
                          <img
                            src={require("../assets/images/kucoinLogo.png")}
                            className="img-fluid"
                            alt="API Logo"
                          />
                        )}

                      <div className="mt-3">
                        {item &&
                          item.tradeSetting &&
                          item.tradeSetting.BotType && (
                            <>
                              <h4>{item.tradeSetting.BotType}</h4>
                              {/* {item && item.StrategyPeriod == "cycle" ? (
                              //   <img
                              //     src={require("../assets/images/cycle_icon.png")}
                              //     className="img-fluid"
                              //     alt="Cycle"
                              //   />
                              // ) : (
                              //   <img
                              //     src={require("../assets/images/oneshot_icon.png")}
                              //     className="img-fluid"
                              //     alt="OneShot"
                              //   />
                              )} */}
                            </>
                          )}
                        {/* <span class="badge bg_yellow trade_type">One shot</span>   */}
                      </div>
                      <div className="share_trade_details_panel">
                        <h3>{item.pairName}</h3>

                        <div>
                          <h3
                            className={
                              item && item.pl < 0 ? "redText" : "greenText"
                            }
                          >
                            {" "}
                            {item && item.pl && toFixed(item.pl, 2)} %
                          </h3>
                          <h4>
                            {item && item.profitinprice > 0
                              ? "+" +
                                toFixed(item.profitinprice, item.priceDecimal)
                              : +toFixed(
                                  item.profitinprice,
                                  item.priceDecimal
                                )}{" "}
                            {item.secondCurrency}
                            {/* (+ 2589.64 USDT)<span>(+ 14589.64 INR)</span> */}
                          </h4>
                        </div>
                        <div className="d-block">
                          <h5>
                            <label>Entry Price</label>{" "}
                            <span>
                              {item &&
                                item.entryPrice &&
                                item.entryPrice[0] &&
                                toFixed(
                                  item.entryPrice[0].price,
                                  item.priceDecimal
                                )}{" "}
                              {item.secondCurrency}
                            </span>
                          </h5>
                          <h5>
                            <label>Market Price</label>{" "}
                            <span>
                              {item && toFixed(item.cmp, item.priceDecimal)}{" "}
                              {item.secondCurrency}
                            </span>
                          </h5>
                        </div>
                        <div>
                          <img
                            src={
                              "https://chart.googleapis.com/chart?chs=100x100&chld=L|0&cht=qr&chl=" +
                              config.FRONT_URL +
                              "?" +
                              item.referralCode
                            }
                            className="img-fluid me-3"
                            alt="API Logo"
                          />
                          <div>
                            <label>Referral Code</label>
                            <h2>{item && item.referralCode}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="share_modal_body_right">
                      <img
                        src={require("../assets/images/popup_logo.png")}
                        className="img-fluid"
                        alt="API Logo"
                      />
                    </div>
                  </div>
                  <div className="share_modal_body_bottom">
                    <ul className="social_share_list">
                      <li>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${
                            config.API_URL + "/images/sharemodal/" + image
                          }`}
                          target="_blank"
                        >
                          <img
                            onClick={downloadImage}
                            src={require("../assets/images/fb_icon.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://api.whatsapp.com/send?text=${
                            config.API_URL + "/images/sharemodal/" + image
                          }`}
                          target="_blank"
                        >
                          <img
                            onClick={downloadImage}
                            src={require("../assets/images/whatsapp_icon.png")}
                            className="img-fluid"
                            alt="Icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={` https://telegram.me/share/url?url=${
                            config.API_URL + "/images/sharemodal/" + image
                          }`}
                          target="_blank"
                        >
                          <img
                            src={require("../assets/images/telegram_icon.png")}
                            className="img-fluid"
                            onClick={downloadImage}
                            alt="Icon"
                          />
                        </a>
                      </li>
                    </ul>
                    <button
                      className="btn btn-green-big ms-0 me-0"
                      onClick={handleGeneratePdf}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="share_download_pdf">
        {!isEmpty(item) && (
          <div
            id="reportTemplateRef"
            ref={reportTemplateRef}
            className="share_modal primary_modal"
            style={{ width: "88%", margin: "50px" }}
          >
            <div
              className="modal-content"
              style={{
                border: "1px solid #000",
                boxShadow: "0 0 8px 3px rgb(0 0 0 / 20%)",
              }}
            >
              <div className="modal-body" style={{ padding: "15px" }}>
                <div className="share_modal_body_top">
                  <div className="share_modal_body_left">
                    {item &&
                      item.tradeSetting &&
                      item.tradeSetting.exchange == "Binance" && (
                        <img
                          src={require("../assets/images/bianaceLogo.png")}
                          className="img-fluid"
                          alt="API Logo"
                        />
                      )}

                    {item &&
                      item.tradeSetting &&
                      item.tradeSetting.exchange == "Bitmart" && (
                        <img
                          src={require("../assets/images/bitmartLogo.png")}
                          className="img-fluid"
                          alt="API Logo"
                        />
                      )}

                    {item &&
                      item.tradeSetting &&
                      item.tradeSetting.exchange == "Kucoin" && (
                        <img
                          src={require("../assets/images/kucoinLogo.png")}
                          className="img-fluid"
                          alt="API Logo"
                        />
                      )}

                    <div className="mt-3">
                      {item &&
                        item.tradeSetting &&
                        item.tradeSetting.BotType && (
                          <>
                            <h4>{item.tradeSetting.BotType}</h4>
                            {/* {item && item.StrategyPeriod == "cycle" ? (
                              <img
                                src={require("../assets/images/cycle_icon.png")}
                                className="img-fluid"
                                alt="Cycle"
                              />
                            ) : (
                              <img
                                src={require("../assets/images/oneshot_icon.png")}
                                className="img-fluid"
                                alt="OneShot"
                              />
                            )} */}
                          </>
                        )}
                      {/* <span class="badge bg_yellow trade_type">One shot</span>   */}
                    </div>
                    <div className="share_trade_details_panel">
                      <h3>{item.pairName}</h3>

                      <div>
                        <h3
                          className={
                            item && item.pl < 0 ? "redText" : "greenText"
                          }
                        >
                          &nbsp;&nbsp;
                          {item && item.pl && toFixed(item.pl, 2)}
                          &nbsp;&nbsp;%
                        </h3>
                        <h4>
                          {item && item.profitinprice > 0
                            ? "+" +
                              toFixed(item.profitinprice, item.priceDecimal)
                            : +toFixed(item.profitinprice, item.priceDecimal)}
                          &nbsp;&nbsp; USDT
                          {/* (+ 2589.64 USDT)<span>(+ 14589.64 INR)</span> */}
                        </h4>
                      </div>
                      <div className="d-block">
                        <h5>
                          <label>Entry&nbsp;&nbsp;Price</label>&nbsp;&nbsp;
                          <span>
                            {item &&
                              item.entryPrice &&
                              item.entryPrice[0] &&
                              item.entryPrice[0].price}
                            &nbsp;&nbsp; USDT
                          </span>
                        </h5>
                        <h5>
                          <label>Market&nbsp;&nbsp;Price</label>&nbsp;&nbsp;
                          <span>
                            {item && toFixed(item.cmp, item.priceDecimal)}
                            &nbsp;&nbsp; USDT
                          </span>
                        </h5>
                      </div>
                      <div>
                        <img
                          src={
                            "https://chart.googleapis.com/chart?chs=100x100&chld=L|0&cht=qr&chl=" +
                            config.FRONT_URL +
                            "?" +
                            item.referralCode
                          }
                          className="img-fluid me-3"
                          alt="API Logo"
                        />
                        <div>
                          <label>Referral&nbsp;&nbsp;&nbsp;Code</label>
                          <h2>{item && item.referralCode}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="share_modal_body_right">
                    <img
                      src={require("../assets/images/popup_logo.png")}
                      className="img-fluid"
                      alt="API Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
