import React, { useEffect } from "react";
import Header from "../components/Header.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";

import SecurityPage from "../components/Security"


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Security() {
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <SecurityPage />
      <Footer />
    </main>

  );
}
