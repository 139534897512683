import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import Header from "../Header.js";
import ProfileSideMenu from "../ProfileSideMenu";
import Footer from "../Footer.js";
import { Country, State, City } from "country-state-city";

import { MenuItem, Select } from "@material-ui/core";

import { PersonalDetails } from "./PersonalDet";

import validation from "./validation";

// import action
import { ProfileUpdate, getProfileDetail } from "../../actions/users";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  email: "",
  mobile: "",
  firstName: "",
  lastName: "",
  address: "",
  country: "",
  state: "",
  city: "",
  type: "personal",
  postalCode: "",
  countryName: "",
  stateName: "",
  cityName: "",
};
let countryCodeSave = "";

const PersonalDet = (props) => {
  const { editable } = props;

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [loader, setLoader] = useState(false);
  const [profileDet, setProfileDet] = useState({});
  const [stateArray, setstateArray] = useState([]);
  const [cityArray, setcityArray] = useState([]);
  const {
    email,
    mobile,
    firstName,
    lastName,
    address,
    country,
    state,
    city,
    countryName,
    stateName,
    cityName,
    postalCode,
  } = formValue;

  const countryArr = [];
  const cityArr = [];
  const stateArr = [];
  const countryDetails = Country.getAllCountries();
  const stateDetails = State.getAllStates();
  const cityDetails = City.getAllCities();

  // function

  countryDetails.map((data, i) => {
    countryArr.push({
      value: data.isoCode,
      label: data.name,
    });
  });

  const updateStates = (countryCode) => {
    if (!countryCode) return;
    countryCodeSave = countryCode;
    const stateFillter = stateDetails.filter((data) => {
      return data.countryCode == countryCode;
    });
    // console.log(stateFillter, "______-----stateFillter");
    stateFillter.map((data, i) => {
      stateArr.push({
        value: data.isoCode,
        name: data.name,
        label: data.name,
      });
    });
    setstateArray(stateArr);
  };

  const updateCity = (stateCode) => {
    if (!stateCode) return;

    const cityillter = cityDetails.filter((data) => {
      return data.stateCode == stateCode && data.countryCode == countryCodeSave;
    });
    cityillter.map((data, i) => {
      cityArr.push({
        // stateCode: data.stateCode,
        value: data.name,
        label: data.name,
        name: data.name,
      });
      setcityArray(cityArr);
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "firstName" || name == "lastName") {
      if (/[^a-zA-Z ]/.test(value)) return;
    }
    if (name == "postalCode") {
      if (/[^a-zA-Z0-9]/.test(value)) return;
    }

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleCountry = (e) => {
    const { name, value } = e.target;
    if (!value) return;
    var getCountry = countryArr.find((e) => e.value == value);
    // console.log(getCountry, "_________getCountry");
    updateStates(value);
    formValue.country = getCountry.value;
    formValue.city = "";
    formValue.cityName = "";
    formValue.countryName = getCountry.label;
    // let formData = { ...formValue, ...{ [name]: getCountry.value } };
    setFormValue(formValue);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleState = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!value) return;
    var getState = stateArray.find((e) => e.value == value);
    // console.log(getState, "___getState");
    updateCity(value);
    formValue.state = getState.value;
    formValue.city = "";
    formValue.stateName = getState.label;
    // let formData = { ...formValue, ...{ [name]: getState.name } };
    setFormValue(formValue);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleCity = (e) => {
    const { name, value } = e.target;
    if (!value) return;

    if (!value) return;
    // updateCity(value);
    var getCity = cityArray.find((e) => e.value == value);
    // console.log(getCity, "____getCity");

    let formData = { ...formValue, ...{ [name]: getCity.name } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let stateStatus;
    if (isEmpty(stateArray)) {
      stateStatus = "empty";
    } else {
      stateStatus = "notempty";
    }

    let reqData = {
      email,
      mobile,
      firstName,
      lastName,
      address,
      country,
      state,
      city,
      type: "personal",
      countryName,
      stateName,
      cityName,
      postalCode,
      stateStatus,
    };

    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }
    try {
      const { status, loading, message, error } = await ProfileUpdate(reqData);
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        fetchDetail();
      } else {
        if (error) {
          setValidateError(error);
        }

        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    try {
      const { status, loading, result, message, error } =
        await getProfileDetail();
      setLoader(loading);
      
      if (status == "success") {
        let formData = {
          ...formValue,
          ...{
            email: result.email,
            mobile: result.phoneNo,
            firstName: result.firstName,
            lastName: result.lastName,
            address: result.address,
            country: result.country,
            state: result.state,
            city: result.city,
            stateName: result.stateName,
            countryName: result.countryName,
            postalCode: result.postalCode,
          },
        };
        updateStates(result.country);
        updateCity(result.state);
        setFormValue(formData);
      }
    } catch (err) {}
  };

  // console.log(validateError, "____________--validateError", stateArray);
  return (
    <div className="form-card">
      <h3>
        <span>Personal Details</span>
      </h3>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              className="form-control"
              disabled={true}
              value={email}
            />
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Mobile Number</label>
            <input
              type="number"
              className="form-control"
              disabled
              value={mobile}
            />
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              disabled={editable}
              name="firstName"
              value={firstName}
              onChange={handleChange}
            />
            {validateError && validateError.firstName && (
              <p className="text-danger">{validateError.firstName}</p>
            )}

            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              disabled={editable}
              name="lastName"
              value={lastName}
              onChange={handleChange}
            />
            {validateError && validateError.lastName && (
              <p className="text-danger">{validateError.lastName}</p>
            )}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              disabled={editable}
              name="address"
              value={address}
              onChange={handleChange}
            />
            {validateError && validateError.address && (
              <p className="text-danger">{validateError.address}</p>
            )}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>Country</label>
            {/*<select className="form-select">
              <option selected>Select Country</option>
              <option value="1">India</option>
              <option value="2">China</option>
              <option value="3">Australia</option>
            </select>*/}
            {/* <CountryDropdown
              value={country}
              onChange={handleCountry}
              className="country_dropdown form-control"
              disabled={editable}
            /> */}
            <Select
              name="country"
              value={country}
              onChange={handleCountry}
              disabled={editable}
              className="country_dropdown form-control w-80"
            >
              <MenuItem value={""}>Select country</MenuItem>
              {countryArr.map((data, i) => {
                return <MenuItem value={data.value}>{data.label}</MenuItem>;
              })}
            </Select>
            {validateError && validateError.country && (
              <p className="text-danger">{validateError.country}</p>
            )}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
        {!isEmpty(stateArray) && (
          <>
            <div className="col-lg-6">
              <div className="form-group">
                <label>State / Province</label>
                {/* <input
              type="text"
              className="form-control"
              disabled={editable}
              name="state"
              value={state}
              onChange={handleChange}
            /> */}
                <Select
                  name="state"
                  value={state}
                  onChange={handleState}
                  disabled={editable}
                  className="form-control"
                >
                  <MenuItem value={""}>Select state</MenuItem>

                  {stateArray.map((data, i) => {
                    return <MenuItem value={data.value}>{data.label}</MenuItem>;
                  })}
                </Select>
                {validateError && validateError.state && (
                  <p className="text-danger">{validateError.state}</p>
                )}
                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>City</label>
                {/* <input
              type="text"
              className="form-control"
              disabled={editable}
              name="city"
              value={city}
              onChange={handleChange}
            /> */}
                <Select
                  name="city"
                  value={city}
                  onChange={handleCity}
                  disabled={editable}
                  className="form-control w-100"
                >
                  {cityArray.length > 0 &&
                    cityArray.map((data, i) => {
                      return (
                        <MenuItem value={data.name} key={i}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {validateError && validateError.city && (
                  <p className="text-danger">{validateError.city}</p>
                )}
                {/* Developer Note: Insert Error Message here code here */}
              </div>
            </div>
          </>
        )}
        <div className="col-lg-6">
          <div className="form-group">
            <label>Postal / Zip code</label>
            <input
              type="text"
              className="form-control"
              disabled={editable}
              name="postalCode"
              value={postalCode}
              onChange={handleChange}
            />
            {validateError && validateError.postalCode && (
              <p className="text-danger">{validateError.postalCode}</p>
            )}
            {/* Developer Note: Insert Error Message here code here */}
          </div>
        </div>
      </div>
      {!editable && (
        <div className="form-group text-left mt-4">
          <button
            className="btn btn-green-big ms-0 me-0"
            onClick={handleSubmit}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export default PersonalDet;
