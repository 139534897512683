// import constant
import {
    SET_USER_ACCOUNT,
    UPDATE_USER_ACCOUNT,
    UPDATE_2FA_STATUS,SET_THEME
} from '../constant';

const initialValue = {
    isAuthenticated: false
}
const account = (state = initialValue, action) => {
    switch (action.type) {
        case SET_USER_ACCOUNT:
            return {
                ...state,
                ...action.data
            };
        case UPDATE_USER_ACCOUNT:
            return {
                ...state,
                ...action.data
            };
        case UPDATE_2FA_STATUS:
            return {
                ...state,
                twoFAStatus: action.data
            };case SET_THEME:
            return {
                ...state,
                ...{
                    theme: action.theme
                }
            };
        default:
            return state;
    }
}

export default account;