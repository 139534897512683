import React, { useEffect, useState } from "react";

import Binding from "./Binding";
import Notification from "./Notification";
import BitmartBind from "./BitmartBind";
import BinanceBind from "./BinanceBind";
import KucoinBind from "./KucoinBind";

// import Actions
import { getSubscribe } from "../../actions/Wallet";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ApiBinding() {
  // state
  const [type, setType] = useState("Binance");
  const [transData, setTransData] = useState();

  useEffect(() => {
    fetchSubscribe();
  }, []);
  const fetchSubscribe = async () => {
    const { status, loading, message, error, result } = await getSubscribe();
    if (status == "success") {
      setTransData(result);
    }
  };
  return (
    <div className="col-lg-9 col-md-12">
      <div className="userContent">
        <Binding result={setType} />
        <div class="tab-content mt-4" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id={type}
            role="tabpanel"
            aria-labelledby="api_binding01-tab"
            tabindex="0"
          >
            <Notification />
            {type == "Binance" && (
              <BinanceBind result={type} transData={transData} />
            )}
            {type == "Kucoin" && (
              <KucoinBind result={type} transData={transData} />
            )}
            {type == "BitMart" && (
              <BitmartBind result={type} transData={transData} />
            )}
          </div>
          {/*<div class="tab-pane fade" id="api_binding02" role="tabpanel" aria-labelledby="api_binding02-tab" tabindex="0">
                        <div className="api_binding_card">
                          <div className="api_binding_card_title">
                            <h3>Notification</h3>
                            <a href="javascipt:void(0)">See the instructions</a>
                          </div>
                          <div className="api_binding_card_content">
                            <ol>
                              <li>Please confirm that the API permissions has checked "Enable Spot & Margin Trading" Do not check "Withdraw" option.</li>
                              <li>Please enter the correct API please do not enter special character.</li>
                            </ol>
                          </div>
                        </div>

                        <div className="api_binding_card mt-4">
                          <div className="api_binding_card_title">
                            <h3>IP Group Binding <span>(Optional)</span></h3>                            
                          </div>
                          <div className="api_binding_card_content">
                            <p>For security, UGain India recommends binding the server IP address when creating the API. For users who need to bind the IP address, simply copy the IP addresses from below and paste it in the input box of Kucoin Exchange. Please note that IP address binding is optional.</p>                           
                            <div class="form-group">
                              <div class="input-group">
                                <input type="text" class="form-control" value="194.233.71.147  157.119.217.164  188.208.140.65" />
                                <button class="input-group-text">Copy</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="api_binding_card mt-4">
                          <div class="form-group">
                            <label>API Key</label>
                            <input type="text" class="form-control" placeholder="Please enter API Key" />
                          </div>
                          <div class="form-group">
                            <label>Secret Key</label>
                            <input type="text" class="form-control" placeholder="Please enter Secret Key" />
                          </div>
                          <div class="form-group">
                            <label>Email Verification code</label>
                            <input type="text" class="form-control" placeholder="Please enter Verification Code" />
                          </div>
                          <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                              <label class="form-check-label" for="defaultCheck1">I have read <a href="javascript:void">the risk notice carefully</a></label>
                            </div>
                          </div>
                          <div className="form-group text-left">
                            <button className="primary_btn w-auto px-5">Bind</button>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="api_binding_03" role="tabpanel" aria-labelledby="api_binding_03-tab" tabindex="0">
                        <div className="api_binding_card">
                          <div className="api_binding_card_title">
                            <h3>Notification</h3>
                            <a href="javascipt:void(0)">See the instructions</a>
                          </div>
                          <div className="api_binding_card_content">
                            <ol>
                              <li>Please confirm that the API permissions has checked "Enable Spot & Margin Trading" Do not check "Withdraw" option.</li>
                              <li>Please enter the correct API please do not enter special character.</li>
                            </ol>
                          </div>
                        </div>

                        <div className="api_binding_card mt-4">
                          <div className="api_binding_card_title">
                            <h3>IP Group Binding <span>(Optional)</span></h3>                            
                          </div>
                          <div className="api_binding_card_content">
                            <p>For security, UGain India recommends binding the server IP address when creating the API. For users who need to bind the IP address, simply copy the IP addresses from below and paste it in the input box of Bitmart Exchange. Please note that IP address binding is optional.</p>                           
                            <div class="form-group">
                              <div class="input-group">
                                <input type="text" class="form-control" value="194.233.71.147  157.119.217.164  188.208.140.65" />
                                <button class="input-group-text">Copy</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="api_binding_card mt-4">
                          <div class="form-group">
                            <label>API Key</label>
                            <input type="text" class="form-control" placeholder="Please enter API Key" />
                          </div>
                          <div class="form-group">
                            <label>Secret Key</label>
                            <input type="text" class="form-control" placeholder="Please enter Secret Key" />
                          </div>
                          <div class="form-group">
                            <label>Email Verification code</label>
                            <input type="text" class="form-control" placeholder="Please enter Verification Code" />
                          </div>
                          <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                              <label class="form-check-label" for="defaultCheck1">I have read <a href="javascript:void">the risk notice carefully</a></label>
                            </div>
                          </div>
                          <div className="form-group text-left">
                            <button className="primary_btn w-auto px-5">Bind</button>
                          </div>
                        </div>
                      </div>   */}
        </div>
      </div>
    </div>
  );
}
