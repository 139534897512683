import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import clsx from "classnames";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import action
import { createUser, sentOTP } from "../../actions/users";

// import config
import config from "../../config";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import Logo from "../../components/Logo";
const initialFormValue = {
  email: "",
  phoneCode: "",
  phoneNo: "",
  otp: "",
  password: "",
  confirmPassword: "",
  referenceCode: "",
  isTerms: false,
  isReferral: false,
  showPassword: false,
  showConfirmPassword: false,
};
var setInt;

function useQuery() {
  const { search } = useLocation();
  let splitSearch = search.split("?");
  return splitSearch[1];
}

export default function Register() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const query = useQuery();
  const navigate = useNavigate();
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  // const [reCaptcha, setReCaptcha] = useState('');
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [resend, setResend] = useState(false);
  const [optStatus, setOtpStatus] = useState(false);
  const [Timer, setTimer] = useState(300);
  const [loaders, setLoaders] = useState(false);
  const [count, setCount] = useState("send");
  const {
    email,
    phoneCode,
    otp,
    phoneNo,
    isReferral,
    password,
    confirmPassword,
    isTerms,
    showPassword,
    showConfirmPassword,
    referenceCode,
  } = formValue;
  const [emailchecked, setemailchecked] = useState(true);
  const [mobilechecked, setmobilechecked] = useState(false);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleReferral = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, [name]: checked };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!(isTerms == true)) {
      setValidateError({ isTerms: "Please Accept Terms & Conditions" });
      return;
    }

    let reCaptcha = await handleReCaptcha();
    if (isEmpty(reCaptcha)) {
      toastAlert("error", "Invalid ReCaptcha");
      return;
    }

    setLoader(true);

    let reqData = {
      email,
      phoneCode,
      phoneNo,
      password,
      confirmPassword,
      otp,
      reCaptcha,
      isTerms,
      // langCode: getLang(),
      referenceCode,
      isReferral,
    };
    let { status, loading, message, error } = await createUser(reqData);
    setLoader(loading);
    // setReCaptcha('')
    if (status == "success") {
      setFormValue(initialFormValue);
      navigate("/signin");
      toastAlert("success", message, "signup");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "signup");
    }
  };
  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let newPhoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        phoneCode: dialCode,
        phoneNo: newPhoneNo.slice(dialCode.length),
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo: value } };
    }
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleBlurPhone = (e) => {
    setToched({ ...toched, ...{ phoneNo: true, phoneCode: true } });
  };

  const handleSentOTP = async (e) => {
    e.preventDefault();
    let reqData = {
      phoneCode,
      phoneNo,
      type: "register",
    };
    try {
      let { status, loading, error, message } = await sentOTP(reqData);
      if (status == "success") {
        setLoaders(true);
        callinterval();
        setCount("re" + count);

        setOtpStatus(true);
        toastAlert("success", message, "mobileForm");
        // setTimeout(() => {
        //   setOtpStatus(false);
        //   setResend(true);
        // }, 10000);
      } else {
        if (error) {
          console.log(error, "-----------185");
          setValidateError(error);
          return;
        }
        toastAlert("error", message, "mobileForm");
      }
    } catch (err) {}
  };

  async function callinterval() {
    var ts = 300;
    setTimer(ts);
    setInt = setInterval(() => {
      ts = ts - 1;
      if (ts == 0) {
        setTimer(ts);
        clearInterval(setInt);
        setLoaders(false);
      } else {
        setTimer(ts);
      }
    }, 1000);
  }

  const handleReCaptcha = async () => {
    try {
      if (!executeRecaptcha) {
        toastAlert("error", "Recaptcha error");
        return "";
      }
      return await executeRecaptcha("register");
    } catch (err) {
      toastAlert("error", err.toString());
      return "";
    }
  };

  useEffect(() => {
    if (query) {
      setFormValue((prev) => {
        return { ...prev, referenceCode: query };
      });
    } else {
      setFormValue((prev) => {
        return { ...prev, referenceCode: config.REFERRAL_CODE };
      });
    }
    setValidateError(validation(formValue));
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-5 mx-auto logo_beforeauth">
          <Logo />
          <div className="crediantial-form">
            <h2>REGISTER</h2>
            <h6>Enter details to create your account</h6>
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="email"
                autocomplete="off"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {toched.email && validateError.email && (
                <p className="text-danger">{validateError.email}</p>
              )}
            </div>
            {/*<div className="form-group">
                            <label>Mobile Number</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Mobile Number"
                                name="newPhoneNo"
                                value={newPhoneNo}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                            />
                            {validateError.newPhoneNo && <p className="text-danger">{validateError.newPhoneNo}</p>}
                        </div>*/}
            <div className="form-group">
              <label className="login_label">Mobile Number</label>
              <PhoneInput
                className="input-group regGroupInput mt-2"
                placeholder="Enter Mobile No"
                value={phoneCode + phoneNo}
                onChange={handlePhoneNumber}
                // onBlur={handleBlurPhone}
                specialLabel={false}
                enableSearch={true}
                country={"in"}
              />
              <div className="input-group regGroupInput mt-2">
                <div className="auth_otp_btn">
                  {!loaders && (
                    <button class="input-group-text" onClick={handleSentOTP}>
                      {Timer == 0 ? "Resend Mobile OTP" : "Send Mobile OTP"}
                    </button>
                  )}
                  {loaders && <>{Timer} seconds </>}
                </div>
              </div>

              

              {/* <div className="input-group regGroupInput mt-2">

              
                {!optStatus && (
                  <div className="auth_otp_btn">
                    {" "}
                    <button
                      onClick={handleSentOTP}
                      disabled={validateError && validateError.phoneCode}
                      className="new_bot_btn"
                    >
                      {resend && resend
                        ? "Resend Mobile OTP"
                        : "Send Mobile OTP"}
                    </button>
                  </div>
                )}
              </div> */}

              {toched.phoneNo && validateError.phoneNo && (
                <p className="text-danger">{validateError.phoneNo}</p>
              )}
            </div>

            <div className="form-group">
              <label className="login_label">Mobile Verification Code</label>
              <div className="input-group regGroupInput mt-2">
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Verify Code"
                  name="otp"
                  value={otp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {toched.otp && validateError.otp && (
                <p className="text-danger">{validateError.otp}</p>
              )}
            </div>
            <div className="form-group">
              <label>Password</label>
              <div className="input-group regGroupInput mt-2">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="input-group-text inp_grp_width">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return { ...el, ...{ showPassword: !el.showPassword } };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showPassword },
                        { "bi-eye-slash": !showPassword }
                      )}
                      // aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
              {toched.password && validateError.password && (
                <p className="text-danger">{validateError.password}</p>
              )}
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <div className="input-group regGroupInput mt-2">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="input-group-text inp_grp_width">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setFormValue((el) => {
                        return {
                          ...el,
                          ...{ showConfirmPassword: !el.showConfirmPassword },
                        };
                      });
                    }}
                  >
                    <i
                      className={clsx(
                        "bi",
                        { "bi-eye": showConfirmPassword },
                        { "bi-eye-slash": !showConfirmPassword }
                      )}
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            {toched.confirmPassword && validateError.confirmPassword && (
              <p className="text-danger">{validateError.confirmPassword}</p>
            )}

            <div className="form-group">
              <div className="switch-group">
                <label htmlFor="flexSwitchCheckChecked">
                  Referral Code (Optional)
                </label>
                {/*<div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isReferral"
                    onClick={handleReferral}
                    checked={isReferral}
                    id="flexSwitchCheckChecked"
                  />
                </div>*/}
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Referral Code"
                name="referenceCode"
                value={referenceCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {toched.referenceCode && validateError.referenceCode && (
                <p className="text-danger">{validateError.referenceCode}</p>
              )}
            </div>
            <div className="form-group">
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="isTerms"
                  onChange={handleCheckBox}
                  checked={isTerms}
                />
                <span>
                  I have read &amp; I agree to the{" "}
                  <a
                    target="_blank"
                    href="https://ugainindia.com/TermsConditions.html"
                  >
                    Terms &amp; Conditions.
                  </a>
                </span>
                {validateError.isTerms && (
                  <p className="text-danger">{validateError.isTerms}</p>
                )}
              </label>
            </div>
            <div className="form-group mb-0">
              <button
                className="btn btn-green-big"
                onClick={handleFormSubmit}
                disabled={!isEmpty(validateError) || loader}
              >
                {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
              </button>
            </div>
          </div>
          <h6 className="crediantila-bottom">
            Already have an account? <Link to="/signin">Sign in here</Link>
          </h6>
        </div>
      </div>
    </div>
  );
}
