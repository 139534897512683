import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ExchangeBalance from "./ExchangeBalance.js";

// import context
import SocketContext from "../Context/SocketContext";

// import action
import {
  getGenTradeSet,
  SaveTradeSetting,
  AddStrategyPeriod,
  getStrategyPeriod,
  getStatusTrade,
  changeTradeStatus,
  comManBuyOrder,
  comManSellOrder,
  getTotQuant,
  geniesmartclearSell,
} from "../../actions/Bottrade";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed, toFixedDown } from "../../lib/roundOf";

import validation from "./validation";

const Datas = {
  firstbuyAmount: "",
  margincallLimit: "",
  takeprofitRatio: "",
  takeprofitCallback: "",
  buyinCallback: "",
  marginCall: "",
  Ratio: "",
  AverageCallDrop: "",
};
const initialFormValue = {
  StrategyPeriod: "cycle",
  AveragingCall: "Start",
  // RunStatus: "",
};

const buySell = {
  buyAmount: 0,
  sellAmount: 0,
};

export default function GenieSettings(props) {
  const socketContext = useContext(SocketContext);
  const { params } = props;

  const [tradedata, setTradeData] = useState(Datas);
  const [inputFields, setInputFields] = useState([]);
  const [confModal, setConfModal] = useState(false);
  const [modalName, setModalName] = useState();
  const [data, setData] = useState("");
  const [BSForm, setBSForm] = useState(buySell);
  const [validateError, setValidateError] = useState({});
  const [buyLoader, setBuyLoader] = useState(false);
  const [sellLoader, setSellLoader] = useState(false);
  const [clrSellLoad, setClrLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  const [posPerc, setPosPerc] = useState(0);
  const [totalQuant, setTotQuant] = useState(0);
  const [RunStatus, setRunStatus] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const { StrategyPeriod, AveragingCall } = formValue;
  const { buyAmount, sellAmount } = BSForm;
  const [loadermodal, setLoaderModal] = useState();

  const fetchTradeSetting = async () => {
    try {
      let pairdata = params.pair.replace(/\-/g, "");
      let reqdata = {
        pair: pairdata,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
      };
      const { status, type, result } = await getGenTradeSet(reqdata);
      if (status == "success") {
        result.type = type;
        setData(result);
        if (!isEmpty(result.Tradestrategy[0])) {
          setTradeData(result.Tradestrategy[0]);
          setInputFields(result.Tradestrategy[0].customAmountSetup);
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const fetchStatusTrade = async () => {
    try {
      let pairdata = params.pair.replace(/\-/g, "");
      let reqData = {
        pairName: pairdata,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
      };
      const { status, result } = await getStatusTrade(reqData);
      console.log(status, "-----------93", result);
      if (status == "success") {
        if (result && result.RunStatus) {
          setRunStatus(result.RunStatus);
        } else {
          setRunStatus("");
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const fetchStrategyPeriod = async () => {
    try {
      let pairdata = params.pair.replace(/\-/g, "");
      let reqdata = {
        pair: pairdata,
        exchange: params.exchange,
        bot: params.bot,
      };
      const { status, result } = await getStrategyPeriod(reqdata);
      if (status == "success") {
        let formdata = {};
        formdata.StrategyPeriod = result.StrategyPeriod;
        // result.StrategyPeriod == "cycle" ? "one-shot" : "cycle";
        formdata.AveragingCall = result.AveragingCall;
        // result.AveragingCall == "Start" ? "Stop" : "Start";
        setFormValue(formdata);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleStatusChange = async () => {
    try {
      let pairData = params.pair.replace(/\-/g, "");
      let reqData = {
        pair: pairData,
        BotType: params.bot.toUpperCase(),
        exchange: params.exchange,
      };
      const { status, message, result } = await changeTradeStatus(reqData);
      if (status == "success") {
        setRunStatus(result.RunStatus);
        toastAlert("success", message, "save");
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {}
  };
  const handleBSChange = async (e) => {
    const { name, value } = e.target;
    let formData = { ...BSForm, ...{ [name]: value } };
    setBSForm(formData);
    setPosPerc(0);
    setValidateError({});
  };
  const onHide = async () => {
    setConfModal(false);
  };
  const handleConfModal = async (e) => {
    try {
      setLoaderModal(true);
      const { name } = e.target;
      let reqData = {
        pairId: data.type == "SpotPair" ? data._id : data.pairId,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
      };

      if (name == "StrategyPeriod") {
        reqData.StrategyPeriod =
          StrategyPeriod == "cycle" ? "one-shot" : "cycle";
      } else if (name == "AveragingCall") {
        reqData.AveragingCall = AveragingCall == "Start" ? "Stop" : "Start";
      }

      const { status, message, result } = await AddStrategyPeriod(reqData);
      if (status == "success") {
        setFormValue(result);
        toastAlert("success", message, "save");
        setLoaderModal(false);
        setConfModal(false);
      } else {
        setLoaderModal(false);
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleChange = async (name) => {
    try {
      // const { name } = e.target;
      setModalName(name);
      setConfModal(true);
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleSubmit = async () => {
    try {
      setBuyLoader(true);
      let reqData = {
        pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
        amount: buyAmount,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        formType: "buyGenie",
      };
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setBuyLoader(false);
        return;
      }
      const { status, loading, message, error, result } = await comManBuyOrder(
        reqData
      );
      setBuyLoader(false);
      if (status == "success") {
        toastAlert("success", message, "save");
        fetTotalBal();
        setBSForm(buySell);
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const handleSellSubmit = async (value) => {
    try {
      let reqData = {},
        orgValue = (totalQuant * 100) / 100,
        orgPerc = 0;
      if (parseFloat(sellAmount) == parseFloat(orgValue)) {
        orgPerc = 100;
      }
      if (value == "sell") {
        setSellLoader(true);
        reqData = {
          pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
          amount: sellAmount,
          exchange: params.exchange,
          BotType: params.bot.toUpperCase(),
          formType: "sellGenie",
          percentage: orgPerc > 0 ? orgPerc : posPerc,
        };
      }
      // if (value == "clrSell") {
      //   setClrLoad(true);
      //   reqData = {
      //     pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
      //     amount: totalQuant,
      //     exchange: params.exchange,
      //     BotType: params.bot.toUpperCase(),
      //     formType: "sellGenie",
      //   };
      // }
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setSellLoader(false);
        return;
      }
      const { status, message } = await comManSellOrder(reqData);
      if (value == "sell") {
        setSellLoader(false);
      }
      if (value == "clrSell") {
        setClrLoad(false);
      }
      if (status == "success") {
        toastAlert("success", message, "save");
        fetTotalBal();
        setBSForm(buySell);
        fetchStatusTrade();
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const handlesave = async () => {
    try {
      setLoader(true);
      let tradeArr = [];
      tradeArr.push({
        firstbuyAmount: tradedata.firstbuyAmount,
        margincallLimit: tradedata.margincallLimit,
        takeprofitRatio: tradedata.takeprofitRatio,
        takeprofitCallback: tradedata.takeprofitCallback,
        buyinCallback: tradedata.buyinCallback,
        AverageCallDrop: tradedata.AverageCallDrop,
        customAmountSetup: inputFields,
      });
      let pairName = params.pair.replace(/\-/g, "");

      let reqData = {
        pairId: data.type == "SpotPair" ? data._id : data.pairId,
        pairName: pairName,
        firstCurrencyId: data.firstCurrencyId,
        firstCurrency: data.firstCurrencySymbol,
        secondCurrencyId: data.secondCurrencyId,
        secondCurrency: data.secondCurrencySymbol,
        exchange: params.exchange,
        BotType: params.bot.toUpperCase(),
        StrategyPeriod,
        AveragingCall,
        RunStatus,
        tradesetting: tradeArr,
      };
      const { status, loading, message, error, result } =
        await SaveTradeSetting(reqData);
      if (status == "success") {
        setLoader(false);
        fetchStatusTrade();
        toastAlert("success", message, "save");
      } else {
        setLoader(false);
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  const splitOne = (data, type) => {
    let check = data.split("-");
    if (type == "first") {
      return check[0];
    }
    if (type == "second") {
      return check[1];
    }
  };
  const fetTotalBal = async () => {
    try {
      let pairData = params.pair.replace(/\-/g, "");
      let reqData = {
        pair: pairData,
        botType: params.bot,
        exchange: params.exchange,
      };
      const { status, result } = await getTotQuant(reqData);
      if (status == "success") {
        setTotQuant(result.quantity);
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };
  const handlePercent = async (value) => {
    let quantity = totalQuant,
      orgValue = (quantity * value) / 100;
    setPosPerc(value);
    let formData = { ...BSForm, ...{ sellAmount: orgValue } };
    setBSForm(formData);
  };
  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };
  const handlesellClear = async () => {
    setLoader(true);
    const { bot, exchange, pair } = params;
    let reqData = {
      bot: bot.toUpperCase(),
      exchange: exchange,
      pair: splitData(pair),
    };
    const { status, loading, message, error } = await geniesmartclearSell(
      reqData
    );
    setLoader(false);
    if (status == "success") {
      toastAlert("success", message, "genieorderplace");
      setFormValue(initialFormValue);
      setValidateError({});
      fetchStatusTrade();
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "zaporderplace");
    }
  };

  useEffect(() => {
    fetchTradeSetting();
    fetchStrategyPeriod();
    fetchStatusTrade();

    socketContext.socket.on("runningOrder", (result) => {
      fetchStatusTrade();
    });
  }, [params.exchange, params.bot, params.pair]);
  useEffect(() => {
    fetTotalBal();
  }, [totalQuant, params.exchange, params.bot, params.pair]);
  const buysell_modalClick = async () => {
    setBSForm({ buyAmount: 0, sellAmount: 0 });
    fetTotalBal();
  };

  console.log(RunStatus, "-----------344");
  return (
    <div className="trade_main_right">
      {/* Trade Main Settings */}
      <div className="trade_main_settings">
        <div className="card trade_card">
          <div className="card-header">
            <h2>
              <img
                src={require("../../assets/images/icon_genie.png")}
                alt="Icon"
                className="img-fluid"
              />{" "}
              Genie
            </h2>
            {/* <p>
      <i className="bi bi-pencil-fill"></i> Change Mini Bot
    </p> */}
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="form-group">
                <label>Exchange</label>

                <ExchangeBalance params={params} />
              </div>
              {/* <div className="form-group">
            <label>Balance</label>
            <div className="input-group">
              <input type="text" className="form-control" />
              <div className="input-group-text">USDT</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="f_14">You will get</span>
              <span className="f_14">0.26 BTC</span>
            </div>
          </div> */}
            </div>
          </div>
        </div>
        <div className="trade_options_card">
          <div>
            <span
              className="edit_indicators"
              data-bs-toggle="modal"
              data-bs-target="#buy_modal"
            >
              <img
                src={require("../../assets/images/icon_01.png")}
                alt="Icon"
              />

              <h3>Buy</h3>
            </span>
          </div>
          <div>
            <span
              className="edit_indicators"
              data-bs-toggle="modal"
              data-bs-target="#sell_modal"
              onClick={() => buysell_modalClick()}
            >
              <img
                src={require("../../assets/images/icon_02.png")}
                alt="Icon"
              />
              <h3>Sell</h3>
            </span>
          </div>
          <div>
            <span
              className="edit_indicators"
              onClick={() => handleChange("StrategyPeriod")}
            >
              <img
                src={
                  StrategyPeriod == "cycle"
                    ? require("../../assets/images/batch_setting_icon_05.png")
                    : require("../../assets/images/batch_setting_icon_06.png")
                }
                alt="Icon"
                name="StrategyPeriod"
                value={StrategyPeriod}
              />
              {/* {!isEmpty(StrategyPeriod) ? (
              <h3>{StrategyPeriod}</h3>
            ) : (
              <h3>one-shot</h3>
            )} */}
              <h3>{StrategyPeriod == "cycle" ? "one-shot" : "cycle"}</h3>
            </span>
          </div>
          <div>
            <span
              className="edit_indicators"
              onClick={() => handleChange("AveragingCall")}
            >
              <img
                src={
                  AveragingCall == "Start"
                    ? require("../../assets/images/pause_icon.png")
                    : require("../../assets/images/icon_04.png")
                }
                alt="Icon"
                name="AveragingCall"
                value={AveragingCall}
              />
              <h3>
                {" "}
                {AveragingCall == "Stop" ? "Start" : "Stop"}Averaging Call
              </h3>
            </span>
          </div>
        </div>

        {/* <button id="strategy_btn" className="strategy_btn" data-bs-toggle="modal" data-bs-target="#predefined_strategy_modal">Strategy Settings <i className="bi bi-gear"></i></button> */}

        <div className="strategy_settings_panel">
          <div className="strategy_settings_div">
            <div>
              <img
                src={require("../../assets/images/icon_05.png")}
                alt="Icon"
              />
              <h5>Initial Buying Amount</h5>
            </div>
            <h6>{tradedata.firstbuyAmount}</h6>
          </div>
          <div className="strategy_settings_div">
            <div>
              <img
                src={require("../../assets/images/icon_06.png")}
                alt="Icon"
              />
              <h5>No. of Averaging Calls</h5>
            </div>
            <h6>{tradedata.margincallLimit}</h6>
          </div>
          <div className="strategy_settings_div">
            <div>
              <img
                src={require("../../assets/images/icon_07.png")}
                alt="Icon"
              />
              <h5>Take Profit Ratio</h5>
            </div>
            <h6>{tradedata.takeprofitRatio}%</h6>
          </div>
          <div className="strategy_settings_div">
            <div>
              <img
                src={require("../../assets/images/icon_08.png")}
                alt="Icon"
              />
              <h5>Sell Pull-Back Rate</h5>
            </div>
            <h6>{tradedata.takeprofitCallback}%</h6>
          </div>
          {/* <div className="strategy_settings_div">
            <div>
              <img
                src={require("../../assets/images/icon_09.png")}
                alt="Icon"
              />
              <h5>Averaging Call Drop</h5>
            </div>
            <h6>{tradedata.AverageCallDrop}%</h6>
          </div> */}
          <div className="strategy_settings_div">
            <div>
              <img
                src={require("../../assets/images/icon_10.png")}
                alt="Icon"
              />
              <h5>Buy Pull-Back Rate</h5>
            </div>
            <h6>{tradedata.buyinCallback}%</h6>
          </div>
        </div>
        {(isEmpty(RunStatus) || RunStatus == "Pause") && (
          <button
            className="primary_btn primary_blue_btn"
            onClick={handlesave}
            disabled={loader}
          >
            {loader && <div class="loader"></div>}
            Start Trade
          </button>
        )}
        {!isEmpty(RunStatus) && RunStatus == "Start" && (
          <button
            className="primary_btn primary_blue_btn"
            onClick={handleStatusChange}
          >
            Pause Trade
          </button>
        )}
      </div>

      {/* Trade Strategy Settings */}
      {/* <div className="trade_strategy_settings" style={{display: "none"}}>
    <div className="card trade_card">
      <div className="card-header">
        <h2>Strategy Settings</h2>
        <p><i className="bi bi-x-circle strategy_settings_close"></i></p>
      </div>
      <div className="card-body">
        <div className="card_body_padding">
          <button className="primary_btn" id="add_new_strategy">Add New Strategy</button>
          <div className="p-0 search_inp_div">
            <input type="text" placeholder="Seach Strategy" className="form-control search_inp" />
            <i className="bi bi-search"></i>
          </div>
          <div className="saved_strategies_panel">
            <h2>Saved Strategies</h2>
            <button>Droid</button>
            <button>Gynoid</button>
            <button>Cyborg</button>
            <button>Cobot</button>
            <button>Drone</button>
          </div>
        </div>
      </div>
    </div>
  </div> */}

      {/* Trade New Strategy Settings */}
      <div className="trade_new_strategy_settings" style={{ display: "none" }}>
        <div className="card trade_card">
          <div className="card-header">
            <h2>Smart</h2>
            <p>
              <i className="bi bi-x-circle strategy_settings_close"></i>
            </p>
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="form-group">
                <label>Exchange</label>
                <select className="form-select">
                  <option value="1">Binance</option>
                  <option value="2">Kucoin</option>
                  <option value="3">Bitmart</option>
                </select>
              </div>
              {/* <div className="form-group">
            <label>Balance</label>
            <div className="input-group">
              <input type="text" className="form-control" />
              <div className="input-group-text">USDT</div>
            </div>                            
          </div> */}
              {/* <div className="alert info_alert alert-dismissible fade show mt-2" role="alert">
            <img src={require("../assets/images/info_icon.png")} alt="Icon"/>
            <p>The first buy in amount is calculated according to the currency pair, principle and trade unit.</p>                            
          </div> */}
              <div className="new_strategy_settings_panel">
                <div>
                  <label>Initial Buying Amount</label>
                  {/* <h6>15.0</h6> */}
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div>
                  <label className="label_large">Indicators</label>
                  <div className="d-flex align-items-center">
                    <span
                      className="edit_indicators"
                      data-bs-toggle="modal"
                      data-bs-target="#indicators_modal"
                    >
                      <i class="bi bi-pencil-fill"></i> Edit
                    </span>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label className="label_large">Custom Amount</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                </div>
                <div>
                  <label>No. of Averaging Calls</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div>
                  <label>Take Profit Ratio</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div>
                  <label>Sell Pull-Back Rate</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
                <div id="custom_amt_setup">
                  <label className="label_large">Custom Amount Setup</label>
                  <i class="bi bi-chevron-right"></i>
                </div>
                <div>
                  <label>Buy Pull-Back Rate</label>
                  <input
                    type="text"
                    className="form-control new_strategy_inp"
                  />
                </div>
              </div>
              <button className="primary_btn">Pre Defined Strategies</button>
              <button className="primary_btn primary_blue_btn">Start</button>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Amount Setup */}
      <div className="custom_amt_setup_panel" style={{ display: "none" }}>
        <div className="card trade_card">
          <div className="card-header custom_amt_setup_back">
            <h2>
              <i class="bi bi-chevron-left me-2"></i> Custom Amount Setup
            </h2>
          </div>
          <div className="card-body">
            <div className="card_body_padding">
              <div className="custom_amt_setup_div">
                <div className="row">
                  <div className="col-lg-6  col-md-6 col-6">
                    <label>Averaging call drop</label>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <label></label>
                  </div>
                </div>
                <div className="row">
                  <label>First Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>2nd Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>3rd Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>4th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>5th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>6th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label>7th Call</label>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">%</div>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-6 col-6">
                    <div class="input-group">
                      <input type="text" class="form-control" />
                      <div class="input-group-text inp_grp_width">USDT</div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="primary_btn custom_amt_setup_back">
                Save & Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Buy Modal PopUp */}
      <div
        class="modal fade primary_modal"
        id="buy_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="buy_modal_label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="buy_modal_label">
                Buy
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    onChange={handleBSChange}
                    value={buyAmount}
                    name="buyAmount"
                    className="form-control"
                  />
                  <div className="input-group-text">
                    {splitOne(params.pair, "second")}
                  </div>
                </div>
                {validateError && validateError.buyAmount && (
                  <p className="text-danger">{validateError.buyAmount}</p>
                )}
              </div>
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={handleSubmit}
                  disabled={buyLoader}
                >
                  {buyLoader && <div class="loader"></div>}
                  {!buyLoader && "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sell Modal PopUp */}
      <div
        class="modal fade primary_modal"
        id="sell_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="sell_modal_label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="sell_modal_label">
                Sell
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    onChange={handleBSChange}
                    value={sellAmount}
                    name="sellAmount"
                    className="form-control"
                  />
                  <div className="input-group-text">
                    {splitOne(params.pair, "first")}
                  </div>
                </div>
                {validateError && validateError.sellAmount && (
                  <p className="text-danger">{validateError.sellAmount}</p>
                )}
              </div>
              <div
                className="input-group-text"
                onClick={() => handlePercent("25")}
              >
                25%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("50")}
              >
                50%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("75")}
              >
                75%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("100")}
              >
                100%
              </div>{" "}
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={() => handleSellSubmit("sell")}
                  disabled={sellLoader}
                >
                  {sellLoader && <div class="loader"></div>}
                  {!sellLoader && "Sell"}
                </button>
              </div>
              <div className="text-center">
                <button
                  class="primary_btn primary_red_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={() => handlesellClear()}
                  disabled={clrSellLoad}
                >
                  {clrSellLoad && <div class="loader"></div>}
                  {!clrSellLoad && "Clear Sell"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={confModal}
        onHide={onHide}
        centered
        size="md"
        className="modal fade primary_modal"
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5">
            Are you sure want to Change Your Setting ?
          </h1>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <button
              class="primary_btn primary_blue_btn w-25"
              data-bs-dismiss="modal"
              onClick={onHide}
            >
              Cancel
            </button>{" "}
            {/* </div>
          <div className="text-center"> */}
            <button
              disabled={loadermodal}
              class="primary_btn primary_blue_btn w-25"
              data-bs-dismiss="modal"
              onClick={handleConfModal}
              name={modalName}
            >
              {loadermodal && <div class="">Loading ...</div>}
              {!loadermodal && "OK"}{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
