// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {},
        passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/g;
    if (isEmpty(value.password)) {
        errors.password = "Please Enter Password"
    } else if (value.password.length < 6) {
        errors.password = "Minimum 6 and Maximum 18"
    }
    else if (value.password.length > 18) {
        errors.password = "Minimum 6 and Maximum 18"
    } else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character"
    }


    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Please Enter Confirm Password"
    } else if (!(isEmpty(value.confirmPassword)) && value.password != value.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password Must Match"
    }


    return errors;
}

export default validation;