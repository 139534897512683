import React, { useEffect, useState, useContext } from "react";

// import context
import SocketContext from "../Context/SocketContext";

// import action
import { getAnnouncement } from "../../actions/Bottrade";
import isEmpty from "../../lib/isEmpty";
export default function Announcement() {
  const socketContext = useContext(SocketContext);

  // state
  const [announceData, setAnnData] = useState();

  // function
  const fetchData = async () => {
    const { status, result } = await getAnnouncement();
    if (status == "success") {
      setAnnData(result);
    }
  };
  useEffect(() => {
    fetchData();
    socketContext.socket.on("announcement", (result) => {
      setAnnData(result);
    });
  }, []);
  return (
    !isEmpty(announceData) && (
      <>
        <div
          className="alert info_alert alert-dismissible fade show"
          role="alert"
        >
          <p>You have {announceData.length} announcement from UGAIN team</p>
        </div>
      </>
    )
  );
}
