import React, { Component } from 'react';

export class CameraFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {take: "none",label:"Take Photo !"};
      }

    /**
     * Processes available devices and identifies one by the label
     * @memberof CameraFeed
     * @instance
     */

    
    processDevices(devices) {
        devices.forEach(device => {
            console.log(device.label);
            this.setDevice(device);
        });
    }

    

    /**
     * Sets the active device and starts playing the feed
     * @memberof CameraFeed
     * @instance
     */
    async setDevice(device) {
        const { deviceId } = device;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } });
        this.videoPlayer.srcObject = stream;
        this.videoPlayer.play();
    }

    /**
     * On mount, grab the users connected devices and process them
     * @memberof CameraFeed
     * @instance
     * @override
     */
    async componentDidMount() {
        const cameras = await navigator.mediaDevices.enumerateDevices();
        this.processDevices(cameras);
    }

    /**
     * Handles taking a still image from the video feed on the camera
     * @memberof CameraFeed
     * @instance
     */
    takePhoto = () => {
     this.setState({take:"block",label:"ReTake Photo !"})
        const { sendFile } = this.props;
        const context = this.canvas.getContext('2d');
        context.drawImage(this.videoPlayer, 0, 0, 680, 360);
        this.canvas.toBlob(sendFile);

    };

    componentDidUpdate() {
       
      }


    render() {
        return (
            <>
                <div className="col-lg-5">
                <div className="form-group captureFrame">
                <video   className="img-fluid" ref={ref => (this.videoPlayer = ref)} width="250" height="215" />

                  {/* Developer Note: After clicking below image need to show camera preview screen instead of below image tag*/}
                  {/* <img src={require("../../assets/images/live-capture-screen.jpg")} className="img-fluid" alt="" /> */}
                  {/* Developer Note: Insert screen capture vide code here*/}
                </div>                
                <button className="primary_btn w-auto px-5" onClick={this.takePhoto}>{this.state.label}</button>

            </div>
            <div className="col-lg-7">
                <ul className="noteList">
                  <li>- Look straight at the camera</li>
                  <li>- Light or neutral background</li>
                  <li>- No Sunglasses, Hat or a Headband</li>
                </ul>
              </div>
            <div>&nbsp;</div>
             <div className="c-camera-feed__stage">
                <div className="row img-fluid">
                     <canvas  style={{display:this.state.take}} width="680" height="360" ref={ref => (this.canvas = ref)} /> 
                     </div>
                </div> 
    

                 
                </>
        );
    }
}
