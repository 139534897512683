import React, { useEffect } from "react";
import HeaderTrade from "../components/HeaderTrade.js";
import Footer from "../components/Footer.js";
import { Scrollbars } from "react-custom-scrollbars";
import DataTable from "react-data-table-component";
import $ from "jquery";
import ShareModal from "../components/ShareModal";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const columns = [
  {
    name: "Pair",
    selector: (row) => row.pair,
  },
  {
    name: "Created Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "Unrealized PNL",
    selector: (row) => row.pl,
    sortable: true,
  },
  {
    name: "Current Market Price (CMP)",
    selector: (row) => row.cmp,
    sortable: true,
    width: "200px",
  },
  {
    name: "Entry Price",
    selector: (row) => row.ep,
    sortable: true,
  },
  {
    name: "Current Status",
    selector: (row) => row.status,
  },
];

const data = [
  {
    pair: "BTC/USDT",
    id: 1,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 2,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "BTC/USDT",
    id: 3,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 4,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "BTC/USDT",
    id: 5,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 6,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "BTC/USDT",
    id: 7,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 8,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "BTC/USDT",
    id: 9,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 10,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        {" "}
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 11,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        {" "}
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "BTC/USDT",
    id: 12,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 13,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        {" "}
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "BTC/USDT",
    id: 14,
    date: "19-09-2022 18:56",
    pl: (
      <div>
        <span className="greenText">+2.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "450.04 USDT",
    ep: "250.65 USDT",
    status: <span className="greenText">Open</span>,
  },
  {
    pair: "ETH/USDT",
    id: 15,
    date: "25-09-2022 18:56",
    pl: (
      <div>
        {" "}
        <span className="redText">-12.05%</span>
        <a href="#" data-bs-toggle="modal" data-bs-target="#share_modal">
          {" "}
          <img
            src={require("../assets/images/shareIcon.png")}
            className="img-fluid share_icon"
            alt=""
          />
        </a>
      </div>
    ),
    cmp: "24.01 USDT",
    ep: "102.88 USDT",
    status: <span className="greenText">Open</span>,
  },
];

export default function TradeZap() {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // $("#add_new_strategy").click(function() {
    //   $(".trade_new_strategy_settings").show();
    //   $(".trade_main_settings").hide();
    // });
    // $("#custom_amt_setup").click(function() {
    //   $(".custom_amt_setup_panel").show();
    //   $(".trade_new_strategy_settings").hide();
    // });
    // $(".custom_amt_setup_back").click(function() {
    //   $(".custom_amt_setup_panel").hide();
    //   $(".trade_new_strategy_settings").show();
    // });
    // $(".strategy_settings_close").click(function() {
    //   $(".trade_main_settings").show();
    //   $(".trade_strategy_settings").hide();
    //   $(".trade_new_strategy_settings").hide();
    // });
  }
  return (
    <main>
      <ScrollToTopOnMount />
      <HeaderTrade />
      <div className="trade_wrapper">
        <div className="container-fluid">
          <div className="trade_topbar">
            <div>
              <img
                src={require("../assets/images/btc.png")}
                alt="Icon"
                className="img-fluid"
              />
              <h3>
                BTC<span>/USDT</span>
              </h3>
              {/* <p>Exchange Balance</p>
                  <h3 className="greenText">$2589.64 USDT</h3>
                  <h6>₹ 206368.97</h6> */}
            </div>
            <div>
              <p>Investment (USDT)</p>
              <h3>$ 299.94</h3>
            </div>
            <div>
              <p>Quantity (USDT)</p>
              <h3>$ 616.196</h3>
            </div>
            <div>
              <p>Average Price (USDT)</p>
              <h3>$ 350.54</h3>
            </div>
            <div>
              <p>Current Price (USDT)</p>
              <h3>$ 4354.21</h3>
            </div>
            <div>
              <p>No. of Averaging Calls</p>
              <h3>1.0</h3>
            </div>
            <div>
              <p>Return Rate</p>
              <h3 className="redText">-10.5721%</h3>
            </div>
          </div>
          <div className="trade_main">
            <div className="trade_main_left">
              <div className="trade_main_left_top">
                <div className="coin_details">
                  <div className="card trade_card">
                    <div className="card-header">
                      <h2>Coins</h2>
                    </div>
                    <div className="card-body">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="coin_one-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_one"
                            type="button"
                            role="tab"
                            aria-controls="coin_one"
                            aria-selected="true"
                          >
                            USDT
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="coin_two-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_two"
                            type="button"
                            role="tab"
                            aria-controls="coin_two"
                            aria-selected="false"
                          >
                            BTC
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="coin_three-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_three"
                            type="button"
                            role="tab"
                            aria-controls="coin_three"
                            aria-selected="false"
                          >
                            ETH
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="search_inp_div">
                          <input
                            type="text"
                            placeholder="Seach Pair"
                            className="form-control search_inp"
                          />
                          <i className="bi bi-search"></i>
                        </div>
                        <div
                          className="tab-pane fade show active"
                          id="coin_one"
                          role="tabpanel"
                          aria-labelledby="coin_one-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 328 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      backgroundColor: "#191d42",
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#2b5af6",
                                    }}
                                  />
                                )}
                              >
                                <div className="pair_table_bodyrow active">
                                  <div className="pair_table_bodyrow_top">
                                    <div>
                                      <img
                                        src={require("../assets/images/btc.png")}
                                        alt="Icon"
                                        className="img-fluid"
                                      />
                                      <h3>
                                        BTC<span>/USDT</span>
                                      </h3>
                                    </div>
                                    <div>
                                      <span className="badge bg_blue trade_type">
                                        Cycle
                                      </span>
                                      <i className="bi bi-recycle"></i>
                                    </div>
                                    <div className="badge bg_green per_change">
                                      0.2647%
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom">
                                    <div className="pair_table_bodyrow_bottom_left">
                                      <div>
                                        <label>Quantity:</label>
                                        <label>718.0</label>
                                      </div>
                                      <div>
                                        <label>Price:</label>
                                        <label>0.2063</label>
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom_right">
                                      <div>
                                        <label>Profit($):</label>
                                        <label className="greenText">
                                          0.3894
                                        </label>
                                      </div>
                                      <div>
                                        <label>Chg 24H(%):</label>
                                        <label className="greenText">
                                          0.0003
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="pair_table_bodyrow">
                                  <div className="pair_table_bodyrow_top">
                                    <div>
                                      <img
                                        src={require("../assets/images/eth.png")}
                                        alt="Icon"
                                        className="img-fluid"
                                      />
                                      <h3>
                                        ETH<span>/USDT</span>
                                      </h3>
                                    </div>
                                    <div>
                                      <span className="badge bg_yellow trade_type">
                                        One shot
                                      </span>
                                      <i className="bi bi-recycle"></i>
                                    </div>
                                    <div className="badge bg_red per_change">
                                      -50.22%
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom">
                                    <div className="pair_table_bodyrow_bottom_left">
                                      <div>
                                        <label>Quantity:</label>
                                        <label>718.0</label>
                                      </div>
                                      <div>
                                        <label>Price:</label>
                                        <label>0.2063</label>
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom_right">
                                      <div>
                                        <label>Profit($):</label>
                                        <label className="redText">
                                          0.3894
                                        </label>
                                      </div>
                                      <div>
                                        <label>Chg 24H(%):</label>
                                        <label className="redText">
                                          0.0003
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="pair_table_bodyrow">
                                  <div className="pair_table_bodyrow_top">
                                    <div>
                                      <img
                                        src={require("../assets/images/ada.png")}
                                        alt="Icon"
                                        className="img-fluid"
                                      />
                                      <h3>
                                        ADA<span>/USDT</span>
                                      </h3>
                                    </div>
                                    <div>
                                      <span className="badge bg_blue trade_type">
                                        Cycle
                                      </span>
                                      <i className="bi bi-recycle"></i>
                                    </div>
                                    <div className="badge bg_red per_change">
                                      -12.85%
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom">
                                    <div className="pair_table_bodyrow_bottom_left">
                                      <div>
                                        <label>Quantity:</label>
                                        <label>718.0</label>
                                      </div>
                                      <div>
                                        <label>Price:</label>
                                        <label>0.2063</label>
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom_right">
                                      <div>
                                        <label>Profit($):</label>
                                        <label className="">---</label>
                                      </div>
                                      <div>
                                        <label>Chg 24H(%):</label>
                                        <label className="redText">
                                          0.0003
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="pair_table_bodyrow">
                                  <div className="pair_table_bodyrow_top">
                                    <div>
                                      <img
                                        src={require("../assets/images/dash.png")}
                                        alt="Icon"
                                        className="img-fluid"
                                      />
                                      <h3>
                                        DASH<span>/USDT</span>
                                      </h3>
                                    </div>
                                    <div>
                                      <span className="badge bg_yellow trade_type">
                                        One shot
                                      </span>
                                      <i className="bi bi-recycle"></i>
                                    </div>
                                    <div className="badge bg_green per_change">
                                      0.2647%
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom">
                                    <div className="pair_table_bodyrow_bottom_left">
                                      <div>
                                        <label>Quantity:</label>
                                        <label>718.0</label>
                                      </div>
                                      <div>
                                        <label>Price:</label>
                                        <label>0.2063</label>
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom_right">
                                      <div>
                                        <label>Profit($):</label>
                                        <label className="greenText">
                                          0.3894
                                        </label>
                                      </div>
                                      <div>
                                        <label>Chg 24H(%):</label>
                                        <label className="greenText">
                                          0.0003
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="pair_table_bodyrow">
                                  <div className="pair_table_bodyrow_top">
                                    <div>
                                      <img
                                        src={require("../assets/images/sol.png")}
                                        alt="Icon"
                                        className="img-fluid"
                                      />
                                      <h3>
                                        SOL<span>/USDT</span>
                                      </h3>
                                    </div>
                                    <div>
                                      <span className="badge bg_yellow trade_type">
                                        One shot
                                      </span>
                                      <i className="bi bi-recycle"></i>
                                    </div>
                                    <div className="badge bg_green per_change">
                                      0.2647%
                                    </div>
                                  </div>
                                  <div className="pair_table_bodyrow_bottom">
                                    <div className="pair_table_bodyrow_bottom_left">
                                      <div>
                                        <label>Quantity:</label>
                                        <label>718.0</label>
                                      </div>
                                      <div>
                                        <label>Price:</label>
                                        <label>0.2063</label>
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom_right">
                                      <div>
                                        <label>Profit($):</label>
                                        <label className="greenText">
                                          0.3894
                                        </label>
                                      </div>
                                      <div>
                                        <label>Chg 24H(%):</label>
                                        <label className="greenText">
                                          0.0003
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coin_two"
                          role="tabpanel"
                          aria-labelledby="coin_two-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 328 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      backgroundColor: "#191d42",
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#2b5af6",
                                    }}
                                  />
                                )}
                              ></Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coin_three"
                          role="tabpanel"
                          aria-labelledby="coin_three-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 328 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      backgroundColor: "#191d42",
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#2b5af6",
                                    }}
                                  />
                                )}
                              ></Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div className="batch_settings">
                          {/* <p>Binance <i className="bi bi-eye"></i></p> */}
                          <p
                            data-bs-toggle="modal"
                            data-bs-target="#batch_setting_modal"
                          >
                            <i className="bi bi-arrow-clockwise"></i> Batch
                            Setting
                          </p>
                        </div>
                        {/* <div className="choose_pair">
                              <img src={require("../assets/images/usdt.png")} alt="Icon" className="img-fluid" />
                              <p>USDT: 1</p>
                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="trade_chart">
                  <img
                    src={require("../assets/images/trade_chart.png")}
                    alt="chart"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="trade_main_left_bottom">
                <div className="card trade_card trade_history_card">
                  <div className="card-header">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Running
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Trade History
                        </button>
                      </li>
                    </ul>
                    <h2>
                      <img
                        src={require("../assets/images/icon_zap.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                      Zap
                    </h2>
                  </div>
                  <div className="card-body">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="primary_table">
                          <DataTable columns={columns} data={data} pagination />
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <p className="text-center mt-5">No Records Found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="trade_main_right">
              {/* Trade Main Settings */}
              <div className="trade_main_settings">
                <div className="card trade_card">
                  <div className="card-header">
                    <h2>
                      <img
                        src={require("../assets/images/icon_zap.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                      Zap
                    </h2>
                    <p>
                      <i className="bi bi-x-circle strategy_settings_close"></i>
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="card_body_padding">
                      <div className="form-group">
                        <label>Exchange</label>
                        <select className="form-select">
                          <option value="1">Binance</option>
                          <option value="2">Kucoin</option>
                          <option value="3">Bitmart</option>
                        </select>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <span className="f_16">USDT Balance:</span>
                          <span className="f_16">52.2085</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <span className="f_16">BTC Balance:</span>
                          <span className="f_16">8.0256</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <span className="f_16">ETH Balance:</span>
                          <span className="f_16">10.1014</span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Amount</label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <div className="input-group-text">USDT</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <span className="f_16">Net Amount</span>
                          <span className="f_16">0.00 BTC</span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Stop Loss</label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <div className="input-group-text select-input-group">
                            <select className="form-select">
                              <option value="1" selected>USDT</option>
                              <option value="2">%</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Take Profit</label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <div className="input-group-text select-input-group">
                            <select className="form-select">
                              <option value="1" selected>USDT</option>
                              <option value="2">%</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Sell Pull Back Ratio</label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <div className="input-group-text">%</div>
                        </div>
                      </div>
                      <div>
                        {/* <button class="primary_btn">Pre Defined Strategies</button> */}
                        <button class="primary_btn primary_blue_btn">
                          Start
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Trade Strategy Settings */}
              {/* <div className="trade_strategy_settings" style={{display: "none"}}>
                    <div className="card trade_card">
                      <div className="card-header">
                        <h2>Strategy Settings</h2>
                        <p><i className="bi bi-x-circle strategy_settings_close"></i></p>
                      </div>
                      <div className="card-body">
                        <div className="card_body_padding">
                          <button className="primary_btn" id="add_new_strategy">Add New Strategy</button>
                          <div className="p-0 search_inp_div">
                            <input type="text" placeholder="Seach Strategy" className="form-control search_inp" />
                            <i className="bi bi-search"></i>
                          </div>
                          <div className="saved_strategies_panel">
                            <h2>Saved Strategies</h2>
                            <button>Droid</button>
                            <button>Gynoid</button>
                            <button>Cyborg</button>
                            <button>Cobot</button>
                            <button>Drone</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

              {/* Trade New Strategy Settings */}
              <div
                className="trade_new_strategy_settings"
                style={{ display: "none" }}
              >
                <div className="card trade_card">
                  <div className="card-header">
                    <h2>Smart</h2>
                    <p>
                      <i className="bi bi-x-circle strategy_settings_close"></i>
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="card_body_padding">
                      <div className="form-group">
                        <label>Exchange</label>
                        <select className="form-select">
                          <option value="1">Binance</option>
                          <option value="2">Kraken</option>
                          <option value="3">Huobi</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Balance</label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <div className="input-group-text">USDT</div>
                        </div>
                      </div>
                      {/* <div className="alert info_alert alert-dismissible fade show mt-2" role="alert">
                            <img src={require("../assets/images/info_icon.png")} alt="Icon"/>
                            <p>The first buy in amount is calculated according to the currency pair, principle and trade unit.</p>                            
                          </div> */}
                      <div className="new_strategy_settings_panel">
                        <div>
                          <label>Initial Buying Amount</label>
                          {/* <h6>15.0</h6> */}
                          <input
                            type="text"
                            className="form-control new_strategy_inp"
                          />
                        </div>
                        <div>
                          <label className="label_large">Indicators</label>
                          <div className="d-flex align-items-center">
                            <span
                              className="edit_indicators"
                              data-bs-toggle="modal"
                              data-bs-target="#indicators_modal"
                            >
                              <i class="bi bi-pencil-fill"></i> Edit
                            </span>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="label_large">Custom Amount</label>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                            />
                          </div>
                        </div>
                        <div>
                          <label>No. of Averaging Calls</label>
                          <input
                            type="text"
                            className="form-control new_strategy_inp"
                          />
                        </div>
                        <div>
                          <label>Take Profit Ratio</label>
                          <input
                            type="text"
                            className="form-control new_strategy_inp"
                          />
                        </div>
                        <div>
                          <label>Sell Pull-Back Rate</label>
                          <input
                            type="text"
                            className="form-control new_strategy_inp"
                          />
                        </div>
                        <div id="custom_amt_setup">
                          <label className="label_large">
                            Custom Amount Setup
                          </label>
                          <i class="bi bi-chevron-right"></i>
                        </div>
                        <div>
                          <label>Buy Pull-Back Rate</label>
                          <input
                            type="text"
                            className="form-control new_strategy_inp"
                          />
                        </div>
                      </div>
                      <button className="primary_btn">
                        Pre Defined Strategies
                      </button>
                      <button className="primary_btn primary_blue_btn">
                        Start
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Custom Amount Setup */}
              <div
                className="custom_amt_setup_panel"
                style={{ display: "none" }}
              >
                <div className="card trade_card">
                  <div className="card-header custom_amt_setup_back">
                    <h2>
                      <i class="bi bi-chevron-left me-2"></i> Custom Amount
                      Setup
                    </h2>
                  </div>
                  <div className="card-body">
                    <div className="card_body_padding">
                      <div className="custom_amt_setup_div">
                        <div className="row">
                          <div className="col-lg-6  col-md-6 col-6">
                            <label>Averaging call drop</label>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <label>Amount</label>
                          </div>
                        </div>
                        <div className="row">
                          <label>First Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label>2nd Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label>3rd Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label>4th Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label>5th Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label>6th Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label>7th Call</label>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                %
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  col-md-6 col-6">
                            <div class="input-group">
                              <input type="text" class="form-control" />
                              <div class="input-group-text inp_grp_width">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="primary_btn custom_amt_setup_back">
                        Save & Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="alert info_alert alert-dismissible fade show"
            role="alert"
          >
            <img src={require("../assets/images/info_icon.png")} alt="Icon" />
            <p>
              All information on this site is for educational purpose only, do
              not consider it as an investment advice.
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <Footer />

      {/* Strategy Modal */}
      <div
        class="modal fade primary_modal"
        id="predefined_strategy_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="predefined_strategy_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="predefined_strategy_modalLabel">
                Pre-Defined Strategies
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="p-0 search_inp_div">
                <input
                  type="text"
                  placeholder="Seach Strategy"
                  className="form-control search_inp"
                />
                <i className="bi bi-search"></i>
              </div>
              <div className="saved_strategies_panel">
                <button>Droid</button>
                <button>Gynoid</button>
                <button>Cyborg</button>
                <button>Cobot</button>
                <button>Drone</button>
              </div>
              <button
                className="primary_btn"
                data-bs-dismiss="modal"
                id="add_new_strategy"
              >
                Add New Strategy
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Indicators Modal */}
      <div
        class="modal fade primary_modal"
        id="indicators_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="indicators_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="indicators_modalLabel">
                Indicator Strategies
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h5 className="text-center">No Indicators Added</h5>
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-toggle="modal"
                  data-bs-target="#add_indicators_modal"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Indicators Modal */}
      <div
        class="modal fade primary_modal"
        id="add_indicators_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="add_indicators_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
                Select
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="accordion primary_accordian" id="indicators_list">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      RSI-2
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#indicators_list"
                  >
                    <div class="accordion-body">
                      <div className="indicators_panel">
                        <div>
                          <label>Candle</label>
                          <select className="form-select indicators_inp">
                            <option value="1">1 min</option>
                            <option value="2">10 min</option>
                            <option value="3">30 min</option>
                          </select>
                        </div>
                        <div>
                          <label>Condition</label>
                          <select className="form-select indicators_inp">
                            <option value="1">Less than</option>
                            <option value="2">Greater than</option>
                          </select>
                        </div>
                        <div>
                          <label>Value</label>
                          <input
                            type="text"
                            class="form-control indicators_inp"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button class="primary_btn primary_blue_btn w-50">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      RSI-7
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#indicators_list"
                  >
                    <div class="accordion-body">
                      <div className="indicators_panel">
                        <div>
                          <label>Candle</label>
                          <select className="form-select indicators_inp">
                            <option value="1">1 min</option>
                            <option value="2">10 min</option>
                            <option value="3">30 min</option>
                          </select>
                        </div>
                        <div>
                          <label>Condition</label>
                          <select className="form-select indicators_inp">
                            <option value="1">Less than</option>
                            <option value="2">Greater than</option>
                          </select>
                        </div>
                        <div>
                          <label>Value</label>
                          <input
                            type="text"
                            class="form-control indicators_inp"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button class="primary_btn primary_blue_btn w-50">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      RSI-14
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#indicators_list"
                  >
                    <div class="accordion-body">
                      <div className="indicators_panel">
                        <div>
                          <label>Candle</label>
                          <select className="form-select indicators_inp">
                            <option value="1">1 min</option>
                            <option value="2">10 min</option>
                            <option value="3">30 min</option>
                          </select>
                        </div>
                        <div>
                          <label>Condition</label>
                          <select className="form-select indicators_inp">
                            <option value="1">Less than</option>
                            <option value="2">Greater than</option>
                          </select>
                        </div>
                        <div>
                          <label>Value</label>
                          <input
                            type="text"
                            class="form-control indicators_inp"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button class="primary_btn primary_blue_btn w-50">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      BB-2
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#indicators_list"
                  >
                    <div class="accordion-body">
                      <div className="indicators_panel">
                        <div>
                          <label>Candle</label>
                          <select className="form-select indicators_inp">
                            <option value="1">1 min</option>
                            <option value="2">10 min</option>
                            <option value="3">30 min</option>
                          </select>
                        </div>
                        <div>
                          <label>Condition</label>
                          <select className="form-select indicators_inp">
                            <option value="1">Less than</option>
                            <option value="2">Greater than</option>
                          </select>
                        </div>
                        <div>
                          <label>Value</label>
                          <input
                            type="text"
                            class="form-control indicators_inp"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button class="primary_btn primary_blue_btn w-50">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Batch Settings Modal */}
      <div
        class="modal fade primary_modal"
        id="batch_setting_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="batch_setting_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="batch_setting_modalLabel">
                Batch Setting
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="batch_settings_modal_panel">
                <div className="coin_details">
                  <div className="card trade_card">
                    <div className="card-header">
                      <h2>Coins</h2>
                    </div>
                    <div className="card-body">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="coin_one-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_one"
                            type="button"
                            role="tab"
                            aria-controls="coin_one"
                            aria-selected="true"
                          >
                            USDT
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="coin_two-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_two"
                            type="button"
                            role="tab"
                            aria-controls="coin_two"
                            aria-selected="false"
                          >
                            BTC
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="coin_three-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#coin_three"
                            type="button"
                            role="tab"
                            aria-controls="coin_three"
                            aria-selected="false"
                          >
                            ETH
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="search_inp_div">
                          <input
                            type="text"
                            placeholder="Seach Pair"
                            className="form-control search_inp"
                          />
                          <i className="bi bi-search"></i>
                        </div>
                        <div
                          className="tab-pane fade show active"
                          id="coin_one"
                          role="tabpanel"
                          aria-labelledby="coin_one-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 300 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      backgroundColor: "#191d42",
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#2b5af6",
                                    }}
                                  />
                                )}
                              >
                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/btc.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          BTC<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_blue trade_type">
                                          Cycle
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_green per_change">
                                        0.2647%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="greenText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="greenText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/eth.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          ETH<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_yellow trade_type">
                                          One shot
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_red per_change">
                                        -50.22%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="redText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="redText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/ada.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          ADA<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_blue trade_type">
                                          Cycle
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_red per_change">
                                        -12.85%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="redText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="redText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/dash.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          DASH<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_yellow trade_type">
                                          One shot
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_green per_change">
                                        0.2647%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="greenText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="greenText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <div className="pair_table_bodyrow">
                                    <div className="pair_table_bodyrow_top">
                                      <div>
                                        <img
                                          src={require("../assets/images/sol.png")}
                                          alt="Icon"
                                          className="img-fluid"
                                        />
                                        <h3>
                                          SOL<span>/USDT</span>
                                        </h3>
                                      </div>
                                      <div>
                                        <span className="badge bg_yellow trade_type">
                                          One shot
                                        </span>
                                        <i className="bi bi-recycle"></i>
                                      </div>
                                      <div className="badge bg_green per_change">
                                        0.2647%
                                      </div>
                                    </div>
                                    <div className="pair_table_bodyrow_bottom">
                                      <div className="pair_table_bodyrow_bottom_left">
                                        <div>
                                          <label>Quantity:</label>
                                          <label>718.0</label>
                                        </div>
                                        <div>
                                          <label>Price:</label>
                                          <label>0.2063</label>
                                        </div>
                                      </div>
                                      <div className="pair_table_bodyrow_bottom_right">
                                        <div>
                                          <label>Profit($):</label>
                                          <label className="greenText">
                                            0.3894
                                          </label>
                                        </div>
                                        <div>
                                          <label>Chg 24H(%):</label>
                                          <label className="greenText">
                                            0.0003
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coin_two"
                          role="tabpanel"
                          aria-labelledby="coin_two-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 300 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      backgroundColor: "#191d42",
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#2b5af6",
                                    }}
                                  />
                                )}
                              ></Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="coin_three"
                          role="tabpanel"
                          aria-labelledby="coin_three-tab"
                        >
                          <div className="pair_table">
                            <div className="pair_table_head">
                              <div>Qty</div>
                              <div>Price</div>
                              <div>Profit</div>
                              <div>24H Chg</div>
                            </div>
                            <div className="pair_table_body">
                              <Scrollbars
                                style={{ height: 300 }}
                                renderTrackVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      backgroundColor: "#191d42",
                                      right: "2px",
                                      bottom: "2px",
                                      top: "2px",
                                      borderRadius: "10px",
                                      width: "5px",
                                    }}
                                  />
                                )}
                                renderThumbVertical={({ style, ...props }) => (
                                  <div
                                    {...props}
                                    style={{
                                      ...style,
                                      width: "5px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                                      backgroundColor: "#2b5af6",
                                    }}
                                  />
                                )}
                              ></Scrollbars>
                            </div>
                          </div>
                        </div>
                        <div className="batch_settings">
                          {/* <p>Binance <i className="bi bi-eye"></i></p> */}
                          <p
                            data-bs-toggle="modal"
                            data-bs-target="#batch_setting_modal"
                          >
                            <i className="bi bi-arrow-clockwise"></i> Batch
                            Setting
                          </p>
                        </div>
                        {/* <div className="choose_pair">
                              <img src={require("../assets/images/usdt.png")} alt="Icon" className="img-fluid" />
                              <p>USDT: 1</p>
                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="batch_settings_modal_right">
                  <div className="operate_settings">
                    <h3>Operate only for selected coin</h3>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        All
                      </label>
                    </div>
                  </div>
                  <div className="batch_settings_btn_grp">
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_01.png")}
                          alt="Icon"
                        />
                        Trade Setting
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_02.png")}
                          alt="Icon"
                        />
                        Sell at CMP
                      </button>
                    </div>
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_03.png")}
                          alt="Icon"
                        />
                        Start
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_04.png")}
                          alt="Icon"
                        />
                        Pause
                      </button>
                    </div>
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_05.png")}
                          alt="Icon"
                        />
                        One-Shot
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_06.png")}
                          alt="Icon"
                        />
                        Cycle
                      </button>
                    </div>
                    <div>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_07.png")}
                          alt="Icon"
                        />
                        Open Averaging
                      </button>
                      <button>
                        <img
                          src={require("../assets/images/batch_setting_icon_08.png")}
                          alt="Icon"
                        />
                        Stop Averaging
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal */}
      <ShareModal />
    </main>
  );
}
