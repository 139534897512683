import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import validation from "./validation";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { UploadIndDepoist, adminbank } from "../../actions/Deposit";
import {
  currencyDetails,
  getTrans,
  adminwalletlist,
} from "../../actions/Wallet";

//import config
import config from "../../config/index";

// import validation from "./validation";
const initialFormValue = {
  amount: "",
  depositType: "",
  tokenType: "",
  bank: "",
  docName: "",
  docImage: "",
  nationality: "indian",
};
export default function DepositWallet(props) {
  const dispatch = useDispatch();
  const { data, fetchTransactionHistory } = props;

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [currencyData, setCurrencyData] = useState({});
  const [transData, setTransData] = useState({});
  const [loader, setLoader] = useState(false);

  const {
    amount,
    depositType,
    tokenType,
    bank,
    docName,
    docImage,
    nationality,
  } = formValue;

  // state
  const [BankDetails, setBankDetails] = useState();

  const [BankList, SetBankList] = useState([]);
  const [adminwallet, setadminwallet] = useState();
  const [WalletDetails, setWalletDetails] = useState({});

  const fetchadmibank = async () => {
    const { status, loading, message, error, result } = await adminbank();
    // console.log(result, "resultresultresult");
    SetBankList(result);
  };
  // let datas = BankList.concat(adminwallet);
  // console.log(datas, "-------data");
  const fetchadminwallet = async () => {
    const { status, loading, message, error, result } = await adminwalletlist();
    // console.log(result, "----------------result");
    let selectedwallet = result.find((el) => el.tokenType == tokenType);
    setWalletDetails(selectedwallet);

    setadminwallet(result);
  };
  // console.log(BankList, "------------------BankList");
  const fetchCurrency = async () => {
    const { status, loading, message, error, result } = await currencyDetails();
    if (status == "success") {
      result &&
        result.map((item, i) => {
          if (item.coin == "INR") {
            setCurrencyData(item);
          }
        });
    }
  };

  const fetchTrans = async () => {
    const { status, loading, message, error, result } = await getTrans();
    if (status == "success") {
      setTransData(result);
    }
  };
  useEffect(() => {
    fetchadmibank();
    fetchCurrency();
    fetchadminwallet();
    fetchTrans();
  }, []);

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, "-------------74", value);
    if (name == "depositType" && value == "Subscription") {
      let formData = {
        ...formValue,
        ...{ [name]: value, amount: currencyData.Subscription },
      };
      setFormValue(formData);
      return;
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleChangeBank = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    let selectedbank = BankList.find((el) => el._id == value);
    setBankDetails(selectedbank);

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleChangetokentype = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    let splitValue = value.split("-");

    let selectedwallet = adminwallet.find(
      (el) =>
        el.tokenType == splitValue[0] && el.currencySymbol == splitValue[1]
    );
    setWalletDetails(selectedwallet);

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    // console.log(files, "Filesssss");
    let formData = {
      ...formValue,
      ...{ [name]: files[0], docName: files[0].name },
    };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  console.log(tokenType);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let validationError = validation(formValue);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      const formData = new FormData();
      let splitValue = tokenType.split("-");
      // console.log(splitValue, "-----------splitValue");

      formData.append("depositType", depositType);
      formData.append("bank", bank);
      formData.append("tokenType", splitValue[0] ? splitValue[0] : "");
      formData.append("currencySymbol", splitValue[1] ? splitValue[1] : "");
      formData.append("amount", amount);
      formData.append("docName", docName);
      formData.append("docImage", docImage);

      const { status, loading, message, error } = await UploadIndDepoist(
        formData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        toastAlert("success", message, "kycsection");
        setFormValue(initialFormValue);
        setBankDetails();
        fetchTransactionHistory();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  // console.log(transData, "_)))))transData");
  // console.log(formValue, "-----------------------formValue");
  return (
    <div className="userContent mt-4">
      <h2>Upload Deposit Proof</h2>
      <div className="walletForm">
        <div className="form-group">
          <label>Purpose</label>
          <select
            name="depositType"
            onChange={handleChange}
            className="form-select"
            value={depositType}
          >
            <option value="">Select Purpose</option>
            {transData == "subscribe" && (
              <option selected value="Top-Up">
                Top-up
              </option>
            )}

            {transData == "unsubscribe" && (
              <option selected value="Subscription">
                Subscription
              </option>
            )}
          </select>
          {validateError && validateError.depositType && (
            <p className="text-danger">{validateError.depositType}</p>
          )}
        </div>
        <div className="form-group">
          <label>Amount</label>
          <div className="input-group mb-3">
            <input
              type="text"
              name="amount"
              value={amount}
              onChange={handleChange}
              className="form-control"
              disabled={depositType == "Subscription" && "true"}
            />
            <div className="input-group-text">INR</div>
          </div>
          {validateError && validateError.amount && (
            <p className="text-danger">{validateError.amount}</p>
          )}
        </div>
        {isEmpty(bank) && (
          <>
            <div className="form-group">
              <label>Coin</label>
              <select
                onChange={handleChangetokentype}
                name="tokenType"
                value={tokenType}
                className="form-select"
              >
                <option value="">Select</option>
                {adminwallet &&
                  adminwallet.length > 0 &&
                  adminwallet.map((item, key) => {
                    return (
                      <>
                        <option
                          key={key}
                          value={item.tokenType + "-" + item.currencySymbol}
                        >
                          {item.currencySymbol}{" "}
                          {item.currencySymbol == "USDT"
                            ? " - " + item.tokenType.toUpperCase()
                            : ""}
                          {/* {item.currencySymbol} */}
                        </option>
                      </>
                    );
                  })}
                {/* <option selected value="trc20">
              USDT TRC 20
            </option>
            <option selected value="bep20">
              BSC BEP 20
            </option> */}
              </select>
            </div>
            {!isEmpty(tokenType) && (
              <>
                <div className="qrCodeBox">
                  <QRCode
                    size="300"
                    className="img-fluid"
                    value={WalletDetails.address}
                  />

                  {/* <img
              src={require("../../assets/images/qr-code.png")}
              className="img-fluid"
              alt=""
            /> */}
                </div>
                <div className="copyCodeBox">
                  <p>{WalletDetails.address}</p>
                  <CopyToClipboard
                    text={WalletDetails.address}
                    onCopy={() => {
                      toastAlert("success", "Copied!", "address");
                    }}
                  >
                    <a href="javacript:void(0)">
                      <img
                        src={require("../../assets/images/copy-icon.png")}
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </CopyToClipboard>
                </div>
              </>
            )}
          </>
        )}
        {isEmpty(tokenType) && (
          <>
            <div className="form-group">
              <label>Bank/Wallet</label>
              <select
                onChange={handleChangeBank}
                className="form-select"
                name="bank"
                value={bank}
              >
                <option value="">Select</option>
                {BankList &&
                  BankList.length > 0 &&
                  BankList.map((item, key) => {
                    // console.log(item, "_______________item");
                    if (item && item.Type == "bank") {
                      return (
                        <option value={item._id}>
                          {item.Type && item.Type.toUpperCase()} {item.bankName}{" "}
                          {item.accountNumber}
                        </option>
                      );
                    } else {
                      return (
                        <option value={item && item._id}>
                          {item && item.Type && item.Type.toUpperCase()}
                        </option>
                      );
                    }
                  })}
              </select>
              {/* {validateError && validateError.bank && (
            <p className="text-danger">{validateError.bank}</p>
          )} */}
            </div>
            {/* {console.log(BankDetails, "__________________BankDetails")} */}

            {BankDetails && BankDetails.Type == "bank" && (
              <div className="form-group">
                <label>Bank/Wallet Details</label>
                <div className="bankDetailBox">
                  <ul>
                    <li>
                      <span className="bdLabel">Bank Name</span>
                      <span className="bdDetails">{BankDetails.bankName}</span>
                    </li>
                    <li>
                      <span className="bdLabel">Accout Number</span>
                      <span className="bdDetails">
                        {BankDetails.accountNumber}
                      </span>
                    </li>
                    <li>
                      <span className="bdLabel">Account Holder Name</span>
                      <span className="bdDetails">
                        {BankDetails.accountholderName}
                      </span>
                    </li>
                    <li>
                      <span className="bdLabel">Account Type</span>
                      <span className="bdDetails">
                        {BankDetails.accountType}
                      </span>
                    </li>
                    <li>
                      <span className="bdLabel">IFSC Code</span>
                      <span className="bdDetails">{BankDetails.ifscCode}</span>
                    </li>
                    <li>
                      <span className="bdLabel">Bank Branch</span>
                      <span className="bdDetails">{BankDetails.branch}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {BankDetails && BankDetails.Type == "Gpay" && (
              <>
                <div className="qrCodeBox">
                  <img
                    src={
                      config.API_URL + "/images/Gpay/" + BankDetails.frontImage
                    }
                    // src={require("../../assets/images/qr-code.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                {/* <div className="copyCodeBox">
              <p>ugainindia@icici</p>
              <a href="#">
                <img
                  src={require("../../assets/images/copy-icon.png")}
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div> */}
              </>
            )}
            {BankDetails && BankDetails.Type == "upi" && (
              // <div className="copyCodeBox">
              <p>{BankDetails.upiId}</p>
              // </div>
            )}

            {BankDetails && BankDetails.Type == "paytm" && (
              <>
                <div className="qrCodeBox">
                  <img
                    src={
                      config.API_URL + "/images/Gpay/" + BankDetails.frontImage
                    }
                    // src={require("../../assets/images/qr-code.png")}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </>
            )}
          </>
        )}

        {validateError && validateError.bank && (
          <p className="text-danger">{validateError.bank}</p>
        )}
        <br />

        <div className="form-group">
          <label>Screenshot Upload</label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              name="docName"
              value={docName}
              // onChange={handleChange}
            />
            <div className="input-group-text green-bg">
              <div className="fileUploadButton">
                <img
                  src={require("../../assets/images/uploadIcon.png")}
                  className="img-fluid"
                  alt=""
                />
                <input type="file" name="docImage" onChange={handleFile} />{" "}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className={"text-success"}>
              Max. 10MB size, jpg, jpeg, png, pdf Allowed
            </label>
          </div>
          {/*<div className="form-group">
            <label>{docImage && docImage.name}</label>
          </div>*/}
          {validateError && validateError.docName && (
            <p className="text-danger">{validateError.docName}</p>
          )}

          {validateError && validateError.docImage && (
            <p className="text-danger">{validateError.docImage}</p>
          )}
        </div>
        <div className="text-danger">
          {" "}
          Note: Amount - INR (Do not Top-Up below{" "}
          {currencyData && currencyData.minimumTopUp} INR)
        </div>
        <div className="form-group mt-5">
          <button
            onClick={handleSubmit}
            className="btn btn-green-big mx-auto mb-0"
            disabled={loader}
          >
            {!loader && <i class="fas fa-spinner fa-spin"></i>}Submit
          </button>
        </div>
      </div>
    </div>
  );
}
