// import config
import axios, { handleResp } from "../config/axios";

// import constant
import { SET_DEPOSIT_VALIDATE } from "../constant";

export const UploadNonIndDepoist = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/depositnonindian`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const UploadIndDepoist = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/depositindian`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      // userToken: respData.data.userToken,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const DepositList = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/depositnonindian`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const adminbank = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/adminbank`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
