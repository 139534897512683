import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// import component
import ForgotPassword from "../components/ForgotPassword"


// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}


export default function Login() {

    return (
        <section className="crediantial-layout">
            <ScrollToTopOnMount />
            <div className="container">
                <ForgotPassword />
            </div>
        </section>

    );
}
