import { SET_DASHBOARD_BOTTYPE } from "../constant";

const initialState = {
  BotType: "",
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_BOTTYPE:
      return {
        ...action.bot,
      };
    default:
      return state;
  }
};

export default Dashboard;
