import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import $ from "jquery";

export default function NotificationSideMenu() {
  const location = useLocation();
  console.log(location.pathname, "locationlocation");
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    dropdown("#mobileMenuDrop");
    function dropdown(e) {
      var obj = $(e + ".dropdown");
      var btn = obj.find(".btn-selector");
      var dd = obj.find("ul");
      var opt = dd.find("li");
      obj
        .on("click", function () {
          dd.show();
        })
        .on("mouseleave", function () {
          dd.hide();
        });

      opt.on("click", function () {
        dd.hide();
        var txt = $(this).text();
        opt.removeClass("active");
        $(this).addClass("active");
        btn.text(txt);
      });
    }
  }

  return (
    <div className="walletSideMenu">
      <div className="d-none d-lg-block">
        <div className="walletSideMenuBottom">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/notification-system-errors" className="nav-link">
                <span className="message_icon"></span> System
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notification-trade-related" className="nav-link">
                <span className="message_icon"></span> Trade
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notification-announcement" className="nav-link">
                <span className="message_icon"></span> Announcement
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notification-tip-offs" className="nav-link">
                <span className="message_icon"></span> Tip-Offs
              </NavLink>
            </li>
            {/* <li className="nav-item">
                        <NavLink to="/notification-alerts" className="nav-link"><span className="message_icon"></span> Alerts</NavLink>
                    </li> */}
          </ul>
        </div>
      </div>
      <div className="d-block d-lg-none">
        <div id="mobileMenuDrop" class="dropdown">
          {location.pathname == "/notification-system-errors" ? (
            <Link to="" className="nav-link active">
              <span className="flexIconLeft">
                <span className="message_icon"></span> System
              </span>
            </Link>
          ) : location.pathname == "/notification-trade-related" ? (
            <Link to="" className="nav-link active">
              <span className="flexIconLeft">
                <span className="message_icon"></span> Trade
              </span>
            </Link>
          ) : location.pathname == "/notification-announcement" ? (
            <Link to="" className="nav-link active">
              <span className="flexIconLeft">
                <span className="message_icon"></span> Announcement
              </span>
            </Link>
          ) : location.pathname == "/notification-tip-offs" ? (
            <Link to="" className="nav-link active">
              <span className="flexIconLeft">
                <span className="message_icon"></span> Tip-Offs
              </span>
            </Link>
          )  : (
            <Link to="/notification-system-errors" className="nav-link">
              <span className="flexIconLeft">
                <span className="message_icon"></span> System
              </span>
            </Link>
          )}
          <ul>
            <li>
              <NavLink to="/notification-system-errors" className="nav-link">
                <span className="flexIconLeft">
                  <span className="message_icon"></span> System
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/notification-trade-related" className="nav-link">
                <span className="flexIconLeft">
                  <span className="message_icon"></span> Trade
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/notification-announcement" className="nav-link">
                <span className="flexIconLeft">
                  <span className="message_icon"></span> Announcement
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/notification-tip-offs" className="nav-link">
                <span className="flexIconLeft">
                  <span className="message_icon"></span> Tip-Offs
                </span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/notification-alerts" className="nav-link">
                <span className="flexIconLeft">
                  <span className="message_icon"></span> Alerts
                </span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
